import React, { useEffect, useState } from "react";

const App = ({spaces=4, onUpdate, textRawInputRef, value, isInvalid}) => {

    const [text, setText] = useState({value: "", caret: -1, target: null});

    useEffect(() => {
        if(text.caret >= 0){
            text.target.setSelectionRange(text.caret + spaces, text.caret + spaces);
        }
    }, [text]);

    useEffect(()=>{
        setText({value: value, caret: -1, target: null})
    },[value]);

    const handleTab =(e)=> {
        let content=e.target.value;
        let caret=e.target.selectionStart;
        if(e.key === "Tab"){
            e.preventDefault();
            let newText=content.substring(0, caret) + " ".repeat(spaces) + content.substring(caret);
            setText({value: newText, caret: caret, target: e.target});
        }

    }

    const handleText=(e)=>{
        onUpdate(e.target.value);
        setText({value: e.target.value, caret: -1, target: e.target})
    };

    return(
        <textarea
            className={isInvalid ? "invalid":""}
            spellCheck={false}
            ref={textRawInputRef}
            onChange={handleText}
            onKeyDown={handleTab}
            value={text.value}
        />
    )

}

export default App;
