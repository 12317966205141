/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CampaignTopDetails from "./CampaignDetails";
import ConnectBasicLeadSource from "./ConnectBasicLeadSource";
import ConnectAdvancedLeadSource from "./ConnectAdvancedLeadSource";
import CampaignSettingSection from "./CampaignSettingSection";
import {
  fetchCampaignDetails,
  fetchCampaignLeadSourceDetails,
  fetchLeadGroups,
  fetchLeadFlows,
  fetchCampaignContactStat,
  fetchCampaignTimelines,
  updateReducerState,
} from "../../../actions/marketingAction";
import NewLoader from "../../Common/NewLoader";
import Utils from "../../../helpers/Utils";
import { useHistory } from "react-router-dom";
import "./modify-style.css";

const App = (props) => {
  const history = useHistory();
  const campaignId = props.match.params.campaignId;

  /* for lead source collapse */
  const [isShowBasicLeadSource, setIsShowBasicLeadSource] = useState(false);
  const [isShowAdvancedLeadSource, setIsShowAdvancedLeadSource] =
    useState(false);

  const handleToggleLeadSource = (type = "basic") => {
    if (type === "basic") {
      if (isShowBasicLeadSource) {
        setIsShowAdvancedLeadSource(!isShowAdvancedLeadSource);
        setTimeout(() => {
          setIsShowBasicLeadSource(!isShowBasicLeadSource);
        }, 300);
      } else {
        setIsShowBasicLeadSource(!isShowBasicLeadSource);
        setTimeout(() => {
          setIsShowAdvancedLeadSource(!isShowAdvancedLeadSource);
        }, 300);
      }
    } else {
      if (!isShowAdvancedLeadSource) {
        setIsShowBasicLeadSource(!isShowBasicLeadSource);
        setTimeout(() => {
          setIsShowAdvancedLeadSource(!isShowAdvancedLeadSource);
        }, 300);
      } else {
        setIsShowAdvancedLeadSource(!isShowAdvancedLeadSource);
        setTimeout(() => {
          setIsShowBasicLeadSource(!isShowBasicLeadSource);
        }, 300);
      }
    }
  };

  useEffect(() => {
    document.title = `Campaign Detail | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("marketing");
    }

    if (props.campaignDetails == null) {
      loadData();
    } else {
      try {
        if (history.location.state !== undefined) {
          let state_value = history.location.state;
          if (typeof state_value === "object") {
            if (
              state_value.from !== undefined &&
              state_value.from === "direct-mail-setting-v2"
            ) {
              if (
                state_value.afterSend !== undefined &&
                state_value.afterSend
              ) {
                loadData();
              }
            }
          }
        }
        if (props.match.params.campaignId != props.campaignDetails.basic.id) {
          loadData();
        }
      } catch (error) {
        loadData();
      }
    }
    return () => {
      props.updateReducerState({
        campaignDetails: null,
        campaignDetailsLoading: true,
      });
    };
  }, []);

  useEffect(() => {
    if (props.campaignTimeline !== null) {
      setTimeout(() => {
        focusCallback();
      }, 100);
    }
  }, [props.campaignTimeline]);

  const loadData = () => {
    props.fetchCampaignDetails({
      campaignId: campaignId,
    });

    /* for campaign settings */
    props.fetchCampaignTimelines({
      campaignId: campaignId,
    });

    props.fetchCampaignLeadSourceDetails({
      campaignId: campaignId,
    });

    props.fetchLeadGroups({
      campaignId: campaignId,
    });

    props.fetchLeadFlows({
      campaignId: campaignId,
    });

    props.fetchCampaignContactStat({
      campaignId: campaignId,
    });
  };

  const focusCallback = () => {
    try {
      if (history.location.state !== undefined) {
        let state_value = history.location.state;
        if (typeof state_value === "object") {
          if (
            state_value.from !== undefined &&
            state_value.from === "campaign-list-view"
          ) {
            if (state_value.focus !== undefined && state_value.focus) {
              if (
                state_value.focus !== "timezone" &&
                state_value.focus !== "virtual_number"
              ) {
                let element = document.getElementById(
                  `campaign_setting_each_id_${state_value.focus}`
                );
                if (element) {
                  let child = element.querySelector(
                    ".campaign_setting_each_title"
                  );
                  if (child) {
                    child.style.border = "1px solid red";
                  }
                  element.scrollIntoView({ behavior: "smooth" });
                }
              } else if (state_value.focus === "timezone") {
                let element = document.getElementById(
                  `campaign_details_timezone`
                );
                if (element) {
                  element.parentNode.setAttribute(
                    "style",
                    "border: 1px solid red !important"
                  );
                  element.parentNode.style.setProperty(
                    "border",
                    "1px solid red",
                    "important"
                  );
                  element.parentNode.scrollIntoView({ behavior: "smooth" });
                }
              } else if (state_value.focus === "virtual_number") {
                let element = document.getElementById(
                  `campaign_details_virtual_number`
                );
                if (element) {
                  element.parentNode.setAttribute(
                    "style",
                    "border: 1px solid red !important"
                  );
                  element.parentNode.style.setProperty(
                    "border",
                    "1px solid red",
                    "important"
                  );
                  element.parentNode.scrollIntoView({ behavior: "smooth" });
                }
              }
            } else if (state_value.focus === "timezone") {
              let element = document.getElementById(
                `campaign_details_timezone`
              );
              if (element) {
                element.parentNode.setAttribute(
                  "style",
                  "border: 1px solid red !important"
                );
                element.parentNode.style.setProperty(
                  "border",
                  "1px solid red",
                  "important"
                );
                element.parentNode.scrollIntoView({ behavior: "smooth" });
              }
            } else if (state_value.focus === "virtual_number") {
              let element = document.getElementById(
                `campaign_details_virtual_number`
              );
              if (element) {
                element.parentNode.setAttribute(
                  "style",
                  "border: 1px solid red !important"
                );
                element.parentNode.style.setProperty(
                  "border",
                  "1px solid red",
                  "important"
                );
                element.parentNode.scrollIntoView({ behavior: "smooth" });
              }
            }
            window.history.replaceState(null, "");
          }
        }
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <div className='updated_group_leadFlow_assigned group_leadflow_assigned content_loader'>
        {props.isLoading && (
          // <div className='loader-cotainer'><Loading /></div>
          <div className='loader-cotainer parent-loader-div'>
            <NewLoader
              loading={props.isLoading}
              onlyLoader={true}
              size={34}
              message={""}
            />
          </div>
        )}

        {!props.isLoading && (
          <>
            <div className='row'>
              <div className='col s12 m12 l5 xl6'>
                <CampaignTopDetails />
              </div>
              <div className='col s12 m12 l7 xl6'>
                <ConnectBasicLeadSource
                  isShowBasicLeadSource={isShowBasicLeadSource}
                  // hanldeCollapse={() => setIsShowBasicLeadSource(!isShowBasicLeadSource)}
                  hanldeCollapse={handleToggleLeadSource}
                />
                <ConnectAdvancedLeadSource
                  isShowBasicLeadSource={isShowAdvancedLeadSource}
                  // hanldeCollapse={() => setIsShowBasicLeadSource(!isShowBasicLeadSource)}
                  hanldeCollapse={handleToggleLeadSource}
                />
              </div>
            </div>
            <CampaignSettingSection campaignId={campaignId} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignDetails: state.marketingReducer.campaignDetails,
    isLoading: state.marketingReducer.campaignDetailsLoading,
    campaignTimeline: state.marketingReducer.campaignTimeline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaignDetails: (params) => dispatch(fetchCampaignDetails(params)),
    fetchCampaignTimelines: (params) =>
      dispatch(fetchCampaignTimelines(params)),
    fetchCampaignLeadSourceDetails: (params) =>
      dispatch(fetchCampaignLeadSourceDetails(params)),
    fetchLeadGroups: (params) => dispatch(fetchLeadGroups(params)),
    fetchLeadFlows: (params) => dispatch(fetchLeadFlows(params)),
    fetchCampaignContactStat: (params) =>
      dispatch(fetchCampaignContactStat(params)),
    updateReducerState: (params) => dispatch(updateReducerState(params)),
  };
};

const CampaignDetailsPage = connect(mapStateToProps, mapDispatchToProps)(App);

export default CampaignDetailsPage;
