/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, {useState, useRef, useEffect} from 'react'
import { PlatTypeForPackage, QuickReplyType } from '../../../constants/CoreConstants';
import { IconList } from '../../../constants/IconList';
import Label from '../../../constants/Label.js';
import Messages from '../../../constants/Messages';
import Utils from '../../../helpers/Utils';
import AddDirectEmailTemplate from './AddDirectEmailTemplate';
import AddEmailTemplate from './AddEmailTemplate';
import AddSmsTemplate from './AddSmsTemplate';
import AddVideoEmailTemplate from './AddVideoEmailTemplate';
import AddVoiceTemplate from './AddVoiceTemplate';
import './quickTemplate.css';
import moment from "moment";
import OutSideConstants from '../../../constants/ConstantFromOutside';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getSingleMessageTemplate, updateMessageTemplate } from '../../../api/marketingApi';
import Loading from '../../Common/Loading';
import showNotification from "../../globals/ShowNotification";
import { connect } from 'react-redux'
import { showGlobalNotification } from '../../../actions/globalAction';
import CustomAudioPlayer from '../../globals/CustomAudioPlayer';
import RecordedVideo from '../../Video/RecordedVideo';
import { TextField } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const tabOptions = [
    {id:QuickReplyType.SMS, title: 'Text', iconActive: IconList.smsIconBlue, iconInactive: IconList.smsIconDeepBlue},
    {id:QuickReplyType.EMAIL, title: 'Email', iconActive: IconList.emailIconBlue, iconInactive: IconList.emailIconDeepBlue},
    {id:QuickReplyType.VIDEO_EMAIL, title: 'Video Email', iconActive: IconList.videoIconBlue, iconInactive: IconList.videoIconDeepBlue},
    {id:QuickReplyType.VOICE, title: 'Voice', iconActive: IconList.voiceIconBlue, iconInactive: IconList.voiceIconDeepBlue},
    {id:0, title: 'Direct Mail', iconActive: IconList.sendIconBlue, iconInactive: IconList.sendIconDeepBlue},
]

const EditTemplateApp = props => {

    const history = useHistory();

    // refs
    const titleRef = useRef(null);
    /* states variable start */
    const [title, setTitle] = useState('')
    const [tabIndex, setTabIndex] = useState(null)
    const [contentType, setContentType] = useState(QuickReplyType.SMS)
    const [textForView, setTextForView] = useState('')
    const [fileShow, setFileShow] = useState(null)
    const [showSubject, setShowSubject] = useState('')
    const [dataLoading, setDataLoading] = useState(true)

    const [submitingTemplate, setSubmitingTemplate] = useState(false)
    const {templateId} = useParams();
    const [template,setTemplate] = useState(null);
    /* states variable end */

    /* use effect start */
    useEffect(() => {
        document.title = `Quick Replies | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }

        getSingleMessageTemplate({
            id : templateId
        }).then(res => {
            if(res.data.success) {
                let temp = res.data.data.template;
                setTemplate(temp);
                setTitle(temp.title);
                setTabIndex(temp.type == QuickReplyType.MMS ? QuickReplyType.SMS : temp.type);
                setContentType(temp.type);
                setTextForView(temp.message);
                setDataLoading(false)
                setFileShow(temp.video_file_url)
            }
        });
    },[]);
    /* use effect end */
    const handlePreviewFile = (file) => {
        setFileShow(file)
    }
    /* render functinos start */
    const leftInputPortion = () => {
        return(
            <div className="add_template_content">
                            
            <h6>Title</h6>
            <div className="input-field">
                <input placeholder="Title Here" type="text" className="validate" value={title} onChange={(event) => setTitle(event.target.value)} ref={titleRef}/>                                
            </div>

            <div className="row" style={{marginTop:20}}>
                {!dataLoading &&
                <div className="tab_content">
                    {
                    tabIndex === QuickReplyType.SMS ? 
                        <AddSmsTemplate 
                            handleSaveTemplate={handleSaveTemplate}
                            handlePreview={handlePreview}
                            handlePreviewFile={handlePreviewFile}
                            handleContentType={handleContentType}
                            template={template}
                            submitingTemplate={submitingTemplate}
                        /> :
                    tabIndex === QuickReplyType.EMAIL ?
                        <AddEmailTemplate
                            handleSaveTemplate={handleSaveTemplate} 
                            handlePreview={handlePreview}
                            template={template}
                            submitingTemplate={submitingTemplate}
                            handleSubject={(value) => setShowSubject(value)}
                        /> :
                    tabIndex === QuickReplyType.VIDEO_EMAIL ?
                        <AddVideoEmailTemplate 
                            handleSaveTemplate={handleSaveTemplate} 
                            handleContentType={handleContentType}
                            template={template}
                        /> :
                    tabIndex === QuickReplyType.VOICE ?
                        <AddVoiceTemplate 
                            handleSaveTemplate={handleSaveTemplate}
                            handlePreview={handlePreview}
                            template={template}
                            handlePreviewFile={handlePreviewFile}
                            submitingTemplate={submitingTemplate}
                        /> :
                    tabIndex !== null ?
                        <AddDirectEmailTemplate handleSaveTemplate={handleSaveTemplate} submitingTemplate={submitingTemplate} editTemplate={true}
                            previousTemplate={template}
                        /> : 
                    ''
                    }
                </div>
                }
                {dataLoading &&
                    <Loading />
                }
            </div>
            
        </div>
        )
    }

    const rightViewPortion = () => {

        if(tabIndex == QuickReplyType.VOICE) {
            return <div className="add_template_display">
                <div className='m-4'>
                    <h5>Sample script</h5>
                    <TextField
                        multiline
                        rows={15}
                        placeholder={"Type your Message"}
                        variant="outlined"
                        value={textForView}
                        style={{width:"100%"}}
                        onChange={(e) => setTextForView(e.target.value)}
                    />
                </div>
            </div>
        }

        return(
            <div className="add_template_display">

                <div className="smartphone_image">
                    {/* <img src="assets/images/quick-reply-template/smartphone.png" alt="" /> */}
                    <img src={process.env.REACT_APP_PUBLIC_URL_CUSTOM+'assets/images/quick-reply-template/smartphone.png'} alt="" />
                    <div className="smartphone_display">

                        <div className="display_number">
                            {tabIndex != QuickReplyType.SMS ? 
                                <h6 className="truncate"  style={{textTransform : 'lowercase'}}>{'example_email@example.mail'}</h6>
                            :
                                <h6 className="truncate" >{Utils.formatPhoneNumber('+10175806261')}</h6>
                            }
                        </div>

                        <div className="message_box">

                            <div className="display_message">
                                <div className="message_time ">
                                    <p className="right-align">{moment(new Date()).format('YYYY-MM-DD hh:mm:ss A')}</p>
                                </div>
                                <div className="display_message_body awesome__scroll_bar">
                                    {/* <p className="left-align" dangerouslySetInnerHTML={{__html: textForView}} />
                                    {fileShow != null &&
                                    <div style={{height: 200, width: '100%', marginTop: 10}}>
                                        <img style={{height:'100%', widht:'100%'}} src={fileShow}/>
                                    </div>
                                    } */}
                                    {showSubject != '' &&
                                    <span className="messageSubject truncate">{showSubject}</span>
                                    }
                                    {tabIndex != QuickReplyType.VOICE &&
                                    <p className="left-align" dangerouslySetInnerHTML={{__html: textForView}} />
                                    }
                                    {(fileShow != null && tabIndex == QuickReplyType.SMS) &&
                                    <img style={{width: "100%"}} className="display_message_body_img" alt="" src={fileShow}/>
                                    }
                                    {(fileShow != null && tabIndex == QuickReplyType.VOICE) &&
                                    <CustomAudioPlayer
                                        hanldePlayer={() => {}}
                                        activePlayerId={fileShow}
                                        unique_key={fileShow}
                                        src={fileShow}
                                    />
                                    }
                                    {(fileShow != null && tabIndex == QuickReplyType.VIDEO_EMAIL) &&
                                    <RecordedVideo urlLink={fileShow}/>
                                    }
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>                           

            </div>
        )
    }
    /* render functinos end */

    /* others */
    const handlePreview = (text) => {
        setTextForView(text)
    }

    const handleContentType = (type) => {
        setContentType(type)
    }

    const handleBackButton = () => {
        history.push('/campaign/quick-replies');
        if(typeof props.handleBack != 'undefined'){
            props.handleBack()
        }
    }

    const handleSaveTemplate = (data) => {
        // check validation
        if(submitingTemplate)return;
        setSubmitingTemplate(true)
        if(title.trim() == ''){
            props.showNotification(Messages.emptyTitleInAddQuickReply, 'error')
            titleRef.current.focus()
            setSubmitingTemplate(false)
            return false
        }

        let formData = new FormData()
        //$packageType = ViewHelper::getPackTypeForView(auth()->id());
        let package_type = OutSideConstants.packageType
        formData.append('media_type', package_type == PlatTypeForPackage.PLAN_TYPE_STANDARD ? QuickReplyType.SMS : QuickReplyType.EMAIL)
        formData.append('content', contentType)
        formData.append('title', title)
        formData.append('id', templateId)
        if(contentType == QuickReplyType.SMS){
            // sms save
            for (let key in data) {
                formData.append(key === 'body_sms' ? 'message' : key, data[key])
            }

            formData.append('type', QuickReplyType.SMS);
        
        } else if(contentType == QuickReplyType.MMS){
            // mms save
            for (let key in data) {
                formData.append(key, data[key])
            }

            formData.append('type', QuickReplyType.MMS);

        } else if(contentType == QuickReplyType.VOICE){
            // voice save
            for (let key in data) {
                formData.append(key, data[key])
            }
            formData.append('message',textForView);
        } else if(contentType == QuickReplyType.VIDEO_EMAIL){
            // video email save
            for (let key in data) {
                formData.append(key, data[key])
            }
        } else if(contentType == QuickReplyType.EMAIL){
            // email save
            for (let key in data) {
                if(key === 'email_subject') {
                    formData.append('subject', data[key])
                } else if(key === 'body_sms') {
                    formData.append('message', data[key])
                } else if(key !== 'content') {
                    formData.append(key, data[key])
                }
            }
            formData.append('type', QuickReplyType.EMAIL);
        }
         else if(contentType == QuickReplyType.POSTCARD || contentType == QuickReplyType.LETTER ||
            contentType == QuickReplyType.GREETING_CARD || contentType == QuickReplyType.GIFT){
             
                for (let key in data) {
                    formData.append([key], data[key])

                }
        }

        updateMessageTemplate(formData).then((res) => {
            let response = res.data
            setSubmitingTemplate(false)
            if(response.status === 'success' || response.success == true){
                props.showGlobalNotification({
                    showNotification: true,
                    message:  response.html,
                    type: 'success'
                })
                // todo here
                handleBackButton()
            }
            else if(response.status === 'validation-error'){
                // do validation here
                if(typeof response.html.title != 'undefined'){
                    props.showNotification(response.html.title, 'error')
                }
            }
            else{
                props.showNotification('Something went wrong. Try again.', 'error')
            }
        });

        
    }

    return(
        <div className="main_content_container add_template_page white">
            <div className="">
                <div className="main_content_topbar d-flex justify-content-between align-items-center">
                    <h6>{Label.updateQuickReplyTemplateHeadTitle}</h6>
                    <div className="back_button">
                        <Link to={'/campaign/quick-replies?button=back'} className="accent--bg--color">{IconList.backCircleWhite}<span>Back</span></Link>                                
                    </div>
                </div>
                <div className="main_content_divider"></div>
                {/* <div className="main_content_description"> */}
                <div className={`main_content_description  ${tabIndex == QuickReplyType.VIDEO_EMAIL ? 'activeVideoEmail' : ''}`}>
                    {leftInputPortion()}
                    {rightViewPortion()}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    showGlobalNotification: (params) => dispatch(showGlobalNotification(params)),
})
const EditTemplate = connect(null, mapDispatchToProps)(showNotification(EditTemplateApp))
export default EditTemplate;
