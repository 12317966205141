import React from "react";


const RenderHelper = {
    emptyView: (emptyMessage, actionMessage) => {
        return(
            <div className="col s12 m12 l12 xl12">
                <div className="mt-0 card message-info-details b-radius-10 box-shadow-none">
                    <div className="inbox-main-section">
                        <div className="chatbox-area chatbox-empty" id="cutomScrollbar2">
                            <div className="row">
                                <div className="col s6">
                                    <div className="no-data-found">
                                        <img className=" responsive-img" src={`${process.env.REACT_APP_APP_URL}/assets/images/no-data-found.svg`}
                                            alt="No Data Found"/>
                                    </div>
                                </div>
                                <div className="col s6">
                                    <h3>{emptyMessage}</h3>
                                    <p>{actionMessage}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    },

    syntaxHighlight :  (json) => {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        // eslint-disable-next-line no-useless-escape
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
}   

export default RenderHelper;