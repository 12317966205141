import * as ACTION from "../constants/marketingTemplateActionTypes";

/* middleware */
export const getCampaignTemplateCategory = (payload, callback) => ({
    type:ACTION.GET_CAMPAIGN_TEMPLATE_CATEGORY,
    payload: {payload, callback}
})

export const getCampaignTemplateByCategory = (payload, callback) => ({
    type:ACTION.GET_CAMPAIGN_TEMPLATES,
    payload: {payload, callback}
})

export const getCampaignTemplateDetails = (payload, callback) => ({
    type:ACTION.GET_CAMPAIGN_TEMPLATE_DETAILS,
    payload: {payload, callback}
})

export const createCampaignByTemplate = (payload, callback) => ({
    type:ACTION.CREATE_CAMPAIGN_BY_TEMPLATE,
    payload: {payload, callback}
})

/* reducers */
export const _storeCampaignTemplateCategory = (payload) => ({
    type:ACTION.STORE_CAMPAIGN_TEMPLATE_CATEGORY,
    payload: payload
})

export const _storeSelectedCampaignTemplateCategory = (payload) => ({
    type:ACTION.STORE_SELECTED_CAMPAIGN_TEMPLATE_CATEGORY,
    payload: payload
})

export const _storeCampaignTemplateSearch = (payload) => ({
    type:ACTION.STORE_CAMPAIGN_TEMPLATE_SEARCH,
    payload: payload
})