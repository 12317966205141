import { useEffect, useState } from "react";
import CustomConfirmAlert from "../../components/custom-alert/CustomConfirmAlert";
import CustomCircleLoader from "../../components/CustomCircleLoader";
import { IconHandler } from "../../hanlders/IconHandler";
import { Icons } from "../../helpers/Icons";
import Link from "../../Link";
import { handleStates } from "../../reducer/Actions";
import { all_campaign_item, colors } from "../../reducer/states";

const AddFolder = ({reducer , onClose}) => {
    const [readyState, setReadyState] = useState(true);
    const [states, setStates] = useState({
        'title': '',
        'icon': 0,
        'color': '#FFFFFF',
        'text_color': '#546376',
        'campaign_count': 0,
        'description': '',
        'errorMessage': ''
    })
    useEffect(() => {
        if(reducer.state.editFolderData != null){
            setStates({...states, ...reducer.state.editFolderData})
            setReadyState(false)
        }
        else{
            setReadyState(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleTitleChange = (e) => {
        setStates({...states, title: e.target.value})
    }
    const handleDescriptionChange = (e) => {
        setStates({...states, description: e.target.value})
    }
    const handleIconChange = (index) => {
        setStates({...states, icon: index})
    }
    const handleColorChange = (item) => {
        setStates({
            ...states,
            'color': item.bg,
            'text_color': item.text
        })
    }
    const handleClose = () => {
        onClose(false)
    }
    const handleDelete = () => {
        if(reducer.state.deletingFolder){
            return
        }
        CustomConfirmAlert({
            handleConfirm : () => {
                /* call delete api */
                Link.deleteCampaignToFolder({ campaign_folder_id: reducer.state.editFolderData.id })
                .then((res)=>{
                    let response = res.data;
                    if(response.status === "success"){
                        let old = [...reducer.state.folders]
                        old.splice(parseInt(reducer.state.editFolderIndex), 1)
                        
                        if(reducer.state.selectedFolder === reducer.state.editFolderData){
                            reducer.dispatch(handleStates({deletingFolder: false, folders: old, selectedFolder: all_campaign_item, gettingList: true}))
                        }
                        else{
                            reducer.dispatch(handleStates({deletingFolder: false, folders: old}))
                        }
                        handleClose()
                        // reducer.dispatch(handleStates({refresh: true, deletingFolder: false}))
                        // handleClose()
                    }
                    else{
                        setStates({...states, errorMessage: response.message})
                        reducer.dispatch(handleStates({deletingFolder: false}))
                    }
                }).catch((err)=>{
                    reducer.dispatch(handleStates({deletingFolder: false}))
                });
            },
            handleOnClose: () => {
            },
            title: 'Are you sure?',
            description: 'Campaigns inside this folder will not be deleted.',
            icon: 'question',
            closeButtonText: 'No, Keep it.',
            confirmButtonText: "Yes, Delete it.",
            callConfirmLast: true,
            type_word: 'DELETE FOLDER',
        })
    }
    const handleSubmit = () => {
        /* TODO: check validaiton */
        if(states.title.trim() === ""){
            setStates({...states, errorMessage: 'Title can not be empty !'})
            return
        }
        setStates({...states, errorMessage: ''})
        reducer.dispatch(handleStates({submittingFolder: true}))
        if(reducer.state.editFolderData !== null){
            states['campaign_folder_id'] = reducer.state.editFolderData.id;
        }
        Link.createOrUpdateCampaignFolderApi(states)
        .then((res)=>{
            let response = res.data;
            reducer.dispatch(handleStates({submittingFolder: false}))
            if (response.status === 'success'){
                reducer.dispatch(handleStates({refresh: true}))
                handleClose()
            }
            else{
                setStates({...states, errorMessage: response.message})
            }
        }).catch((err)=>{
            reducer.dispatch(handleStates({submittingFolder: false}))
        })
    }
    const renderHeader = () => {
        return (
            <div className="add_campaign_fm_header">
                <h3 className="add_campaign_fm_title">Campaign Folder</h3>
                <span className="add_campaign_fm_icon" onClick={handleClose}>{Icons.close_icon}</span>
            </div>
        );
    }
    const renderFooter = () => {
        return (
            <div className="add_campaign_fm_footer">
                
                <div className="add_campaign_fm_footer_left">
                    {reducer.state.editFolderData != null &&
                    <div className="add_campaign_fm_delete_btn" onClick={handleDelete}>
                        {reducer.state.deletingFolder ? <CustomCircleLoader fill={'#940015'} size="extra-small" /> : Icons.c_delete}
                        Delete
                    </div>
                    }
                </div>
                <div className="add_campaign_fm_footer_right">
                    <div className="add_campaign_fm_cancel_btn" onClick={handleClose}>
                        Cancel
                    </div>
                    <div className="add_campaign_fm_add_btn" onClick={handleSubmit}>
                        {reducer.state.submittingFolder ? <CustomCircleLoader fill={'#FFF'} size="extra-small" /> : Icons.plusWhite}
                        Add Folder
                    </div>
                </div>
            </div>
        );
    }
    const renderIconList = () => {
        const iconHandler = new IconHandler(states.text_color, parseInt(states.icon), "", '24')
        let view = []
        const icons = iconHandler.allIcons;
        Object.entries(icons).forEach(([key, value], index) => {
            if(index < 19){
                let title = ""
                let svg_element = (value);
                if(svg_element && svg_element?.props?.title !== undefined){
                    title = svg_element?.props?.title;
                }
                if(svg_element?.props?.title !== ""){
                    view.push(
                        <Link.TOOL_TIP title={title} arrow placement="top">
                            <span 
                                className={`cfamw_b_icpw_iw_icons_item ${states.icon === index ? 'active_selectd_icon' : ''}`} 
                                key={index} 
                                onClick={() => handleIconChange(index)}
                            >
                                {value}
                            </span>
                        </Link.TOOL_TIP>
                    )
                }else{
                    view.push(
                        <span 
                            className={`cfamw_b_icpw_iw_icons_item ${states.icon === index ? 'active_selectd_icon' : ''}`} 
                            key={index} 
                            onClick={() => handleIconChange(index)}
                        >
                            {value}
                        </span>
                    )
                }
            }
        });
        return view;
    }
    const renderIcon = () => {
        return (
            <div className="add_campaign_fm_ic_left">
                <span className="add_campaign_fm_ic_title">Icons</span>
                <div className="add_campaign_fm_ic_left_inner">
                    {renderIconList()}
                </div>
            </div>
        );
    }
    const renderColors = () => {
        let view = [];
        colors.forEach((item, index) => {
            view.push(
                <div 
                    className="add_campaign_fm_single_color"
                    style={{backgroundColor: item.bg}}
                    onClick={() => handleColorChange(item)}
                    key={index}
                >   
                    <span className={`${index === 6 ? 'acfsc_white' : ''}`}>{states.color === item.bg ? Icons.c_check : ''}</span>
                </div>
            )
        });
        return view;
    }
    const renderColor = () => {
        return (
            <div className="add_campaign_fm_ic_right">
                <span className="add_campaign_fm_ic_title">Text colors</span>
                <div className="add_campaign_fm_ic_right_inner">
                    {renderColors()}
                </div>
            </div>
        );
    }
    const renderSkeleton = () => {
        return (
            <div className="acfmv">
                <span>Please wait...</span>
            </div>
        );
    }
    const renderMain = () => {
        return (
            <>
            {renderHeader()}
            <div className="add_campaign_fm_body awesome__scroll_bar">
                <div className="add_campaign_fm_body_form_group">
                    <h5 className="add_campaign_fm_body_form_group_label">Folder Name</h5>
                    <input placeholder="Folder name" defaultValue={states.title}  className="add_campaign_fm_body_from_group_input" onChange={handleTitleChange} />
                </div>
                <div className="add_campaign_fm_body_form_group">
                    <h5 className="add_campaign_fm_body_form_group_label">Description</h5>
                    <textarea placeholder="Write description" className="add_campaign_fm_body_from_group_textarea" defaultValue={states.description} onChange={handleDescriptionChange} />
                </div>
                <div className="add_campaign_fm_body_ic">
                    {renderIcon()}
                    {renderColor()}
                </div>
                
                <div className="add_campaign_fm_body_ic_error_msg">{states.errorMessage}</div>
            </div>
            {renderFooter()}
            </>
        );
    }
    const renderView = () => {
        if(readyState){
            return renderSkeleton();
        }
        return renderMain();
    }
    return (
        <div className="add_campaign_folder_modal_v2">
            {renderView()}
        </div>
    );
}
export default AddFolder;