export const ArrowDownIcon = ({width = '24px', height = '24px', fill= '#949DB2'}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill={fill}/>
        </svg>
    )
}

export const CircleRemoveIcon = ({width = '24px', height = '24px', fill= '#940015'}) => {
    return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 13H5V11H19V13Z" fill={fill}/>
      </svg>
    )
}

export const CircleAddMoreIcon = ({width = '16px', height = '16px', fill= '#002E75'}) => {
    return (
      <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00004 1.33325C4.32004 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00004 1.33325ZM11.3334 8.66659H8.66671V11.3333H7.33337V8.66659H4.66671V7.33325H7.33337V4.66659H8.66671V7.33325H11.3334V8.66659Z" fill={fill} />
      </svg>
    )
}

export const CollapsedArrowIcon = ({width = '26px', height = '26px', fill= '#006DF5'}) => {
    return (
      <svg width={width} height={height} viewBox="0 0 26 26" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="26" rx="13" fill="#006DF5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 20C19.4477 20 19 19.5523 19 19L19 7C19 6.44771 19.4477 6 20 6C20.5523 6 21 6.44771 21 7L21 19C21 19.5523 20.5523 20 20 20ZM12.2929 16.7071C11.9024 16.3166 11.9024 15.6834 12.2929 15.2929L13.5858 14L6 14C5.44772 14 5 13.5523 5 13C5 12.4477 5.44771 12 6 12L13.5858 12L12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289L16.7071 12.2929C16.8946 12.4804 17 12.7348 17 13C17 13.2652 16.8946 13.5196 16.7071 13.7071L13.7071 16.7071C13.3166 17.0976 12.6834 17.0976 12.2929 16.7071Z" fill="white"/>
      </svg>
    )
}

export const CircleAddIcon = ({width = '24px', height = '24px', fill= '#006DF5'}) => {
    return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="white"/>
      </svg>
    )
}

export const RadioUncheckIcon = ({width = '16px', height = '16px', fill= '#949DB2'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill={fill} />
    </svg>
  )
}

export const RadioCheckIcon = ({width = '16px', height = '16px', fill= '#6258FF'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill={fill}/>
    </svg>
  )
}
