import React from "react";
import {bodyType, tab} from "../../constants";
import {RadioCheckIcon, RadioUncheckIcon} from "../../icons";
import FormDataTab from "./FormDataTab";
import RawDataTab from "./RawDataTab";
import {useSWHKContext} from "../../reducers/SWHKProvider";
import {handleState} from "../../reducers/Actions";
import Styles from "./Styles.module.css";

const App = ({callback}) => {
    const {state, dispatch} = useSWHKContext();

    const handleChangeBodyTab = (bodyTabVal, bodyTypeVal=bodyType.NONE) => {
        dispatch(handleState({
            currentBodyTab: bodyTabVal,
            bodyType: bodyTypeVal
        }));
        callback({
            bodyType: bodyTypeVal
        });
    };

    return(
          <div className={Styles.wbh_payload_body}>
            <ul className={Styles.wbh_payload_body__type_list}>
              <li className={Styles.wbh_payload_body__type_item} onClick={()=>{handleChangeBodyTab(tab.NONE_TAB)}}>{state.currentBodyTab === tab.NONE_TAB ? <RadioCheckIcon /> : <RadioUncheckIcon />} None</li>
              <li className={Styles.wbh_payload_body__type_item} onClick={()=>{handleChangeBodyTab(tab.FORM_DATA_TAB, bodyType.FORM_DATA)}}>{state.currentBodyTab === tab.FORM_DATA_TAB ? <RadioCheckIcon /> : <RadioUncheckIcon />} Form Data</li>
              <li className={Styles.wbh_payload_body__type_item} onClick={()=>{handleChangeBodyTab(tab.RAW_TAB, bodyType.TEXT)}}>{(state.currentBodyTab === tab.RAW_TAB && state.bodyType === bodyType.TEXT) ? <RadioCheckIcon /> : <RadioUncheckIcon />} Text </li>
              <li className={Styles.wbh_payload_body__type_item} onClick={()=>{handleChangeBodyTab(tab.RAW_TAB, bodyType.JSON)}}>{(state.currentBodyTab === tab.RAW_TAB && state.bodyType === bodyType.JSON) ? <RadioCheckIcon /> : <RadioUncheckIcon />} JSON </li>
              <li className={Styles.wbh_payload_body__type_item} onClick={()=>{handleChangeBodyTab(tab.RAW_TAB, bodyType.XML)}}>{(state.currentBodyTab === tab.RAW_TAB && state.bodyType === bodyType.XML) ? <RadioCheckIcon /> : <RadioUncheckIcon />} XML </li>
            </ul>

              {
                  state.currentBodyTab === tab.NONE_TAB && (
                      <div className={Styles.wbh_payload_body_none}>
                          <p>This request does not have a body.</p>
                      </div>
                  )
              }

              {
                  state.currentBodyTab === tab.FORM_DATA_TAB && (
                      <FormDataTab callback={callback}/>
                  )
              }

              {
                  state.currentBodyTab === tab.RAW_TAB && (
                      <RawDataTab callback={callback}/>
                  )
              }
          </div>
    );
};

export default App;