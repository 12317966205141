import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getCampaignTemplateCategoryApi = async (payload) => {
    return sendRequest("POST", Api.getCampaginTemplateCategory, payload);
}

export const getCampaignTemplateByCategoryApi = async (payload) => {
    return sendRequest("POST", Api.getCampaignTemplateByCategory, payload);
}

export const getCampaignTemplateDetailsApi = async (payload) => {
    return sendRequest("POST", Api.getCampaignTemplateDetails, payload);
}

export const createCampaignByTemplateApi = async (payload) => {
    return sendRequest("POST", Api.createCampaignFromTemplate, payload);
}