/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import { connect } from 'react-redux'
import { shareCampaign } from "../../../actions/marketingAction";
import { generateShareLink } from "../../../api/marketingApi";
import { IconList } from "../../../constants/IconList";
import Utils from "../../../helpers/Utils";
import Loading from "../../Common/Loading";
import GlobalModal from "../../globals/Modal/GlobalModal";
// import '../marketing.css';

const App = props => {

    const copyTextRef = useRef(null)

    const [copyText, setCopyText] = useState('Copy');
    const [shareData, setShareData] = useState({
        friendsEmail: '',
        shareableLink: '',
        message: '',
        campaignId: ''
    });
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [processingShareCampaign, setProcessingShareCampaign] = useState(false)

    useEffect(()=>{
        setShareData({...shareData, campaignId:  props.campaign.id});
        generateShareLink({campaignId : props.campaign.id}).then(res => {
            setShareData(sharedState => ({...sharedState,shareableLink:res.data.link}));
        });
    }, [])

    const handleCopyButton = () => {
        // let copyText = document.getElementById("copy-each-campaign-"+props.campaign.id);
        let copyText = copyTextRef.current;
        var textarea = document.createElement('textarea');
        textarea.textContent = copyText.value;
        textarea.id = "copy-text-id"
        document.body.appendChild(textarea);
      
        var selection = document.getSelection();
        var range = document.createRange();
        range.selectNode(textarea);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        document.body.removeChild(textarea);
        
        setCopyText('Copied')
        setTimeout(() => {
            setCopyText('Copy')
        }, 3000);
    }

    const handleInput = (element, field) => {
        let value = element.target.value
        shareData[field] = value
    }

    const handleOnBlur = (value) => {
        if(value != '' && !Utils.validateEmail(value)){
            setInvalidEmail(true)
            document.getElementById("share-each-campaign-email-"+props.campaign.id).focus()
        }
        else{
            setInvalidEmail(false)
        }
    }

    const shareLinkWithFriend = () => {
        if(processingShareCampaign){
          window.showNotification("WARNING", "A process is running!")
            return false
        }
        setProcessingShareCampaign(true)
        props.shareCampaign(shareData, (response) => {
            
            if(response.status == 'success'){
              window.showNotification("SUCCESS", response.html)
                props.handleSharePopUpModal(false)
            } else {
              window.showNotification("ERROR", response.html)
            }
            setProcessingShareCampaign(false)
        })
    }
    
    return (
        <GlobalModal 
            open={props.open} 
            onClose={props.onClose}
            title="Follow the steps below to share your campaign." 
            hideFooter={true}
            className={'global-medium-modal'}
            
        >
            <div className="share_form">
                <div className="option_1 share_option">
                    <div className="form-group">
                        <label for="Option-1">Option 1 : Share the link below with another user</label>
                        <input type="text" ref={copyTextRef} className="validate" id={`copy-each-campaign-${props.campaign.id}`} value={shareData.shareableLink} readonly disabled />
                    </div>
                    <a onClick={handleCopyButton} style={{display: 'flex',cursor:'pointer'}}>
                        <span>{IconList.copyIcon}</span>{copyText}
                    </a>
                </div>
                <div className="option_1 option_2">
                    <div className="form-group">
                        <label for="option-2">Option 2 : Share your campaign via email. Add the recipients email address below.</label>
                        {invalidEmail &&
                            <span>Email not valid! Please provide a valid email.</span>
                        }
                        <input type="email" id={`share-each-campaign-email-${props.campaign.id}`} className="validate" placeholder="Email"  onChange={(e) => handleInput(e, 'friendsEmail')} onBlur={() => handleOnBlur(shareData.friendsEmail)} />
                        <textarea className="materialize-textarea" id={`share-each-campaign-message-${props.campaign.id}`}  placeholder="Type a message to include with your email here (optional)" onChange={(e) => handleInput(e, 'message')}></textarea>
                    </div>
                    <a onClick={shareLinkWithFriend} className="cursor-pointer">
                        {processingShareCampaign &&
                            <Loading />
                        }
                        {!processingShareCampaign &&
                        <>
                        <span>{IconList.sendIcon}</span>
                        Send
                        </>
                        }
                    </a>
                </div>
            </div>
        </GlobalModal>
    );
}

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {
    return {
        shareCampaign: (params, callback) => dispatch(shareCampaign(params, callback))
    };
}

const ShareCampaign = connect(mapStateToProps, mapDispatchToProps)(App);
export default ShareCampaign;
