import * as ACTION from "../constants/marketingActionTypes";

export const updateReducerState = payload => ({
    type: ACTION.UPDATE_STATE_FIELD,
    payload: payload
});

export const fetchCampaign = (payload, callback) => ({
    type: ACTION.FETCH_CAMPAIGNS,
    payload: {payload, callback}
});

export const fetchCampaignDetails = payload => ({
    type: ACTION.FETCH_CAMPAIGN_DETAILS,
    payload: payload
});

export const fetchCampaignTimelines = payload => ({
    type: ACTION.FETCH_CAMPAIGN_TIMELINE,
    payload: payload
});

export const successFetchCampaign = payload => ({
    type: ACTION.SUCCESS_FETCH_CAMPAIGNS,
    payload: payload
});

export const failedFetchCampaign = payload => ({
    type: ACTION.FAILED_FETCH_CAMPAIGNS,
    payload: payload
});

export const addSingleContactToCampaign = (payload, callback) => ({
    type: ACTION.STORE_SINGLE_CONTACT,
    payload: {payload, callback}
});


export const getCustomFieldData = (payload) => ({
    type: ACTION.FETCH_CUSTOM_FIELD,
    payload: payload
});

export const getCustomFieldDataNew = (payload) => ({
    type: ACTION.FETCH_CUSTOM_FIELD_NEW,
    payload: payload
});

export const getUserInfo = () => ({
    type: ACTION.FETCH_USER_INFO,
    payload: {}
});

export const fetchLeadGroups = payload => ({
    type: ACTION.FETCH_LEAD_GROUPS,
    payload: payload
});

export const fetchLeadFlows = payload => ({
    type: ACTION.FETCH_LEAD_FLOWS,
    payload: payload
});

export const fetchCampaignContactStat = payload => ({
    type: ACTION.FETCH_CAMPAIGN_CONTACT_STAT,
    payload: payload
});

export const fetchCampaignLeadSourceDetails = payload => ({
    type: ACTION.FETCH_CAMPAIGN_LEAD_SOURCE_DETAILS,
    payload: payload
});

export const updateCampaignDetails = payload => ({
    type: ACTION.UPDATE_CAMPAIGN_DETAILS,
    payload: payload
});

export const updateCampaignDetailsAction = payload => ({
    type: ACTION.UPDATE_CAMPAIGN_DETAILS,
    payload: payload
});

export const followupSave = (payload, callback) => ({
    type: ACTION.SAVE_FOLLOWUP,
    payload: {payload, callback}
});

export const updateEmailStepSetting = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_EMAIL_STEP_SETTING,
    payload: {payload, callback}
});

export const updateVideoEmailStepSetting = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_VIDEO_EMAIL_STEP_SETTING,
    payload: {payload, callback}
});

export const updateSmsStepSetting = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_SMS_STEP_SETTING,
    payload: {payload, callback}
});


export const updateCallBridgeStepSetting = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_CALL_BRIDGE_STEP_SETTING,
    payload: {payload, callback}
})

export const followupActionSave = (payload, callback) => ({
    type: ACTION.SAVE_ACTION_FOLLOWUP,
    payload: {payload, callback}
});

export const followupActivitySave = (payload, callback) => ({
    type: ACTION.SAVE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
});

// share campaign
export const shareCampaign = (payload, callback) => ({
    type: ACTION.SHARE_CAMPAIGN,
    payload: {payload, callback}
});

// delete campaign
export const deleteCampaign = (payload, callback) => ({
    type: ACTION.DELETE_CAMPAIGN,
    payload: {payload, callback}
});

/* Time schedule settings */
export const updateTabValue = payload => ({
    type:ACTION.UPDATE_TAB_VALUE,
    payload: payload
});
export const updateHour = payload => ({
    type:ACTION.UPDATE_HOUR,
    payload: payload
});
export const updateMin = payload => ({
    type:ACTION.UPDATE_MIN,
    payload: payload
});
export const updateDay = payload => ({
    type:ACTION.UPDATE_DAY,
    payload: payload
});
export const updateRecurringDay = payload => ({
    type:ACTION.UPDATE_RECURRING_DAY,
    payload: payload
});
export const updateLaterHour = payload => ({
    type:ACTION.UPDATE_LATER_HOUR,
    payload: payload
});
export const updateLaterMin = payload => ({
    type:ACTION.UPDATE_LATER_MIN,
    payload: payload
});
export const updateTime = payload => ({
    type:ACTION.UPDATE_TIME,
    payload: payload
});
export const updateStartDate = payload => ({
    type:ACTION.UPDATE_START_DATE,
    payload: payload
});
export const updateArrSlotTimeHour1 = payload => ({
    type:ACTION.UPDATE_ARR_SLOT_TIME_HOUR1,
    payload: payload
});
export const updateArrSlotTimeMin1 = payload => ({
    type:ACTION.UPDATE_ARR_SLOT_TIME_MIN1,
    payload: payload
});
export const updateGreetingOption = payload => ({
    type:ACTION.UPDATE_GREETING_OPTION,
    payload: payload
});
export const updateGreetingsOptionTimeMin1 = payload => ({
    type:ACTION.UPDATE_GREETING_OPTION_TIME_MIN1,
    payload: payload
});
export const updateGreetingsOptionTimeHour1 = payload => ({
    type:ACTION.UPDATE_GREETING_OPTION_TIME_HOUR1,
    payload: payload
});
export const updateRecurringTimeHour1 = payload => ({
    type:ACTION.UPDATE_RECURRING_TIME_HOUR1,
    payload: payload
});
export const updateRecurringTimeMin1 = payload => ({
    type:ACTION.UPDATE_RECURRING_TIME_MIN1,
    payload: payload
});

export const fetchPreRecording = () => ({
    type:ACTION.FETCH_PRE_RECORDING,
    payload: {}
});

export const addCampaignStepSetting = (payload, callback) => ({
    type:ACTION.STORE_CAMPAIGN_STEP_SETTING,
    payload: {payload, callback}
})

export const addCampaignStepSettingPreTemp = (payload, callback) => ({
    type:ACTION.STORE_CAMPAIGN_STEP_SETTING_PRE_TEMP,
    payload: {payload, callback}
})

export const updateCampaignSetting = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_STEP_SETTING,
    payload: {payload, callback}
})

export const updateCampaignActivity = (payload, callback) => ({
    type: ACTION.UPDATE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
})

export const updateCampaignSettingWithPreviousFile = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_STEP_SETTING_WITH_PREVIOUS_FILE,
    payload: {payload, callback}
})

export const updateCampaignSettingWithPreTemp = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_STEP_SETTING_WITH_PRE_TEMP,
    payload: {payload, callback}
})

export const updateCampaignSettingWithMultimedia = (payload, callback) => ({
    type: ACTION.UPDATE_CAMPAIGN_STEP_SETTING_WITH_MULTIMEDIA,
    payload: {payload, callback}
})

export const getVideoFolders = () => ({
    type:ACTION.FETCH_VIDEO_FOLDERS,
    payload: {}
});
export const getVideos = payload => ({
    type:ACTION.FETCH_VIDEOS,
    payload: payload
});
export const deleteVideo = (payload, callback)=> ({
    type:ACTION.DELETE_VIDEOS,
    payload: {payload, callback}
});
export const updateFolderId = payload => ({
    type:ACTION.UPDATE_FOLDER_ID,
    payload: payload
});
export const getMessageTemplates = payload => ({
    type:ACTION.FETCH_MESSAGE_TEMPLATES,
    payload: payload
});

export const deleteMessageTemplate = (payload, callback)=> ({
    type:ACTION.DELETE_MESSAGE_TEMPLATE,
    payload: {payload, callback}
});

export const deleteCampaignSetting = (payload, callback)=> ({
    type:ACTION.DELETE_CAMPAIGN_SETTING,
    payload: {payload, callback}
});

export const getCategoryList = (payload, callback) =>({
    type: ACTION.GET_MAIL_CATEGORY,
    payload: {payload, callback}
})

export const CreateCardTemplate = (payload,callback)=>({
    type: ACTION.CREATE_POSTCARD_TEMPLATE,
    payload: {payload, callback}
})

export const fetchContactForDataTable = (payload, callback) => ({
    type: ACTION.FETCH_CONTACT_LIST_DATATABLE,
    payload: {payload, callback}
})

export const moveContactToCampaign = (payload, callback) => ({
    type: ACTION.MOVE_CONTACT_TO_CAMPAIGN,
    payload: {payload, callback}
})

export const storeActiveAudioPlayer = (payload) => ({
    type: ACTION.STORE_AUDIO_PLAYER,
    payload: {payload}
})

export const copyCampaign = (payload, callback) => ({
    type: ACTION.COPY_CAMPAIGN,
    payload: {payload, callback}
})

export const campaignEnableToStart = (payload, callback) => ({
    type: ACTION.CAMPAIGN_ENABLE_TO_START,
    payload: {payload, callback}
})

export const getCampaignBasicInfo = (payload, callback) => ({
    type: ACTION.GET_CAMPAIGN_BASIC_INFO,
    payload: {payload, callback}
})

export const campaignCheckToStart = (payload, callback) => ({
    type: ACTION.CAMPAIGN_CHECK_TO_START,
    payload: {payload, callback}
})

export const campaignStart = (payload, callback) => ({
    type: ACTION.CAMPAIGN_START,
    payload: {payload, callback}
})

export const campaignPause = (payload, callback) => ({
    type: ACTION.CAMPAIGN_PAUSE,
    payload: {payload, callback}
})

export const addQuickReplyTemplate = (payload, callback) => ({
    type: ACTION.ADD_QUICK_REPLY_TEMPLATE,
    payload: {payload, callback}
})

export const createNewCampaign = (payload, callback) => ({
    type: ACTION.CREATE_NEW_CAMPAIGN,
    payload: {payload, callback}
})

export const fetchLeadFlow = (payload, callback) => ({
    type: ACTION.FETCH_LEADFLOW,
    payload: {payload, callback}
});

export const reloadCampaignSettingsStatus = (payload) => ({
    type: ACTION.RELOAD_CAMPAIGN_SETTINGS,
    payload: payload
});

/* for fetching quick replies on scroll */
export const storeCurrentPageAction = (payload) => ({
    type: ACTION.STORE_CURRENT_PAGE,
    payload: payload
});

export const storePerPageAction = (payload) => ({
    type: ACTION.STORE_PER_PAGE,
    payload: payload
});

export const updateLoadMoreFlagAction = (payload) => ({
    type: ACTION.STORE_NEED_TO_FETCH_FLAG,
    payload: payload
});

export const updateFetchingMoreFlagAction = (payload) => ({
    type: ACTION.STORE_FETCHING_MORE_FLAG,
    payload: payload
});

export const updateMessageTemplateData = payload => ({
    type:ACTION.STORE_MESSAGE_TEMPLATE,
    payload: payload
});