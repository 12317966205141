import { Icon } from '@material-ui/core';
import { Icons } from '../../helpers/Icons';
import './style.css'
const NewCustomAlert = () => {
    return (
                // new_custom_alert_warning_v3 ,info,delete,success
        <div className='new_custom_alert_v3 new_custom_alert_warning_v3'>
           <div className='new_cav3_top'>
                <span>{Icons.close_icon}</span>
           </div>
           <div className='new_cav3_body'>
                <div className='new_cav3_body_top'>
                    <span className='new_cav3_top_icon'>{Icons.alt_delete_Icon}</span>
                    <h4 className='new_cav3_top_title'>Delete Form</h4>
                    <h5 className='new_cav3_top_subtitle'>Are you sure, want to delete this Form?</h5>
                </div>
                <div className='new_cav3_body_delete_section'>
                    <span className='new_cav3_bdi_icon'>{Icons.alt_warning_icon}</span>
                    <div className='new_cav3_bdi_content'>
                        <h5 className='new_cav3_bdi_content_title'>Warning</h5>
                        <p className='new_cav3_bdi_content_p'>All your submissions & reports data to this form will be gone forever. This operation cannot be undone.</p>
                    </div>
                </div>
                <div className='new_cav3_input_group'>
                    <label className='new_cav3_input_label'>Name <span>*</span></label>
                    <input className='new_cav3_input' placeholder='Mr.'/>
                    <p className='new_cav3_input_error'>error text</p>
                </div>
                <div className='new_cav3_label_group'>
                  <span className='new_cav3_label_group_span'> {Icons.alt_uncheck_icon}
                    label</span>
                </div>
                
                
           </div>
           <div className='new_cav3_footer'>
                <div className='new_cav3_footer_no_btn'>
                    no, Keep it
                </div>
                <div className='new_cav3_footer_yes_btn'>
                    Yes, Delete Forever
                </div>
           </div>
        </div>
    );
}
export default NewCustomAlert;


