import React from "react";
import {SWHKProvider} from "./reducers/SWHKProvider";
import Preview from "./components/Preview";
import PayloadHandler from "./components/PayloadHandler";

const App = ({ callback, data, errors }) => {
    return(
          <SWHKProvider>
              <PayloadHandler callback={callback} data={data} errors={errors}/>
              <Preview callback={callback} data={data}/>
          </SWHKProvider>
    );
};

export default App;