/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { deleteContactFromCampaignV2Api, getCampaignStatsContactApi } from "../../../api/marketingApi";
import { IconList } from "../../../constants/IconList";
import Helper from "../../../helpers/Helper";
import Utils from "../../../helpers/Utils";
import { GlobalTable } from "../../globals/GlobalTable";
//moved to CDN
// import '../marketing.css';
import './campaign-stats.css';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import { confirmAlert } from 'react-confirm-alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ContactTimeline from "./ContactTimeline";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { HistoryProvider } from "../../../App";
import GlobalSearchBox from "../../globals/GlobalSearchBox";
import useDelayCallback from "../../../hooks/useDelayCallback";


const CampaignStats = (props) => {
    const history = useContext(HistoryProvider);
    const [states, setStates] = useState({
        contacts: [],
        totalContacts: 0,
        page: 1,
        perPage: 10,
        totalPages: 0,
        fetching: false,
        searchText: '',
        firstLanding: true,
        refresh: false
    })
    // const [campaignId, setCampaignId] = useState(props.match.params.campaignId)
    const [campaignId, setCampaignId] = useState(null)
    const [tagId, setTagId] = useState(null)
    const [showTimeline, setShowTimeline] = useState(false)
    const [selectedContact, setSelectedContact] = useState(null)
    const [paginationChange, setPaginationChange] = useState(false);
    
    const renderBreadcrumb = () => {
        return (
        <div className="campaign_stats_bradcumb_container">
            <ul className="campaign_stats_bradcumb_list">
            <li
                className="campaign_stats_bradcumb_each"
                onClick={handleBradcumbClick}
            >
                Campaigns
            </li>

            <li className="campaign_stats_bradcumb_each">
                <NavigateNextIcon fontSize="small" />
            </li>
            <li className="campaign_stats_bradcumb_each">
                Campaign stats
            </li>
            </ul>
        </div>
        );
    };

    const handleBradcumbClick = () => {
        history.push("/campaign");
    };


    const HeaderRight = (props) =>{
        return (
            <>
                <div className="campaign_stats_lists">
                    <div className="campaign_stats_lists_heading_alt">
                        <div className="campaign_stats_lists_heading_bread__alt">
                            <h3>Campaign Stats</h3>
                            {renderBreadcrumb()}
                        </div>
                        {history.location.state !== undefined &&
                        history.location.state.from === "campaignPage" && (
                            <div
                                className="accent--bg--text--color inactive-contact-list-back-button"
                                onClick={(e) => history.push("/campaign")}
                            >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path>
                            </svg>
                            <span>Back</span>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }

    const HeaderBody = (props) => {
        return (
            <div className="main_content_topbar">
                <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                    <HeaderRight onClick={props.onClick}/>
                    <div>
                        <div onClick={ props.onClick } className="main_content_container_button white-text d-flex justify-content-center align-items-center import_file_list_back_button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="main_content_container_button_icon">
                                <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z" fill="white"/>
                                </svg>
                            <span>Back</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if(props.match.params.campaignId !== campaignId){
            setCampaignId(props.match.params.campaignId)
            setTagId(props.match.params.tagId)
            getCampaignContacts(props.match.params.campaignId, props.match.params.tagId);
        }
    }, [])

    useEffect(() => {
        if(!states.firstLanding){
            getCampaignContacts(campaignId, tagId)
        }
    }, [])

    useEffect(() => {
        if(!states.firstLanding && states.refresh){
            getCampaignContacts(campaignId, tagId)
        }
    }, [states.refresh])

    useDelayCallback(() => {
        if(states.page !== "" && parseInt(states.page) !== 0 && paginationChange){
            // if(parseInt(states.totalPages) >= parseInt(states.page)) {
            //     getCampaignContacts(campaignId, tagId)
            // }
            getCampaignContacts(campaignId, tagId)
        }
    }, [states.page,states.searchText]);

    const getCampaignContacts = (campaignId, tagId) => {
        if(states.fetching){
            return;
        }
        setStates({...states, fetching: true})
        getCampaignStatsContactApi({
            campaignId: campaignId,
            tagId: tagId,
            page: states.page,
            perPage: states.perPage,
            searchText: states.searchText
        }).then(res => {
            let response = res.data;
            setStates({
                ...states, 
                contacts: response.data,
                totalContacts: response.total,
                totalPages: response.last_page,
                fetching: false,
                refresh: false,
                firstLanding: false
            })
        }).catch(error => {
            if( window.showNotification !== undefined)
                window.showNotification("ERROR", "Something went wrong")
            setStates({...states, fetching: false})
        })
    }
    const renderPagination = () => {
    
        let previous_page = null, next_page = null; 
        if(states.page > 1){
            previous_page = states.page - 1
        }
    
        if(states.page != states.totalPages){
            next_page = states.page + 1
        }
    
        if(states.totalContacts == 0){
            return false;
        }
    
        return(
            <div className="campaign__statictics__pagination_bar">
                <ul className="campaign__statictics__pagination_ul">
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event,previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.previous}
                        </a>
                    </li>

                    <li className="active">
                        <div className="campaign-pagination-text" >
                        <input
                            className="campaign-pagination-no"
                            type="text" 
                            placeholder="Search Campaign Page"
                            value={states.page}
                            onChange={(event)=> handleChangeSearchPage(event.target.value)}
                        />               
                        </div>
                    </li>

                    <li>

                        <div className="pagination__divider__v3">/</div>
                    </li>
                    <li>
                        <div  className="pagination__total__v3 d-flex align-items-center"> 
                        {states.totalPages}
                        </div>
                    </li>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, next_page)}
                            style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.next}	
                        </a>
                    </li>
                    <li style={{color: '#9daac1', marginLeft: 20}}>Total leads : {states.totalContacts}</li>
                </ul>
            </div>
        )
    }

    const handleDeleteContact = (contactId) => {
        // let formData = {
        //     contactId: contactId, 
        //     tagId: tagId, 
        //     campaignId: campaignId
        // }
        let formData = {
            contact_id: contactId, 
            campaign_id: campaignId
        }
        deleteContactFromCampaignV2Api(formData).then(res => {
            let response = res.data;
            if (response.status === 'success') {
                if(window.showNotification){
                    window.showNotification("SUCCESS", response.html);
                }
                setStates({...states, page: 1, refresh: true})
            } else if (response.status == 'error') {
                if(window.showNotification){
                    window.showNotification("ERROR", response.html);
                }
            }
        })
    };

    const handleDeleteConfirm = (contactId) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to unsubscribe this contact from this campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteContact(contactId)
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const onChangePage = (event, page) => {
        event.preventDefault()
        if(page != null){
            setStates({...states, page: page})
            setPaginationChange(true)
        }
    }

    const handleChangeSearchPage = (pageNo) => {
        let newPage = parseInt(pageNo)
        if (!Utils.checkLetterExistInText(newPage) || newPage === ""){
            setPaginationChange(true);
            setStates({...states, page: newPage})  
        }
    }

    const handleView = (contactId) => {
        if(contactId === null){
            setShowTimeline(false)
            setSelectedContact(null)
        }  
        else{
            setShowTimeline(true)
            setSelectedContact(contactId)
        }
    }

    const columns = [
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Contact</span>,
            selector: row => row.contacts['first_name'],
            sortable: false,
            cell: row => {
                let name = ''
                if(Helper.isvalidText(row.contacts.first_name) && Helper.isvalidText(row.contacts.last_name)){
                    name = row.contacts.first_name + ' ' + row.contacts.last_name
                }
                else if(Helper.isvalidText(row.contacts.first_name)){
                    name = row.contacts.first_name
                }
                else if(Helper.isvalidText(row.contacts.last_name)){
                    name = row.contacts.last_name
                }
                else if(row.email !== ''){
                    name = row.contacts.email
                }
                else{
                    name = Utils.formatPhoneNumber(row.contacts['number'])
                }
                return name;
            },
            // minWidth:'250px',
            // grow: 2
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Start Date</span>,
            selector: row => row['tag_managements'],
            cell: row => {
                if(row.tag_managements !== null && row.tag_managements.created_at !== undefined) {
                    return window.globalTimezoneConversionToDifferentTimezone(row.tag_managements.created_at, 'UTC', row.campaigns.timezone, 'MM/DD/yyyy hh:mm:ss A')
                    // return ViewHelper::getFormattedTimezoneDateTime($contactData->tag_managements->created_at, $contactData->campaigns->timezone);
                }
                return '-----';
            },
            sortable: false,
            // minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Status</span>,
            selector: row => row['status'],
            cell: row => {
                if( row.status == 6){
                    return <Chip label="Finished" />
                    // return <label className="btn btn-sm btn-success">Finished</label>;
                }else if(row.campaign_contact_states && row.campaign_contact_states?.is_unsubscribed == 1){
                    return <Chip label="Unsubscribed" />
                    // return <label className="btn btn-sm btn-success">Unsubscribed <i className="fa fa-info-circle" data-toggle="tooltip" title="Due to contact response"></i></label>;
                }else if(row.tag_managements && row.tag_managements?.campaign_run == 1) {
                    return <Chip label="Running" color="primary"/>
                    // return <label className="btn btn-sm btn-success">Running</label>;
                }else if(row.tag_managements && row.tag_managements?.campaign_run == 0) {
                    return <Chip label="Pause" color="secondary" />
                    // return <label className="btn btn-sm btn-warning">Pause</label>;
                }else if(row.tag_managements && row.tag_managements?.campaign_run == 2) {
                    return <Chip label="Stop" />
                    // return <label className="btn btn-sm btn-warning">Stop</label>;
                }
            },
            sortable: false,
            // minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Next Execution Time</span>,
            selector: row => row['campaign_queue_contacts'],
            cell: row => {
                if(row.campaign_queue_contacts !== null && (row.campaign_queue_contacts.schedule_time !== undefined)) {
                    // return row.campaign_queue_contacts.schedule_time;
                    return window.globalTimezoneConversionToDifferentTimezone(row.campaign_queue_contacts.schedule_time, 'UTC', row.campaigns.timezone, 'MM/DD/yyyy hh:mm:ss A')
                    /* 
                    return ViewHelper::getFormattedTimezoneDateTime($contactData->campaign_queue_contacts->schedule_time, $contactData->campaigns->timezone);
                    */
                }
                return '-----';
            },
            sortable: false,
            // minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Progress</span>,
            selector: row => row['campaign_sent_responses_count'],
            cell: row => {
                let percentage = 0
                if(row.campaign_sent_responses_count > row.campaign_settings_count){
                    percentage = 100;
                }else if(row.campaign_settings_count > 0) {
                    percentage = (row.campaign_sent_responses_count / row.campaign_settings_count) * 100;
                }

                if(row.status == 6){
                    return <label className="btn btn-sm btn-success">Complete</label>;
                }else{
                    return  <div className="contact___percentage">
                                {/* <div className="progress m-progress--sm">
                                    <div className="progress-bar m--bg-primary" role="progressbar" style={{width: percentage}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div> */}
                                <LinearProgress variant="determinate" value={percentage} />
                                <div className="text-right">{row.campaign_sent_responses_count + '/' + row.campaign_settings_count}</div>
                            </div>;
                }
            },
            sortable: false,
            // minWidth:'200px',
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Action</span>,
            selector: row => row['contact_id'],
            cell : row => {
                return (
                  <>
                    {!(row.campaign_contact_states && row.campaign_contact_states?.is_unsubscribed == 1) &&
                      <Chip avatar={<Avatar><DeleteIcon fontSize="small" /></Avatar>} label="Unsubscribe" onClick={() => handleDeleteConfirm(row.contact_id)} color="secondary" />
                    }
                    <Chip avatar={<Avatar><VisibilityIcon fontSize="small" /></Avatar>} label="View" onClick={() => handleView(row.contact_id)} />
                  </>
                )
            },
            sortable: false
        }
    ];

    const handleSearch = (event) => {
        let text = event.target.value;
        setPaginationChange(true)
        setStates({
            ...states,
            searchText: text,
            page: 1
        })
        // props.marketing.campaignSearchText = text
        // props.updateReducerState({isLoading: true})
        // props.fetchCampaign({"query" : text, "page" : 0})
    }

    // console.log(states.searchText,"ok")

    return (
        <>
            <div className="campaign___stats___container">
                <div className="campaign___stats___body" >
                    <HeaderBody className="campaign___stats___body__header" onClick={(e) => history.push("/campaign")}/>
                    {/* <GlobalSearchBox wrapperClass="search_button_campaign_stat_global" type="search" autoComplete="off" name="search" id="campaign_stat_contact_search" placeholder="Search contact" onChange={handleSearch}></GlobalSearchBox> */}
                    <GlobalTable
                        table_unique_id={'campaign___stats___contact_table'}
                        columns={columns}
                        data={states.contacts}
                        keyField={'id'}
                        noDataComponent={(
                            <span className="empty___table___content">No contact added</span>
                        )}
                        progressPending={states.fetching}
                        defaultSortField="title"
                        selectableRows={false}
                        pagination={false}
                        subHeader={false}
                        subHeaderData={{
                            subHeaderComponent : (
                                <div className="top_search_field d-flex align-items-center" style={{width: '100%'}}>
                                    <input /* value={''} onChange={(event) => setQueryText(event.target.value)} */ className="" type="text" placeholder="Search" />
                                    <span>
                                        {IconList.search}
                                    </span>
                                </div>
                            )
                        }}
                        noHeader={true}
                    />
                    {renderPagination()}
                </div>
            </div>
        
            {showTimeline &&
                <ContactTimeline 
                    contactId={selectedContact}
                    campaignId={campaignId}
                    open={showTimeline}
                    onClose={() => handleView(null)}
                    timezone = {states.contacts[0]?.campaigns?.timezone}
                />
            }
        </>
    );
}
export default CampaignStats;