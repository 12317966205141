import * as ACTION from "../constants/globalActionTypes";

const initialState = {
    showNotification: false,
    message: '',
    type: 'success'
};

const globalReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case ACTION.GLOBAL_NOTIFICATION_HANDLE:
            return {
                ...state, 
                showNotification: payload.showNotification,
                message: payload.message,
                type: payload.type
            }
        default:
            return state;
    }
}
export default globalReducer;