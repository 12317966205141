import Styles from './style.module.css'
const UnicodeAlert = (props) => {
    return (
        <div className={Styles.mainWrp}>
            <div className={Styles.headerWrp}>
                <AlertIcon />
                <span className={Styles.headerTitle}>Attention: Your Message Contains Unicode Characters</span>
            </div>
            <div className={`${Styles.bodyWrp} tdp_scroll_bar`}>
                <span className={Styles.bodyTitle}>What is unicode?</span>
                <span className={Styles.bodyText}>{`Unicode is a universal character encoding standard that assigns a code to every character and symbol. (Ex. Emojis, “, ››, ‘, ⧸, ⁃, ＠, ＃)`}</span>
                <span className={`${Styles.bodyText} ${Styles.marginTop20}`}>{`When unicode characters are present the phone companies charge more to deliver your text. Sometimes hidden characters get added to text when you copy/paste from an outside system. These hidden characters aren't always obvious. When pasting text to our system we recommend you paste as plain text. `}</span>

                <div className={Styles.buttonWrp}>
                    <span className={`${Styles.button} ${Styles.editButton}`} onClick={props.handleEdit}>Edit Message</span>
                    <span className={`${Styles.button} ${Styles.sendButton}`} onClick={props.handleSend}>Save Anyways</span>
                </div>
            </div>
        </div>
    );
}
export default UnicodeAlert;
const AlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#ff0000">
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
        </svg>
    );
}