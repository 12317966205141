import React from 'react';
// import CampaignSetting from "./CampaignSetting";
import {connect} from "react-redux";
import EachSetting from './EachSetting';
// import {getData} from "../../actions/campaign-action";
// import EachSettings from './EachSettings';

const mapStateToProps = state => {
    return {
        basicInfo: state.basicInfo,
        loading : state.loading
    };
};

function mapDispatchToProps(dispatch) {
    return {
        // getData: (campaignId) => dispatch(getData(campaignId))
    };
}

class CampaignTimelineEachDay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedAccordion : false
        };
    }


  handleAccordionChange = (panel) => (event, isExpanded) => {
      this.setState({
        expandedAccordion : isExpanded ? panel : false
      })
  };

    render() {
        let eachData = [];
        let type_title ='';
        if(Array.isArray(this.props.data)) {
            for(let i = 0; i < this.props.data.length ; i++){
                if(this.props.data[i].type===3) {
                    type_title = "Custom Date";
                } else if(this.props.data[i].type===4){
                    type_title = "Greeting";
                }else if(this.props.data[i].type===5){
                    type_title = "Recurring";
                }else{
                    type_title = "Day "+this.props.day;
                }

                eachData.push(<EachSetting 
                    data={this.props.data[i]} 
                    key={i+'-'+this.props.data[i]?.updated_at}
                    handleTimelineEdit={this.props.handleTimelineEdit} 
                    refreshTimeline={this.props.refreshTimeline}
                    panelKey={("accordion-panel"+this.props.data[i].id)}
                    expandedAccordion={this.state.expandedAccordion}
                    handleAccordionChange={this.handleAccordionChange}
                    systemTemplate={typeof this.props.systemTemplate != 'undefined' ? this.props.systemTemplate : false}
                />);
            }
        }

        return (
            <div className="greeting_content">
            <h5>{type_title}
                <span>
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.125 1.75V2.25H9.625H10.9375C11.386 2.25 11.75 2.61403 11.75 3.0625V3.875H0.5V3.0625C0.5 2.61403 0.864033 2.25 1.3125 2.25H2.625H3.125V1.75V0.5H3.875V1.75V2.25H4.375H7.875H8.375V1.75V0.5H9.125V1.75ZM1.3125 13.5C0.864033 13.5 0.5 13.136 0.5 12.6875V5.75H11.75V12.6875C11.75 13.136 11.386 13.5 10.9375 13.5H1.3125ZM9.07812 6.5C8.62151 6.5 8.25 6.87151 8.25 7.32812V8.42188C8.25 8.87849 8.62151 9.25 9.07812 9.25H10.1719C10.6285 9.25 11 8.87849 11 8.42188V7.32812C11 6.87151 10.6285 6.5 10.1719 6.5H9.07812ZM9.07812 10C8.62151 10 8.25 10.3715 8.25 10.8281V11.9219C8.25 12.3785 8.62151 12.75 9.07812 12.75H10.1719C10.6285 12.75 11 12.3785 11 11.9219V10.8281C11 10.3715 10.6285 10 10.1719 10H9.07812ZM5.57812 6.5C5.12151 6.5 4.75 6.87151 4.75 7.32812V8.42188C4.75 8.87849 5.12151 9.25 5.57812 9.25H6.67188C7.12849 9.25 7.5 8.87849 7.5 8.42188V7.32812C7.5 6.87151 7.12849 6.5 6.67188 6.5H5.57812ZM5.57812 10C5.12151 10 4.75 10.3715 4.75 10.8281V11.9219C4.75 12.3785 5.12151 12.75 5.57812 12.75H6.67188C7.12849 12.75 7.5 12.3785 7.5 11.9219V10.8281C7.5 10.3715 7.12849 10 6.67188 10H5.57812ZM2.07812 6.5C1.62151 6.5 1.25 6.87151 1.25 7.32812V8.42188C1.25 8.87849 1.62151 9.25 2.07812 9.25H3.17188C3.62849 9.25 4 8.87849 4 8.42188V7.32812C4 6.87151 3.62849 6.5 3.17188 6.5H2.07812ZM2.07812 10C1.62151 10 1.25 10.3715 1.25 10.8281V11.9219C1.25 12.3785 1.62151 12.75 2.07812 12.75H3.17188C3.62849 12.75 4 12.3785 4 11.9219V10.8281C4 10.3715 3.62849 10 3.17188 10H2.07812Z" fill="#3C7EF3" stroke="#3C7EF3"/>
                    </svg>
                </span>
            </h5>
            <div className="greeting_content_details">
                {eachData}
            </div>
        </div>
        );
    }
}

const TimelineEachDay = connect(mapStateToProps, mapDispatchToProps)(CampaignTimelineEachDay);

export default TimelineEachDay;
