import Add from '@material-ui/icons/Add'
import React, { useEffect, useState } from 'react'
import GlobalModal from '../globals/Modal/GlobalModal'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { addNumberInMessagingServiceApi } from '../../api/marketingApi';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      border : 0,
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    containerRoot : {
      overflow : 'unset'
    }
}));

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
}));
  

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
  
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    const checked = rowCount > 0 && numSelected === rowCount;
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={checked}
              onClick={() => onSelectAllClick(!checked)}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
  
          <TableCell
              align={'center'}
              sortDirection={orderBy === 'virtual_number' ? order : false}>
              <TableSortLabel
                className='justify-content-center'
                active={orderBy === 'virtual_number'}
                direction={orderBy === 'virtual_number' ? order : 'asc'}
                onClick={createSortHandler('virtual_number')}
              >
                {'Number'}
                {orderBy === 'virtual_number' ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
          </TableCell>
  
          <TableCell
              align={'center'}>
              {'SID'}
          </TableCell>
  
        </TableRow>
      </TableHead>
    );
}


  

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Select Senders
          </Typography>
        )}
      </Toolbar>
    );
};


export const ModalAddSenders = (props) => {

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        try{
            if(props.messagingService != null && props.messagingService.attached_virtual_numbers !== undefined){
                if(props.messagingService.attached_virtual_numbers !== null){
                    let attached_virtual_numbers = props.messagingService.attached_virtual_numbers;
                    let list = attached_virtual_numbers.split(',');
                    setSelected(list)
                }
            }
        }
        catch(error){
            
        }
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (checked) => {
        if (checked) {
          const newSelecteds = props.numbers.map((n) => n.sid);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
    };
    
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
    };
    
    

    const handleSubmit = () => {
        if(submitting) {
            return;
        }
        if(selected.length === 0){
            window.showNotification('ERROR','Please select at least one sid');
            return;
        }

        let add_vn_form = {
            user_messaging_service_id: props.messagingService.id,
            virtual_number_sids: selected
        }

        addNumberInMessagingServiceApi(add_vn_form).then(res => {
            let response = res.data;
            if(response.success){
                props.onUpdate({
                    ...props.messagingService,
                    attached_virtual_numbers : selected.join()
                });
                props.onClose();
            }
            else{
                window.showNotification("warning", response.message);
            }
            setSubmitting(false)
        }).catch(error => {
            console.log(error)
            setSubmitting(false)
        });
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;


    return (
        <GlobalModal
            title="Add Senders"
            open={props.open}
            onClose={() => props.onClose()}
            onSubmit={handleSubmit}
            buttonText={`Add${submitting ? 'ing' : ''} Senders`}
            buttonIcon={<Add/>}
            className="create_messaging_service_modal global-750-modal global-modal-avoid-header"
        >
            <div className="create__message__service">
                <div className="field__content">
                    <div className="field__input">
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <EnhancedTableToolbar numSelected={selected.length} />
                                <TableContainer className={classes.containerRoot}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={props.numbers.length}/>
                                        <TableBody>
                                        {stableSort(props.numbers, getComparator(order, orderBy)).map((row, index) => {
                                            const isItemSelected = isSelected(row.sid);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.sid)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                                                        {row.virtual_number}
                                                    </TableCell>
                                                    <TableCell align="center">{row.sid}</TableCell>
                                                </TableRow>
                                            );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </GlobalModal>
    )
}
