import * as ACTION from "./cardBroadcastActionTypes";

const initialState = {
    postcardCategoryList : null,
    greetingCardCategoryList: null,
    letterCategoryList: null,
    giftCategoryList: null,

    storedPersonalizeFields: []
};

const cardBroadcastReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST:
            return {...state,postcardCategoryList:action.payload}
        case ACTION.SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST:
            return {...state,greetingCardCategoryList:action.payload}
        case ACTION.SET_LETTER_CATEGORY_IN_CARD_BROADCAST:
            return {...state,letterCategoryList:action.payload} 
        case ACTION.SET_GIFT_CATEGORY_IN_CARD_BROADCAST:
            return {...state,giftCategoryList:action.payload}              
        case ACTION.SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST:
            return {...state,storedPersonalizeFields:action.payload}
        default:
            return state;
    }
}

export default cardBroadcastReducer;
