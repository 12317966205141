/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-multi-str */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { QuickReplyType } from '../../../constants/CoreConstants';
import Messages from '../../../constants/Messages';
import CustomSelectBox from '../../Common/CustomSelectBox'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconList } from '../../../constants/IconList';
import { image_upload_handler } from '../../../api/rootApi';
import { fetchUserInfo } from '../../../api/marketingApi';
import GlobalCustomFieldGrouped from "../../globals/GlobalCustomFieldGrouped/Index";

const App = props => {
    const editorRef = useRef(null);
    const subjectInputRef = useRef(null);
    const [subject,setSubject] = useState('');
    const [message,setMessage] = useState('');

    useEffect(() => {

        if(props.template !== undefined && props.template !== null) {
            setMessage(props.template.message);
            setSubject(props.template.subject);
            props.handleSubject(props.template.subject)
        }
        else{
            fetchUserInfo().then(res => {
                let response = res.data;
                let content = '<br />' + (response.show_appointment_url == 1 ? (response.appointment_url !== null ? response.appointment_url : '') : '');
                content += response.show_signature == 1 ? (response.signature !== null ? response.signature : '') : '';
                setMessage(content)
            })
        }
    },[props.template]);


    const handleSujectPersonalize = (event) => {
        let start = subjectInputRef.current.selectionStart;
        let end = subjectInputRef.current.selectionEnd;
        let text = subjectInputRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event + after);

        setSubject(newText);

        setTimeout(() => {
            //Lines below not works without timeout
            subjectInputRef.current.selectionStart = subjectInputRef.current.selectionEnd = start + event.length;
            subjectInputRef.current.focus();
        }, 500);
    }

    const handleBodyPersonalize = (event) => {
        editorRef.current.insertContent(event);
        setTimeout(() => {
            editorRef.current.focus();
        }, 500);
    }

    const handleChangeEditor = (event) => {
        setMessage(event)
        props.handlePreview(event)
    }

    const handleSaveTemplate = () => {
        let formData = []
        // check validation
        if(message.trim() == ''){
          window.showNotification("WARNING", Messages.emptyMessageBody)
            editorRef.current.focus()
            return false
        }
        
        formData['content'] = QuickReplyType.EMAIL
        formData['email_subject'] = subject
        formData['body_sms'] = message
        props.handleSaveTemplate(formData)
    }

    const handleSubject = (event) => {
        setSubject(event.target.value)
        props.handleSubject(event.target.value)
    }

   const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    return(
        <div id="email" className="">
            
            <div className="upper_tab_buttons">
                <div className="d-flex">
                    <div className="input-field subject_form">
                        <input ref={subjectInputRef} placeholder="Subject" value={subject} onChange={handleSubject} type="text" className="validate emailSubjectTextField" />
                    </div>
                    <GlobalCustomFieldGrouped className={"marketing__module__personalized_tag__subject"} labelText={"Personalize"} handleSelect={handleSujectPersonalize}/>
                </div>
            </div>

            <Editor
                apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                onInit={(evt, editor) => editorRef.current = editor}
                value={message}
                cloudChannel='5-dev'
                init={{
                    height: 300,
                    browser_spellcheck: true,
                    // menubar: false,
                    menu: {
                        favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                    },
                    menubar: 'favs file edit view insert format tools table help',
                    plugins: [
                        'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                        'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                        'insertdatetime media table contextmenu paste code help wordcount template'
                    ],
                    convert_urls: false,
                    toolbar:
                        'insertfile undo redo | styleselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | link image | \
                        bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                    imagetools_proxy: emailUploadFileApi,
                    images_upload_handler: image_upload_handler,
                    automatic_uploads: true,
                    file_picker_types: 'file image media',
                    placeholder: "Type your message"
                }}
                onEditorChange={handleChangeEditor}
                id="add-sms-template-text-area"
            />
            
            <div className="under_tab_buttons">
                <div className="d-flex justify-content-between">

                    <div className="d-flex">
                        <GlobalCustomFieldGrouped className={"marketing__module__personalized_tag"} labelText={"Personalize"} handleSelect={handleBodyPersonalize}/>
                    </div>

                    <div className="save_template_button cursor-pointer" onClick={handleSaveTemplate}>
                        <a onClick={(e) => e.preventDefault()} className="accent--bg--color">
                            {props.submitingTemplate ? <MoreHorizIcon /> : IconList.saveTemplate}
                            <span>{props.submitingTemplate ? "Saving" : "Save" } Template</span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )

}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const AddEmailTemplate = connect(mapStateToProps, mapDispatchToProps)(App)
export default AddEmailTemplate