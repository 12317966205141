import GlobalModal from "../globals/Modal/GlobalModal";
import ResentFailedContact from "./ResentFailedContact";
import './style.css';

const ViewFailedContact = (props) => {
    /* will added drawer component here later */
    return (
        <GlobalModal 
            open={props.open} 
            onClose={props.onClose}
            title={props.title()} 
            className="global-medium-modal campaign-setting-stats-contact"
            hideFooter={true}
            // hideTitle={true}
        >
            <ResentFailedContact {...props} />
        </GlobalModal>
    );
}
export default ViewFailedContact;