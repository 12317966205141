/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import LeadGroupModal from "./LeadGroupModal";
import {
  updateLeadGroups,
  updateLeadFlows,
  deleteLeadFlowFromCampaignApi,
} from "../../../api/marketingApi";
import {
  fetchLeadFlows,
  fetchLeadGroups,
} from "../../../actions/marketingAction";
import { connect } from "react-redux";
import If from "if-else-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AddNewLeadFlow from "./AddNewLeadFlow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert";
import ActionControl from "../../Common/ActionControl/ActionControl";
import Utils from "../../../helpers/Utils";

const ConnectAdvanceLeadSource = (props) => {
  const isTempUser = Utils.getAccountData("isTemplateUser");
  const [openLeadGroupModal, setOpenLeadGroupModal] = useState(false);
  const [userLeadGroups, setUserLeadGroups] = useState([]);
  const [connectedGroupArray, setConnectedGroupArray] = useState([]);
  const [addNewModal, setAddNewModal] = useState(false);
  const [connectedLeadFlows, setConnectedLeadFlows] = useState([]);

  const toggleLeadSource = () => {
    props.hanldeCollapse("advanced");
  };

  const handleCloseLeadGroupModal = () => {
    setOpenLeadGroupModal(false);
  };

  const handleCloseLeadGroupModalAndFetch = () => {
    setOpenLeadGroupModal(false);

    props.fetchLeadGroups({
      campaignId: props.campaignDetails.basic.id,
    });
  };

  const closeModalAndFetch = () => {
    props.fetchLeadFlows({
      campaignId: props.campaignDetails.basic.id,
    });

    setAddNewModal(false);
  };

  useEffect(() => {
    if (props.leadGroups !== undefined && props.leadGroups != null) {
      setUserLeadGroups(
        props.leadGroups.allGroup !== undefined ? props.leadGroups.allGroup : []
      );
      setConnectedGroupArray(
        props.leadGroups.connectedGroupArray !== undefined
          ? props.leadGroups.connectedGroupArray
          : [[]]
      );
    }
  }, [props.leadGroups]);

  const handleSelectLeadGroup = (event, value) => {
    event.preventDefault();
    updateLeadGroups({
      campaignId: props.campaignDetails.basic.id,
      groups: value,
    });
  };

  useEffect(() => {
    if (props.leadFlows !== undefined && props.leadFlows != null)
      setConnectedLeadFlows(props.leadFlows.connectedLeadFlows);
  }, [props.leadFlows]);

  const handleConfirmDeleteLeadFlow = (item) => {
    confirmAlert({
      title: "Are you sure?",
      message: `This campaign will disconnected from "${item.title}" leadflow's all rules`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteLeadFlowFromCampaignApi({
              leadflow_id: item.id,
              campaign_id: props.campaignDetails.basic.id,
            }).then((res) => {
              let response = res.data;
              if (response.status === "error") {
                if (window.showNotification !== undefined) {
                  window.showNotification(
                    "ERROR",
                    "Something wrong! try again."
                  );
                }
              } else {
                props.fetchLeadFlows({
                  campaignId: props.campaignDetails.basic.id,
                });
                if (window.showNotification !== undefined) {
                  window.showNotification("SUCCESS", response.html);
                }
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  let leadFlowList = [];
  if (props.leadFlows) {
    leadFlowList = connectedLeadFlows.map(function (data, index) {
      return (
        <li
          key={index}
          className='d-flex align-items-center py-2 campaign-lead-flow-content___alt'
        >
          <p className='truncate'>{data.title}</p>
          <span onClick={() => handleConfirmDeleteLeadFlow(data)}>
            <DeleteIcon color='error' />
          </span>
        </li>
      );
    });
  }

  return (
    <div
      className={`lead_src_advance ${
        props.isShowBasicLeadSource ? "active" : ""
      }`}
    >
      <div className='connect_lead_source_title'>
        <h2>Connect Your Lead Source - Advanced</h2>
        {props.isShowBasicLeadSource ? (
          <ExpandLessIcon onClick={toggleLeadSource} />
        ) : (
          <KeyboardArrowDownIcon onClick={toggleLeadSource} />
        )}
      </div>
      <div className='lead_src_group'>
        <ul className='lead_src_advance_items'>
          <li>
            <div className='leads_flow_content d-flex justify-content-start align-items-center pt-2'>
              <p>
                Select Your Lead Group(s){" "}
                <span>(lead groups receive leads from the lead flow)</span>
              </p>
              <ActionControl
                alertMessage='Campaign Lead Group feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <a
                  href='#!'
                  className='modal-trigger'
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenLeadGroupModal(true);
                  }}
                >
                  <span className='mr-1'>
                    <svg
                      width='30'
                      height='31'
                      viewBox='0 0 30 31'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M30 15.5C30 19.4782 28.4196 23.2936 25.6066 26.1066C22.7936 28.9196 18.9782 30.5 15 30.5C11.0218 30.5 7.20644 28.9196 4.3934 26.1066C1.58035 23.2936 0 19.4782 0 15.5C0 11.5218 1.58035 7.70644 4.3934 4.8934C7.20644 2.08035 11.0218 0.5 15 0.5C18.9782 0.5 22.7936 2.08035 25.6066 4.8934C28.4196 7.70644 30 11.5218 30 15.5ZM15.9375 8.9375C15.9375 8.68886 15.8387 8.4504 15.6629 8.27459C15.4871 8.09877 15.2486 8 15 8C14.7514 8 14.5129 8.09877 14.3371 8.27459C14.1613 8.4504 14.0625 8.68886 14.0625 8.9375V14.5625H8.4375C8.18886 14.5625 7.9504 14.6613 7.77459 14.8371C7.59877 15.0129 7.5 15.2514 7.5 15.5C7.5 15.7486 7.59877 15.9871 7.77459 16.1629C7.9504 16.3387 8.18886 16.4375 8.4375 16.4375H14.0625V22.0625C14.0625 22.3111 14.1613 22.5496 14.3371 22.7254C14.5129 22.9012 14.7514 23 15 23C15.2486 23 15.4871 22.9012 15.6629 22.7254C15.8387 22.5496 15.9375 22.3111 15.9375 22.0625V16.4375H21.5625C21.8111 16.4375 22.0496 16.3387 22.2254 16.1629C22.4012 15.9871 22.5 15.7486 22.5 15.5C22.5 15.2514 22.4012 15.0129 22.2254 14.8371C22.0496 14.6613 21.8111 14.5625 21.5625 14.5625H15.9375V8.9375Z'
                        fill='#3C7EF3'
                      />
                    </svg>
                  </span>
                </a>
              </ActionControl>
              {openLeadGroupModal && (
                <LeadGroupModal
                  openLeadGroupModal={openLeadGroupModal}
                  data={null}
                  hideModal={handleCloseLeadGroupModal}
                  hideModalAndFetch={handleCloseLeadGroupModalAndFetch}
                  title={"New Group"}
                  className={"global-small-modal"}
                />
              )}
              <ActionControl
                alertMessage='Campaign Lead Group feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <a href='/user/lead-group' className='modal-trigger'>
                  <span>
                    <svg
                      width='37'
                      height='37'
                      viewBox='0 0 37 37'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M18.0312 3C9.7431 3 3 9.7431 3 18.0312C3 26.3194 9.7431 33.0625 18.0312 33.0625C26.3194 33.0625 33.0625 26.3194 33.0625 18.0312C33.0625 9.7431 26.3194 3 18.0312 3ZM13.4062 23.7764C13.4062 24.083 13.2844 24.3771 13.0676 24.594C12.8508 24.8108 12.5567 24.9326 12.25 24.9326H11.0938C10.7871 24.9326 10.493 24.8108 10.2762 24.594C10.0593 24.3771 9.9375 24.083 9.9375 23.7764V22.6201C9.9375 22.3135 10.0593 22.0194 10.2762 21.8025C10.493 21.5857 10.7871 21.4639 11.0938 21.4639H12.25C12.5567 21.4639 12.8508 21.5857 13.0676 21.8025C13.2844 22.0194 13.4062 22.3135 13.4062 22.6201V23.7764ZM13.4062 18.6455C13.4062 18.9522 13.2844 19.2463 13.0676 19.4631C12.8508 19.6799 12.5567 19.8018 12.25 19.8018H11.0938C10.7871 19.8018 10.493 19.6799 10.2762 19.4631C10.0593 19.2463 9.9375 18.9522 9.9375 18.6455V17.4893C9.9375 17.1826 10.0593 16.8885 10.2762 16.6717C10.493 16.4548 10.7871 16.333 11.0938 16.333H12.25C12.5567 16.333 12.8508 16.4548 13.0676 16.6717C13.2844 16.8885 13.4062 17.1826 13.4062 17.4893V18.6455ZM13.4062 13.4424C13.4062 13.749 13.2844 14.0431 13.0676 14.26C12.8508 14.4768 12.5567 14.5986 12.25 14.5986H11.0938C10.7871 14.5986 10.493 14.4768 10.2762 14.26C10.0593 14.0431 9.9375 13.749 9.9375 13.4424V12.2861C9.9375 11.9795 10.0593 11.6854 10.2762 11.4685C10.493 11.2517 10.7871 11.1299 11.0938 11.1299H12.25C12.5567 11.1299 12.8508 11.2517 13.0676 11.4685C13.2844 11.6854 13.4062 11.9795 13.4062 12.2861V13.4424ZM26.125 24.3545H14.9V22.042H26.125V24.3545ZM26.125 19.2236H14.9V16.9111H26.125V19.2236ZM26.125 14.0205H14.9V11.708H26.125V14.0205Z'
                        fill='#133159'
                        fillOpacity='0.65'
                      />
                    </svg>
                  </span>
                </a>
              </ActionControl>
            </div>
            <div className='leads_flow_drp'>
              <div className='lead_group_select_field'>
                <ActionControl
                  alertMessage='Campaign Lead Group feature is not available for template user'
                  status='error'
                  isDisabled={isTempUser == 1}
                >
                  <If condition={userLeadGroups.length > 0}>
                    <Autocomplete
                      onChange={(event, value) =>
                        handleSelectLeadGroup(event, value)
                      }
                      multiple
                      id='tags-outlined'
                      options={userLeadGroups}
                      getOptionLabel={(option) => option.title}
                      defaultValue={connectedGroupArray}
                      getOptionSelected={(option, value) =>
                        option.title === value.title
                      }
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Select Lead Groups'
                          placeholder='Search Groups'
                        />
                      )}
                    />
                  </If>
                </ActionControl>
                <ActionControl
                  alertMessage='Campaign Lead Group feature is not available for template user'
                  status='error'
                  isDisabled={isTempUser == 1}
                >
                  <If condition={userLeadGroups.length <= 0}>
                    <div className='create_new_lead_flow_select cr_nw_lead_flow_select'>
                      <a
                        href='#!'
                        className='d-flex'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenLeadGroupModal(true);
                        }}
                      >
                        <span>
                          <svg
                            width='21'
                            height='20'
                            viewBox='0 0 21 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M20.5 10C20.5 12.6522 19.4464 15.1957 17.5711 17.0711C15.6957 18.9464 13.1522 20 10.5 20C7.84784 20 5.3043 18.9464 3.42893 17.0711C1.55357 15.1957 0.5 12.6522 0.5 10C0.5 7.34784 1.55357 4.8043 3.42893 2.92893C5.3043 1.05357 7.84784 0 10.5 0C13.1522 0 15.6957 1.05357 17.5711 2.92893C19.4464 4.8043 20.5 7.34784 20.5 10ZM11.125 5.625C11.125 5.45924 11.0592 5.30027 10.9419 5.18306C10.8247 5.06585 10.6658 5 10.5 5C10.3342 5 10.1753 5.06585 10.0581 5.18306C9.94085 5.30027 9.875 5.45924 9.875 5.625V9.375H6.125C5.95924 9.375 5.80027 9.44085 5.68306 9.55806C5.56585 9.67527 5.5 9.83424 5.5 10C5.5 10.1658 5.56585 10.3247 5.68306 10.4419C5.80027 10.5592 5.95924 10.625 6.125 10.625H9.875V14.375C9.875 14.5408 9.94085 14.6997 10.0581 14.8169C10.1753 14.9342 10.3342 15 10.5 15C10.6658 15 10.8247 14.9342 10.9419 14.8169C11.0592 14.6997 11.125 14.5408 11.125 14.375V10.625H14.875C15.0408 10.625 15.1997 10.5592 15.3169 10.4419C15.4342 10.3247 15.5 10.1658 15.5 10C15.5 9.83424 15.4342 9.67527 15.3169 9.55806C15.1997 9.44085 15.0408 9.375 14.875 9.375H11.125V5.625Z'
                              fill='#3C7EF3'
                            />
                          </svg>
                        </span>
                        Create New Group
                      </a>
                    </div>
                  </If>
                </ActionControl>
              </div>
            </div>
          </li>
          <li>
            <div className='leads_flow_content d-flex justify-content-start align-items-center pt-2'>
              <p>Select Your Lead Flow(s) </p>
              <ActionControl
                alertMessage='Campaign Lead Flow feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <a
                  href='#!'
                  className='modal-trigger'
                  onClick={(e) => {
                    e.preventDefault();
                    setAddNewModal(true);
                  }}
                >
                  <span className='mr-1'>
                    <svg
                      width='30'
                      height='31'
                      viewBox='0 0 30 31'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M30 15.5C30 19.4782 28.4196 23.2936 25.6066 26.1066C22.7936 28.9196 18.9782 30.5 15 30.5C11.0218 30.5 7.20644 28.9196 4.3934 26.1066C1.58035 23.2936 0 19.4782 0 15.5C0 11.5218 1.58035 7.70644 4.3934 4.8934C7.20644 2.08035 11.0218 0.5 15 0.5C18.9782 0.5 22.7936 2.08035 25.6066 4.8934C28.4196 7.70644 30 11.5218 30 15.5ZM15.9375 8.9375C15.9375 8.68886 15.8387 8.4504 15.6629 8.27459C15.4871 8.09877 15.2486 8 15 8C14.7514 8 14.5129 8.09877 14.3371 8.27459C14.1613 8.4504 14.0625 8.68886 14.0625 8.9375V14.5625H8.4375C8.18886 14.5625 7.9504 14.6613 7.77459 14.8371C7.59877 15.0129 7.5 15.2514 7.5 15.5C7.5 15.7486 7.59877 15.9871 7.77459 16.1629C7.9504 16.3387 8.18886 16.4375 8.4375 16.4375H14.0625V22.0625C14.0625 22.3111 14.1613 22.5496 14.3371 22.7254C14.5129 22.9012 14.7514 23 15 23C15.2486 23 15.4871 22.9012 15.6629 22.7254C15.8387 22.5496 15.9375 22.3111 15.9375 22.0625V16.4375H21.5625C21.8111 16.4375 22.0496 16.3387 22.2254 16.1629C22.4012 15.9871 22.5 15.7486 22.5 15.5C22.5 15.2514 22.4012 15.0129 22.2254 14.8371C22.0496 14.6613 21.8111 14.5625 21.5625 14.5625H15.9375V8.9375Z'
                        fill='#3C7EF3'
                      />
                    </svg>
                  </span>
                </a>
              </ActionControl>
              <If condition={addNewModal}>
                <AddNewLeadFlow
                  closeModalAndFetch={closeModalAndFetch}
                  addNewModal={addNewModal}
                  setAddNewModal={setAddNewModal}
                />
              </If>
              <ActionControl
                alertMessage='Campaign Lead Flow feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <a href='/leadflow' className='modal-trigger'>
                  <span>
                    <svg
                      width='37'
                      height='37'
                      viewBox='0 0 37 37'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M18.0312 3C9.7431 3 3 9.7431 3 18.0312C3 26.3194 9.7431 33.0625 18.0312 33.0625C26.3194 33.0625 33.0625 26.3194 33.0625 18.0312C33.0625 9.7431 26.3194 3 18.0312 3ZM13.4062 23.7764C13.4062 24.083 13.2844 24.3771 13.0676 24.594C12.8508 24.8108 12.5567 24.9326 12.25 24.9326H11.0938C10.7871 24.9326 10.493 24.8108 10.2762 24.594C10.0593 24.3771 9.9375 24.083 9.9375 23.7764V22.6201C9.9375 22.3135 10.0593 22.0194 10.2762 21.8025C10.493 21.5857 10.7871 21.4639 11.0938 21.4639H12.25C12.5567 21.4639 12.8508 21.5857 13.0676 21.8025C13.2844 22.0194 13.4062 22.3135 13.4062 22.6201V23.7764ZM13.4062 18.6455C13.4062 18.9522 13.2844 19.2463 13.0676 19.4631C12.8508 19.6799 12.5567 19.8018 12.25 19.8018H11.0938C10.7871 19.8018 10.493 19.6799 10.2762 19.4631C10.0593 19.2463 9.9375 18.9522 9.9375 18.6455V17.4893C9.9375 17.1826 10.0593 16.8885 10.2762 16.6717C10.493 16.4548 10.7871 16.333 11.0938 16.333H12.25C12.5567 16.333 12.8508 16.4548 13.0676 16.6717C13.2844 16.8885 13.4062 17.1826 13.4062 17.4893V18.6455ZM13.4062 13.4424C13.4062 13.749 13.2844 14.0431 13.0676 14.26C12.8508 14.4768 12.5567 14.5986 12.25 14.5986H11.0938C10.7871 14.5986 10.493 14.4768 10.2762 14.26C10.0593 14.0431 9.9375 13.749 9.9375 13.4424V12.2861C9.9375 11.9795 10.0593 11.6854 10.2762 11.4685C10.493 11.2517 10.7871 11.1299 11.0938 11.1299H12.25C12.5567 11.1299 12.8508 11.2517 13.0676 11.4685C13.2844 11.6854 13.4062 11.9795 13.4062 12.2861V13.4424ZM26.125 24.3545H14.9V22.042H26.125V24.3545ZM26.125 19.2236H14.9V16.9111H26.125V19.2236ZM26.125 14.0205H14.9V11.708H26.125V14.0205Z'
                        fill='#133159'
                        fillOpacity='0.65'
                      />
                    </svg>
                  </span>
                </a>
              </ActionControl>
            </div>
            <div className='leads_flow_drp'>
              <ActionControl
                alertMessage='Lead Flow Group feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <If condition={connectedLeadFlows.length > 0}>
                  <div className='create_new_lead_flow_select mt-3'>
                    <div className='select_lead_flow_contents'>
                      <ul className='awesome__scroll_bar'>{leadFlowList}</ul>
                    </div>
                  </div>
                </If>
              </ActionControl>
              <ActionControl
                alertMessage='Lead Flow Group feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <If condition={connectedLeadFlows.length <= 0}>
                  <div className='create_new_lead_flow_select mt-3'>
                    <a
                      href='#!'
                      className='d-flex modal-trigger'
                      onClick={(e) => {
                        e.preventDefault();
                        setAddNewModal(true);
                      }}
                    >
                      <span>
                        <svg
                          width='21'
                          height='20'
                          viewBox='0 0 21 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M20.5 10C20.5 12.6522 19.4464 15.1957 17.5711 17.0711C15.6957 18.9464 13.1522 20 10.5 20C7.84784 20 5.3043 18.9464 3.42893 17.0711C1.55357 15.1957 0.5 12.6522 0.5 10C0.5 7.34784 1.55357 4.8043 3.42893 2.92893C5.3043 1.05357 7.84784 0 10.5 0C13.1522 0 15.6957 1.05357 17.5711 2.92893C19.4464 4.8043 20.5 7.34784 20.5 10ZM11.125 5.625C11.125 5.45924 11.0592 5.30027 10.9419 5.18306C10.8247 5.06585 10.6658 5 10.5 5C10.3342 5 10.1753 5.06585 10.0581 5.18306C9.94085 5.30027 9.875 5.45924 9.875 5.625V9.375H6.125C5.95924 9.375 5.80027 9.44085 5.68306 9.55806C5.56585 9.67527 5.5 9.83424 5.5 10C5.5 10.1658 5.56585 10.3247 5.68306 10.4419C5.80027 10.5592 5.95924 10.625 6.125 10.625H9.875V14.375C9.875 14.5408 9.94085 14.6997 10.0581 14.8169C10.1753 14.9342 10.3342 15 10.5 15C10.6658 15 10.8247 14.9342 10.9419 14.8169C11.0592 14.6997 11.125 14.5408 11.125 14.375V10.625H14.875C15.0408 10.625 15.1997 10.5592 15.3169 10.4419C15.4342 10.3247 15.5 10.1658 15.5 10C15.5 9.83424 15.4342 9.67527 15.3169 9.55806C15.1997 9.44085 15.0408 9.375 14.875 9.375H11.125V5.625Z'
                            fill='#3C7EF3'
                          />
                        </svg>
                      </span>
                      Create New LeadFlow
                    </a>
                  </div>
                </If>
              </ActionControl>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignDetails: state.marketingReducer.campaignDetails,
    leadGroups: state.marketingReducer.leadGroups,
    leadFlows: state.marketingReducer.leadFlows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLeadGroups: (params) => dispatch(updateLeadGroups(params)),
    fetchLeadGroups: (params) => dispatch(fetchLeadGroups(params)),
    fetchLeadFlows: (params) => dispatch(fetchLeadFlows(params)),
    updateLeadFlows: (params) => dispatch(updateLeadFlows(params)),
  };
};

const ConnectAdvanceLeadSourceApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectAdvanceLeadSource);

export default ConnectAdvanceLeadSourceApp;
