/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {connect} from "react-redux";
import ScheduleSetting from "./ScheduleSetting";
import moment from "moment";
import { fetchCampaignTimelines, followupSave, updateCampaignSetting, updateDay, updateHour, updateLaterHour, updateLaterMin, updateMin, updateRecurringDay, updateTabValue, updateTime } from "../../../actions/marketingAction";
import Utils from "../../../helpers/Utils";
import GlobalModal from "../../globals/Modal/GlobalModal";
import CallIcon from '@material-ui/icons/Call';

const App = props => {
    const textInput = useRef(null);

    const [conversationText, setConversationText] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [campaignSetting, setCampaignSetting] = useState(5);

    const [campaignDetails, setCampaignDetails] = React.useState(props.campaignDetails);

    // eslint-disable-next-line no-unused-vars
    const [showAllTimeSetting, setShowAllTimeSetting] = useState(true);
    const [tabValue, setTabValue] = useState(props.tabValue);
    const [hour, setHour] = useState(props.hour);
    const [min, setMin] = useState(props.min);
    const [day, setDay] = useState(props.day);
    const [time, setTime] = useState(props.time);
    const [startDate, setStartDate] = useState(props.startDate);
    // eslint-disable-next-line no-unused-vars
    const [greetingOption, setGreetingOption] = useState(props.greetingOption);
    const [savingToServer, setSavingToServer] = useState(false)

    // for update
    const [updateSetting, setUpdateSetting] = useState(false)
    const [editableCampaignSettingId, setEditableCampaignSettingId] = useState(null)

    // validation flag
    const [numberValidation, setNumberValidation] = useState(false)


    // const call_bridge_message_type = 5;

    /* use effect start */
    useEffect(() => {
        if(props.currentClip != null){
            setUpdateSetting(true);
            setEditableCampaignSettingId(props.currentClip.id);
            setConversationText(props.currentClip.message);
        }
    }, [updateSetting])

    useEffect(() => {
        setTabValue(props.tabValue);
        setHour(props.hour);
        setMin(props.min);
        setDay(props.day);
        setTime(props.time);
        setStartDate(props.startDate);
    }, [
        props.tabValue, props.hour, props.min, props.day, props.recurringDay, props.laterHour, props.laterMin, props.time, props.startDate, props.arrSlotTimeHour1, props.arrSlotTimeMin1, props.greetingOption, props.greetingsOptionTimeMin1, props.greetingsOptionTimeHour1, props.recurringTimeHour1, props.recurringTimeMin1,
    ]);

    useEffect(() => {
        setCampaignDetails(props.campaignDetails);
        let defaultNumber = props.campaignDetails.basic.virtual_numbers.find(data => data.is_default);
        if(defaultNumber)
            setConversationText(defaultNumber.virtual_number);
    }, [props.campaignDetails]);

    useEffect(() => {
        if(props.currentClip != null){
            setUpdateSetting(true);
            setEditableCampaignSettingId(props.currentClip.id);
            setConversationText(props.currentClip.message);

            let selectedTab = 0;
            let mint = 0;
            let hour = 0;
            let time = '12:00';
            if(props.currentClip.type > 1){
                if(props.currentClip.type === 2 && props.currentClip.day === 1){
                    selectedTab = 1;
                    let timeData = props.currentClip.hour.split(":");
                    hour = parseInt(timeData[0]);
                    mint = parseInt(timeData[1]);
                }else{
                    selectedTab = props.currentClip.type;
                }
                time = props.currentClip.hour;
            }
            else{
                if(props.currentClip.type === 1 ){
                    selectedTab = 0;
                }else {
                    selectedTab =  1;
                    let timeData = props.currentClip.hour.split(":");
                    hour = parseInt(timeData[0]);
                    mint = parseInt(timeData[1]);
                }
            }
            props.updateTabValue(selectedTab)
            props.updateHour(hour)
            props.updateMin(mint)
            props.updateTime(time)
        }
        else{
            props.updateTabValue(0)
        }
    }, [updateSetting])

    const sendText = () => {
        if(savingToServer){
            return false
        }
        let numberText = conversationText.trim().replace("+", "")
        let numberToStore = numberText
        if(numberText == ''){
            setNumberValidation(true)
            textInput.current.focus()
            return false
        }
        else if(numberText.length == 10){
            numberToStore = "+1" + numberText
            setConversationText(numberToStore)
        }
        else if(!Utils.validateMobileNumber(numberText)){
            setNumberValidation(true)
            textInput.current.focus()
            return false
        }
        setSavingToServer(true)

        let type;

        switch (tabValue) {
            case 1:
            case 2:
                type = 2;
                break;
            case 0:
                type = 1;
                break;
            default:
                type = tabValue;
                break;
        }

        let formData = new FormData();
        formData.append('message', numberToStore);
        formData.append('message_type', campaignSetting);
        formData.append('nScheduleHours', hour);
        formData.append('nScheduleMinutes', min);
        formData.append('nScheduleDays', day);
        formData.append('followupScheduleTime', time);
        formData.append('campaign_id', campaignDetails.basic.id);
        formData.append('type', type);
        formData.append('day_type', (tabValue != 0 ? tabValue : 1));
        formData.append('date', moment(startDate).format('YYYY-MM-DD'));
        formData.append('greeting_option', greetingOption);

        if(updateSetting){
            formData.append('id', editableCampaignSettingId);
            props.updateCampaignSetting(formData, successFollowupAdd);
        }
        else{
            props.followupSave(formData, successFollowupAdd);
        }

    }

    const successFollowupAdd = (response) => {
        props.fetchCampaignTimelines({
            campaignId: campaignDetails.basic.id
        });
        props.setOpenCallBridgeModal(false)
        setSavingToServer(false)
    }



    return (

        <React.Fragment>
            <GlobalModal 
                open={props.openCallBridgeModal} 
                onClose={props.setOpenCallBridgeModal}
                title="Call Bridge" 
                onSubmit={sendText}
                buttonText={'Set Call Bridge'}
                buttonIcon={<CallIcon fontSize="small"/>}
                className="global-large-modal"
            >
                {/*Massaging Editor*/}
                <div className="tiny-mce mt-3">
                    <div className="mb-0 row">
                        <div className="col s12">
                            <input id="basic-example" className="materialize-textarea" placeholder="Type a message"  onChange={(event) => setConversationText(event.target.value)} ref={textInput} value={conversationText}/>
                            {numberValidation &&
                            <span className={"text-red"}>A valid number is required.</span>
                            }
                        </div>
                    </div>
                </div>
                <ScheduleSetting showAllTimeSetting={showAllTimeSetting}/>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.marketingReducer.userInfo,
        campaignDetails: state.marketingReducer.campaignDetails,

        tabValue: state.marketingReducer.tabValue,
        hour: state.marketingReducer.hour,
        min: state.marketingReducer.min,
        day: state.marketingReducer.day,
        recurringDay: state.marketingReducer.recurringDay,
        laterHour: state.marketingReducer.laterHour,
        laterMin: state.marketingReducer.laterMin,
        time: state.marketingReducer.time,
        startDate: state.marketingReducer.startDate,
        arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
        arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
        greetingOption: state.marketingReducer.greetingOption,
        greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
        greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
        recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
        recurringTimeMin1: state.marketingReducer.recurringTimeMin1,

    };
};

const mapDispatchToProps = dispatch => {
    return {

        followupSave: (params, callback) => dispatch(followupSave(params, callback)),
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),
        // updateCallBridgeStepSetting: (params, callback) => dispatch(updateCallBridgeStepSetting(params, callback)),

        updateCampaignSetting: (params, callback) => dispatch(updateCampaignSetting(params, callback)),

        updateTabValue: (params) => dispatch(updateTabValue(params)),
        updateHour: (params) => dispatch(updateHour(params)),
        updateMin: (params) => dispatch(updateMin(params)),
        updateDay: (params) => dispatch(updateDay(params)),
        updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        updateTime: (params) => dispatch(updateTime(params)),
    };
}

const CallBridgeModal = connect(mapStateToProps, mapDispatchToProps)(App);

export default CallBridgeModal;