// export const UPDATE_STATE_FIELD = 'UPDATE_STATE_FIELD';
// export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
// export const SUCCESS_FETCH_CAMPAIGNS = 'SUCCESS_FETCH_CAMPAIGNS';
// export const FAILED_FETCH_CAMPAIGNS = 'FAILED_FETCH_CAMPAIGNS';
// export const STORE_CAMPAIGNS = 'STORE_CAMPAIGNS';

// // for adding contact into campaign
// export const STORE_SINGLE_CONTACT = 'STORE_SINGLE_CONTACT'
// export const SUCCESS_STORE_SINGLE_CONTACT = 'SUCCESS_STORE_SINGLE_CONTACT'
// export const FAILED_STORE_SINGLE_CONTACT = 'FAILED_STORE_SINGLE_CONTACT'
// export const FETCH_CONTACT_LIST_DATATABLE = 'FETCH_CONTACT_LIST_DATATABLE'
// export const MOVE_CONTACT_TO_CAMPAIGN = 'MOVE_CONTACT_TO_CAMPAIGN'

// export const FETCH_CAMPAIGN_DETAILS = 'FETCH_CAMPAIGN_DETAILS';
// export const STORE_CAMPAIGN_DETAILS = 'STORE_CAMPAIGN_DETAILS';

// export const FETCH_CAMPAIGN_TIMELINE = 'FETCH_CAMPAIGN_TIMELINE';
// export const STORE_CAMPAIGN_TIMELINE = 'STORE_CAMPAIGN_TIMELINE';

// export const FETCH_CUSTOM_FIELD = 'FETCH_CUSTOM_FIELD';
// export const STORE_CUSTOM_FIELD = 'STORE_CUSTOM_FIELD';
// export const UPDATE_CAMPAIGN_DETAILS = 'UPDATE_CAMPAIGN_DETAILS';
// export const FETCH_CAMPAIGN_LEAD_SOURCE_DETAILS = 'FETCH_CAMPAIGN_LEAD_SOURCE_DETAILS';
// export const STORE_CAMPAIGN_LEAD_SOURCE_DETAILS = 'STORE_CAMPAIGN_LEAD_SOURCE_DETAILS';
// export const FETCH_USER_INFO = 'FETCH_USER_INFO';
// export const STORE_USER_INFO = 'STORE_USER_INFO';
// export const FETCH_LEAD_GROUPS = 'FETCH_LEAD_GROUPS';
// export const STORE_LEAD_GROUPS = 'STORE_LEAD_GROUPS';
// export const UPDATE_LEAD_GROUPS = 'UPDATE_LEAD_GROUPS';
// export const FETCH_LEAD_FLOWS = 'FETCH_LEAD_FLOWS';
// export const STORE_LEAD_FLOWS = 'STORE_LEAD_FLOWS';
// export const UPDATE_LEAD_FLOWS = 'UPDATE_LEAD_FLOWS';
// export const FETCH_CAMPAIGN_CONTACT_STAT = 'FETCH_CAMPAIGN_CONTACT_STAT';
// export const STORE_CAMPAIGN_CONTACT_STAT = 'STORE_CAMPAIGN_CONTACT_STAT';

// // share campaign
// export const SHARE_CAMPAIGN = 'SHARE_CAMPAIGN';
// export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

//save followup
export const SAVE_FOLLOWUP_IN_CARD_BROADCAST = 'SAVE_FOLLOWUP_IN_CARD_BROADCAST';
// export const SAVE_ACTION_FOLLOWUP = 'SAVE_ACTION_FOLLOWUP';
// export const SAVE_ACTIVITY_FOLLOWUP = 'SAVE_ACTIVITY_FOLLOWUP';
// export const UPDATE_ACTIVITY_FOLLOWUP = 'UPDATE_ACTIVITY_FOLLOWUP';
// export const UPDATE_CAMPAIGN_SMS_STEP_SETTING = 'UPDATE_CAMPAIGN_SMS_STEP_SETTING';
export const UPDATE_CAMPAIGN_EMAIL_STEP_SETTING_IN_CARD_BROADCAST = 'UPDATE_CAMPAIGN_EMAIL_STEP_SETTING_IN_CARD_BROADCAST';
// export const UPDATE_CAMPAIGN_VIDEO_EMAIL_STEP_SETTING = 'UPDATE_CAMPAIGN_VIDEO_EMAIL_STEP_SETTING';
// export const UPDATE_CAMPAIGN_CALL_BRIDGE_STEP_SETTING = 'UPDATE_CAMPAIGN_CALL_BRIDGE_STEP_SETTING';

// /* TIME SCHEDULE SETTING START */
// export const UPDATE_TAB_VALUE = 'UPDATE_TAB_VALUE';
// export const UPDATE_HOUR = 'UPDATE_HOUR';
// export const UPDATE_MIN = 'UPDATE_MIN';
// export const UPDATE_DAY = 'UPDATE_DAY';
// export const UPDATE_RECURRING_DAY = 'UPDATE_RECURRING_DAY';
// export const UPDATE_LATER_HOUR = 'UPDATE_LATER_HOUR';
// export const UPDATE_LATER_MIN = 'UPDATE_LATER_MIN';
// export const UPDATE_TIME = 'UPDATE_TIME';
// export const UPDATE_START_DATE = 'UPDATE_START_DATE';
// export const UPDATE_ARR_SLOT_TIME_HOUR1 = 'UPDATE_ARR_SLOT_TIME_HOUR1';
// export const UPDATE_ARR_SLOT_TIME_MIN1 = 'UPDATE_ARR_SLOT_TIME_MIN1';
// export const UPDATE_GREETING_OPTION = 'UPDATE_GREETING_OPTION';
// export const UPDATE_GREETING_OPTION_TIME_MIN1 = 'UPDATE_GREETING_OPTION_TIME_MIN1';
// export const UPDATE_GREETING_OPTION_TIME_HOUR1 = 'UPDATE_GREETING_OPTION_TIME_HOUR1';
// export const UPDATE_RECURRING_TIME_HOUR1 = 'UPDATE_RECURRING_TIME_HOUR1';
// export const UPDATE_RECURRING_TIME_MIN1 = 'UPDATE_RECURRING_TIME_MIN1';
// /* TIME SCHEDULE SETTING END */

// /* vioce mail modal start */
// export const FETCH_PRE_RECORDING = 'FETCH_PRE_RECORDING';
// export const STORE_PRE_RECORDING = 'STORE_PRE_RECORDING';

// export const STORE_CAMPAIGN_STEP_SETTING  = 'STORE_CAMPAIGN_STEP_SETTING';
// export const STORE_CAMPAIGN_STEP_SETTING_PRE_TEMP  = 'STORE_CAMPAIGN_STEP_SETTING_PRE_TEMP';
// export const UPDATE_CAMPAIGN_STEP_SETTING  = 'UPDATE_CAMPAIGN_STEP_SETTING';
// export const UPDATE_CAMPAIGN_STEP_SETTING_WITH_MULTIMEDIA  = 'UPDATE_CAMPAIGN_STEP_SETTING_WITH_MULTIMEDIA';
// export const UPDATE_CAMPAIGN_STEP_SETTING_WITH_PREVIOUS_FILE  = 'UPDATE_CAMPAIGN_STEP_SETTING_WITH_PREVIOUS_FILE';
// export const UPDATE_CAMPAIGN_STEP_SETTING_WITH_PRE_TEMP = 'UPDATE_CAMPAIGN_STEP_SETTING_WITH_PRE_TEMP';

// /* vioce mail modal end */

// /* VIDEO EMAIL START */
// export const FETCH_VIDEO_FOLDERS = 'FETCH_VIDEO_FOLDERS';
// export const STORE_VIDEO_FOLDERS = 'STORE_VIDEO_FOLDERS';
// export const FETCH_VIDEOS = 'FETCH_VIDEOS';
// export const STORE_VIDEOS = 'STORE_VIDEOS';
// export const DELETE_VIDEOS = 'DELETE_VIDEOS';
// export const UPDATE_FOLDER_ID = 'UPDATE_FOLDER_ID';
// /* VIDEO EMAIL END */

// export const DELETE_CAMPAIGN_SETTING = 'DELETE_CAMPAIGN_SETTING';

// /* MESSAGE TEMPLATE START */
// export const FETCH_MESSAGE_TEMPLATES = 'FETCH_MESSAGE_TEMPLATES';
// export const DELETE_MESSAGE_TEMPLATE = 'DELETE_MESSAGE_TEMPLATES';
// export const STORE_MESSAGE_TEMPLATE = 'STORE_MESSAGE_TEMPLATE';
// export const SET_FILTER_MESSAGE_TEMPLATE = 'SET_FILTER_MESSAGE_TEMPLATE';
// export const SET_LOADING_MESSAGE_TEMPLATE = 'SET_LOADING_MESSAGE_TEMPLATE';

/* MESSAGE TEMPLATE END */
//Card broadcast
export const GET_MAIL_CATEGORY_IN_CARD_BROADCAST = 'GET_MAIL_CATEGORY_IN_CARD_BROADCAST';
export const SET_MAIL_CATEGORY_IN_CARD_BROADCAST = 'SET_MAIL_CATEGORY_IN_CARD_BROADCAST';
export const GET_MAIL_TEMPLATES_IN_CARD_BROADCAST = 'GET_MAIL_TEMPLATES_IN_CARD_BROADCAST';

export const GET_POSTCARD_CATEGORY_IN_CARD_BROADCAST = 'GET_POSTCARD_CATEGORY_IN_CARD_BROADCAST';
export const SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST = 'SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST';
export const GET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST = 'GET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST';
export const SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST = 'SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST';
export const GET_LETTER_CATEGORY_IN_CARD_BROADCAST = 'GET_LETTER_CATEGORY_IN_CARD_BROADCAST';
export const SET_LETTER_CATEGORY_IN_CARD_BROADCAST = 'SET_LETTER_CATEGORY_IN_CARD_BROADCAST';
export const GET_GIFT_CATEGORY_IN_CARD_BROADCAST = 'GET_GIFT_CATEGORY_IN_CARD_BROADCAST';
export const SET_GIFT_CATEGORY_IN_CARD_BROADCAST = 'SET_GIFT_CATEGORY_IN_CARD_BROADCAST';
export const CREATE_POSTCARD_TEMPLATE_IN_CARD_BROADCAST = 'CREATE_POSTCARD_TEMPLATE_IN_CARD_BROADCAST';

export const GET_PERSONALIZEFIELDS_IN_CARD_BROADCAST= 'GET_PERSONALIZEFIELDS_IN_CARD_BROADCAST';
export const SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST= 'SET_PERSONALIZEFIELDS_IN_CARD_BROADCAST';

// // for audio player
// export const STORE_AUDIO_PLAYER = 'STORE_AUDIO_PLAYER';

// // copy campaign
// export const COPY_CAMPAIGN = 'COPY_CAMPAIGN';

// export const CAMPAIGN_ENABLE_TO_START = 'CAMPAIGN_ENABLE_TO_START';
// export const STORE_CAMPAIGN_ENABLE_TO_START = 'STORE_CAMPAIGN_ENABLE_TO_START';

// export const GET_CAMPAIGN_BASIC_INFO = 'GET_CAMPAIGN_BASIC_INFO';
// export const CAMPAIGN_CHECK_TO_START = 'CAMPAIGN_CHECK_TO_START';
// export const CAMPAIGN_START = 'CAMPAIGN_START';
// export const CAMPAIGN_PAUSE = 'CAMPAIGN_PAUSE';

// // quick reply template
// export const ADD_QUICK_REPLY_TEMPLATE = 'ADD_QUICK_REPLY_TEMPLATE';

// export const CREATE_NEW_CAMPAIGN = 'CREATE_NEW_CAMPAIGN';
// export const FETCH_LEADFLOW = 'FETCH_LEADFLOW';

