/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-multi-str */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, {useState, useEffect, useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";
import CustomSelectBox from "../../Common/CustomSelectBox";
import {
    fetchCampaignTimelines,
    followupSave,
    getCustomFieldData,
    updateArrSlotTimeHour1,
    updateArrSlotTimeMin1,
    updateDay,
    updateEmailStepSetting,
    updateGreetingOption,
    updateGreetingsOptionTimeHour1,
    updateGreetingsOptionTimeMin1,
    updateHour,
    updateLaterHour,
    updateLaterMin,
    updateMin,
    updateRecurringDay,
    updateRecurringTimeHour1,
    updateRecurringTimeMin1,
    updateStartDate,
    updateTabValue,
    updateTime,
} from "../../../actions/marketingAction";
import {connect} from "react-redux";
import ScheduleSetting from "./ScheduleSetting";
import moment from "moment";
import CoreConstants from "../../../constants/CoreConstants";
import { image_upload_handler } from "../../../api/rootApi";
import {fetchFormList, getMessageTemplate, testEmailSend} from "../../../api/marketingApi";
import GlobalModal from "../../globals/Modal/GlobalModal";
import SimpleReactValidator from "simple-react-validator";

import Uploader from '../../Common/Uploader/Uploader';
import QucikReplyCard from "../../QuickReplyCard";
import GlobalCustomFieldGrouped from "../../globals/GlobalCustomFieldGrouped/Index";

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box p={3}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

const EmailModal = props => {
    const self = this;
    const [, forceUpdate] = useState()
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));

    const editorRef = useRef(null);
    const textInput = useRef(null);
    const bottomRef = useRef(null)
    const testEmailRef = useRef(null)
    const [bottomScroll, setBottomScroll] = useState(false)
    const [isSendTestEmail, setIsSendTestEmail] = useState(false);

    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [campaignDetails, setCampaignDetails] = React.useState(props.campaignDetails);
    // eslint-disable-next-line no-unused-vars
    const [showAllTimeSetting, setShowAllTimeSetting] = useState(props.showAllTimeSettings);

    const [tabValue, setTabValue] = useState(props.tabValue);
    const [hour, setHour] = useState(props.hour);
    const [min, setMin] = useState(props.min);
    const [day, setDay] = useState(props.day);
    const [time, setTime] = useState(props.time);
    const [startDate, setStartDate] = useState(props.startDate);
    const [greetingOption, setGreetingOption] = useState(props.greetingOption);

    // for update
    const [updateSetting, setUpdateSetting] = useState(false)
    const [editableCampaignSettingId, setEditableCampaignSettingId] = useState(null)

    const [attachmentFiles, setAttachmentFiles] = useState([])
    const [editAttachmentFiles, setEditAttachmentFiles] = useState([])
    /* for quick replies */
    const [isShowQuickReplies, setIsShowQuickReplies] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [needToloadMoreTemplate, setNeedToloadMoreTemplate] = useState(false);
    const [loadingMoreTemplate, setLoadingMoreTemplate] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [currentPage, setCurrentPage] = useState(0)
    const [linkList, setLinkList] = useState([])

    const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    useEffect(() => {
        setTabValue(props.tabValue);
        setHour(props.hour);
        setMin(props.min);
        setDay(props.day);
        setTime(props.time);
        setStartDate(props.startDate);
        setGreetingOption(props.greetingOption);


        // setTimeout(() => {
        //     setEmailBody('<p className="test" id="this_is_id" >expand it</p>');
        // }, 5000);

    }, [
        props.tabValue, props.hour, props.min, props.day, props.recurringDay, props.laterHour, props.laterMin, props.time, props.startDate, props.arrSlotTimeHour1, props.arrSlotTimeMin1, props.greetingOption, props.greetingsOptionTimeMin1, props.greetingsOptionTimeHour1, props.recurringTimeHour1, props.recurringTimeMin1,
    ]);

    useEffect(() => {
        if (props.currentClip != null) {
            setUpdateSetting(true);
            setEditableCampaignSettingId(props.currentClip.id);
            setSubject(props.currentClip.subject);
            setEmailBody(props.currentClip.message);

            if (
                props.currentClip.attached_url !== undefined && 
                props.currentClip.attached_url !== null && 
                props.currentClip.attached_url !== "" &&
                props.currentClip.attached_url !== "[]" &&
                props.currentClip.attached_url !== '\"[]\"' &&
                typeof props.currentClip.attached_url === 'string'
            ){
                setEditAttachmentFiles(JSON.parse(props.currentClip.attached_url))
            }
        }
        else{
            let content = '<br />';
            if(typeof props?.userInfo?.show_appointment_url != 'undefined'){
                content  += (props.userInfo.show_appointment_url == 1 ? (props.userInfo.appointment_url ? props.userInfo.appointment_url : '') : '');
            }

            if(typeof props?.userInfo?.show_signature != 'undefined'){
                content += props.userInfo.show_signature == 1 ? (props.userInfo.signature ? props.userInfo.signature : '') : '';
            }
            setEmailBody(content);
        }
    }, [updateSetting])

    useEffect(() => {
        setCampaignDetails(props.campaignDetails);
    }, [props.campaignDetails]);

    useEffect(() => {
        // let content = '<br />';
        // if(typeof props?.userInfo?.show_appointment_url != 'undefined'){
        //     content  += (props.userInfo.show_appointment_url == 1 ? (props.userInfo.appointment_url ? props.userInfo.appointment_url : '') : '');
        // }

        // if(typeof props?.userInfo?.show_signature != 'undefined'){
        //     content += props.userInfo.show_signature == 1 ? (props.userInfo.signature ? props.userInfo.signature : '') : '';
        // }
        // setEmailBody(content);
        // console.log(content)
        // if(editorRef.current){
        //     editorRef.current.insertContent(content);
        // }
    }, [])


    const handleBodyPersonalize = (event) => {
        editorRef.current.insertContent(event);
        setTimeout(() => {
            editorRef.current.focus();
        }, 500);
    }

    const handleSubjectPersonalize = (event) => {
        let start = textInput.current.selectionStart;
        let end = textInput.current.selectionEnd;
        let text = textInput.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event + after);

        setSubject(newText);

        setTimeout(() => {
            //Lines below not works without timeout
            textInput.current.selectionStart = textInput.current.selectionEnd = start + event.length;
            textInput.current.focus();
        }, 500);
    }

    const sendText = () => {
        // check validation
        if(subject.trim() == ''){
            if(window.showNotification !== undefined){
                window.showNotification("WARNING", 'Can not save settings without subject');
            }
            document.getElementById("email-setting-subject-input-field").focus();
            return false
        }
        else if(emailBody == ''){
            if(window.showNotification !== undefined){
                window.showNotification("WARNING", 'Can not save settings without mail body text');
            }
            editorRef.current.focus()
            return false
        }

        let type;
        switch (tabValue) {
            case 1:
            case 2:
                type = CoreConstants.SENDING_TYPE_SCHEDULE;
                break;
            case 0:
                type = CoreConstants.SENDING_TYPE_IMMEDIATE;
                break;
            default:
                type = tabValue;
                break;
        }

        let formData = new FormData();
        formData.append('subject', subject);
        formData.append('message', emailBody);
        formData.append('message_type', CoreConstants.MESSAGE_TYPE_EMAIL);
        formData.append('nScheduleHours', hour);
        formData.append('nScheduleMinutes', min);
        formData.append('nScheduleDays', day);
        formData.append('followupScheduleTime', time);
        formData.append('campaign_id', campaignDetails.basic.id);
        formData.append('type', type);
        formData.append('day_type', (tabValue != 0 ? tabValue : 1));
        formData.append('date', moment(startDate).format('YYYY-MM-DD'));
        formData.append('greeting_option', greetingOption);

        let lists = attachmentFiles;
        let finalList = [];
        lists.forEach(function(list) {
            let name = list.name;
            if(  name !== undefined){
                finalList.push({
                    'file_name': list.file_original_name !== undefined ? list.file_original_name : name,
                    'file_url':name
                })
            }
        });

        if(updateSetting && editAttachmentFiles.length > 0){
            finalList = [...editAttachmentFiles, ...finalList]
        }

        formData.append('attached_url', JSON.stringify(finalList));
        if(updateSetting){
            formData.append('id', editableCampaignSettingId);
            props.updateEmailStepSetting(formData, () => {
                props.handleRemoveCurrentClip()
                props.fetchCampaignTimelines({
                    campaignId: campaignDetails.basic.id
                });
                props.setOpenEmailModal(false)
            })
        }
        else{
            props.followupSave(formData, successFollowupAdd);
        }
    }

    const successFollowupAdd = (response) => {
        props.fetchCampaignTimelines({
            campaignId: campaignDetails.basic.id
        });
        props.setOpenEmailModal(false)
    }

    const scrollToBottom = () => {
        if(!bottomScroll){
            bottomRef.current.scrollIntoView({behavior: "smooth"})
            setBottomScroll(true)
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const sendTestEmail = (e) => {
        e.preventDefault();
        let test_email = testEmailRef.current.value;

        if (test_email == "" && !validateEmail(test_email)) {
            window.showNotification('ERROR', "Please insert a valid email");
            return;
        }

        if(!validator.current.allValid()){
            window.showNotification('ERROR', "Valid email subject and message are not found");
            return;
        }

        if (!isSendTestEmail) {
            setIsSendTestEmail(true);
            testEmailSend({
                email: test_email,
                subject: subject,
                message: emailBody,
                campaign_id: campaignDetails.basic.id,
            }).then(response => {
                setIsSendTestEmail(false);
                if (response.data.status) {
                    window.showNotification('SUCCESS', response.data.message);
                } else {
                    window.showNotification('ERROR', response.data.message);
                }
            }).catch(error => {
                window.showNotification('ERROR', 'Something wrong! Try again');
                setIsSendTestEmail(false);
            })
        }
    }

    const handleAttachment = (images) => {
        setAttachmentFiles(images)
    }

    /* for quick replies */
    const handleQuickReplyView = () => {
        setIsShowQuickReplies(true);
        if(templates.length <= 0){
            if(loadingTemplate){
                return false;
            }
            setLoadingTemplate(true)
            getAllTemplates({ 
                page: 0,
                perPage: 20,
                search_key: "",
                type: [CoreConstants.MESSAGE_TEMPLATE_EMAIL]
            })
        }
        
    }

    const getAllTemplates = (params) => {
        /* 
        page: 0
        perPage: 20
        search_key: ""
        type: ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
        
        */
        getMessageTemplate(params)
        .then(res => {
            let response = res.data;
            if(response.status === 'success'){
                if(response.html.length >= 20){
                    setNeedToloadMoreTemplate(true)
                    setCurrentPage(currentPage + 1)
                }else{
                    setNeedToloadMoreTemplate(false)
                }
                setTemplates([...templates, ...response.html])
                setLoadingTemplate(false)
                setLoadingMoreTemplate(false)
            }
            else{
                setLoadingTemplate(false)
                setLoadingMoreTemplate(false)
                setNeedToloadMoreTemplate(false)
            }
        }).catch(err => {
            setLoadingTemplate(false)
            setLoadingMoreTemplate(false)
            setNeedToloadMoreTemplate(false)
        })
    }


    const handleOnScroll = (e) => {
        if(
            needToloadMoreTemplate && 
            !loadingMoreTemplate && 
            Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
        ) {
            setLoadingMoreTemplate(true)
            getAllTemplates({ 
                page: currentPage,
                perPage: 20,
                search_key: "",
                type: [CoreConstants.MESSAGE_TEMPLATE_EMAIL]
            })
        }


        // if(loadingMoreTemplate){
        //     return false;
        // }
        // let container = document.getElementById("quick__reply_list__main__container_settings");
        // if(container.scrollTop > quickRepliesScrollTopSettings){
        //     let innrerHeight = container.clientHeight;
        //     let dif = container.scrollHeight - (innrerHeight + container.scrollTop);
        //     if ( dif < 100 && needToloadMoreTemplate) {
                
        //     }
        // }
        // quickRepliesScrollTopSettings = container.scrollTop;
    }

    const showTemplates = () => {
        let showTemplates = []
        templates.map((item, index) => {
            showTemplates.push(
                <QucikReplyCard
                    data={item} 
                    key={index}
                    index={index}
                    onSelect={(item) => {
                        setSubject(item.subject)
                        setEmailBody(item.message)
                        setIsShowQuickReplies(false)
                    }}
                    className={'main-content-from-quick-reply'}
                />
            )
        })
        return showTemplates;
    }

    const renderEditAttachments = () => {
        let files = []
        if(editAttachmentFiles.length === 0){
            return null;
        }
        editAttachmentFiles.map((item, index) => {
            files.push(
                <div className="each__edit__attachment" key={index}>
                    <span>{item.file_name}</span>
                    <span className="each__edit__attachment__delete" onClick={() => deleteAttachmentForEdit(index)}>DELETE</span>
                </div>
            )
        })
        return files;
    }

    const deleteAttachmentForEdit = (index) => {
        let files = [...editAttachmentFiles];
        files = editAttachmentFiles.filter((item, indexFile) => indexFile != index)
        setEditAttachmentFiles(files)
    }

    const renderMain = () => {
        return(
            <>
            <div className="row mb-0">  
                <div className="col m8 s12 top_subject_personalize">
                    <div className="row">
                        <div className="col s2">
                            <h5 className="text-dark-blue text-left">Subject:</h5>
                        </div>
                        <div className="col s7">
                            <div className="input-field subject_input selects_fields">
                                <input id="email-setting-subject-input-field" type="text" placeholder="Enter your subject" value={subject} onChange={(e) => setSubject(e.target.value)} ref={textInput}/>
                            </div>
                            {validator.current.message('subject', subject, 'required|string')}
                        </div>
                        <div className="col s3 personiliz-input-field">
                            <GlobalCustomFieldGrouped className={"marketing__module__email_modal"} labelText={"Personalize"} handleSelect={handleSubjectPersonalize}/>
                        </div>
                    </div>
                </div>
            </div>

            {/*Massaging Editor*/}
            <div className="tiny-mce mt-3 height-380">
                <div className="mb-0 row parent-height">
                    <div className="col s12 email_editor parent-height">
                        <Editor
                            apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                            onInit={(evt, editor) => editorRef.current = editor}
                            value={emailBody}
                            cloudChannel='5-dev'
                            textareaName='email_reply'
                            init={{
                                height: 500,
                                auto_focus: true,
                                browser_spellcheck: true,
                                // menubar: false,
                                menu: {
                                    favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                                },
                                menubar: 'favs file edit view insert format tools table help',
                                plugins: [
                                    'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                    'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                    'insertdatetime media table contextmenu code help wordcount template'
                                ],
                                convert_urls: false,
                                toolbar:
                                    'insertfile undo redo | styleselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | link image | \
                                    bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                imagetools_proxy: emailUploadFileApi,
                                images_upload_handler: image_upload_handler,
                                automatic_uploads: true,
                                file_picker_types: 'file image media',
                                target_list: [
                                    {title: 'Same page', value: '_self'},
                                    {title: 'New page', value: '_blank'},
                                ],
                                link_list: function(success) {
                                    let view = [];
                                    fetchFormList().then(res => {
                                        if(res.data.status != 'undefined') {
                                            view = res.data.html;
                                            success(view);
                                        }
                                    });
                                },
                                content_style: "p { margin: 0px; }"
                            }}

                            onEditorChange={setEmailBody}
                            id="email-setting-body-input-field"
                        />
                        {/*<textarea id="basic-example" className="materialize-textarea" placeholder="Type a message"  onChange={(event) => setConversationText(event.target.value)} ref={textInput} value={conversationText}/>*/}
                    </div>
                    {validator.current.message('subject', emailBody, 'required|string')}
                </div>
            </div>

            {/*Massage Editor Bottom Button*/}
            <div className="send-button-area">
                <div className="mb-0 row">
                    <div className="">
                        <div className="mb-0 quick_reply_hanlde__wr__v2">
                            <div className="input-field">
                                <GlobalCustomFieldGrouped className={"marketing__module__email_modal_body"} labelText={"Personalize"} handleSelect={handleBodyPersonalize}/>
                            </div>
                            <div className="pl-1">
                                <div className="quick-reply-old quick_reply_hanlde_button" onClick={handleQuickReplyView}>
                                    <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"></path>
                                    </svg>
                                    Use Quick Reply
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="g__attachment__content">
                {editAttachmentFiles.length > 0 &&
                    <div className="edit___attachments___contetn">
                        {renderEditAttachments()}
                    </div>
                }
                <Uploader imagesList={(list) => handleAttachment(list)} updateComponent={ attachmentFiles }/>
            </div>

            {/*Massage Editor Bottom Button*/}
            <ScheduleSetting showAllTimeSetting={showAllTimeSetting} tabChangeCallback={scrollToBottom}/>
            <div ref={bottomRef}></div>

            <div className="send_test_wrapper mt-5">
                <div className="send_test">
                    <label className="mb-2">Send Test Email</label>
                    <div className="inputs">
                        <input type="email" className="form-control" ref={testEmailRef} placeholder="Your email here..."/>
                        <a href="#" className="btn accent--bg--text--color" onClick={(e) => sendTestEmail(e)}>{isSendTestEmail ? 'Sending ...' : 'Send Test Email'}</a>
                    </div>
                </div>
            </div>
            </>
        )
    }

    const renderTemplate = () => {
        return(
            <div className="awesome__scroll_bar campaign_setting_template_container" onScroll={handleOnScroll} id="quick__reply_list__main__container_settings">
                {loadingTemplate ? 
                    <div className="loading_content">Please wait...</div>
                    :
                    <div className="campaign_setting_template_body">
                        {templates.length > 0 ? 
                            <div className="templates___body">
                                <div className="templates___body__back_button" onClick={() => setIsShowQuickReplies(false)}>{'<< Back'}</div>
                                {showTemplates()}
                            </div>
                            :
                            <div className="empty_template_body">No email templated added</div>
                        }
                    </div>
                }
            </div>
        )
    }

    const renderView = () => {
        if(isShowQuickReplies){
            return renderTemplate()
        }
        return renderMain()
    }

    return (
        <GlobalModal 
            open={props.openEmailModal} 
            onClose={props.setOpenEmailModal}
            title="Email Followup" 
            onSubmit={sendText}
            buttonText={'Set Followup Email'}
            buttonIcon={
                <svg width="16" height="16" viewBox="0 0 14 12" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z"
                        fill="#FFFFFF"/>
                </svg>
            }
            className="global-large-modal campaign__setting__email"
            extra_props_active={true}
        >
            {renderView()}
        </GlobalModal>
    );
}

const mapStateToProps = state => {
    return {
        customFieldData: state.marketingReducer.customFieldData,
        userInfo: state.marketingReducer.userInfo,
        campaignDetails: state.marketingReducer.campaignDetails,

        tabValue: state.marketingReducer.tabValue,
        hour: state.marketingReducer.hour,
        min: state.marketingReducer.min,
        day: state.marketingReducer.day,
        recurringDay: state.marketingReducer.recurringDay,
        laterHour: state.marketingReducer.laterHour,
        laterMin: state.marketingReducer.laterMin,
        time: state.marketingReducer.time,
        startDate: state.marketingReducer.startDate,
        arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
        arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
        greetingOption: state.marketingReducer.greetingOption,
        greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
        greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
        recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
        recurringTimeMin1: state.marketingReducer.recurringTimeMin1,
        formList: state.marketingReducer.formList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomFieldData: () => dispatch(getCustomFieldData()),
        followupSave: (params,callback) => dispatch(followupSave(params, callback)),
        updateEmailStepSetting: (params, callback) => dispatch(updateEmailStepSetting(params, callback)),
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),

        // for time settings
        updateTabValue: (params) => dispatch(updateTabValue(params)),
        updateHour: (params) => dispatch(updateHour(params)),
        updateMin: (params) => dispatch(updateMin(params)),
        updateDay: (params) => dispatch(updateDay(params)),
        updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        updateTime: (params) => dispatch(updateTime(params)),
        updateStartDate: (params) => dispatch(updateStartDate(params)),
        updateArrSlotTimeHour1: (params) => dispatch(updateArrSlotTimeHour1(params)),
        updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
        updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
        updateGreetingsOptionTimeMin1: (params) => dispatch(updateGreetingsOptionTimeMin1(params)),
        updateGreetingsOptionTimeHour1: (params) => dispatch(updateGreetingsOptionTimeHour1(params)),
        updateRecurringTimeHour1: (params) => dispatch(updateRecurringTimeHour1(params)),
        updateRecurringTimeMin1: (params) => dispatch(updateRecurringTimeMin1(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailModal);