import React from "react";
import Drawer from "@material-ui/core/Drawer";
import "./style.css"

const CustomDrawerPype = (props) =>  {
    const toggleDrawer = (open) => (event, reason) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        if(props.backdropClick !== undefined && !props.backdropClick && reason === "backdropClick"){
            return;
        }
        if(props.hanldeDrawer !== undefined){
            props.hanldeDrawer(open)
        }
    };

    const renderEmptyBox = () => {
        return (
            <div className="drawer_empty_container">
                No data found !
            </div>
        )
    }

    return (
        <Drawer 
            open={props.isShow} 
            onClose={toggleDrawer(false)} 
            anchor={props.anchor}
            className={`${props.drawerClassName}`}
            ModalProps={{
                disableEnforceFocus: true,
                disableAutoFocus: true,
            }}
        >
            <div className={`wbh_custom_drawer_wrp ${props?.wrapperClass} ${props.customWidthClass}`} style={props.width !== undefined ? {width: props.width} : {}} >
                {props?.renderChild ? props.renderChild() : renderEmptyBox()}
            </div>
        </Drawer>
    );
}
export default CustomDrawerPype;