import { Icons } from "./Icons";

const Actions = ({onChange, value, actions, is_continue_after_fail}) => {
    const renderEachAction = () => {
        const view = [];
        actions.forEach((item, index) => {
            // eslint-disable-next-line eqeqeq
            if(index !== 0 && is_continue_after_fail == 1){
                view.push(
                    <div key={index} className="mrfcm_ac_each_action">
                        <span className="mrfcm_ac_ea_icon" onClick={() => onChange(item.value)}>{item.value === value ? Icons.radioCheck : Icons.radioUncheck}</span>
                        {/* <span className="mrfcm_ac_ea_title">{item.title}</span> */}
                        <div className="mrfcm_ac_ea_info_container">
                            <span className="mrfcm_ac_ea_title">{item.title}</span>
                            {item.des !== undefined &&
                            <span className="mrfcm_ac_ea_des">{item.des}</span>
                            }
                        </div>
                    </div>
                );
            }
            else{
                view.push(
                    <div key={index} className="mrfcm_ac_each_action">
                        <span className="mrfcm_ac_ea_icon" onClick={() => onChange(item.value)}>{item.value === value ? Icons.radioCheck : Icons.radioUncheck}</span>
                        {/* <span className="mrfcm_ac_ea_title">{item.title}</span> */}
                        <div className="mrfcm_ac_ea_info_container">
                            <span className="mrfcm_ac_ea_title">{item.title}</span>
                            {item.des !== undefined &&
                            <span className="mrfcm_ac_ea_des">{item.des}</span>
                            }
                        </div>
                    </div>
                ); 
            }
        });
        return view;
    }

    return (
        <div className="mrfcm_action_container">
            {renderEachAction()}
        </div>
    );
}
export default Actions;