import React from 'react'
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CustomBootstrapTooltip from '../Common/CustomBootstrapTooltip';

export const MessagingServiceListTable = (props) => {

    const renderServiceList = () => {

        if(props.data.length === 0) {
            return <tr key={1} className="messaging_list_each_service__empty">
                        <td colSpan={4}>No service created yet !</td>
                    </tr>
        }

        return props.data.map((item, index) => (
            <tr key={index} className="messaging_list_each_service">
                <td className="messaging__service__for__underline">
                    <CustomBootstrapTooltip arrow title="Click here to configure" placement="top">
                        <span className='messaging_service_link' onClick={() => props.onSelectService(item)}>{item.messaging_service_name}</span>
                    </CustomBootstrapTooltip>
                </td>
                <td>{item.messaging_service_sid}</td>
                {
                    item.a2p_10dlc_status === 0 ? 
                        <td className="not__registered align-items-center d-flex"><WarningIcon className='mr-1'/><span> Not Registered</span></td> :
                    item.a2p_10dlc_status === 1 ?
                        <td className="not__registered align-items-center d-flex"><CheckCircleIcon className='mr-1'/><span>Registered</span></td> :
                        <td className="not__registered align-items-center d-flex"><MoreHorizIcon className='mr-1'/><span>Pending</span></td>
                }
                   
            </tr>
        ));
    }

    return (
        <div className="messaging_service_list">
            <table className="cam__list__content_table">
                <thead className="list__content_table__header">
                    <th className="cam__list__contant__1st">Messaging Service Name</th>
                    <th className="cam__list__contant__2nd">Messaging Service SID</th>
                    <th className="cam__list__contant__3rd">US A2P 10DLC Status</th>
                </thead>
                <tbody className="table__body">
                    {renderServiceList()}
                </tbody>
            </table>
        </div>
    )
}
