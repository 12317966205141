import { SkeletonRectangle } from "../../components/skeleton/SkeletonRectangle";

const CampaignListSkeleton = () => {
    const renderSkeletons = () => {
        const view = [];
        for(let i = 0; i < 5; i++){
            view.push(
                <div className="mlvc_each_campaign_body_wrapper" key={i}>
                    <div className={`mlvc_each_campaign_body`}>
                        {/* top bar */}
                        <div className="mlvc_ecb_top_bar">
                            {/* left side */}
                            <div className="mlvc_ecb__tb_left" style={{width:"60%"}}>
                                <span className="mlvc_ecb__tb_left_title"><SkeletonRectangle height="24px" width="auto"/></span>
                                {/* description */}
                                <div className="mlvc_ecb__tb_left_row">
                                    <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_feature">
                                    <span><SkeletonRectangle height="17px" width="70px"/></span>
                                    </div>
                                    <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_date">
                                        <span><SkeletonRectangle height="17px" width="20px"/></span>
                                        <span><SkeletonRectangle height="17px" width="50px"/></span>
                                    </div>
                                    <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_folder_title">
                                        {/* folder info */}
                                        <span><SkeletonRectangle height="17px" width="20px"/></span>
                                        <span><SkeletonRectangle height="17px" width="50px"/></span>
                                    </div>
                                </div>
                            </div>
                            {/* right side */}
                            <div className="mlvc_ecb__tb_right">
                            
                                <div className={`mlvc_ecb_sc_right`}>
                                
                                    <span><SkeletonRectangle height="20px" width="60px"/></span>
                                    <span><SkeletonRectangle height="20px" width="20px"/></span>
                                </div>
                            </div>
                        </div>
                        {/* stats */}
                        <div className="mlvc_ecb_stats_container">
                            <div className="mlvc_ecb_sc_left">
                                <div className="mlvc_ecb_sc_l_each_stats">
                                    <div className="mlvc_ecb_sc_l_each_stats_icon"><SkeletonRectangle borderRadius="50%" height="28px" width="28px"/></div>
                                    <div className="mlvc_ecb_sc_l_each_stats_content">
                                        <span className="mlvc_ecb_sc_l_each_stats_content_value"><SkeletonRectangle height="10px" width="16px"/></span>
                                        <span className="mlvc_ecb_sc_l_each_stats_content_tite"><SkeletonRectangle height="10px" width="50px"/></span>
                                    </div>
                                </div>
                                <div className="mlvc_ecb_sc_l_each_stats">
                                    <div className="mlvc_ecb_sc_l_each_stats_icon"><SkeletonRectangle borderRadius="50%" height="28px" width="28px"/></div>
                                    <div className="mlvc_ecb_sc_l_each_stats_content">
                                        <span className="mlvc_ecb_sc_l_each_stats_content_value"><SkeletonRectangle height="10px" width="16px"/></span>
                                        <span className="mlvc_ecb_sc_l_each_stats_content_tite"><SkeletonRectangle height="10px" width="50px"/></span>
                                    </div>
                                </div>
                                
                                <div className="mlvc_ecb_sc_l_each_stats">
                                    <div className="mlvc_ecb_sc_l_each_stats_icon"><SkeletonRectangle borderRadius="50%" height="28px" width="28px"/></div>
                                    <div className="mlvc_ecb_sc_l_each_stats_content">
                                        <span className="mlvc_ecb_sc_l_each_stats_content_value"><SkeletonRectangle height="10px" width="16px"/></span>
                                        <span className="mlvc_ecb_sc_l_each_stats_content_tite"><SkeletonRectangle height="10px" width="50px"/></span>
                                    </div>
                                </div>
                                
                                <div className="mlvc_ecb_sc_l_each_stats">
                                    <div className="mlvc_ecb_sc_l_each_stats_icon"><SkeletonRectangle borderRadius="50%" height="28px" width="28px"/></div>
                                    <div className="mlvc_ecb_sc_l_each_stats_content">
                                    <span className="mlvc_ecb_sc_l_each_stats_content_value"><SkeletonRectangle height="10px" width="16px"/></span>
                                        <span className="mlvc_ecb_sc_l_each_stats_content_tite"><SkeletonRectangle height="10px" width="50px"/></span>
                                    </div>
                                </div>
                                
                                <div className="mlvc_ecb_sc_l_each_stats">
                                    <div className="mlvc_ecb_sc_l_each_stats_icon"><SkeletonRectangle borderRadius="50%" height="28px" width="28px"/></div>
                                    <div className="mlvc_ecb_sc_l_each_stats_content">
                                    <span className="mlvc_ecb_sc_l_each_stats_content_value"><SkeletonRectangle height="10px" width="16px"/></span>
                                        <span className="mlvc_ecb_sc_l_each_stats_content_tite"><SkeletonRectangle height="10px" width="50px"/></span>
                                    </div>
                                </div>
                            </div>

                            <div className="mlvc_ecb_sc_right">
                            <span><SkeletonRectangle height="20px" width="60px"/></span>
                            <span><SkeletonRectangle height="20px" width="20px"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return view;
    }
    return (renderSkeletons());
}
export default CampaignListSkeleton;