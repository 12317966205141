import * as Action from "./ActionTypes";

const SWHKReducer = (state, action) =>{
    switch (action.type){
        case Action.HANDLE_STATE:
            return {
              ...state,
              ...action.payload
            };
        default:
            return state;
    }
};

export default SWHKReducer;