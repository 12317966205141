import { handleStates } from "../reducer/Actions";
import { useMLv2Context } from "../reducer/MLV2Provider";
import CampaignList from "./campaign-list/CampaignList";
import CampaignListTitleBar from "./campaign-list/CampaignListTitleBar";
import CampaignListTopBar from "./campaign-list/CampaignListTopBar";
import CampaignPagination from "./campaign-list/CampaignPagination";
import EmptyList from "./campaign-list/EmptyList";
import CampaignFolder from "./CampaignFolder";
import ScreenWiseTopBar from "./ScreenWiseTopBar";

const CampaignBody = () => {
    const {state, dispatch} = useMLv2Context();

    window.campaignStatusSocketProcess = (campaign_id = null) => {
        console.log(campaign_id)
        //campaignIdFromSocket
        if(campaign_id !== null){
            let old_socket = [...state.campaignIdFromSocket]
            if(old_socket.indexOf(campaign_id) !== -1){
                console.log("--1--")
                dispatch(handleStates({
                    resetSwitchForSocket: campaign_id
                }));
            }else{
                console.log("--2--")
                old_socket.push(campaign_id)
                dispatch(handleStates({
                    campaignIdFromSocket: old_socket
                }));
            }
        }
    }

    const is_empty = (state.campaignList.length > 0 || state.gettingList) ? false : true;

    return (
        <div className={`mlvc_body_container ${window.screen.width <= 1240 ? 'mlvc_bc_flex_row' : ''}`}>
            {/* left side */}
            {window.screen.width > 1240 && <CampaignFolder /> }
            {(window.screen.width > 768 && window.screen.width <=1240) && <ScreenWiseTopBar /> }

            {/* right side */}
            <div className={`mlvc_body_container_right ${is_empty ? 'mlvc_bcr_empty' : ''}`}>
                {/* <div className="mlvc_bcr_c_top_search_bar"><CampaignPagination /></div> */}
                <CampaignListTopBar className={is_empty ? 'mlvc_bcr_c_top_b_empty' : ''} />
                
                {/* <CampaignListTitleBar className={is_empty ? 'mlvc_bcr_c_title_b_empty' : ''} /> */}
                {/* mlvc_body_container_middle_bottom_not_fixed : mlvc_body_container_middle_bottom_fixed */}
                <div className={`mlvc_body_container_middle_bottom_fixed ${is_empty ? 'mlvc_bcmbf_empty' : ''}`}> {/* mlvc_body_container_middle_bottom_not_fixed */}
                    <div className={`mlvc_body_container_middle  awesome__scroll_bar ${is_empty ? 'mlvc_bcm_empty' : ''}`}>
                        <CampaignList />
                        {/* if not fixed */}
                        {/* <CampaignPagination />  */}
                    </div>
                    {/* if fixed */}
                    <CampaignPagination />
                </div>

                <EmptyList className={is_empty ? 'mlvc_bcr_empty_container ' : 'mlvc_bcr_empty_container_revers'} />
            </div>
    </div>
    );
}
export default CampaignBody;