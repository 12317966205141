import * as ACTION from "../constants/marketingTemplateActionTypes";

const initialState = {
    categories: [],
    selectedCategoryId : '',
    selectedCategoryData: null,
    selectedCategoryIndex: '',
    templateSearchText: ''
};

const marketingTemplateReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case ACTION.STORE_CAMPAIGN_TEMPLATE_CATEGORY:
            return {...state, categories : payload}

        case ACTION.STORE_SELECTED_CAMPAIGN_TEMPLATE_CATEGORY:
            return {...state, selectedCategoryId : payload.id, selectedCategoryData: payload.data, selectedCategoryIndex: payload.index}

        case ACTION.STORE_CAMPAIGN_TEMPLATE_SEARCH:
            return {...state, templateSearchText: payload}

        default:
            return state;
    }
}
export default marketingTemplateReducer;