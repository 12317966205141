import React, {useRef} from "react";
import {useSWHKContext} from "../../reducers/SWHKProvider";
import GlobalCustomFieldGrouped from "../GlobalCustomFieldGrouped/Index";
import {CircleRemoveIcon} from "../../icons";
import {handleState} from "../../reducers/Actions";
import Styles from "./Styles.module.css";

const App = ({callback}) => {
  const {state, dispatch} = useSWHKContext();
  const headersValueRefs = useRef([]);

  const handleChangeHeadersField = (index, name, value) => {
    let data = [...state.headers];
    data[index][name] = value;

    if(index === (state.headers.length-1)){
      let object = {
        key: "",
        value: ""
      };
      data = [...state.headers, object];
    }

    dispatch(handleState({ headers: data }));
    callback({ headers: data });
  };

  const handleRemoveHeadersField = (index) => {
    let data = [...state.headers];
    data.splice(index, 1);

    dispatch(handleState({ headers: data }));
    callback({ headers: data });
  };

  const renderHeadersItems = () => {
    const headersItems = [];

    state.headers.forEach((param, index)=>{
      headersItems.push(
          <div className={Styles.wbh_headers_panel_item}>
            <input type="text" name="key" onChange={(event)=>{handleChangeHeadersField(index, event.target.name, event.target.value)}} value={param.key} placeholder="Key" />
            <input type="text" name="value" ref={(el)=>(headersValueRefs.current[index]=el)} onChange={(event)=>{handleChangeHeadersField(index, event.target.name, event.target.value)}} value={param.value}  placeholder="Value"/>
            <GlobalCustomFieldGrouped className="wbh_global__personalized" handleSelect={(placeholderValue)=>{
              let start = headersValueRefs.current[index].selectionStart;
              let end = headersValueRefs.current[index].selectionEnd;
              let text = headersValueRefs.current[index].value;
              let before = text.substring(0, start);
              let after = text.substring(end, text.length);

              let newText = (before + placeholderValue + after);

              handleChangeHeadersField(index, "value", newText);

              setTimeout(() => {
                headersValueRefs.current[index].selectionStart = headersValueRefs.current[index].selectionEnd = start + placeholderValue.length;
                headersValueRefs.current[index].focus();
              }, 500);
            }} labelText={"Personalized"}/>
            {
              (state.headers.length !== 1 && index !== (state.headers.length-1)) ? (
                    <span
                        className={Styles.sh_hp_remove}
                        onClick={()=>{handleRemoveHeadersField(index)}}
                    >
                            <CircleRemoveIcon />
                        </span>
                ) : (<span style={{ height: "24px", width:"24px" }}/>)
            }
          </div>
      );
    });

    return headersItems;
  };
  return(
      <div className={Styles.wbh_content_border_wrp}>
        <div className={`${Styles.wbh_headers_panel_content} awesome__scroll_bar`}>
          {renderHeadersItems()}
        </div>
      </div>
  );
};

export default App;