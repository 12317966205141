import {authType, authTypeLabel, bodyType, method, methodLabel, tab} from "../constants";

export const swhkStates = {
    currentTab: tab.PARAMS_TAB,
    params: [
        {
            key: "",
            value: ""
        }
    ],
    formData: [
        {
            key: "",
            value: ""
        }
    ],
    headers: [
        {
            key: "",
            value: ""
        }
    ],

    method: method.GET_METHOD,

    url: "",
    urlRaw: "",

    queryParamsString: "",
    
    authType: authType.NO_AUTH,
    authData: "",

    currentBodyTab: tab.NONE_TAB,
    bodyType: bodyType.NONE,

    rawBodyData: "",
    bodyData: "",
};