export const Icons = {
    plusBlue: (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#006DF5"/>
            </svg>
        ),
    plusWhite: (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#FFFFFF"/>
            </svg>
        ),
    collapse: (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6625 22H5.3375C3.5 22 2 20.5 2 18.6625V5.3375C2 3.5 3.5 2 5.3375 2H18.675C20.5 2 22 3.5 22 5.3375V18.675C22 20.5 20.5 22 18.6625 22ZM5.3375 3.6625C4.425 3.6625 3.675 4.4125 3.675 5.325V18.6625C3.675 19.575 4.425 20.325 5.3375 20.325H18.675C19.5875 20.325 20.3375 19.575 20.3375 18.6625V5.3375C20.3375 4.425 19.5875 3.675 18.675 3.675H5.3375V3.6625ZM8.6625 22C8.1625 22 7.825 21.6625 7.825 21.1625V2.8375C7.8375 2.3375 8.1625 2 8.6625 2C9.1625 2 9.5 2.3375 9.5 2.8375V21.175C9.5 21.6625 9.1625 22 8.6625 22ZM13.6625 12.8375C13.4125 12.8375 13.25 12.75 13.075 12.5875C12.75 12.25 12.75 11.75 13.0875 11.4125L15.5875 8.9125C15.925 8.575 16.425 8.575 16.75 8.9125C17.0875 9.25 17.0875 9.75 16.75 10.075L14.25 12.575C14.0875 12.75 13.9125 12.8375 13.6625 12.8375ZM16.1625 15.3375C15.9125 15.3375 15.75 15.25 15.575 15.0875L13.075 12.5875C12.75 12.25 12.75 11.75 13.0875 11.4125C13.425 11.075 13.925 11.075 14.25 11.4125L16.75 13.9125C17.0875 14.25 17.0875 14.75 16.75 15.075C16.5875 15.25 16.4125 15.3375 16.1625 15.3375Z" fill="#006DF5"/>
        </svg>
        ),
    collapseLarge: (<svg width="32" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6625 22H5.3375C3.5 22 2 20.5 2 18.6625V5.3375C2 3.5 3.5 2 5.3375 2H18.675C20.5 2 22 3.5 22 5.3375V18.675C22 20.5 20.5 22 18.6625 22ZM5.3375 3.6625C4.425 3.6625 3.675 4.4125 3.675 5.325V18.6625C3.675 19.575 4.425 20.325 5.3375 20.325H18.675C19.5875 20.325 20.3375 19.575 20.3375 18.6625V5.3375C20.3375 4.425 19.5875 3.675 18.675 3.675H5.3375V3.6625ZM8.6625 22C8.1625 22 7.825 21.6625 7.825 21.1625V2.8375C7.8375 2.3375 8.1625 2 8.6625 2C9.1625 2 9.5 2.3375 9.5 2.8375V21.175C9.5 21.6625 9.1625 22 8.6625 22ZM13.6625 12.8375C13.4125 12.8375 13.25 12.75 13.075 12.5875C12.75 12.25 12.75 11.75 13.0875 11.4125L15.5875 8.9125C15.925 8.575 16.425 8.575 16.75 8.9125C17.0875 9.25 17.0875 9.75 16.75 10.075L14.25 12.575C14.0875 12.75 13.9125 12.8375 13.6625 12.8375ZM16.1625 15.3375C15.9125 15.3375 15.75 15.25 15.575 15.0875L13.075 12.5875C12.75 12.25 12.75 11.75 13.0875 11.4125C13.425 11.075 13.925 11.075 14.25 11.4125L16.75 13.9125C17.0875 14.25 17.0875 14.75 16.75 15.075C16.5875 15.25 16.4125 15.3375 16.1625 15.3375Z" fill="#006DF5"/>
        </svg>
        ),
    plusGray: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8343 10.8333H10.8343V15.8333H9.16766V10.8333H4.16766V9.16666H9.16766V4.16666H10.8343V9.16666H15.8343V10.8333Z" fill="#546376"/>
        </svg>
        ),
    folderBlue:(
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.33332 0.333344H2.33332C1.41666 0.333344 0.67499 1.08334 0.67499 2.00001L0.666656 12C0.666656 12.9167 1.41666 13.6667 2.33332 13.6667H15.6667C16.5833 13.6667 17.3333 12.9167 17.3333 12V3.66668C17.3333 2.75001 16.5833 2.00001 15.6667 2.00001H8.99999L7.33332 0.333344Z" fill="#6258FF" fill-opacity="0.5"/>
        </svg>
    ),
    calendar: (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.75 8.25H5.25V9.75H6.75V8.25ZM9.75 8.25H8.25V9.75H9.75V8.25ZM12.75 8.25H11.25V9.75H12.75V8.25ZM14.25 3H13.5V1.5H12V3H6V1.5H4.5V3H3.75C2.9175 3 2.2575 3.675 2.2575 4.5L2.25 15C2.25 15.825 2.9175 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM14.25 15H3.75V6.75H14.25V15Z" fill="#546376"/>
        </svg>
    ),
    /* campaign list icons */
    c_add: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 10.8334H10.8333V15.8334H9.16663V10.8334H4.16663V9.16675H9.16663V4.16675H10.8333V9.16675H15.8333V10.8334Z" fill="#949DB2"/>
    </svg>
    ),
    c_edit: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 14.3751V17.5001H5.625L14.8417 8.28346L11.7167 5.15846L2.5 14.3751ZM17.2583 5.8668C17.5833 5.5418 17.5833 5.0168 17.2583 4.6918L15.3083 2.7418C14.9833 2.4168 14.4583 2.4168 14.1333 2.7418L12.6083 4.2668L15.7333 7.3918L17.2583 5.8668Z" fill="#949DB2"/>
    </svg>
    ),
    c_folder: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6666 4.99992H9.99996L8.33329 3.33325H3.33329C2.40829 3.33325 1.67496 4.07492 1.67496 4.99992L1.66663 14.9999C1.66663 15.9249 2.40829 16.6666 3.33329 16.6666H16.6666C17.5916 16.6666 18.3333 15.9249 18.3333 14.9999V6.66659C18.3333 5.74159 17.5916 4.99992 16.6666 4.99992ZM15.8333 11.6666H13.3333V14.1666H11.6666V11.6666H9.16663V9.99992H11.6666V7.49992H13.3333V9.99992H15.8333V11.6666Z" fill="#949DB2"/>
    </svg>
    ),
    c_copy: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3333 0.833252H3.33329C2.41663 0.833252 1.66663 1.58325 1.66663 2.49992V14.1666H3.33329V2.49992H13.3333V0.833252ZM12.5 4.16658L17.5 9.16658V17.4999C17.5 18.4166 16.75 19.1666 15.8333 19.1666H6.65829C5.74163 19.1666 4.99996 18.4166 4.99996 17.4999L5.00829 5.83325C5.00829 4.91658 5.74996 4.16658 6.66663 4.16658H12.5ZM11.6666 9.99992H16.25L11.6666 5.41658V9.99992Z" fill="#949DB2"/>
    </svg>
    ),
    c_delete: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.99996 15.8333C4.99996 16.75 5.74996 17.5 6.66663 17.5H13.3333C14.25 17.5 15 16.75 15 15.8333V5.83333H4.99996V15.8333ZM15.8333 3.33333H12.9166L12.0833 2.5H7.91663L7.08329 3.33333H4.16663V5H15.8333V3.33333Z" fill="#949DB2"/>
    </svg>
    ),
    c_share: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 13.4001C14.3667 13.4001 13.8 13.6501 13.3667 14.0417L7.425 10.5834C7.46667 10.3917 7.5 10.2001 7.5 10.0001C7.5 9.80008 7.46667 9.60842 7.425 9.41675L13.3 5.99175C13.75 6.40841 14.3417 6.66675 15 6.66675C16.3833 6.66675 17.5 5.55008 17.5 4.16675C17.5 2.78341 16.3833 1.66675 15 1.66675C13.6167 1.66675 12.5 2.78341 12.5 4.16675C12.5 4.36675 12.5333 4.55841 12.575 4.75008L6.7 8.17508C6.25 7.75842 5.65833 7.50008 5 7.50008C3.61667 7.50008 2.5 8.61675 2.5 10.0001C2.5 11.3834 3.61667 12.5001 5 12.5001C5.65833 12.5001 6.25 12.2417 6.7 11.8251L12.6333 15.2917C12.5917 15.4667 12.5667 15.6501 12.5667 15.8334C12.5667 17.1751 13.6583 18.2667 15 18.2667C16.3417 18.2667 17.4333 17.1751 17.4333 15.8334C17.4333 14.4917 16.3417 13.4001 15 13.4001Z" fill="#949DB2"/>
    </svg>
    ),
    c_stats: (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5667 15.3917C16.9334 14.8084 17.15 14.1334 17.15 13.4001C17.15 11.3251 15.475 9.65008 13.4 9.65008C11.325 9.65008 9.65004 11.3251 9.65004 13.4001C9.65004 15.4751 11.325 17.1501 13.3917 17.1501C14.125 17.1501 14.8084 16.9334 15.3834 16.5667L17.9834 19.1667L19.1667 17.9834L16.5667 15.3917ZM13.4 15.4834C12.25 15.4834 11.3167 14.5501 11.3167 13.4001C11.3167 12.2501 12.25 11.3167 13.4 11.3167C14.55 11.3167 15.4834 12.2501 15.4834 13.4001C15.4834 14.5501 14.55 15.4834 13.4 15.4834ZM13.1 8.40008C12.4834 8.41675 11.8917 8.55008 11.35 8.77508L10.8917 8.08341L7.72504 13.2334L5.21671 10.3001L2.19171 15.1417L0.833374 14.1667L5.00004 7.50008L7.50004 10.4167L10.8334 5.00008L13.1 8.40008ZM15.2584 8.81675C14.725 8.58341 14.15 8.44175 13.55 8.40841L17.8167 1.66675L19.1667 2.65008L15.2584 8.81675Z" fill="#949DB2"/>
    </svg>
    ),
    p_left_arrow: (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1258 6.7925L12.8333 5.5L7.33331 11L12.8333 16.5L14.1258 15.2075L9.92748 11L14.1258 6.7925Z" fill="#1C1B1F"/>
    </svg>
    
    ),
    p_dwon_arrow:(
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.115 0.884766L9 7.75477L15.885 0.884766L18 2.99977L9 11.9998L0 2.99977L2.115 0.884766Z" fill="#949DB2"/>
    </svg>

    ),
    p_right_arrow:(
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.16671 5.5L7.87421 6.7925L12.0725 11L7.87421 15.2075L9.16671 16.5L14.6667 11L9.16671 5.5Z" fill="#1C1B1F"/>
    </svg>

    ),
    search_icon:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#949DB2"/>
    </svg>

    ),
    date_icon:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z" fill="#949DB2"/>
    </svg>

    ),
    close_icon:(
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z" fill="#FF264A"/>
    </svg>

    ),
    f_upload_icon:(
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 18.6667H17.5V11.6667H22.1666L14 3.5L5.83331 11.6667H10.5V18.6667ZM5.83331 21H22.1666V23.3333H5.83331V21Z" fill="#949DB2"/>
    </svg>
    ),
    folder_icon:(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6667 5.00004H10.0001L8.33342 3.33337H3.33341C2.40841 3.33337 1.67508 4.07504 1.67508 5.00004L1.66675 15C1.66675 15.925 2.40841 16.6667 3.33341 16.6667H16.6667C17.5917 16.6667 18.3334 15.925 18.3334 15V6.66671C18.3334 5.74171 17.5917 5.00004 16.6667 5.00004ZM15.8334 11.6667H13.3334V14.1667H11.6667V11.6667H9.16675V10H11.6667V7.50004H13.3334V10H15.8334V11.6667Z" fill="#546376"/>
        </svg>  
    ),
    f_copy_icon:(
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6666 1.16669H4.66665C3.38331 1.16669 2.33331 2.21669 2.33331 3.50002V19.8334H4.66665V3.50002H18.6666V1.16669ZM17.5 5.83335L24.5 12.8334V24.5C24.5 25.7834 23.45 26.8334 22.1666 26.8334H9.32165C8.03831 26.8334 6.99998 25.7834 6.99998 24.5L7.01165 8.16669C7.01165 6.88335 8.04998 5.83335 9.33331 5.83335H17.5ZM16.3333 14H22.75L16.3333 7.58335V14Z" fill="#949DB2"/>
    </svg>

    ),
    f_fire_icon:(
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.33331 2.33331V25.6666H25.6666V2.33331H2.33331ZM13.8366 19.7866C14.7233 19.5066 15.47 18.5733 15.6216 17.885C15.7733 17.2316 15.505 16.66 15.3883 16.0183C15.295 15.4816 15.3066 15.0266 15.4816 14.525C16.1116 15.9366 17.99 16.4383 17.7916 18.235C17.57 20.2183 15.33 21.0116 13.8366 19.7866ZM23.3333 23.3333H21V21H18.6433C19.3783 20.02 19.8333 18.8183 19.8333 17.5C19.8333 15.295 18.5616 14.175 17.675 13.5683C14.2333 11.2116 15.1666 8.16665 15.1666 8.16665C7.31498 12.3316 8.14331 16.8816 8.16665 17.5C8.20165 18.62 8.73831 19.915 9.60165 21H6.99998V23.3333H4.66665V4.66665H23.3333V23.3333Z" fill="#949DB2"/>
    </svg>

    ),
    c_check:(
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00003 11.1698L1.83003 6.99984L0.410034 8.40984L6.00003 13.9998L18 1.99984L16.59 0.589844L6.00003 11.1698Z" fill="white"/>
    </svg>

    ),
    arrow_down: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 15L17 10H7Z" fill="#949DB2"/>
        </svg>
    ),
    list_uncheck:(
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00033 0.667679C4.40033 0.667679 0.666992 4.40101 0.666992 9.00101C0.666992 13.601 4.40033 17.3343 9.00033 17.3343C13.6003 17.3343 17.3337 13.601 17.3337 9.00101C17.3337 4.40101 13.6003 0.667679 9.00033 0.667679ZM9.00033 15.6677C5.31699 15.6677 2.33366 12.6843 2.33366 9.00101C2.33366 5.31768 5.31699 2.33435 9.00033 2.33435C12.6837 2.33435 15.667 5.31768 15.667 9.00101C15.667 12.6843 12.6837 15.6677 9.00033 15.6677Z" fill="#949DB2"/>
        </svg>
    ),
    green_check_icon: (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0003 1.66768C5.40033 1.66768 1.66699 5.40101 1.66699 10.001C1.66699 14.601 5.40033 18.3343 10.0003 18.3343C14.6003 18.3343 18.3337 14.601 18.3337 10.001C18.3337 5.40101 14.6003 1.66768 10.0003 1.66768ZM8.33366 14.1677L4.16699 10.001L5.34199 8.82601L8.33366 11.8093L14.6587 5.48435L15.8337 6.66768L8.33366 14.1677Z" fill="#008945"/>
        </svg>
    )


}