/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect} from 'react'
import { connect } from 'react-redux'
import CardMultiStepForm from "../DirectCardSend/CardMultiStepForm"
import Loading from '../../Common/Loading';



const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;



const App = props => {
    //For Card
    const [viewCardModal, setViewCardModal] = useState(false);
    const [activeMailItemType, setActiveMailItemType] = useState('');
    const [directMailSave, setDirectMailSave] = useState(false)
    const [formData, setFormData] = useState([])


    useEffect(() => {
        setFormData([])
        setDirectMailSave(false)
    }, [props.submitingTemplate])

    useEffect(() => {
        if(props.editTemplate){
            if(props.previousTemplate !== undefined && props.previousTemplate != null) {
                let formData = [];
                  formData['id'] = props.previousTemplate.id;
                  
                  formData['message'] = (props.previousTemplate.message !== undefined &&
                    props.previousTemplate.message != null) ? props.previousTemplate.message : '';

                  formData['content'] = props.previousTemplate.type;
            
                  formData['handwriting_style_id'] = (props.previousTemplate.handwriting_style_id !== undefined &&
                    props.previousTemplate.handwriting_style_id != null) ? props.previousTemplate.handwriting_style_id : null

                  formData['template_category_id'] =(props.previousTemplate.template_category_id !== undefined &&
                    props.previousTemplate.template_category_id != null) ? props.previousTemplate.template_category_id : null;

                  formData['template_id']= (props.previousTemplate.template_id !== undefined &&
                    props.previousTemplate.template_id != null) ? props.previousTemplate.template_id : null;

                  formData['template_thumbnail_path']= (props.previousTemplate.template_thumbnail_path !== undefined &&
                    props.previousTemplate.template_thumbnail_path != null) ? props.previousTemplate.template_thumbnail_path : '';

                  formData['file_path']=  (props.previousTemplate.pdf_url !== undefined &&
                    props.previousTemplate.pdf_url != null) ? props.previousTemplate.pdf_url : '';

                  formData['file_type']= (props.previousTemplate.card_size !== undefined &&
                    props.previousTemplate.card_size != null) ? props.previousTemplate.card_size : '';

                  formData['template_pdf_path']= (props.previousTemplate.pdf_url !== undefined &&
                    props.previousTemplate.pdf_url != null) ? props.previousTemplate.pdf_url : '';

                  formData['gift_production_id']= (props.previousTemplate.gift_production_id !== undefined &&
                    props.previousTemplate.gift_production_id != null) ? props.previousTemplate.gift_production_id : '';

                  formData['gift_product_id']= (props.previousTemplate.gift_product_id !== undefined &&
                    props.previousTemplate.gift_product_id != null) ? props.previousTemplate.gift_product_id : '';

                  formData['gift_product_sku']= (props.previousTemplate.gift_sku_id !== undefined &&
                    props.previousTemplate.gift_sku_id != null) ? props.previousTemplate.gift_sku_id : '';

                  formData['gift_handling_fee']= (props.previousTemplate.gift_handling_fee !== undefined &&
                    props.previousTemplate.gift_handling_fee != null) ? props.previousTemplate.gift_handling_fee : '';

                  formData['gift_price']= (props.previousTemplate.gift_price !== undefined &&
                    props.previousTemplate.gift_price != null) ? props.previousTemplate.gift_price : '';

                  formData['gift_image_url']= (props.previousTemplate.gift_image_url !== undefined &&
                    props.previousTemplate.gift_image_url != null) ? props.previousTemplate.gift_image_url : '';
                  
                  formData['subject'] = '';

                setFormData(formData)
                  setActiveMailItemType(props.previousTemplate.type)
                // if(props.previousTemplate.pdf_url != undefined && props.previousTemplate.pdf_url != undefined){
                //     let tempFormData = formData;
                //     tempFormData.template_pdf_url = props.previousTemplate.pdf_url
                //     setFormData(formData)
                // }
                  setDirectMailSave(true)
                  setViewCardModal(true)
            }
            
        }
    }, [])

    const selectActiveMailType = (e,type) => {
        // setSelectedDropdown(type)
        e.preventDefault()
        setActiveMailItemType(type)
        setViewCardModal(true)
        // setMailOptionVisible(false)
    }

    const saveTemplate=(e)=>{
        e.preventDefault();
        props.handleSaveTemplate(formData)
    }

    const onSubmit=(formData)=>{
      setFormData(formData);
      setViewCardModal(false);
      setDirectMailSave(true)

    }


    return (
        <div id="direct_mail" className="">
            {viewCardModal &&
                <CardMultiStepForm
                    open={viewCardModal}
                    onClose={() => setViewCardModal(false)}
                    messageType={activeMailItemType}
                    setViewCardModal={setViewCardModal}
                    from={'addQuickTemplate'}
                    // setDirectMailSave={setDirectMailSave}
                    onSubmit={onSubmit}
                    editableCampaign={props.previousTemplate}
                />
            }
            {/* </Modal> */}
            {!props.submitingTemplate && 
              <div  className={!props.editTemplate ? "direct_mail_container": "direct_mail_container edit_template_container"}>

              <p>{!props.editTemplate ? "Pick a Card Type" : ''}</p>
              {
                !props.editTemplate &&
                  <div className="pick_card_buttons d-flex justify-content-center">
                      <a className={formData.content === POSTCARD ? "accent--bg--color":""} href="#" onClick={(e) =>{ selectActiveMailType(e,POSTCARD); props.updateTabOptionsForDM(POSTCARD)}}>POST CARD</a>
                      <a className={formData.content === GREETING_CARD ? "accent--bg--color":""} href="#" onClick={(e) =>{ selectActiveMailType(e,GREETING_CARD); props.updateTabOptionsForDM(GREETING_CARD)}}>GREETING CARD</a>
                      <a className={formData.content === LETTER ? "accent--bg--color":""} href="#" onClick={(e) =>{ selectActiveMailType(e,LETTER); props.updateTabOptionsForDM(LETTER)}}>LETTER</a>
                      <a className={formData.content === GIFT ? "accent--bg--color":""} href="#" onClick={(e) => { selectActiveMailType(e,GIFT); props.updateTabOptionsForDM(GIFT)}}>GIFT</a>
                  </div>
              }
              {directMailSave &&
                  ((formData.template_pdf_path !== undefined && formData.template_pdf_path != null && formData.template_pdf_path !== '') ||
                  (props.previousTemplate.pdf_url !== undefined && props.previousTemplate.pdf_url != null 
                      && props.previousTemplate.pdf_url !== '')) &&
                  <div className="message_body p-3">

                      <iframe src={formData.template_pdf_path || props.previousTemplate.pdf_url} frameborder="0"
                          title="Postcard" loading={'eager'} name={'Postcard'} menu={false}
                          allowFullScreen
                      ></iframe>
                      <div className="under_tab_buttons">
                          <div className="d-flex justify-content-between">

                              <div className="save_template_button">
                                  <a href="#" className="accent--bg--color" onClick={(e) => saveTemplate(e)}>
                                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="13" cy="13" r="13" fill="white" />
                                          <path d="M15 15H10V16H15V15Z" fill="#3C7EF3" />
                                          <path d="M10 17V17.5V18H15V17.5V17H10Z" fill="#3C7EF3" />
                                          <path d="M17.8998 9.87553L15.8907 7.1998C15.7963 7.07404 15.6482 7 15.4909 7H14.6667V7.50001V8.00001V11.1667C14.6667 11.4428 14.4428 11.6667 14.1667 11.6667H9.50001C9.22387 11.6667 9 11.4428 9 11.1667V7.99999V7.50001V7H7.50001C7.22387 7 7 7.22391 7 7.50001V17.5C7 17.7761 7.22387 18 7.50001 18H9V17.5V17V16.5V14.5C9 14.2238 9.22387 14 9.50001 14H15.5C15.7762 14 16 14.2238 16 14.5V16.5V17V17.5V18H17.5C17.7761 18 18 17.7761 18 17.5V10.1758C18 10.0675 17.9648 9.96209 17.8998 9.87553Z" fill="#3C7EF3" />
                                          <path d="M10 7V7.50001V7.99999V10.6667H13.6667V7.99999V7.50001V7H10Z" fill="#3C7EF3" />
                                      </svg>
                                      <span>{props.editTemplate ? "Update Template" : "Save Template"}</span>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
                }
            </div>
          }
          {props.submitingTemplate && 
              <div className="direct_mail_container">
                <Loading />
              </div>
          }           
        </div>
    )

}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const AddDirectEmailTemplate = connect(mapStateToProps, mapDispatchToProps)(App)
export default AddDirectEmailTemplate