import { ArrowBack } from '@material-ui/icons';
import React, { useState } from 'react';
import { MessageServiceSenderPool } from './MessageServiceSenderPool';
import { MessageServiceUpdateForm } from './MessageServiceUpdateForm';

const OPTION_PROPERTIES = 1;
const OPTION_SENDER_POOL = 2;

export const EditMessageService = (props) => {

    const [selecteOption, setSelecteOption] = useState(OPTION_PROPERTIES);

    return (
        <div className='messaging_service_edit_page'>
            <div className='ms__left_panel'>
                <div className='d-flex align-items-center'>
                    <ArrowBack className='mr-2 cursor-pointer link-text-design mr-2' onClick={() => props.onBack()}/> 
                    {props.data.messaging_service_name}
                </div>
                <div className='ms__edit_link_holders'>
                    <p 
                        className={selecteOption === OPTION_PROPERTIES ? 'active' : ''} 
                        onClick={() => setSelecteOption(OPTION_PROPERTIES)}>
                        Properties
                    </p>
                    <p 
                        className={selecteOption === OPTION_SENDER_POOL ? 'active' : ''}
                        onClick={() => setSelecteOption(OPTION_SENDER_POOL)}>
                        Sender Pool
                    </p>
                </div>
            </div>
            <div className='ms__right_panel'>
                {
                    selecteOption === OPTION_PROPERTIES &&
                    <MessageServiceUpdateForm {...props}/>
                }

                {
                    selecteOption === OPTION_SENDER_POOL &&
                    <MessageServiceSenderPool {...props}/>
                }
            </div>
        </div>
    )
}
