/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import Select from "@material-ui/core/Select";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NewCampaignPopup from "../NewCampaignPopup";
import { fetchCampaign, updateReducerState } from "../../../actions/marketingAction";
import { connect } from 'react-redux'
// import { useNotification } from "../../../Notification/NotificationProvider";
import useDelayCallback from "../../../hooks/useDelayCallback";
import GlobalSearchBox from "../../globals/GlobalSearchBox";
import MEISCustomPopOver from "../../globals/CustomPopOver/Index";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 170,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const filterMenuOptions = [
    {id: 0, title: 'One', value: 'One'},
    {id: 0, title: 'Two', value: 'Two'}
]

const CampaignHeader = props => {
    const classes = useStyles();
    const [userFilterValue, setUserFilterValue] = useState('')
    const [openAddNewCampaignModal, setOpenAddNewCampaignModal] = React.useState(false);

    const [search, setSearch] = useState('')
    /* for notification */
    // const dispatch = useNotification();
    /* end */

    useEffect(() => {
        // call all subuser functions
    })

    useDelayCallback(() => {
        props.fetchCampaign({"query" : search, "page" : 0})
    }, [search])

    const handleAddNewCampaignModal = (status) => {
        setOpenAddNewCampaignModal(status);
    };

    const hanldeUserFilterMenu = (element) => {
        setUserFilterValue(element.target.value);
    }

    const renderFilter = () => {
        let filterOptions = [];
        filterMenuOptions.map((row, index) => {
            filterOptions.push(
                <option value={row.id} key={index}>{row.title}</option>
            );
        });

        return filterOptions;
    }

    const handleSearch = (event) => {
        let text = event.target.value
        setSearch(text)
        props.marketing.campaignSearchText = text
        props.updateReducerState({isLoading: true})
        // props.fetchCampaign({"query" : text, "page" : 0})
    }
    
    return(
        <>
            <div className="marketing mt-2">
                <div className="marketing_top_content">
                    <div className="row d-flex align-items-center mb-0">

                        <div className="col s4 m4 l5 xl4">
                            <div className="sub_title d-flex justify-content-start align-items-center">
                                <h2>Manage Campaigns</h2>
                            </div>
                        </div>

                        <div className="col s8 m8 l7 xl8">
                            <div className="mark_top_buttons d-flex justify-content-end align-items-center">

                                {/* <MEISCustomPopOver 
                                    className={"marketing__module__email_modal"} 
                                    labelText={(
                                        <span className="folder_create_new_video_wr">
                                            <span className="folder_create_new_video_title">New</span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                <path d="m16 13 6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z"/>
                                            </svg>
                                        </span>
                                    )} 
                                /> */}
                                <div className="campaign_drp_button">
                                    {/* dropdown_button subuser_button */}

                                    <FormControl variant="outlined"  className={`${classes.formControl} d-none`}>
                                        {/* variant="outlined" */}
                                        <InputLabel id="demo-simple-select-outlined-label" className="active">Filter By Subuser</InputLabel>

                                        <Select
                                            native
                                            labelId="sub-user-filter-select-label"
                                            id="sub-user-filter-select"
                                            value={userFilterValue}
                                            onChange={hanldeUserFilterMenu}
                                            className={"sub-user-filter-select"}
                                            label="Filter By Subuser"
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            autoWidth
                                        >
                                            <option value="" disabled>
                                            </option>
                                            {renderFilter()}
                                        </Select>
                                    </FormControl>

                                </div>

                                {/* <div className="search_button d-flex align-items-center">
                                    <input  />
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5 11.2549H11.71L11.43 10.9849C12.41 9.84488 13 8.36488 13 6.75488C13 3.16488 10.09 0.254883 6.5 0.254883C2.91 0.254883 0 3.16488 0 6.75488C0 10.3449 2.91 13.2549 6.5 13.2549C8.11 13.2549 9.59 12.6649 10.73 11.6849L11 11.9649V12.7549L16 17.7449L17.49 16.2549L12.5 11.2549ZM6.5 11.2549C4.01 11.2549 2 9.24488 2 6.75488C2 4.26488 4.01 2.25488 6.5 2.25488C8.99 2.25488 11 4.26488 11 6.75488C11 9.24488 8.99 11.2549 6.5 11.2549Z" fill="#133159" fillOpacity="0.65"/>
                                    </svg>
                                </div> */}

                                <GlobalSearchBox wrapperClass="search_button_marketing_global" type="search" autoComplete="off" name="search" id="campaign_search" placeholder="Search campaigns" onChange={handleSearch}/>

                                <div className="new_campaign_button">
                                    <a className="modal-trigger accent--bg--text--color " onClick={() => handleAddNewCampaignModal(true)}>
                                        <span>
                                            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path className="accent--fill--color" d="M8.33611 4.00045C8.33611 4.35494 8.30534 4.64148 7.95085 4.64148H5.13098V7.46135C5.13098 7.81519 4.84444 7.8466 4.48995 7.8466C4.13547 7.8466 3.84893 7.81519 3.84893 7.46135V4.64148H1.02906C0.675209 4.64148 0.643799 4.35494 0.643799 4.00045C0.643799 3.64596 0.675209 3.35943 1.02906 3.35943H3.84893V0.539553C3.84893 0.185066 4.13547 0.154297 4.48995 0.154297C4.84444 0.154297 5.13098 0.185066 5.13098 0.539553V3.35943H7.95085C8.30534 3.35943 8.33611 3.64596 8.33611 4.00045Z"/>
                                            </svg>
                                        </span>
                                        New Campaign
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            {/* <Modal
                open={openAddNewCampaignModal}
                onClose={() => handleAddNewCampaignModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAddNewCampaignModal}>
                    {<NewCampaignPopup 
                        handleAddNewCampaignModal={handleAddNewCampaignModal}
                        open={openAddNewCampaignModal}
                        close={() => handleAddNewCampaignModal(false)}
                    />}
                </Fade>
            </Modal> */}
            {openAddNewCampaignModal &&
            <NewCampaignPopup 
                handleAddNewCampaignModal={handleAddNewCampaignModal}
                open={openAddNewCampaignModal}
                close={() => handleAddNewCampaignModal(false)}
            />
            }
        </>
    )
}

const mapStateToProps = state => {
    // pass state in props
    return {
        marketing: state.marketingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCampaign: (params) => dispatch(fetchCampaign(params)),
        updateReducerState: (params) => dispatch(updateReducerState(params)),
    };
}

const MarketingHeader = connect(mapStateToProps, mapDispatchToProps)(CampaignHeader);
export default MarketingHeader;