import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

const CustomDatePicker = (props) => {
    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    value={props.activityDate}
                    onChange={(date)=>props.onChange(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className="add_activity_text_field radius-5 white" 
                />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}
export default CustomDatePicker