export const method = {
    GET_METHOD: 5,
    POST_METHOD: 1,
    PUT_METHOD: 2,
    PATCH_METHOD: 3,
    DELETE_METHOD: 4
};

export const methodLabel = {
    [method.GET_METHOD]: "GET",
    [method.POST_METHOD]: "POST",
    [method.PUT_METHOD]: "PUT",
    [method.PATCH_METHOD]: "PATCH",
    [method.DELETE_METHOD]: "DELETE"
}

export const authType = {
    NO_AUTH: 0,
    BEARER: 1,
    BASIC: 2
};

export const authTypeLabel = {
    [authType.NO_AUTH]: "No Auth",
    [authType.BEARER]: "Bearer",
    [authType.BASIC]: "Basic"
}

export const bodyType = {
    NONE: 0,
    FORM_DATA: 5,
    JSON: 2,
    XML: 3,
    TEXT: 4
};

export const tab = {
    PARAMS_TAB: 0,
    AUTHORIZATION_TAB: 1,
    HEADERS_TAB: 2,
    BODY_TAB: 3,
    RAW_TAB: 4,
    NONE_TAB: 5,
    FORM_DATA_TAB: 6
};

export const tabBodyTypeMap = {
  [bodyType.NONE]: tab.NONE_TAB,
  [bodyType.TEXT]: tab.RAW_TAB,
  [bodyType.JSON]: tab.RAW_TAB,
  [bodyType.XML]: tab.RAW_TAB,
  [bodyType.FORM_DATA]: tab.FORM_DATA_TAB
};