/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PlayArrow from '@material-ui/icons/PlayArrow';
import React, { useState } from 'react';
import CustomRadioButton from '../../globals/FormComponents/CustomRadioButton';
import GlobalModal from '../../globals/Modal/GlobalModal';
import './campaignStartModal.css';

const restart_types = [
    { value: '1', label: 'Start Now'},
    { value: '0', label: 'Start Tomorrow'},
]

const followup_types = [
    { value: '0', label: 'Yes'},
    { value: '1', label: 'No'},
]

const CampaignStartModal = props => {
    const [showInfo, setShowInfo] = useState(false)
    const [settings, setSettings] = useState(
        {
            prevent_previous_followup: '0', //int
            sent_immediate_after_resume: '1', //int
            isShowInfo: false,
            isStartProcessing: false,
        }
    )

    const handleSubmit = () => {
        if(props.isLoading){
            return false
        }
        props.handleStartCampaign({
            prevent_previous_followup: parseInt(settings.prevent_previous_followup),
            sent_immediate_after_resume: settings.sent_immediate_after_resume.toString()
        })
    };

    const onChangeRestartType = (value) => {
        setSettings({...settings, 'sent_immediate_after_resume' : value})
    }

    const onChangeFollowupType = (value) => {
        setSettings({...settings, 'prevent_previous_followup' : value})
    }

    const showPauseInfo = (e) => {
        e.preventDefault();
        setShowInfo(!showInfo);
    }

    return(

        <GlobalModal
            open={props.open}
            onClose={props.onClose}
            title="Campaign Start Instruction"
            buttonText={props.isLoading ? "Starting Campaign" : "Yes, Start Campaign"}
            buttonIcon={<PlayArrow/>}
            onSubmit={() => handleSubmit()}
        >
            <div className="campaign-start-modal-container">

                <div className="m-portlet__body p-4 campaign-start-modal-body">
                    <div className="m-form__section m-form__section--first">
                        
                        <h2>Do you want to start this Campaign?</h2>
                        <h4>Read Below Before You Start The Campaign</h4>
                        <p className="mt-4">You currently have <span className={'text-red'}>{props.totalContact}</span> contact(s) in this campaign.</p>
                        {typeof props.campaign.pause_time != 'undefined' && props.campaign.pause_time != null &&
                            <p className="mb-4">You paused the campaign on <span className={'text-red'}>{props.campaign.pause_time}</span>.</p>
                        }

                        <div className="pt-2">
                            <h5>Would you like to send all the messages that have built up in your queue since pausing the campaign?</h5>
                            <div className="m-form__group mt-3 align-items-center">
                                <CustomRadioButton 
                                    ariaLabel="followup_type"
                                    value={settings.prevent_previous_followup}
                                    buttonList={followup_types}
                                    onChange={(value) => onChangeFollowupType(value)}
                                />
                            </div>
                        </div>

                        {settings.prevent_previous_followup == 0 && 
                        <>
                            <h5>Would you like to restart this campaign now or tomorrow?</h5>
                            <div className="m-form__group mt-3 align-items-center">
                                <CustomRadioButton 
                                    ariaLabel="restart_type"
                                    value={settings.sent_immediate_after_resume}
                                    buttonList={restart_types}
                                    onChange={(value) => onChangeRestartType(value)}
                                />
                            </div>
                        </>
                        }

                        <div className="pt-2">
                            <u><b><a href="#!" onClick={showPauseInfo}>See Details</a></b></u>
                        </div>

                        {showInfo && 
                            <div className="m-form__group mt-3 text-left border p-4 campaign__start_info">
                                <b>If you choose Yes: We will send out all previously scheduled messages immediately. This could result in a contact getting multiple messages from you at the same time.</b>
                                <p>Ex. Let’s say you had someone on a campaign with a message scheduled to go out once a day for 5 days. If you pause the campaign on day 2 and then unpause on day 5 we would immediately send out the messages that were scheduled for day 3, 4, and 5 all at once.</p>
                                <b>If you choose No: We will only send out messages scheduled for the future.</b>
                                <p>Ex. Let’s say you had someone on a campaign with a message scheduled to go out once a day for 5 days. If you pause on day 2 then unpause on day 4 we would just send out the messages previously scheduled for day 4 and day 5.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </GlobalModal>
    )
}
export default CampaignStartModal;
