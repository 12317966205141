import React, {createContext, useContext, useReducer} from "react";
import SWHKReducer from "./SWHKReducer";
import { swhkStates } from "./States";

const SWHKReducerContext = createContext({});
const SWHKProvider = ({children}) => {
    const [state, dispatch] = useReducer(SWHKReducer, swhkStates);
    return (
        <SWHKReducerContext.Provider
            value={{ state, dispatch }}>{children}
        </SWHKReducerContext.Provider>
    );
};

export {SWHKReducerContext, SWHKProvider};

export const useSWHKContext = () => {
    return useContext(SWHKReducerContext)
};