const HyperTagShow = ({children, link, modifyLink, className = "", show = true}) => {
    const handleMouseEnter = () => {
        if(!show){
            return
        }
        if(modifyLink !== undefined){
            link = modifyLink(link)
        }
        if(link.showLinkTag !== undefined){
            link.showLinkTag(false)
        }
    }
    const handleMouseLeave = () => {
        if(!show){
            return
        }
        if(modifyLink !== undefined){
            link = modifyLink(link)
        }
        if(link.showLinkTag !== undefined){
            link.showLinkTag(true)
        }
    }
    return (
        <div
            className={`${className}`}
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseLeave}
        >
            {children}
        </div>
    );
}
export default HyperTagShow;