/* eslint-disable require-yield */
import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import * as CAMPAIGN_API from '../api/marketingApi';
import * as ACTION from '../constants/marketingActionTypes'
import Utils from "../helpers/Utils";
import {
    fetchCampaignDetails,
    fetchCustomField,
    fetchCampaignLeadSourceDetails,
    fetchUserInfo,
    fetchLeadGroups,
    updateLeadGroups,
    updateLeadFlows,
    fetchLeadFlows,
    fetchCampaignContactStat
} from "../api/marketingApi";

function* campaignWatcher() {
    yield takeEvery(ACTION.FETCH_CAMPAIGNS, fetchCampaignMiddleware);
    yield takeEvery(ACTION.FETCH_CAMPAIGN_DETAILS, campaignDetailsFlow);
    yield takeEvery(ACTION.FETCH_CAMPAIGN_TIMELINE, campaignTimelineMiddleware);
    yield takeEvery(ACTION.FETCH_CUSTOM_FIELD, customFieldDataFlow);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_DETAILS, campaignUpdateFlow);
    yield takeEvery(ACTION.FETCH_CAMPAIGN_LEAD_SOURCE_DETAILS, campaignLeadSourceDetailsFlow);
    yield takeEvery(ACTION.FETCH_USER_INFO, UserInfoFlow);
    yield takeEvery(ACTION.FETCH_LEAD_GROUPS, leadGroupsFlow);
    yield takeEvery(ACTION.UPDATE_LEAD_GROUPS, leadGroupUpdateFlow);
    yield takeEvery(ACTION.FETCH_LEAD_FLOWS, leadFlowsFlow);
    yield takeEvery(ACTION.FETCH_CAMPAIGN_CONTACT_STAT, campaignContactStatFlows);
    yield takeEvery(ACTION.UPDATE_LEAD_FLOWS, leadFlowUpdateFlow);
    yield takeEvery(ACTION.SHARE_CAMPAIGN, shareCampaignListItem);
    yield takeEvery(ACTION.DELETE_CAMPAIGN, deleteCampaignListItem);

    yield takeEvery(ACTION.FETCH_PRE_RECORDING, getPreRecordingListItem);
    yield takeEvery(ACTION.STORE_CAMPAIGN_STEP_SETTING, addCampaignStepSettingMiddleware);
    yield takeEvery(ACTION.STORE_CAMPAIGN_STEP_SETTING_PRE_TEMP, addCampaignStepSettingPreTempMiddleware);
    yield takeEvery(ACTION.STORE_SINGLE_CONTACT, addSingleContactToCampaign);
    yield takeEvery(ACTION.SAVE_FOLLOWUP, saveFollowUpFlow);
    yield takeEvery(ACTION.SAVE_ACTION_FOLLOWUP, saveActionFollowUpFlow);
    yield takeEvery(ACTION.SAVE_ACTIVITY_FOLLOWUP, saveActivityFollowUpFlow);
    yield takeEvery(ACTION.FETCH_VIDEO_FOLDERS, fetchVideoFoldersFlow);
    yield takeEvery(ACTION.FETCH_VIDEOS, fetchVideosFlow);
    yield takeEvery(ACTION.DELETE_VIDEOS, deleteVideosFlow);
    yield takeEvery(ACTION.FETCH_MESSAGE_TEMPLATES, fetchMessageTemplatesFlow);
    yield takeEvery(ACTION.DELETE_MESSAGE_TEMPLATE, deleteMessageTemplateMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_STEP_SETTING, updateCampaignSettingMiddleware);
    yield takeEvery(ACTION.UPDATE_ACTIVITY_FOLLOWUP, updateCampaignActivityMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_STEP_SETTING_WITH_MULTIMEDIA, updateCampaignSettingWithMultimediaMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_STEP_SETTING_WITH_PREVIOUS_FILE, updateCampaignSettingWithPreviousFileMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_STEP_SETTING_WITH_PRE_TEMP, updateCampaignSettingWithPreTempMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_SMS_STEP_SETTING, updateSmsStepSettingMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_EMAIL_STEP_SETTING, updateEmailStepSettingMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_VIDEO_EMAIL_STEP_SETTING, updateVideoEmailStepSettingMiddleware);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_CALL_BRIDGE_STEP_SETTING, updateCallBridgeStepSettingMiddleware);
    yield takeEvery(ACTION.DELETE_CAMPAIGN_SETTING, deleteCampaignSettings);
    yield takeEvery(ACTION.FETCH_CONTACT_LIST_DATATABLE, fetchContactForDataTableMiddleware);
    yield takeEvery(ACTION.MOVE_CONTACT_TO_CAMPAIGN, moveContactToCampaignMiddleware);
    yield takeEvery(ACTION.COPY_CAMPAIGN, copyCampaignMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_ENABLE_TO_START, campaignEnableToStartMiddleware);
    yield takeEvery(ACTION.GET_CAMPAIGN_BASIC_INFO, getCampaignBasicInfoMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_CHECK_TO_START, campaignCheckToStartMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_START, campaignStartMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_PAUSE, campaignPauseMiddleware);
    yield takeEvery(ACTION.ADD_QUICK_REPLY_TEMPLATE, addQuickReplyTemplateMiddleware);

    yield takeEvery(ACTION.CREATE_NEW_CAMPAIGN, createNewCampaignMiddleware);
}

function* campaignDetailsFlow(action) {
    try {
        yield put({type: ACTION.STORE_CAMPAIGN_DETAILS, payload: null});
        const response = yield call(fetchCampaignDetails, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_CAMPAIGN_DETAILS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignTimelineMiddleware(action) {
    try {
        const response = yield call(CAMPAIGN_API.fetchCampaignTimelineApi, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_CAMPAIGN_TIMELINE, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignUpdateFlow(action) {
    try {
        //const response = yield call(updateCampaignDetails, action.payload);
        //let responseData = response.data;
        //yield put({type: ACTION.STORE_CAMPAIGN_DETAILS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignLeadSourceDetailsFlow(action) {
    try {
        const response = yield call(fetchCampaignLeadSourceDetails, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_CAMPAIGN_LEAD_SOURCE_DETAILS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* leadGroupsFlow(action) {
    try {
        const response = yield call(fetchLeadGroups, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_LEAD_GROUPS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* leadGroupUpdateFlow(action) {
    try {
        const response = yield call(updateLeadGroups, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_LEAD_GROUPS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* leadFlowsFlow(action) {
    try {
        const response = yield call(fetchLeadFlows, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_LEAD_FLOWS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* leadFlowUpdateFlow(action) {
    try {
        const response = yield call(updateLeadFlows, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_LEAD_FLOWS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* customFieldDataFlow(action) {
    try {
        const response = yield call(fetchCustomField, action.payload);
        // let responseData = response.data.data;
        let responseData = response.data;
        yield put({type: ACTION.STORE_CUSTOM_FIELD, payload: responseData});
    } catch (error) {
        Utils.handleException(error);
    }
}

function* UserInfoFlow(action) {
    try {
        const response = yield call(fetchUserInfo, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_USER_INFO, payload: responseData});
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchCampaignMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.fetchCampaignList, payload.payload);
        yield put({type: ACTION.STORE_CAMPAIGNS, payload: response.data});
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        Utils.handleException(error);
    }
}

function* addSingleContactToCampaign(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.addSingleContact, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        yield put({type: ACTION.FAILED_STORE_SINGLE_CONTACT});
        Utils.handleException(error);
    }
    // finally {
    //     // call all camaign list 
    // }
}

function* shareCampaignListItem(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.shareCampaignItem, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': 'error', 'message' : 'Something went wrong! Try again.'});
        Utils.handleException(error);
    }
}

function* deleteCampaignListItem(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.deleteCampaignItem, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': 'error', 'message' : 'Something went wrong! Try again.'});
        Utils.handleException(error);
    }
}

function* fetchContactForDataTableMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.fetchContactForDataTableApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* moveContactToCampaignMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.moveContactToCampaignApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}


/* vioce main start */
function* getPreRecordingListItem(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.fetchPreRecordingList, payload.payload);
        yield put({type: ACTION.STORE_PRE_RECORDING, payload: response.data});
    }
    catch (error) {
        Utils.handleException(error);
    }
}

function* campaignContactStatFlows(action) {
    try {
        const response = yield call(fetchCampaignContactStat, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_CAMPAIGN_CONTACT_STAT, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}


function* addCampaignStepSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.addCampaignStepSettingList, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* addCampaignStepSettingPreTempMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.addCampaignStepSettingPreTempList, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCampaignSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateCampaignSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCampaignActivityMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateCampaignActivityApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCampaignSettingWithMultimediaMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateCampaignSettingWithMultimediaApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCampaignSettingWithPreviousFileMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateCampaignSettingWithPreviousFileApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCampaignSettingWithPreTempMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateCampaignSettingWithPreTempApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* saveFollowUpFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.saveFollowUp, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': false, 'message' : 'Something went wrong! Try again.'});
        Utils.handleException(error);
    }
}

function* updateSmsStepSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateSmsStepSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}


function* updateEmailStepSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateEmailStepSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}


function* updateVideoEmailStepSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateVideoEmailStepSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCallBridgeStepSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.updateCallBridgeStepSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* saveActionFollowUpFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.saveActionFollowup, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': false, 'message' : 'Something went wrong! Try again.'});
        Utils.handleException(error);
    }
}

function* saveActivityFollowUpFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.saveActivityFollowup, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': false, 'message' : 'Something went wrong! Try again.'});
        Utils.handleException(error);
    }
}

function* fetchVideoFoldersFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.fetchVideoFolders, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEO_FOLDERS, payload: responseData.data});
        }

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.fetchVideos, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEOS, payload: {videos: responseData.data, s3ServerVideoUrl: responseData.s3ServerVideoUrl, videoLoader: false}});
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.deleteVideo, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchMessageTemplatesFlow(action){
    try {
        //Putting the filter for onScroll,onDelete
        const { perPage, currentPage, messageTemplates } = yield select(state => state.marketingReducer);
        yield put({ type: ACTION.SET_FILTER_MESSAGE_TEMPLATE, payload: action.payload });
        if(currentPage === 0){
            yield put({ type: ACTION.SET_LOADING_MESSAGE_TEMPLATE, payload: true });
        }
        
        const response = yield call(CAMPAIGN_API.getMessageTemplate, action.payload);
        if(response){
            yield put({ type: ACTION.SET_LOADING_MESSAGE_TEMPLATE, payload: false });
        }
        let responseData = response.data;
        if (responseData.status === 'success') {
            if(currentPage === 0){
                yield put({ type: ACTION.STORE_MESSAGE_TEMPLATE, payload: responseData.html });
            }
            else{
                let templates = [...messageTemplates, ...responseData.html]
                yield put({ type: ACTION.STORE_MESSAGE_TEMPLATE, payload: templates });
            }
            if(responseData.html.length >= perPage){
                yield put({ type: ACTION.STORE_CURRENT_PAGE, payload: (currentPage + 1) });
                yield put({ type: ACTION.STORE_NEED_TO_FETCH_FLAG, payload: true });
            }
            else{
                yield put({ type: ACTION.STORE_NEED_TO_FETCH_FLAG, payload: false });
            }
            yield put({ type: ACTION.STORE_FETCHING_MORE_FLAG, payload: false });
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteMessageTemplateMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.deleteMessageTemplate, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteCampaignSettings(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.deleteCampaignSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* copyCampaignMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.copyCampaignApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}


function* campaignEnableToStartMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.campaignEnableToStartApi, payload.payload);
        yield put({type: ACTION.STORE_CAMPAIGN_ENABLE_TO_START, payload: response});
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getCampaignBasicInfoMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.getCampaignBasicInfoApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignCheckToStartMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.campaignCheckToStartApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignStartMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.campaignStartApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignPauseMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.campaignPauseApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* addQuickReplyTemplateMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.addQuickReplyTemplateApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* createNewCampaignMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CAMPAIGN_API.createNewCampaignApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* marketingMiddleware() {
    yield all([
        campaignWatcher(),
    ])
}
