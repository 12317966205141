/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {getVideoFolders} from "./redux/videoEmailAction";
import {MenuItem, Select} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: 'rgba(255,255,255,0.4)',
        borderRadius: '5px',
        height: '3rem'
    }
}));

const LikeVideo = props => {
    const classes = useStyles();
    const [title, setTitle] = useState((props.videoObj && props.videoObj.title) ? props.videoObj.title : '');
    const [description, setDescription] = useState((props.videoObj && props.videoObj.description) ? props.videoObj.description : '');
    const [folderId, setFolderId] = useState((props.videoObj && props.videoObj.campaign_video_folder_id) ? props.videoObj.campaign_video_folder_id : 'default');
    const [folders, setFolders] = useState(props.videoFolders);
    const [likeStatus, setLikeStatus] = useState(false);

    useEffect(() => {
        if (props.videoFolders == null) {
            props.getVideoFolders();
        } else {
            setFolders(props.videoFolders)
        }
    }, [props.videoFolders]);

    const handleSubmit = () => {
        if (likeStatus) {
            props.selectVideo({
                title: title,
                description: description,
                folderId : folderId === 'default' ? '' : folderId
            });
        } else {
            setLikeStatus(true);
        }
    }

    const renderFolderOptions = () => {
        let folderOptions = []
        if (folders != null) {
            folders.map(function(data, index){
                folderOptions.push(
                    <MenuItem className="menuitem-li-hover-color-with-border" key={data.id} value={data.id}>{data.name}</MenuItem>
                )
            });
        }

        return folderOptions;
    }

    return (
        <div>
            <div className="likeArea">
                {likeStatus &&
                    <React.Fragment>
                        <input name="title" autofocus onChange={(e) => setTitle(e.target.value)} defaultValue={title} placeholder="* Type your video title"/>
                        <textarea name="description" onChange={(e) => setDescription(e.target.value)} defaultValue={description} placeholder="* Type a short description"></textarea>
                        <Select className={classes.root} name="folderId" defaultValue={folderId} onChange={(e) => setFolderId(e.target.value)}>
                            <MenuItem value='default'>Default Folder</MenuItem>
                            {renderFolderOptions()}
                        </Select>
                    </React.Fragment>
                }

                <div className="buttons">
                    <div className="button-set" id="button-set-select-video" onClick={() => handleSubmit()}>
                        <CheckCircleOutlineIcon color='primary' fontSize='large'/>
                        {likeStatus ?
                            <p>Upload</p>
                            :
                            <p>Yes</p>
                        }
                    </div>
                    <div className="button-set" onClick={() => props.selectVideo(0)}>
                        <HighlightOffIcon color='primary' fontSize='large'/>
                        <p>No</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        videoFolders: state.videoEmailReducer.videoFolders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVideoFolders: () => dispatch(getVideoFolders())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeVideo);