import { useMLv2Context } from "../reducer/MLV2Provider";
import {Icons} from '../helpers/Icons'
import CustomButton from "../components/Button";
import { handleStates } from "../reducer/Actions";
import { IconHandler } from "../hanlders/IconHandler";

const ScreenWiseTopBar = () => {
    const {state, dispatch} = useMLv2Context();
    const renderFolder = () => {
        if(state.selectedFolder.id == null){
            return;
        }
        let iconClass = new IconHandler(state.selectedFolder.text_color, parseInt(state.selectedFolder.icon));
        return (
            <div className="mlvc_bc_top_bar_left_inner" style={{backgroundColor: state.selectedFolder.color, borderRadius: '14px', padding: '1px 7px'}}>
                <span>{iconClass.getIcon}</span>
                <span>{state.selectedFolder.title}</span>
            </div>
        )
    }
    return (
        <div className="mlvc_bc_top_bar">
            <div className="mlvc_bc_top_bar_left">
                <div className="mlvc_bc_top_bar_left_inner">
                    <span onClick={() => {dispatch(handleStates({isShowFolderDrawer: true}))}}>{Icons.collapse}</span>
                    <span>My Campaigns</span>
                </div>
                {renderFolder()}
            </div>
            <div className="mlvc_thc_right_side">
                <CustomButton 
                    icon={Icons.plusBlue}
                    textColor={'#006DF5'}
                    onClick={() => dispatch(handleStates({isShowFolderCreate: true}))}
                    bgColor={'transparent'}
                    style={{border: '1px solid #006DF5'}}
                    title={'Folder'}
                />
                <CustomButton 
                    icon={Icons.plusWhite}
                    textColor={'#FFFFFF'}
                    onClick={() => dispatch(handleStates({isShowCampaignCreate: true}))}
                    bgColor={'#006DF5'}
                    title={'Campaign'}
                />
            </div>
        </div>
    );
}
export default ScreenWiseTopBar;