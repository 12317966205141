/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable eqeqeq */
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteMessageTemplate,
  getMessageTemplates,
} from "../../../actions/marketingAction";
import {
  PlatTypeForPackage,
  QuickReplyType,
} from "../../../constants/CoreConstants";
import showNotification from "../../globals/ShowNotification";
import TemplateBroadcastModal from "../quickTemplates/TemplateBroadcastModal";
import CustomImageViewer from "../../Common/CustomImageView";
import CustomBootstrapTooltip from "../../Common/CustomBootstrapTooltip";
import ReactAudioPlayer from "react-audio-player";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Messages from "../../../constants/Messages";
import OutSideConstants from "../../../constants/ConstantFromOutside";
import { updateMessageTemplate } from "../../../api/marketingApi";
import Constants from "../../QuickReplyCard/assets/Constants";
import { handleChangeActionTypes } from "../../Video/redux/videoEmailAction";
import Utils from "../../../helpers/Utils";
import ActionControl from "../../Common/ActionControl/ActionControl";
import NewConfirmAlert from "../../Common/new-alert/NewConfirmAlert";

const TEMPLATE_CLASSES = {
  [QuickReplyType.SMS]: "sms_template",
  [QuickReplyType.MMS]: "mms_template",
  [QuickReplyType.VOICE]: "voice_template",
  [QuickReplyType.EMAIL]: "email_template",
  [QuickReplyType.VIDEO_EMAIL]: "video_email_template",
  [QuickReplyType.POSTCARD]: "postcard_template",
  [QuickReplyType.GREETING_CARD]: "greeting_card_template",
  [QuickReplyType.LETTER]: "letter_template",
  [QuickReplyType.GIFT]: "gift_card_template",
};

const EachTemplate = ({
  template,
  showNotification,
  activeAudioPlayer,
  handleAudioPlayer,
}) => {
  return (
    <div
      className={
        "template update_quick_template_module " +
        TEMPLATE_CLASSES[template.type]
      }
    >
      <div>
        <CustomBootstrapTooltip title={template.title} arrow>
          <h6 className="center-align truncate">{template.title}</h6>
        </CustomBootstrapTooltip>
      </div>
      <div
        className={`template_display quick-reply-display-div ${
          template.type == QuickReplyType.VOICE ? "justify-content-center" : ""
        } ${
          template.type == QuickReplyType.POSTCARD ||
          template.type == QuickReplyType.GREETING_CARD ||
          template.type == QuickReplyType.LETTER
            ? ""
            : "awesome__scroll_bar"
        }`}
      >
        <RenderTemplateBody
          template={template}
          activeAudioPlayer={activeAudioPlayer}
          handleAudioPlayer={handleAudioPlayer}
        />
      </div>
      <TemplateActions
        template={template}
        showNotification={showNotification}
      />
    </div>
  );
};

export default showNotification(EachTemplate);

const RenderTemplateBody = ({
  template,
  activeAudioPlayer,
  handleAudioPlayer,
}) => {
  if (template.type == QuickReplyType.SMS) {
    return <div dangerouslySetInnerHTML={{ __html: template.message }} />;
  } else if (template.type == QuickReplyType.MMS) {
    // return <img src={template.video_file_url} alt="Loading"/>
    return (
      <CustomImageViewer url={template.video_file_url} key={template.id} />
    );
  } else if (template.type == QuickReplyType.VIDEO_EMAIL) {
    return <img className="w-100" src={template.gif_file_url} alt="" />;
  } else if (template.type == QuickReplyType.VOICE) {
    return (
      <div className="audio_player">
        <div className="controls d-flex align-items-center">
          {/* <CustomAudioPlayer 
                        hanldePlayer={(activePlayerId) => handleAudioPlayer(activePlayerId)}
                        activePlayerId={activeAudioPlayer}
                        unique_key={template.id+'audio_player'}
                        src={template.template_files.file}
                /> */}
          <ReactAudioPlayer
            src={template.template_files.file}
            autoPlay={false}
            controls
          />
        </div>
      </div>
    );
  } else if (template.type == QuickReplyType.EMAIL) {
    return (
      <>
        <p className={"email-subject truncate"}>{template.subject}</p>
        <div
          className="regarding_person"
          dangerouslySetInnerHTML={{ __html: template.message }}
        />
      </>
    );
  } else if (
    template.type == QuickReplyType.POSTCARD ||
    template.type == QuickReplyType.GREETING_CARD ||
    template.type == QuickReplyType.LETTER
  ) {
    return <iframe src={template.pdf_url} frameborder="0"></iframe>;
  } else if (template.type == QuickReplyType.GIFT) {
    return (
      <>
        <p>{template.gift_sku_id}</p>
        <iframe src={template.pdf_url} frameborder="0"></iframe>
      </>
    );
  }

  return "";
};

const TemplateAction = ({ template, deleteFromList, index, type }) => {
  const isTempUser = Utils.getAccountData('isTemplateUser');
  const dispatch = useDispatch();
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);
  const [submittingTemplate, setSubmittingTemplate] = useState(false);

  const deleteTemplate = (e) => {
    e.preventDefault();
    NewConfirmAlert({
      onSubmit : async() => {
        dispatch(
          deleteMessageTemplate(
            {
              temp_id: template.id,
            },
            (res) => {
              if (res.success) {
                if (window.showNotification !== undefined) {
                  window.showNotification("SUCCESS", res.message);
                }
                if (deleteFromList !== undefined) {
                  deleteFromList(index);
                }
                // dispatch(getMessageTemplates(filterTemplate))
              } else {
                if (window.showNotification !== undefined) {
                  window.showNotification("ERROR", res.message);
                }
              }
            }
          )
        );
      },
        title: `Are you sure you want to delete this quick reply?`,
        description: '',
        cancelText: 'No',
        submitText: 'Yes'
    });
  };

  const openBroadcastModal = (e) => {
    e.preventDefault();
    setShowBroadcastModal(true);
  };

  const updateQuickReplyEmailVideo = (response) => {
    handleSaveTemplate({
      content_type: response.content,
      content: response.content,
      title: response.title,
      templateId: response.id,
      videoId: response.email_video_id,
      email_body: response.message,
      email_subject: response.subject,
    });
  };

  const handleSaveTemplate = (data) => {
    // check validation
    if (submittingTemplate) return;
    setSubmittingTemplate(true);
    if (data.title.trim() == "") {
      window.showNotification(Messages.emptyTitleInAddQuickReply, "error");
      setSubmittingTemplate(false);
      return false;
    }

    let formData = new FormData();
    //$packageType = ViewHelper::getPackTypeForView(auth()->id());
    let package_type = OutSideConstants.packageType;
    formData.append(
      "media_type",
      package_type == PlatTypeForPackage.PLAN_TYPE_STANDARD
        ? QuickReplyType.SMS
        : QuickReplyType.EMAIL
    );
    formData.append("content", data.content);
    formData.append("title", data.title);
    formData.append("id", data.templateId);
    if (data.content_type == QuickReplyType.SMS) {
      // sms save
      for (var key in data) {
        formData.append(key == "body_sms" ? "message" : key, data[key]);
      }
      formData.append("type", QuickReplyType.SMS);
    } else if (data.content_type == QuickReplyType.MMS) {
      // mms save
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("type", QuickReplyType.MMS);
    } else if (data.content_type == QuickReplyType.VOICE) {
      // voice save
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("type", QuickReplyType.VOICE);
    } else if (data.content_type == QuickReplyType.VIDEO_EMAIL) {
      // video email save
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("type", QuickReplyType.VIDEO_EMAIL);
    } else if (data.content_type == QuickReplyType.EMAIL) {
      // email save
      for (let key in data) {
        if (key == "email_subject") {
          formData.append("subject", data[key]);
        } else if (key == "body_sms") {
          formData.append("message", data[key]);
        } else if (key != "content") {
          formData.append(key, data[key]);
        }
      }
      formData.append("type", QuickReplyType.EMAIL);
    } else if (
      data.content_type == QuickReplyType.POSTCARD ||
      data.content_type == QuickReplyType.LETTER ||
      data.content_type == QuickReplyType.GREETING_CARD ||
      data.content_type == QuickReplyType.GIFT
    ) {
      for (let key in data) {
        formData.append([key], data[key]);
      }
      formData.append("type", data.content_type);
    }

    updateMessageTemplate(formData).then((res) => {
      let response = res.data;
      setSubmittingTemplate(false);
      if (response.status == "success") {
        window.showNotification("success", response.html);
        window.sendCommunication({ open: false }, "videoEmail");
        dispatch(getMessageTemplates({ search_key: "", type: type }));
      } else if (response.status == "validation-error") {
        // do validation here
        if (typeof response.html.title != "undefined") {
          window.showNotification(response.html.title, "error");
        }
      } else {
        window.showNotification("Something went wrong. Try again.", "error");
      }
    });
  };

  return (
    <>
      <div className="quick-reply-card-alt-content">
        <ActionControl alertMessage="Quick Reply Broadcast feature is not available for template user" status="error"   isDisabled={isTempUser == 1}>
          <div
            onClick={(e) => openBroadcastModal(e)}
            className="quick-reply-card-alt-campaign accent--bg--text--color"
          >
            <svg
              width="25"
              height="21"
              viewBox="0 0 25 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.0807 0L8.71094 4.54768V13.2149L20.0807 17.7626V0Z"></path>
              <path d="M7.63481 4.71484H6.71875V13.0354H7.63481V4.71484Z"></path>
              <path d="M5.65064 12.8401V4.51953H1.95599C1.43722 4.51953 0.939714 4.72561 0.572895 5.09243C0.206075 5.45925 0 5.95676 0 6.47552V10.8928C0 11.4115 0.206075 11.9091 0.572895 12.2759C0.939714 12.6427 1.43722 12.8488 1.95599 12.8488L5.65064 12.8401Z"></path>
              <path d="M24.0011 8.72105C24.0002 7.98929 23.7092 7.28775 23.1918 6.77031C22.6743 6.25288 21.9728 5.9618 21.241 5.96094H21.1562V11.4812H21.241C21.9728 11.4803 22.6743 11.1892 23.1918 10.6718C23.7092 10.1544 24.0002 9.45281 24.0011 8.72105Z"></path>
              <path d="M5.1711 20.9403C5.46206 20.9403 5.74908 20.8731 6.00984 20.744C6.27061 20.615 6.49808 20.4275 6.6746 20.1962C6.85111 19.9649 6.9719 19.6961 7.02758 19.4105C7.08325 19.1249 7.07232 18.8303 6.99561 18.5497L5.77421 14.1172H1.95242H1.84375L3.34335 19.5581C3.45566 19.9562 3.69502 20.3068 4.02498 20.5563C4.35494 20.8059 4.75741 20.9407 5.1711 20.9403Z"></path>
            </svg>
            <span>Broadcast</span>
          </div>
        </ActionControl>
        {template.type === Constants.MESSAGE_TEMPLATE.VIDEO_EMAIL ? (
          <a
            href="#"
            className="quick-reply-card-alt-campaign-edit-button accent--bg--text--color"
            onClick={(e) => {
              e.preventDefault();
              window.sendCommunication(
                {
                  open: true,
                  from: "quick_reply",
                  currentQuickReply: template,
                  responseCallback: updateQuickReplyEmailVideo,
                },
                "videoEmail"
              );
            }}
          >
            <EditIcon fontSize="small" />
            <span>Edit</span>
          </a>
        ) : (
          <Link
            to={"/campaign/quick-reply/" + template.id}
            className="quick-reply-card-alt-campaign-edit-button accent--bg--text--color"
          >
            <EditIcon fontSize="small" />
            <span>Edit</span>
          </Link>
        )}

        <div
          onClick={(e) => deleteTemplate(e)}
          className="quick-reply-card-alt-campaign-delete-button"
        >
          <DeleteIcon fontSize="small" />
          <span>Delete</span>
        </div>
      </div>
      {/* <div className="template_buttons d-flex justify-content-center d-none">
            <a href="#!" onClick={(e) => openBroadcastModal(e)} className="view_button accent--bg--color">                
                <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0807 0L8.71094 4.54768V13.2149L20.0807 17.7626V0Z" fill="white"></path><path d="M7.63481 4.71484H6.71875V13.0354H7.63481V4.71484Z" fill="white"></path><path d="M5.65064 12.8401V4.51953H1.95599C1.43722 4.51953 0.939714 4.72561 0.572895 5.09243C0.206075 5.45925 0 5.95676 0 6.47552V10.8928C0 11.4115 0.206075 11.9091 0.572895 12.2759C0.939714 12.6427 1.43722 12.8488 1.95599 12.8488L5.65064 12.8401Z" fill="white"></path><path d="M24.0011 8.72105C24.0002 7.98929 23.7092 7.28775 23.1918 6.77031C22.6743 6.25288 21.9728 5.9618 21.241 5.96094H21.1562V11.4812H21.241C21.9728 11.4803 22.6743 11.1892 23.1918 10.6718C23.7092 10.1544 24.0002 9.45281 24.0011 8.72105Z" fill="white"></path><path d="M5.1711 20.9403C5.46206 20.9403 5.74908 20.8731 6.00984 20.744C6.27061 20.615 6.49808 20.4275 6.6746 20.1962C6.85111 19.9649 6.9719 19.6961 7.02758 19.4105C7.08325 19.1249 7.07232 18.8303 6.99561 18.5497L5.77421 14.1172H1.95242H1.84375L3.34335 19.5581C3.45566 19.9562 3.69502 20.3068 4.02498 20.5563C4.35494 20.8059 4.75741 20.9407 5.1711 20.9403Z" fill="white"></path></svg>
                <span>Broadcast</span>								
            </a>
            <Link to={'/campaign/quick-reply/'+template.id} className="edit_button accent--bg--color ">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13.4751" cy="13.4966" r="13" fill="white"/>
                    <path d="M8.05344 15.8042C8.00059 15.857 7.96504 15.9243 7.94996 15.9968L7.4158 18.6685C7.3909 18.7923 7.43019 18.92 7.51928 19.01C7.59109 19.0818 7.68779 19.1202 7.78685 19.1202C7.81092 19.1202 7.83596 19.118 7.86086 19.1127L10.5318 18.5786C10.6058 18.5634 10.673 18.528 10.7252 18.475L16.7031 12.497L14.0322 9.82625L8.05344 15.8042Z" fill="#3C7EF3"/>
                    <path d="M18.5498 7.98242C17.8132 7.24573 16.6149 7.24573 15.8789 7.98242L14.8333 9.02804L17.5042 11.699L18.5498 10.6532C18.9064 10.2974 19.1029 9.82284 19.1029 9.31816C19.1029 8.81347 18.9064 8.33894 18.5498 7.98242Z" fill="#3C7EF3"/>
                </svg>													
                <span>Edit</span>
            </Link>
            <a href="#!" onClick={(e)=>deleteTemplate(e)} className="delete_button">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13.4751" cy="13.4966" r="13" fill="white"/>
                    <path d="M16.6782 9.18408H14.7876V8.84033C14.7876 8.27078 14.3259 7.80908 13.7563 7.80908H12.3813C11.8118 7.80908 11.3501 8.27078 11.3501 8.84033V9.18408H9.45947C8.98486 9.18408 8.6001 9.56885 8.6001 10.0435V10.731C8.6001 10.9208 8.75399 11.0747 8.94385 11.0747H17.1938C17.3837 11.0747 17.5376 10.9208 17.5376 10.731V10.0435C17.5376 9.56885 17.1528 9.18408 16.6782 9.18408ZM12.0376 8.84033C12.0376 8.65084 12.1919 8.49658 12.3813 8.49658H13.7563C13.9458 8.49658 14.1001 8.65084 14.1001 8.84033V9.18408H12.0376V8.84033Z" fill="#FF5858"/>
                    <path d="M9.25244 11.7622C9.1911 11.7622 9.14222 11.8134 9.14514 11.8747L9.42874 17.8268C9.45495 18.3776 9.90741 18.8091 10.4587 18.8091H15.679C16.2303 18.8091 16.6827 18.3776 16.7089 17.8268L16.9925 11.8747C16.9954 11.8134 16.9466 11.7622 16.8852 11.7622H9.25244ZM14.4438 12.6216C14.4438 12.4316 14.5977 12.2778 14.7876 12.2778C14.9775 12.2778 15.1313 12.4316 15.1313 12.6216V17.0903C15.1313 17.2802 14.9775 17.4341 14.7876 17.4341C14.5977 17.4341 14.4438 17.2802 14.4438 17.0903V12.6216ZM12.7251 12.6216C12.7251 12.4316 12.8789 12.2778 13.0688 12.2778C13.2588 12.2778 13.4126 12.4316 13.4126 12.6216V17.0903C13.4126 17.2802 13.2588 17.4341 13.0688 17.4341C12.8789 17.4341 12.7251 17.2802 12.7251 17.0903V12.6216ZM11.0063 12.6216C11.0063 12.4316 11.1602 12.2778 11.3501 12.2778C11.54 12.2778 11.6938 12.4316 11.6938 12.6216V17.0903C11.6938 17.2802 11.54 17.4341 11.3501 17.4341C11.1602 17.4341 11.0063 17.2802 11.0063 17.0903V12.6216Z" fill="#FF5858"/>
                </svg>											
                <span>Delete</span>								
            </a>
        </div> */}

      {showBroadcastModal && (
        <TemplateBroadcastModal
          show={showBroadcastModal}
          template={template}
          onClose={() => setShowBroadcastModal(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.marketingReducer.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActionTypes: (payload) => dispatch(handleChangeActionTypes(payload)),
  };
};

export const TemplateActions = connect(mapStateToProps,mapDispatchToProps)(TemplateAction);
