import CustomPopupMenu from "./CustomPopupMenu";
import "./mulitEmailSelectDropdownStyle.css";

const GlobalUserListDropdown = ({
    className,
    handleSelect,
    emailList = [],
    selected = [],
    selectedId = [],
    reorganized = false,
    valueField = "",
    labelField = "",
    textLength=26,
    height="42px",
    placeholder=null
}) => {

    return(
        <div className={`pypepro_global__custom__field__grouped__wraper ${className ?? ""}`}>
            <CustomPopupMenu 
                list={emailList}
                handleClick={(value, remove) => handleSelect(value, remove)}
                isLoading={false}
                selected={selected}
                selectedId={selectedId}
                placeholder={placeholder ?? "Select Email"}
                reorganized={reorganized}
                valueField={valueField}
                labelField={labelField}
                textLength={textLength}
                height={height}
            />
        </div>
    );
}
export default GlobalUserListDropdown;