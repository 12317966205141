/* eslint-disable eqeqeq */
import React from 'react';

export default class GetDurationOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            duration : this.props.value
        }

        this.handleChange = this.handleChange.bind(this);
    }


    _getOptions() {
        let options = [];
        let j = 0;
        // loop through half hour increments
        for(let i = 0; i < 8; i++) {
            for(j = 0; j <= 3; j++ ){
                var minute = (j == 0) ? '00' : j * 15;
                //if (("0"+i+":"+minute) !== '00:00') {
                    options.push(<option key={"0"+i+":"+minute} value={"0"+i+":"+minute}>{"0"+i+":"+minute}</option>);
                //}
            }
        }
        options.push(<option key={'08:00'} value={'08:00'}>{'08:00'}</option>);

        return options;
    }

    handleChange(e)
    {
        this.props.durationHandler(e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }


    render() {

        return (

            <React.Fragment>
                <select
                    defaultValue={this.state.duration}
                    name="duration"
                    onChange={this.handleChange}
                    className="form-control m-input m-input--square"
                >
                    {this._getOptions()}
                </select>
            </React.Fragment>

        );
    }
}
