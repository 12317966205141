/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from "react";
import { campaignEnableToStart, fetchCampaign, updateReducerState } from "../../../actions/marketingAction";
import { connect } from 'react-redux'
//moved to CDN
// import '../marketing.css';
// import Messages from "../../../constants/Messages";
import CampaignItem from "./CampaignListItem";
// import RenderHelper from "../../../helpers/RenderHelper";
import CampaignHeader from "./CampaignHeader";
import Loading from "../../Common/Loading";
//import CustomPagination from "../../globals/CustomPagination";
// import CustomAudioPlayer from "../../globals/CustomAudioPlayer";
import CustomProgressbar from "../../Common/CustomProgressbar";
import NewLoader from "../../Common/NewLoader";
import Utils from "../../../helpers/Utils";
import { EmptyPage } from "./EmptyPage";
import useDelayCallback from "../../../hooks/useDelayCallback";
import socketIOClient from "socket.io-client";
import CampaignGroupApp from "../../campaignGroup/providerWrapper";
import '../marketing-new.css'

const paginationIcon = {
    previous: <svg width="14" height="14" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50171 0.248609C8.79377 0.248039 9.07682 0.349756 9.30171 0.536108C9.42828 0.641045 9.53291 0.769922 9.6096 0.915356C9.68629 1.06079 9.73353 1.21992 9.74863 1.38364C9.76372 1.54737 9.74637 1.71246 9.69757 1.86946C9.64876 2.02646 9.56946 2.1723 9.46421 2.29861L3.86421 8.99861L9.26421 15.7111C9.36804 15.839 9.44558 15.9861 9.49237 16.144C9.53916 16.3019 9.55427 16.4676 9.53685 16.6313C9.51943 16.7951 9.46981 16.9539 9.39084 17.0984C9.31187 17.2429 9.20511 17.3705 9.07671 17.4736C8.94737 17.5874 8.79592 17.6732 8.63184 17.7257C8.46776 17.7782 8.2946 17.7962 8.12324 17.7786C7.95187 17.761 7.78599 17.7082 7.63602 17.6234C7.48604 17.5387 7.3552 17.4238 7.25171 17.2861L1.21421 9.78611C1.03035 9.56244 0.929847 9.28189 0.929847 8.99236C0.929847 8.70283 1.03035 8.42227 1.21421 8.19861L7.46421 0.69861C7.5896 0.547338 7.7489 0.427757 7.92916 0.349571C8.10942 0.271387 8.30558 0.236795 8.50171 0.248609Z" fill="white"/>
    </svg>,
    backwardSlash : <svg width="21" height="37" viewBox="0 0 21 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="19.7058" y1="0.470588" x2="0.88231" y2="35.7647" stroke="#3C7EF3" strokeWidth="2"/>
    </svg>,
    next: <svg width="14" height="14" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.32251 17.7514C1.03045 17.752 0.747402 17.6502 0.522512 17.4639C0.395939 17.359 0.291312 17.2301 0.214622 17.0846C0.137932 16.9392 0.0906866 16.7801 0.0755918 16.6164C0.0604969 16.4526 0.0778495 16.2875 0.126655 16.1305C0.175461 15.9735 0.25476 15.8277 0.360012 15.7014L5.96001 9.00139L0.560013 2.28889C0.456181 2.16103 0.378641 2.01391 0.331851 1.85599C0.285062 1.69806 0.269944 1.53245 0.287368 1.36866C0.304792 1.20488 0.354413 1.04615 0.433381 0.901604C0.512348 0.757058 0.619104 0.629544 0.747513 0.52639C0.876845 0.412595 1.0283 0.32676 1.19238 0.274274C1.35646 0.221788 1.52961 0.203783 1.70098 0.221389C1.87235 0.238995 2.03822 0.291833 2.1882 0.376585C2.33818 0.461337 2.46902 0.576175 2.57251 0.713891L8.61001 8.21389C8.79387 8.43756 8.89437 8.71811 8.89437 9.00764C8.89437 9.29717 8.79387 9.57773 8.61001 9.80139L2.36001 17.3014C2.23462 17.4527 2.07532 17.5722 1.89506 17.6504C1.7148 17.7286 1.51865 17.7632 1.32251 17.7514Z" fill="white"/>
    </svg>
}

const App = props => {

    const [totalRecord, setTotalRecord] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [campaignList, setCampaignList] = useState([])
    const [update, setUpdate] = useState(false);
    const [paginationChange, setPaginationChange] = useState(false);

    const marketingSocket = useRef(null);

    const campaignListRef = useRef(campaignList);

    const [firstLanding, setFirstLanding] = useState(true)

    const [groupId, setGroupId] = useState(null)

    useEffect(() => {

        return () => {
            setFirstLanding(true);
            setCurrentPage(1)
            setTotalPage(0)
            setTotalRecord(0)
            setCampaignList([])
            props.updateReducerState({
                isLoading: true,
                firstLoading: true,
                campaigns: []
            })
        }
    }, [])

    useEffect(()=>{
        campaignListRef.current = campaignList
    },[campaignList])
    
    useEffect(() => {

        document.title = `Campaigns | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }
        fetchCampaignList(currentPage);
        loadSocket()

        isEnableToStartCampaign();

        return () => {
            try{
                marketingSocket.current.disconnect()
            }catch(error){
                console.log("Error on disconnect socket from campaign list")
            }
        }
    }, [])

    useDelayCallback(() => {
        if(firstLanding){
            return false;
        }
        if(currentPage !== "" && parseInt(currentPage) !== 0 && paginationChange){
            if(parseInt(totalPage) >= parseInt(currentPage)) {
                fetchCampaignList(currentPage);
            }
        }
    }, [currentPage,/* totalPage */]);

    useDelayCallback(() => {
        setPaginationChange(false);
        if(firstLanding){
            return;
        }
        props.fetchCampaign({"query" : props.marketing.campaignSearchText, "page" : 0},(response) => {
            setCampaignList(response.data)
            setTotalPage(response.last_page)
            setTotalRecord(response.total)
            setCurrentPage(1);
            setUpdate(!update)
        })
    }, [props.marketing.campaignSearchText])

    useEffect(() => {
        setCampaignList(props.campaigns)
        setTotalPage(props.marketing.totalPage)
    }, [props.campaigns]);

    const handleSocketForCampaign = (campaign_id) => {
        let old_list = [...campaignListRef.current];
        let selected_index = null;
        let filter_data = old_list.filter((item, index) => {
            if(item.id == campaign_id){
                selected_index = index;
            }
            return item.id == campaign_id
        })
        if(filter_data.length > 0 && selected_index != null){
            try{
                old_list[selected_index].status = 3
                setCampaignList(old_list)
                setUpdate((state)=> !state)
            }catch(error){
                console.log("updating status error from socket")
            }
        }
    }

    const loadSocket = () =>
    {
        marketingSocket.current = socketIOClient(process.env.REACT_APP_NODE_SERVER, {
            resource: 'B/socket.io',
            'force new connection': true
        });
        
        let userId = Utils.getAccountData('userId');
        const userRoom  = "room:" + userId;
        const userBackgroundNotificationRoom  = "background-task-notification:room:" + userId;
    
        marketingSocket.current.on('connect', function (data) {
            marketingSocket.current.emit('join-notification-room', userRoom);
            marketingSocket.current.emit('join-background-task-notification-room', userBackgroundNotificationRoom);
        });
        
        marketingSocket.current.on('background-task-notification', function (data) {
            if (data.params.action === 'delete') {
                /* for campaign start */
                if (typeof data.params.type !== 'undefined' && data.params.type == 'success') {
                    if(data.params.message !== undefined && data.params.message === "Campaign is started"){
                        handleSocketForCampaign(data.params.related_id)
                    }                    
                } 
            }
        });

        marketingSocket.current.on("disconnect", () => {
            console.log("Socket disconneted for marketing module"); // undefined
        });
    }

    const isEnableToStartCampaign = () => {
        props.campaignEnableToStart()
    }

    const fetchCampaignList = (page, group_id = groupId) => {
        props.fetchCampaign({'page': page, groupId: group_id, "query" : props.marketing.campaignSearchText}, (response) => {
            // setCampaignList(response.html)
            // setTotalPage(response.totalPages) 
            // setUpdate(!update)
            setCampaignList(response.data)
            setTotalPage(response.last_page)
            setTotalRecord(response.total)
            setUpdate(!update)
            setFirstLanding(false)
        })
    }

    //pagination
    const renderPagination = () => {
        let previous_page = null, next_page = null; 
        let totalPages = Math.ceil(totalRecord / perPage)
        let _currentPage = parseInt(currentPage);
        if(_currentPage > 1){
            previous_page = _currentPage - 1
        }
    
        if(_currentPage !== totalPages){
            next_page = _currentPage + 1
        }

        const handleChangeSearchPage = (pageNo) => {
            if (!Utils.checkLetterExistInText(pageNo) || pageNo === ""){
                setPaginationChange(true);
                setCurrentPage(pageNo);  
            }
        }
        
        const handleChangePage = (event, pageNo) => {
            event.preventDefault();
            if (pageNo != null && pageNo !=='') {
                setPaginationChange(true);
                setCurrentPage(pageNo);
            }   
        };
    
        if(totalRecord === 0){
            return false;
        }
    
        return(
            <div className="pagination_bar pagination_bar__update__v2">
                <ul className="pagination_bar__ul__v2">
                    <li>
                        <a 
                            href="#" onClick={(event) => handleChangePage(event, previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {paginationIcon.previous}
                        </a>
                    </li>
    
                    <li className="active">
                        <div className="campaign-pagination-text" >
                        <input
                            className="campaign-pagination-no"
                            type="text" 
                            placeholder="Search Campaign Page"
                            value={currentPage}
                            onChange={(event)=> handleChangeSearchPage(event.target.value)}
                        />               
                        </div>
                    </li>
                    <li className="pagination__divider__v2"><div className="pagination--text">/</div></li>
                    <li>
                        <div className="pagination__divider__total__v2"> {totalPages}</div>
                    </li>
                    <li>
                        <a 
                            href="#" onClick={(event) => handleChangePage(event, next_page)}
                            style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {paginationIcon.next}	
                        </a>
                    </li>
                    
                </ul>
                <span style={{color: '#9daac1', marginLeft: 20}}>Total Outcome : {totalRecord}</span>
            </div>
        )
    }

    const deleteCampaignFromList = async(index, campaign) => {

        let totalPages = Math.ceil(totalRecord / perPage);

        if(totalRecord <= 10 || (totalRecord % 10 !== 1 && currentPage === totalPages)) {
            setTotalRecord(totalRecord-1);
            let campaign_lists =  campaignList;
            campaign_lists.splice(index, 1);
            setCampaignList(campaign_lists)
            setUpdate(!update);
        } else {
            if(currentPage > 1 && totalRecord % 10 === 1) {
                setCurrentPage(currentPage-1);
            } else {
                fetchCampaignList(currentPage);
            }
        }
    }

    return (
        <>
            <div className="container-fulid content_loader">
                <div className="row">
                    <div className="col s12">

                        <CampaignHeader />
                        
                        {(props.marketing.isLoading && !props.marketing.firstLoading) &&
                        <CustomProgressbar />
                        }

                        {/* <CampaignGroupApp 
                            onChangeFolder={(item) => {fetchCampaignList(0, item && item.id)}}
                        /> */}

                        {(props.marketing.isLoading && props.marketing.firstLoading) &&
                            // <div className='loader-cotainer'><Loading /></div>
                            <div className='loader-cotainer parent-loader-div'>
                                <NewLoader loading={props.marketing.isLoading} onlyLoader={true} size={34} message={""} />
                            </div>
                        }

                        {(/* !props.marketing.isLoading &&  */!props.marketing.firstLoading) &&
                        <div className="campaigns_list_content awesome__scroll_bar update_campaign_list">
                            {campaignList.length > 0 && (update || !update) &&
                                campaignList.map((item, index) => {
                                    return (
                                        <CampaignItem eachItem={item} index={index} key={`campaignList-${item.id}${item.status}`} deleteCampaignFromList={deleteCampaignFromList}/>
                                    );
                                })
                            }

                            {(props.marketing.isMoreLoading && campaignList.length>0 && currentPage > 1)&&
                                <Loading />
                            }

                            {campaignList.length === 0 && 
                                <EmptyPage/>
                            }

                            {/* <CustomPagination 
                                total_page={totalPage}
                                total_list = {totalPage*10}
                                // total_list = {200}
                                active_list={currentPage}
                                // active_list={12}
                                handleChange={(response) => {
                                    setCurrentPage(response)
                                }}
                            /> */}
                            {campaignList.length > 0 &&
                            <div className="pagination_position">
                                 {renderPagination()}
                            </div>
                            }
                        </div>
                        }



                        {/* <CustomAudioPlayer 
                            hanldePlayer={(activePlayerId) => setActivePlayerId(activePlayerId)}
                            activePlayerId={activePlayerId}
                            unique_key={'first'}
                            src="https://s3.us-east-1.amazonaws.com/pypepro/user/14/kaF5kbuQalCy0oDmF9AXL9NqxcYDXPiaAS3oYE4F.mp3"
                        />

                        <CustomAudioPlayer 
                            hanldePlayer={(activePlayerId) => setActivePlayerId(activePlayerId)}
                            activePlayerId={activePlayerId}
                            unique_key={'second'}
                            src="https://s3.us-east-1.amazonaws.com/pypepro/user/14/qe93bIjToqebxpY2qaXlSyWbjgPPmRqI59s1tp8U.mp3"
                        /> */}


                        
                    </div>
                    
                </div>
            
                {/* {(props.marketing.isLoading && !props.marketing.firstLoading) &&
                <div className="Custome__loadar">
                    <Loading />
                </div>
                } */}
            </div>
        </>
    );
}


const mapStateToProps = state => ({
    marketing: state.marketingReducer,
    campaigns: state.marketingReducer.campaigns
});

const mapDispatchToProps = dispatch => ({
    updateReducerState: (params) => dispatch(updateReducerState(params)),
    fetchCampaign: (params, callback) => dispatch(fetchCampaign(params, callback)),
    campaignEnableToStart: (params, callback) => dispatch(campaignEnableToStart(params, callback)),
})

const Marketing = connect(mapStateToProps, mapDispatchToProps)(App);

export default Marketing;
