/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { MenuItem } from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from "@material-ui/core/Select";
import Radio from '@material-ui/core/Radio';
import { getTeamUsers, updateOrCreateLeadGroup } from "../../../api/marketingApi";
import './LeadGroupModal.css';
import GlobalModal from "../../globals/Modal/GlobalModal";
import AddCircleIcon from '@material-ui/icons/AddCircle'

const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    return isMounted.current;
}

const LeadGroupModal = (props) => {
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [teamUsers, setTeamUsers] = useState([]);

    const [groupName, setGroupName] = useState('');
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [groupType, setGroupType] = useState(1);
    const [notClaimWithIn, setNotClaimWithIn] = useState(30);
    const [assignTo, setAssignTo] = useState(null);

    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]);

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (props.data) {
            setGroupName(props.data.name);
            setGroupType(parseInt(props.data.method));
            setNotClaimWithIn(props.data.time_to_auto_assign);
            setAssignTo(props.data.assigned_to);
            let userArray = [];
            props.data.group_users.forEach(element => {
                userArray.push(element.id)
            });
            setSelectedUserIds(userArray);
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    const toggleAfterCreate = () => {
        props.hideModalAndFetch();
    }

    const loadData = () => {
        getTeamUsers({})
        .then(response => {
            setTeamUsers(response.data.data);
            setComponentLoading(false);
        });
    }

    const handelCheckBox = (_userId) => {
        let usersArray = [...selectedUserIds];

        if (usersArray.includes(_userId)) {
            usersArray = usersArray.filter(function(item) {
                return item !== _userId
            });
        } else {
            usersArray.push(_userId);
        }

        setSelectedUserIds(usersArray);
    }

    const handleGroupTypeChange = (event) => {
        setGroupType(parseInt(event.target.value));
    };

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (simpleValidator.current.allValid()) {
            let formData;

            if (props.data) {
                formData = {
                    groupName: groupName,
                    assigned_to: assignTo,
                    methodType: groupType,
                    time_to_auto_assign: notClaimWithIn,
                    selectedUsers: selectedUserIds,
                    group_id: props.data.id 
                }
            } else {
                formData = {
                    groupName: groupName,
                    assigned_to: assignTo,
                    methodType: groupType,
                    time_to_auto_assign: notClaimWithIn,
                    selectedUsers: selectedUserIds,
                }
            }

            setLoading(true);

            updateOrCreateLeadGroup(formData).then(response => {
                if (response.data.status === 'success' || response.data.success === true) {
                    toggleAfterCreate();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                          window.showNotification("ERROR", error)
                        });
                    }
                } else {
                  window.showNotification("ERROR", response.data.message)
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <GlobalModal

            title={props.title}
            onClose={toggle}
            open={true}
            buttonText={!loading ? (props.data ? 'Update Group' : 'Create New Group') : 'Please Wait...'}
            buttonIcon={<AddCircleIcon/>}
            onSubmit={onSubmitHandle}
            className={`${props.className} global-large-modal`}
        >
            {
                componentLoading ? (
                    <div style={{paddingTop: 50}}>
                        Loading...
                    </div>
                ) : (
                        <div className="modal__content__bottom">
                            <div className="modal__input__box">
                                <label for="" className="active">Group Name</label>
                                <input className="modal__input" type="text" placeholder="Enter Group Name" value={groupName} onChange={e => setGroupName(e.target.value)}/>
                                {simpleValidator.current.message('group name', groupName, 'required')}
                            </div>
                            <div className="modal__input__box modal__search__box">
                                <input className="modal__input__search modal__search__input" type="text" placeholder="Type and Search User" onChange={(e) => setQuery(e.target.value)}/>
                                <span className="serach_icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#3C7EF3"></path>
                                    </svg>
                                </span>
                                <div className="modal__search__preview">
                                    {
                                        teamUsers.filter((item) => {
                                            let keyword = debouncedQuery.toUpperCase().trim();
                                            if (keyword !== '') {
                                                if (item.full_name.toUpperCase().indexOf(keyword) > -1) {
                                                    return true;
                                                }
                                            } else {
                                                return true;
                                            }
                                        }).map((item, index) => (
                                            <label>
                                                <input type="checkbox" className="filled-in" checked={selectedUserIds.includes(parseInt(item.id))} onChange={() => handelCheckBox(item.id)}/>
                                                <span className="checkbox__text_1">{item.full_name}</span>
                                            </label>
                                        ))
                                    }
                                    {simpleValidator.current.message('user', selectedUserIds, 'required')}
                                </div>
                            </div>
                            <div className="round_robin_checkbox modal__input__box d-flex justify-content-between">
                                <RadioGroup aria-label="gender" name="gender1" value={groupType} onChange={handleGroupTypeChange}>
                                    <div className="modal__checkbox">
                                        <label>
                                            <FormControlLabel value={1} control={<Radio />} label="Round Robin" checked={(groupType === 1)? true : null}/>
                                            <span className="checkbox__text__holder">
                                            <span className="checkbox__text_2">Evenly distributes leads to agents in sequential order</span>
                                        </span>
                                        </label>
                                    </div>
                                    <div className="modal__checkbox">
                                        <label>
                                            <FormControlLabel value={2} control={<Radio />} label="First To Claim" checked={(groupType === 2)? true : null} />
                                            <span className="checkbox__text__holder">
                                            <span className="checkbox__text_2">Multiple agents contacted, first to claim lead wins</span>
                                        </span>
                                        </label>
                                    </div>

                                </RadioGroup>
                            </div>
                            { 
                                groupType == 2 && (
                                    <div className="modal__input__box d-flex">
                                        <div className="modal__select d-flex justify-content-between align-items-center">
                                            <p className="modal__select__text">If not claimed after</p>
                                            <div className="input-field col s12 add_grp_select">
                                                <input type="number" value={notClaimWithIn} onChange={(e) => setNotClaimWithIn(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="modal__select d-flex justify-content-between align-items-center">
                                            <p className="modal__select__text">Assign to</p>
                                            <div className="input-field col s12 add_grp_select">
                                                <Select value={assignTo} onChange={(e)=>setAssignTo(e.target.value)} >
                                                    {
                                                        teamUsers.map((item, index) => (
                                                            <MenuItem className="menuitem-li-hover-color-with-border" key={index} value={item.id} >{item.full_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                {simpleValidator.current.message('', assignTo, 'required')}
                                                <span className="select_icon">
                                                    <svg width="14" height="7" viewBox="0 0 14 7" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 1L7 6L13 1" stroke="white"
                                                            strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                )
            }
        </GlobalModal>
    )
}

export default LeadGroupModal;