import {sendRequestBackEnd} from "./rootApi";
import Api from "../constants/Api";

export const editCollectionSmartWebhooksApi = async (payload) => {
    return await sendRequestBackEnd("put", Api.editCollectionSmartWebhooks,payload);
}

export const collectionSmartWebhooksApi = async (payload) => {
    return await sendRequestBackEnd("get", Api.collectionSmartWebhooks +"?"+ new URLSearchParams(payload));
}

export const collectionSmartWebhooksDetailsApi = async (payload) => {
    return await sendRequestBackEnd("get", Api.collectionSmartWebhooksDetails +"?"+ new URLSearchParams(payload));
}

export const deleteCollectionSmartWebhooksApi = async (payload) => {
    return await sendRequestBackEnd("delete", Api.deleteCollectionSmartWebhooks,payload);
}

export const cloneSmartWebhookApi = async (payload) => {
    return await sendRequestBackEnd("post", Api.cloneSmartWebhook, payload);
}