import React, {useEffect, useRef, useState} from 'react';
import Styles from "./SmartWebhookBuilder.module.css";
import {CancelIcon, CircleAddIcon} from "./Icons";
import Builder from "./builder";
import {authType, bodyType, method} from "./builder/constants";
import ScheduleSetting from "../CampaignDetails/ScheduleSetting";

const SmartWebhookBuilder = ({ onClose, onSubmit, data, isSubmitting }) => {
    const bottomRef = useRef(null);
    const [showAllTimeSetting, setShowAllTimeSetting] = useState(true);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [payload, setPayload] = useState({
        "url": "",
        "method": method.GET_METHOD,
        "params": null,
        "headers": null,
        "authType": authType.NO_AUTH,
        "authData": null,
        "bodyType": bodyType.NONE,
        "bodyData": null,
        "title": "",
        "isTemplate": true,
        "collectionId": null
    });

    const [errorMessages, setErrorMessages] = useState({
       title: "",
       url: ""
    });

    useEffect(()=>{
        // to remove MUI css overwrite
        const MuiPaperRoot = window.document.querySelector(".massage_sending_button");
        if (MuiPaperRoot){
            MuiPaperRoot.classList.add("wbh_overwrite_container");
        }
        return () => {
            if (MuiPaperRoot){
                MuiPaperRoot.classList.remove("wbh_overwrite_container");
            }
        };

    },[]);

    const callback = (data) => {
        let tempPayload={};

        if (data.title !== undefined && data.title !== null){
            setErrorMessages({
                ...errorMessages,
                title: ""
            });
            tempPayload.title = data.title;
        }

        if (data.params){
            let params = [...data.params];
            params.splice(-1);
            tempPayload.params = params;
        }

        if (data.headers){
            let headers = [...data.headers];
            headers.splice(-1);
            tempPayload.headers = headers;
        }

        if (data.url !== undefined && data.url !== null){
            setErrorMessages({
                ...errorMessages,
                url: ""
            });
            tempPayload.url = data.url;
        }

        if(data.method){
            tempPayload.method = data.method;
        }

        if(data.authType){
            tempPayload.authType = data.authType;
        }

        if(data.authData){
            tempPayload.authData = data.authData;
        }

        if(data.bodyType){
            tempPayload.bodyType = data.bodyType;
        }

        if(data.bodyData){
            let bodyData = data.bodyData;
            if (payload.bodyType === bodyType.FORM_DATA){
                bodyData = [...data.bodyData];
                bodyData.splice(-1);
            }
            tempPayload.bodyData = bodyData;
        }

        setPayload({ ...payload, ...tempPayload });
    };

    const handleSubmit = () => {

        let isHot = false;
        let errors = {
          title: "",
          url: ""
        };

        if (!payload.title){
            errors.title = "Webhook title is required";
            isHot = true;
        }

        if (!payload.url){
            errors.url = "Webhook url is required";
            isHot = true;
        }

        if (!isHot){
            onSubmit(payload);
        }else {
            setErrorMessages(errors);
        }
    };

    const scrollToBottom = () => {
        if(!bottomScroll){
            bottomRef.current.scrollIntoView({behavior: "smooth"})
            setBottomScroll(true)
        }
    };

    return (
        <div className={Styles.wbh_smart_web_hook_drawer_wrp} id="wbh_smart_web_hook_drawer_wrp">
            <div className={Styles.pdad_drawer_right_header}>
                <div className={Styles.pdad_drawer_right_header_title}>
                    <p>Smart Webhook</p>
                </div>
                <span className={Styles.pdad_drawer_cancel_icon} onClick={()=>{
                    onClose();
                }}>
                    <CancelIcon height="18" width="18" fill="#546376"/>
                </span>
            </div>
            <div className={`${Styles.wbh_modal_default_body} awesome__scroll_bar`}>
                <div className={Styles.wbh_mdb_content_wrp}>
                    <Builder callback={callback} data={data} errors={errorMessages}/>
                </div>
                <ScheduleSetting showAllTimeSetting={showAllTimeSetting} tabChangeCallback={scrollToBottom} />
                <div ref={bottomRef}></div>
            </div>
            <div className={Styles.wbh_drawer_footer}>
                <button className={`${Styles.wbh_btn} ${Styles.wbh_btn_cancel}`} onClick={()=>{
                    onClose();
                }}>Cancel</button>
                <div className="">
                    <button disabled={isSubmitting} onClick={handleSubmit} className={`${Styles.wbh_btn} ${Styles.wbh_btn_secondary} ${Styles.wbh_btn_followup}`}>
                        <CircleAddIcon /> { isSubmitting ? "Submitting..." : "Set Webhook Followup"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SmartWebhookBuilder;