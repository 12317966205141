import React, { useEffect } from 'react'
import Radio from '@material-ui/core/Radio';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomRadioButton = props => {
    const [value, setValue] = React.useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const handleChange = (event) => {
        // setValue(event.target.value);
        props.onChange(event.target.value)
    };

    return (
        <div className="custom-radio-button-container">
            <RadioGroup className="custom-radio-button-group" aria-label={props.ariaLabel} name={props.ariaLabel} value={value} onChange={handleChange}>
                {props.buttonList.map((item, index) => {
                    return(
                        <FormControlLabel value={item.value} control={<Radio />} label={item.label} key={"custom-radio-button-"+index} disabled={item.disabled ? item.disabled : false}/>
                    )
                })}
            </RadioGroup>
        </div>
    );
}

export default CustomRadioButton;