import CustomCircleLoader from "./loader/CustomCircleLoader";

const SubmitButton = ({submitting, handleSubmit, text = "Submit", loadingText="Submitting"}) => {
    return (
        <div className={`mrfcm_bc_submit_button ${submitting ? "disabled_mrfcm_bc_sb" : ""}`} onClick={handleSubmit}>
            {submitting &&
            <span className="mrfcm_bc_sb_icon"><CustomCircleLoader fill="#fff" size="extra-small" /> </span>
            }
            <span className="mrfcm_bc_sb_text">{submitting ? loadingText : text}</span>
        </div>
    );
}
export default SubmitButton;