import React, {createContext, useContext, useReducer} from 'react'
import mlv2Reducer from './mlv2Reducer';
import { mlv2States } from './states';

const MLV2ReducerContext = createContext({});
const MLV2Provider = ({children}) => {
    const [state, dispatch] = useReducer(mlv2Reducer, mlv2States);
    return (
        <MLV2ReducerContext.Provider value={{ state, dispatch }}>{children} </MLV2ReducerContext.Provider>
    );
}
export {MLV2ReducerContext, MLV2Provider}
export const useMLv2Context = () => {
    return useContext(MLV2ReducerContext)
}