import { sendRequest, sendRequestBackEnd } from './rootApi';
import Api from "../constants/Api";

export const fetchCampaignList = async (payload) => {
    return sendRequest("POST", Api.campaigns, payload);
}

export const fetchMoreCampaignList = async (payload) => {
    return sendRequest("POST", Api.campaignsLoadMore, payload);
}

export const addSingleContact = async (payload) => {
    return sendRequest("POST", Api.addSingleContactToCampaign, payload);
}

export const fetchCampaignDetails = async (payload) => {
    return sendRequest("post", Api.campaignDetails, payload);
}

export const fetchCampaignTimelineApi = async (payload) => {
    return sendRequest("post", Api.campaignTimeline, payload);
}

export const fetchCustomField = async (payload) => {
    return sendRequest("post", Api.customFields,payload);
}

export const updateCampaignDetails = async (payload) => {
    return sendRequest("post", Api.updateCampaignDetails, payload);
}

export const fetchCampaignLeadSourceDetails = async (payload) => {
    return sendRequest("post", Api.campaignLeadSourceDetails, payload);
}

export const fetchUserInfo = async (payload) => {
    return sendRequest("post", Api.getUserData, payload);
}

export const fetchLeadGroups = async (payload) => {
    return sendRequest("post", Api.fetchLeadGroups, payload);
}

export const updateLeadGroups = async (payload) => {
    return sendRequest("post", Api.updateLeadGroups, payload);
}

export const fetchLeadFlows = async (payload) => {
    return sendRequest("post", Api.fetchLeadFlows, payload);
}

export const updateLeadFlows = async (payload) => {
    return sendRequest("post", Api.updateLeadFlows, payload);
}

// share campaign
export const shareCampaignItem = async (payload) => {
    return sendRequest("post", Api.shareCampaign, payload);
}

export const generateShareLink = async (payload) => {
    return sendRequest("post", Api.shareCampaignLink, payload);
}

// delete campaign
export const deleteCampaignItem = async (payload) => {
    return sendRequest("post", Api.deleteCampaign, payload);
}

// fetch pre recording list
export const fetchPreRecordingList = async (payload) => {
    return sendRequest("post", Api.getPreRecording, payload);
}

export const addCampaignStepSettingList = async (payload) => {
    return sendRequest("post", Api.storeCampaignStepSetting, payload);
}

export const addCampaignStepSettingPreTempList = async (payload) => {
    return sendRequest("post", Api.storeCampaignStepSettingPreTemp, payload);
}

export const updateCampaignSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignSetting, payload);
}

export const updateCampaignActivityApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignActivitySetting, payload);
}

export const updateCampaignSettingWithMultimediaApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignStepSettingWithMultimedia, payload);
}

export const updateCampaignSettingWithPreviousFileApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignStepSettingWithPreviousFile, payload);
}

export const updateCampaignSettingWithPreTempApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignStepSettingWithPreTemp, payload);
}

export const fetchStages = async (payload) => {
    return sendRequest("post", Api.fetchStages, payload);
}
export const fetchStage = async (payload) => {
    return sendRequest("post", Api.fetchStage, payload);
}
export const fetchPipelines = async (payload) => {
    return sendRequest("post", Api.fetchPipelines, payload);
}

export const getAllPipeLine = async (payload) => {
    return sendRequest("post", Api.getAllPipeLine, payload);
}

export const fetchTags = async (payload) => {
    return sendRequest("post", Api.fetchTags, payload);
}

export const fetchTeamMembers = async (payload) => {
    return sendRequest("post", Api.fetchTeamMembers, payload);
}

export const fetchCampaignsExceptCurrent = async (payload) => {
    return sendRequest("post", Api.fetchCampaignsExceptCurrent, payload);
}

export const saveFollowUp = async (payload) => {
    return sendRequest("post", Api.saveFollowup, payload);
}

export const saveActionFollowup = async (payload) => {
    return sendRequest("post", Api.saveActionFollowup, payload);
}

export const saveActivityFollowup = async (payload) => {
    return sendRequest("post", Api.saveActivityFollowup, payload);
}

export const fetchCampaignContactStat = async (payload) => {
    return sendRequest("post", Api.fetchCampaignContactStat, payload);
}

export const fetchVideoFolders = async (payload) => {
    return sendRequest("post", Api.fetchVideoFolders, payload);
}

export const fetchVideos = async (payload) => {
    return sendRequest("post", Api.fetchVideos, payload);
}

export const deleteVideo = async (payload) => {
    return sendRequest("post", Api.deleteVideo, payload);
}

export const getMessageTemplate = async (payload) => {
    return sendRequest("post", Api.getMessageTemplate, payload);
}

export const deleteMessageTemplate = async (payload) => {
    return sendRequest("post", Api.messageTemplateDelete, payload);
}

export const getSingleMessageTemplate = async (payload) => {
    return sendRequest("post", Api.getSingleMessageTemplate, payload);
}

export const updateMessageTemplate = async (payload) => {
    return sendRequest("post", Api.updateMessageTemplate, payload);
}

export const addToBroadcast = async (payload) => {
    return sendRequest("post", Api.addToBroadcast, payload);
}


export const updateSmsStepSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignSmsStepSetting, payload);
}

export const updateEmailStepSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignEmailStepSetting, payload);
}

export const updateVideoEmailStepSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignVideoEmailStepSetting, payload);
}

export const updateCallBridgeStepSettingApi = async (payload) => {
    return sendRequest("post", Api.updateCampaignCallBridgeStepSetting, payload);
}

export const deleteCampaignSettingApi = async (payload) => {
    return sendRequest("post", Api.deleteCampaignetting, payload);
}

export const producePDF = async (payload)=>{
    return sendRequest("post", Api.producePDF, payload);
}

export const createCardBroadcast = async (payload)=>{
    return sendRequest("post", Api.addCardBroadcastToContact, payload);
}

export const getThirdPartyService = async (payload)=>{
    return sendRequest("post", Api.getThirdPartyInfo, payload);
}
export const getMailCategory = async (payload)=>{
    return sendRequest("post", Api.getMailCategory, payload);
}

export const getMailTemplate = async (payload)=>{
    return sendRequest("post", Api.getMailTemplate, payload);
}

export const createMailTemplate = async (payload)=>{
    return sendRequest("post", Api.createMailTemplate, payload);
}

export const getCustomFields = async (payload) => {
    return sendRequest("post", Api.getCustomFields, payload);
}

export const fetchLeadSourceApi = async (payload) => {
    return sendRequest("post", Api.fetchLeadSource, payload);
}

export const fetchContactForDataTableApi = async (payload) => {
    return sendRequest("post", Api.fetchContactListForDataTable, payload);
}

export const moveContactToCampaignApi = async (payload) => {
    return sendRequest("post", Api.contactMoveToCampaign, payload);
}

export const copyCampaignApi = async (payload) => {
    return sendRequest("post", Api.copyCampaign, payload);
}

export const campaignEnableToStartApi = async (payload) => {
    return sendRequest("post", Api.campaignEnableToStart, payload);
}

export const getCampaignBasicInfoApi = async (payload) => {
    return sendRequest("post", Api.getCampaignBasicInfo, payload);
}

export const campaignCheckToStartApi = async (payload) => {
    return sendRequest("post", Api.campaignCheckToStart, payload);
}

export const campaignStartApi = async (payload) => {
    return sendRequest("post", Api.campaignStart, payload);
}

export const campaignPauseApi = async (payload) => {
    return sendRequest("post", Api.campaignPause, payload);
}

export const addQuickReplyTemplateApi = async (payload) => {
    return sendRequest("post", Api.addQuickReplyTemplate, payload);
}

export const createNewCampaignApi = async (payload) => {
    return sendRequest("post", Api.createNewCampaign, payload);
}


export const getUserEmailList = async (payload) => {
    return sendRequest("post", Api.userEmailList, payload);
}

export const getUserNumberList = async (payload) => {
    return sendRequest("post", Api.userNumberList, payload);
}

export const updateOrCreateLeadGroup = async (payload) => {
    return sendRequest("post", Api.updateOrCreateLeadGroup, payload);
}

export const getTeamUsers = async (payload) => {
    return sendRequest("post", Api.getTeamUsers, payload);
}

export const getSmsTemplateApi = async (payload) => {
    return sendRequest("post", Api.getSmsTemplate, payload);
}
export const createLeadFlow = async (payload) => {
    return sendRequest("POST", Api.createLeadFlow, payload);
}

/* delete lead flow from campaign */
export const deleteLeadFlowFromCampaignApi = async (payload) => {
    return sendRequest("POST", Api.deleteLeadFlowFromCampaign, payload);
}

/* campaign stats module */
export const getCampaignStatsContactApi = async (payload) => {
    return sendRequest("POST", Api.getCampaignStatsContact, payload);
}

export const deleteContactFromCampaignApi = async (payload) => {
    return sendRequest("POST", Api.deleteContactFromCampaign, payload);
}

export const getSingleContactTimelineApi = async (payload) => {
    return sendRequest("POST", Api.getSingleContactTimeline, payload);
}

export const getZipCodeByPlace = async (payload) => {
    return sendRequest("post", Api.getZipCodeByPlace, payload);
}


export const testEmailSend = async (payload) => {
    return sendRequest("POST", Api.testEmailSend, payload);
}

export const testSMSSend = async (payload) => {
    return sendRequest("POST", Api.testSMSSend, payload);
}

/* campaign setting contact list api */
export const getCampaignSettingContactListApi = async (payload) => {
    return sendRequestBackEnd("POST", Api.getCampaignSettingContactList, payload);
}
export const getCampaignSettingQueueContactListApi = async (payload) => {
    return sendRequestBackEnd("POST", Api.getCampaignSettingQueueContactList, payload);
}

/* for campaign stats contact delete */
export const deleteContactFromCampaignV2Api = async (payload) => {
    return sendRequest("POST", Api.deleteContactFromCampaignV2, payload);
}

/* campaign registration api */
export const submitCampaignRegistrationApi = async (payload) => {
    return sendRequest("POST", Api.submitCampaignRegistration, payload);
}

export const getUserMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.getMessagingService, payload);
}

export const createMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.createMessagingService, payload);
}

export const updateMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.updateMessagingService, payload);
}

export const removeMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.removeMessagingService, payload);
}

export const addNumberInMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.addNumberInMessagingService, payload);
}

export const removeNumberFromMessagingServiceApi = async (payload) => {
    return sendRequest("POST", Api.removeNumberFromMessagingService, payload);
}

export const createCampaignUseCaseApi = async (payload) => {
    return sendRequest("POST", Api.createCampaignUseCase, payload);
}

export const getUserA2pBrnadListApi = async (payload) => {
    return sendRequest("POST", Api.getA2pBrandList, payload);
}

/* campaign folder api */
export const getCampaignFoldersApi = async (payload) => {
    return sendRequest("POST", Api.getCampaignFolders, payload);
}

export const createOrUpdateCampaignFolderApi = async (payload) => {
    return sendRequest("POST", Api.createOrUpdateCampaignFolder ,payload);
}

export const moveCampaignToFolderApi = async (payload) => {
    return sendRequest("POST", Api.moveCampaignToFolder, payload);
}

export const deleteCampaignToFolder = async (payload) => {
    return sendRequest("POST", Api.deleteCampaignToFolder, payload);
}

export const getCampaignCheckListApi = async (payload) => {
    return sendRequest("POST", Api.getCampaignCheckList, payload);
}

export const updateStatusContinueAfterFailedApi = async (payload) => {
    return sendRequest("POST", Api.updateStatusContinueAfterFailed, payload);
}

export const fetchFormList = async (payload) => {
    return sendRequest("post", Api.fetchFormList, payload);
}

export const checkIsValidToStartCampaignApi = async (payload) => {
    return sendRequest("post", Api.checkIsValidToStartCampaign, payload);
}

export const getCollaboratorList = async (payload) => {
    return sendRequest("post", Api.getCollaboratorListDropdown, payload);
}

/* resent failed contacts */
export const resentFailedContactApi = async (payload) => {
    let url = Api.resentFailedContact + payload.campaignSettingId
    let data = {
        action: payload.action
    }
    if(payload.searchKey !== undefined && payload.searchKey !== ""){
        data['filterJson'] ={
            searchKey: payload.searchKey
        }
    }
    else{
        data['contactIds'] = payload.contactId
    }
    return sendRequestBackEnd("POST", url, data);
}

export const reorderCampaignFolderDataV2Api = async (payload) => {
    return await sendRequest("post", Api.campaignFolderReorder, payload);
}

export const getCampaignSettingInfosApi = async (payload) => {
    return await sendRequest("post", Api.getCampaignSettingInfos, payload);
}

export const updateCampaignSettingInfosApi = async (payload) => {
    return await sendRequest("post", Api.updateCampaignSettingInfos, payload);
}