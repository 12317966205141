import { useRef,useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

export function useSimpleValidator(messages = {}) {
    const [,forceUpdate] = useState();

    const simpleValidator = useRef(new SimpleReactValidator({
        className: 'small text-danger mdi mdi-alert pt-1 pl-1',
        autoForceUpdate: {forceUpdate: forceUpdate},
        messages : messages
    }));

    simpleValidator.current.validate = () => {
        if(!simpleValidator.current.allValid()) {
            simpleValidator.current.showMessages();
            forceUpdate(1);
            return false;
        }

        return true;
    }

    return simpleValidator.current;
}