import React, {useRef} from "react";
import {useSWHKContext} from "../../../reducers/SWHKProvider";
import GlobalCustomFieldGrouped from "../../GlobalCustomFieldGrouped/Index";
import {CircleRemoveIcon} from "../../../icons";
import {handleState} from "../../../reducers/Actions";
import Styles from "./Styles.module.css";

const App = ({callback}) => {
    const {state, dispatch} = useSWHKContext();
    const bodyValueRefs = useRef([]);

    const handleChangeHeadersField = (index, name, value) => {
        let data = [...state.formData];
        data[index][name] = value;

        if(index === (state.formData.length-1)){
            let object = {
                key: "",
                value: ""
            };
            data = [...state.formData, object];
        }

        dispatch(handleState({ formData: data }));
        callback({
            bodyData: data
        });
    };

    const handleRemoveHeadersField = (index) => {
        let data = [...state.formData];
        data.splice(index, 1);

        dispatch(handleState({ formData: data }));
        callback({
            bodyData: data
        });
    };

    const renderHeadersItems = () => {
        const bodyItems = [];

        state.formData.forEach((param, index)=>{
            bodyItems.push(
                <div className={Styles.wbh_headers_panel_item}>
                    <input type="text" name="key" onChange={(event)=>{handleChangeHeadersField(index, event.target.name, event.target.value)}} value={param.key} placeholder="Key" />
                    <input type="text" name="value" ref={(el)=>(bodyValueRefs.current[index]=el)} onChange={(event)=>{handleChangeHeadersField(index, event.target.name, event.target.value)}} value={param.value}  placeholder="Value"/>
                    <GlobalCustomFieldGrouped className="wbh_global__personalized" handleSelect={(placeholderValue)=>{
                        let start = bodyValueRefs.current[index].selectionStart;
                        let end = bodyValueRefs.current[index].selectionEnd;
                        let text = bodyValueRefs.current[index].value;
                        let before = text.substring(0, start);
                        let after = text.substring(end, text.length);

                        let newText = (before + placeholderValue + after);

                        handleChangeHeadersField(index, "value", newText);

                        setTimeout(() => {
                            bodyValueRefs.current[index].selectionStart = bodyValueRefs.current[index].selectionEnd = start + placeholderValue.length;
                            bodyValueRefs.current[index].focus();
                        }, 500);
                    }} labelText={"Personalized"}/>
                    {
                        (state.formData.length !== 1 && index !== (state.formData.length-1)) ? (
                            <span
                                className={Styles.sh_hp_remove}
                                onClick={()=>{handleRemoveHeadersField(index)}}
                            >
                            <CircleRemoveIcon />
                        </span>
                        ) : (<span style={{ height: "24px", width:"24px" }}/>)
                    }
                </div>
            );
        });

        return bodyItems;
    };
    return(
        <div className={`${Styles.wbh_content_border_wrp} awesome__scroll_bar`}>
            <div className={Styles.wbh_headers_panel_content}>
                {renderHeadersItems()}
            </div>
        </div>
    );
};

export default App;