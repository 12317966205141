import { useEffect, useState } from "react";

const BroadcastCampaign = (props) => {
    
    const [campaignIds,setCampaignIds] = useState([]);
    const [search,setSearch] = useState('');
    const [campaigns,setCampaigns] = useState([]);
    const [count,setCount] = useState(0);

    const onChangeList = (isChecked,id) => {
        let idsArray = [...campaignIds];
        if(isChecked) {
            idsArray.push(id);
        } else {
            idsArray = idsArray.filter(e => e !== id);
        }
        setCampaignIds(idsArray);
        props.changeIds(idsArray);
    }

    const handleLoadMore = () =>{
        props.loadMore(count, search);
    }

    const handleSearch = (e) =>{
        setCount(0);
        setSearch(e.target.value);
        props.loadMore(0, e.target.value);
    }

    useEffect(() => {
        setCampaigns(props.campaigns);
        setCount(props.campaigns.length);
    }, [props.campaigns]);

    const renderList = () => {
        let filterArr=[];
        // if(search !== ''){
        //     filterArr = campaigns.filter(function (obj) { return obj.title.toUpperCase().indexOf(search.toUpperCase()) !== -1; })
        // } else {
        //     filterArr = campaigns;
        // }
        filterArr = campaigns;
        if(filterArr.length === 0){
            return(
                <div>
                    <p>No campaign found !</p>
                </div>
            )
        }
        else{
            return filterArr.map((campaign,index) => {
                return  (<label key={index}>
                    <input  type="checkbox" onChange={(e) => onChangeList(e.target.checked,campaign.id)} checked={campaignIds.includes(campaign.id)} className="filled-in"/>
                    <span className="campaingName">{campaign.title}</span>
                </label>)
            })
        }
        
    }

    return ( 
        <div>
            <div className="tabBody">
                <form action="#" className="searchBar ">
                    <div className="input-field col s6 card-broadcast-campaign-search-content">
                        <input className="card-broadcast-campaign-search" placeholder="Search Existing Campaign" id="first_name" type="search" autoComplete="off" value={search} onChange={handleSearch} />
                        <span className="searchIcon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.06987 14.6776C8.72151 15.888 10.7693 16.4301 12.8034 16.1955C14.8376 15.9609 16.7082 14.9669 18.041 13.4123C19.3737 11.8578 20.0704 9.85733 19.9915 7.81119C19.9127 5.76505 19.0642 3.82413 17.6157 2.37673C16.1673 0.929335 14.2258 0.0822089 12.1796 0.0048316C10.1334 -0.0725457 8.13342 0.625532 6.57982 1.95941C5.02621 3.29328 4.03354 5.16458 3.80039 7.19892C3.56725 9.23326 4.11082 11.2806 5.32238 12.9314V12.9301C5.27238 12.9676 5.22487 13.0076 5.17862 13.0526L0.366125 17.8651C0.131575 18.0995 -0.000261307 18.4175 -0.000377655 18.7491C-0.000495911 19.0807 0.131115 19.3987 0.365499 19.6333C0.599884 19.8678 0.917845 19.9997 1.24943 19.9998C1.58102 19.9999 1.89907 19.8683 2.13363 19.6339L6.94613 14.8214C6.99136 14.7767 7.03315 14.7286 7.07112 14.6776H7.06987ZM11.8749 15.0001C10.972 15.0001 10.078 14.8223 9.24393 14.4768C8.40981 14.1313 7.65192 13.6249 7.01352 12.9865C6.37511 12.3481 5.8687 11.5902 5.5232 10.7561C5.1777 9.92198 4.99988 9.02798 4.99988 8.12514C4.99988 7.22231 5.1777 6.32831 5.5232 5.4942C5.8687 4.66008 6.37511 3.90219 7.01352 3.26379C7.65192 2.62538 8.40981 2.11897 9.24393 1.77347C10.078 1.42797 10.972 1.25015 11.8749 1.25015C13.6982 1.25015 15.4469 1.97447 16.7362 3.26379C18.0255 4.5531 18.7499 6.30178 18.7499 8.12514C18.7499 9.94851 18.0255 11.6972 16.7362 12.9865C15.4469 14.2758 13.6982 15.0001 11.8749 15.0001Z" fill="#133159" fillOpacity="0.35"/>
                            </svg>
                        </span>
                    </div>
                </form>
                <div className="campaingData awesome__scroll_bar" >
                    {renderList()}
                </div>

                {(count<props.countCampaign)&&
                <div>
                    <button className="btn btn-sm" onClick={handleLoadMore}>Load More Data</button>
                </div>
                }
                {/* <TimeSetting user={userInfo} timeSettingValue={props.timeSettingValue}/>
                <div className="d-flex justify-content-start senderEmailWrapper"> 
                <span>Sender {props.fromText !== undefined ? props.fromText : 'Phone Number'}: </span>
                <Select className="globalFormControlItem" value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                    {
                        props.froms.map((elem,index) => {
                            return <MenuItem className="menuitem-li-hover-color-with-border" key={index} value={elem.id}>{elem.value}</MenuItem>
                        })
                    }
                </Select>
                </div> */}
            </div>
        </div>
     );
}
 
export default BroadcastCampaign;