export const HANDLE_STATES = 'HANDLE_STATES';
export const STORE_FOLDER = 'STORE_FOLDER';
export const HANDLE_FOLDER_SELECT = 'HANDLE_FOLDER_SELECT';
export const STORE_SEARCH_TEXT = 'STORE_SEARCH_TEXT';
export const HANDLE_SELECTED_IDS = 'HANDLE_SELECTED_IDS';
export const HANDLE_COLLAPSE_ID = 'HANDLE_COLLAPSE_ID';
export const HANDLE_SLECTED_FOR_RUNNING = 'HANDLE_SLECTED_FOR_RUNNING';
export const STORE_CAMPAIGN_LIST = 'STORE_CAMPAIGN_LIST';
export const HANDLE_PAGE_NUMBER = 'HANDLE_PAGE_NUMBER';
export const STORE_ACTION_LIST = 'STORE_ACTION_LIST';
export const HANDLE_EDIT_FOLDER = 'HANDLE_EDIT_FOLDER';