/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { fetchContactForDataTableApi } from "../../../api/marketingApi";
import { IconList } from "../../../constants/IconList";
import Helper from "../../../helpers/Helper";
import Utils from "../../../helpers/Utils";
import useDelayCallback from "../../../hooks/useDelayCallback";
import { GlobalTable } from "../../globals/GlobalTable";

const BroadcastContact= (props) => {
    /* for contact list */
    const initialStates = {
        contacts: [],
        loading: true,
        page: 1,
        searchText: '',
        fetching: false,
        totalPages: 0,
        totalContacts: 0,
        itemPerPage: 20,
        noHeader: true
    }
    const [states, setStates] = useState(initialStates)

    useEffect(() => {
        if(props.tabValue === 2){
            setStates({...states, loading: true})
            getContacts(1);
        }
        else{
            setStates(initialStates)
        }
    }, [props.tabValue])

    useDelayCallback(() => {
        if(!states.loading){
            getContacts(states.page, states.searchText);
        }
    }, [states.page, states.searchText])

    const getContacts = (page, text = '') => {
       if(states.fetching) return;
       setStates({...states, fetching: true})
       fetchContactForDataTableApi({
            pageNo: page, 
            page: page, 
            itemPerPage: states.itemPerPage,
            operation: JSON.stringify([]), 
            queryTerm: JSON.stringify([]),
            searchToken: text
       }).then(res => {
           if(props.tabValue === 2){
                let response = res.data;
                setStates({
                    ...states, 
                    contacts: response.html.data,
                    totalContacts: response.html.total,
                    totalPages: response.html.last_page,
                    fetching: false, 
                    loading: false
                })
           }
       }).catch(err => {
            setStates({...states, fetching: false, loading: false})
       })
    }

    const searchText = (value) => {
        setStates({...states, searchText: value, page: 1})
    }

    const onChangePage = (event, pageNumber) => {
        event.preventDefault()
        if(pageNumber !== null){
            setStates({...states, page: pageNumber})
        }
    }

    const renderPagination = () => {
        let previous_page = null, next_page = null; 
        if(states.page > 1){
            previous_page = states.page - 1
        }
    
        if(states.page != states.totalPages){
            next_page = states.page + 1
        }
    
        if(states.totalContacts == 0){
            return false;
        }
    
        return(
            <div className="pagination_bar">
                <ul>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.previous}
                        </a>
                    </li>
                    <li>
                        <a>
                            <span>{states.page}</span>
                        </a>
                    </li>
                    <li>
                        <a className="d-flex align-items-center"> 
                            <span>
                                {IconList.backwardSlash}
                            </span>	
                            {states.totalPages}
                        </a>
                    </li>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, next_page)}
                            style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.next}	
                        </a>
                    </li>
                    <li style={{color: '#9daac1', marginLeft: 20}}>Total leads : {states.totalContacts}</li>
                </ul>
            </div>
        )
    }

    const columns = [
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Full Name</span>,
            selector: row => row['first_name'],
            sortable: false,
            cell: row => {
                let name = ''
                if(Helper.isvalidText(row.first_name) && Helper.isvalidText(row.last_name)){
                    name = row.first_name + ' ' + row.last_name
                }
                else if(Helper.isvalidText(row.first_name)){
                    name = row.last_name
                }
                else if(Helper.isvalidText(row.last_name)){
                    name = row.last_name
                }
                return name;
            },
            minWidth:'250px',
            // grow: 4
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Email</span>,
            selector: row => row['email'],
            sortable: false,
            minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Phone Number</span>,
            selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            // minWidth:'250px'
        },
    ];

    return ( 
        <div className="tabBody">
            <div className="campaingData awesome__scroll_bar" >
                <div className={'existing_contact custom_data_table_container'} style={{padding: '0px 20px 20px 20px'}}>
                    <GlobalTable
                        table_unique_id={'contact_search'}
                        columns={columns}
                        data={states.contacts}
                        keyField={'id'}
                        noDataComponent={(
                            <span style={{padding: 10, color: 'red'}}>No contact saved yet!</span>
                        )}
                        progressPending={states.fetching}
                        defaultSortField="title"
                        selectableRows={true}
                        selectableRowsData={{
                            selectableRowsNoSelectAll: states.fetching ? true : (states.contacts.length == 0 ? true : false),
                            selectableRowsHighlight: false,
                            onSelectedRowsChange: (state) => {
                                if(state.selectedRows.length == []){
                                    setStates({...states, noHeader: true})
                                }
                                else{
                                    setStates({...states, noHeader: false})
                                }
                                setStates({...states, noHeader: false})
                                let contactIds = []
                                state.selectedRows.forEach(element => {
                                    contactIds.push(element.id)
                                });
                                props.changeIds(contactIds)
                            }
                        }}

                        pagination={false}
                        subHeader={true}
                        subHeaderData={{
                            subHeaderComponent : (
                                <div className="top_search_field d-flex align-items-center" style={{width: '100%'}}>
                                    <input value={states.searchText} onChange={(event) => searchText(event.target.value)} className="" type="text" placeholder="Search Existing Contacts Name" />
                                    <span>
                                        {IconList.search}
                                    </span>
                                </div>
                            )
                        }}

                        noHeader={true}
                        noHeaderData={{
                            noContextMenu: false,
                            contextActions: [],
                            actions: []
                        }}
                    />
                    {renderPagination()}
                </div>
            </div>
            {/* <TimeSetting user={userInfo} timeSettingValue={props.timeSettingValue}/>
            <div className="d-flex justify-content-start senderEmailWrapper"> 
                <span>Sender {props.fromText !== undefined ? props.fromText : 'Phone Number'}: </span>
                <Select className="globalFormControlItem" value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                {props.froms.map((elem,index) => {
                    return <MenuItem className="menuitem-li-hover-color-with-border" key={index} value={elem.id}>{elem.value}</MenuItem>
                })}
            </Select>
            </div> */}
        </div>
     );
}
export default BroadcastContact;