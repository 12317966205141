/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { updateCampaignDetails } from "../../../api/marketingApi";
import { connect } from "react-redux";
import ApiDetailsPopup from "./ApiDetailsPopup";
import If from "if-else-react";
import Select from "@material-ui/core/Select";
import AddContactToCampaign from "../EachCampaignActions/AddContact";
import { Link } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Utils from "../../../helpers/Utils";
import ActionControl from "../../Common/ActionControl/ActionControl";

const App = (props) => {
  const isTempUser = Utils.getAccountData("isTemplateUser");
  const [openApiLinkPopup, setOpenApiLinkPopup] = React.useState(false);
  const [openAddNewContactModal, setOpenAddNewContactModal] =
    React.useState(false);
  const [selectedProspectSource, setSelectedProspectSource] =
    React.useState("");

  const toggleLeadSource = () => {
    props.hanldeCollapse();
  };

  const toggleApiLinkPopup = () => {
    setOpenApiLinkPopup(!openApiLinkPopup);
  };

  const addContact = (event) => {
    event.preventDefault();
    handleAddNewContactModal(true);
  };

  const handleAddNewContactModal = (status) => {
    setOpenAddNewContactModal(status);
  };

  const handleProspectChange = (event) => {
    event.preventDefault();
    setSelectedProspectSource(event.target.value);

    updateCampaignDetails({
      campaignId: props.campaignDetails.basic.id,
      key: "prospect",
      value: event.target.value,
    });
  };

  const handleProspectClear = () => {
    setSelectedProspectSource("");
    updateCampaignDetails({
      campaignId: props.campaignDetails.basic.id,
      key: "prospect",
      value: "",
    });
  };

  let apiDetails = {
    rootUrl: `${process.env.REACT_APP_BACKEND_ROOT_URL}`,
    link: `${process.env.REACT_APP_BACKEND_CONNECT_API_LINK}`,
  };

  const leadSourceDetails = props.campaignLeadSourceDetails;

  let prospectSourceOption = [];
  if (leadSourceDetails) {
    prospectSourceOption = leadSourceDetails.prospectSources.map(function (
      data,
      index
    ) {
      return (
        <MenuItem
          className='menuitem-li-hover-color-with-border'
          key={index}
          value={data.id}
        >
          {data.name}
        </MenuItem>
      );
    });
  }

  useEffect(() => {
    if (props.campaignDetails !== undefined && props.campaignDetails != null)
      if (
        props.campaignDetails.selectedProspectSource !== undefined &&
        props.campaignDetails.selectedProspectSource != null
      ) {
        setSelectedProspectSource(
          props.campaignDetails.selectedProspectSource.id
        );
      }
  }, [props.campaignDetails]);

  return (
    <>
      <div
        className={`connect_lead_source m-marketing-lead-source ${
          props.isShowBasicLeadSource ? "" : "active"
        }`}
      >
        <div
          className={`campaign_details_title ${
            props.isShowBasicLeadSource ? "remove_border" : ""
          }`}
        >
          <div className='d-flex justify-content-between'>
            <h2>Connect Your Lead Source - Basic</h2>
            {props.isShowBasicLeadSource && (
              <KeyboardArrowDownIcon
                className='basic_toggle_icon'
                onClick={toggleLeadSource}
              />
            )}
          </div>
        </div>
        <div className='contact_lead_src_btn alt'>
          <div className='row'>
            <div className='col s6'>
              <ActionControl
                alertMessage='Campaign Contact feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <div className='add_contact lead_src_btn'>
                  <a
                    onClick={(event) => addContact(event)}
                    href='#'
                    className='d-flex'
                  >
                    <span>
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM10.625 5.625C10.625 5.45924 10.5592 5.30027 10.4419 5.18306C10.3247 5.06585 10.1658 5 10 5C9.83424 5 9.67527 5.06585 9.55806 5.18306C9.44085 5.30027 9.375 5.45924 9.375 5.625V9.375H5.625C5.45924 9.375 5.30027 9.44085 5.18306 9.55806C5.06585 9.67527 5 9.83424 5 10C5 10.1658 5.06585 10.3247 5.18306 10.4419C5.30027 10.5592 5.45924 10.625 5.625 10.625H9.375V14.375C9.375 14.5408 9.44085 14.6997 9.55806 14.8169C9.67527 14.9342 9.83424 15 10 15C10.1658 15 10.3247 14.9342 10.4419 14.8169C10.5592 14.6997 10.625 14.5408 10.625 14.375V10.625H14.375C14.5408 10.625 14.6997 10.5592 14.8169 10.4419C14.9342 10.3247 15 10.1658 15 10C15 9.83424 14.9342 9.67527 14.8169 9.55806C14.6997 9.44085 14.5408 9.375 14.375 9.375H10.625V5.625Z'
                          fill='#3C7EF3'
                        />
                      </svg>
                    </span>{" "}
                    Add a Contact
                  </a>
                </div>
              </ActionControl>
              <ActionControl
                alertMessage='Campaign Contact feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <div className='connect_vai_fb lead_src_btn'>
                  <a
                    href={"/integrations/facebook"}
                    target={"_blank"}
                    className='d-flex'
                  >
                    <span>
                      <svg
                        width='10'
                        height='20'
                        viewBox='0 0 10 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.17432 3.32083H10V0.140833C9.68503 0.0975 8.60178 0 7.34022 0C4.70794 0 2.90476 1.65583 2.90476 4.69917V7.5H0V11.055H2.90476V20H6.46613V11.0558H9.2534L9.69586 7.50083H6.4653V5.05167C6.46613 4.02417 6.74277 3.32083 8.17432 3.32083Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                    Connect Via Facebook
                  </a>
                </div>
              </ActionControl>
              <ActionControl
                alertMessage='Campaign Contact feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <div className='connect_vai_zapier lead_src_btn'>
                  {/* <p>Connect Via Zapier</p> */}
                  <a
                    href={
                      leadSourceDetails ? leadSourceDetails.zapierAppUrl : "#"
                    }
                    target={"_blank"}
                    className='d-flex'
                  >
                    <span>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.1582 7.84185C9.54389 7.22778 8.71084 6.88281 7.84222 6.88281C6.97361 6.88281 6.14055 7.22778 5.52622 7.84185L3.20947 10.1578C2.59513 10.7722 2.25 11.6054 2.25 12.4742C2.25 13.343 2.59513 14.1763 3.20947 14.7906C3.82381 15.4049 4.65704 15.7501 5.52585 15.7501C6.39466 15.7501 7.22788 15.4049 7.84222 14.7906L9.00022 13.6326'
                          stroke='#FFF'
                          strokeWidth='3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.84277 10.1582C8.4571 10.7723 9.29016 11.1173 10.1588 11.1173C11.0274 11.1173 11.8604 10.7723 12.4748 10.1582L14.7915 7.84222C15.4059 7.22788 15.751 6.39466 15.751 5.52585C15.751 4.65704 15.4059 3.82381 14.7915 3.20947C14.1772 2.59513 13.344 2.25 12.4751 2.25C11.6063 2.25 10.7731 2.59513 10.1588 3.20947L9.00077 4.36747'
                          stroke='#FFF'
                          strokeWidth='3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    Connect Via Zapier
                  </a>
                </div>
              </ActionControl>
            </div>
            <div className='col s6'>
              <ActionControl
                alertMessage='Campaign Contact feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <div className='upload_contact lead_src_btn'>
                  <Link
                    to={`/campaign/import/${props.campaignDetails?.basic?.id}`}
                    target='_blank'
                    className='d-flex'
                  >
                    <span>
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.8125 6.20546H9.25586V12.8129C9.25586 12.8988 9.32617 12.9691 9.41211 12.9691H10.584C10.6699 12.9691 10.7402 12.8988 10.7402 12.8129V6.20546H12.1875C12.3184 6.20546 12.3906 6.05507 12.3105 5.95351L10.123 3.18397C10.1084 3.1653 10.0898 3.1502 10.0684 3.13981C10.0471 3.12942 10.0237 3.12402 10 3.12402C9.97629 3.12402 9.95288 3.12942 9.93156 3.13981C9.91024 3.1502 9.89157 3.1653 9.87695 3.18397L7.68945 5.95155C7.60938 6.05507 7.68164 6.20546 7.8125 6.20546ZM17.1484 12.2269H15.9766C15.8906 12.2269 15.8203 12.2973 15.8203 12.3832V15.391H4.17969V12.3832C4.17969 12.2973 4.10938 12.2269 4.02344 12.2269H2.85156C2.76562 12.2269 2.69531 12.2973 2.69531 12.3832V16.2504C2.69531 16.5961 2.97461 16.8754 3.32031 16.8754H16.6797C17.0254 16.8754 17.3047 16.5961 17.3047 16.2504V12.3832C17.3047 12.2973 17.2344 12.2269 17.1484 12.2269Z'
                          fill='#3C7EF3'
                          stroke='#3C7EF3'
                          strokeWidth='2'
                        />
                      </svg>
                    </span>
                    Upload Contact(s)
                  </Link>
                </div>
              </ActionControl>
              {/* <div className="connect_via_email lead_src_btn">
                                <a className="modal-trigger d-flex" href="#contact-vai-mail-modal">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1886 0.0371094H0.811005C0.67544 0.0372683 0.545476 0.0912052 0.449636 0.187083C0.353797 0.28296 0.299911 0.412945 0.299805 0.54851V1.57731C0.299805 1.62471 0.351205 1.67911 0.393205 1.70111L7.9504 6.01771C7.96976 6.02877 7.99171 6.0345 8.014 6.03431C8.03679 6.03437 8.05918 6.02829 8.0788 6.01671L15.4076 1.70531C15.4488 1.68231 15.558 1.62151 15.599 1.59351C15.6486 1.55971 15.6998 1.52911 15.6998 1.46851V0.54831C15.6996 0.41278 15.6457 0.282846 15.5499 0.187012C15.4541 0.0911779 15.3241 0.0372682 15.1886 0.0371094Z" fill="white"/>
                                            <path d="M15.6356 3.12824C15.6159 3.11702 15.5936 3.11119 15.571 3.11133C15.5483 3.11147 15.5261 3.11758 15.5066 3.12905L11.3592 5.56925C11.3426 5.57888 11.3285 5.5921 11.3178 5.60795C11.3071 5.62379 11.3 5.64184 11.2973 5.66077C11.2945 5.67969 11.296 5.699 11.3017 5.71726C11.3074 5.73552 11.3171 5.75226 11.3302 5.76624L15.4782 10.2376C15.49 10.2506 15.5045 10.2609 15.5206 10.2679C15.5366 10.2749 15.554 10.2785 15.5716 10.2784C15.6054 10.2784 15.6379 10.2649 15.6619 10.241C15.6858 10.217 15.6993 10.1845 15.6994 10.1506V3.23925C15.6994 3.21676 15.6936 3.19466 15.6824 3.17516C15.6712 3.15567 15.655 3.13949 15.6356 3.12824Z" fill="white"/>
                                            <path d="M10.1648 6.38883C10.1451 6.36738 10.1186 6.35334 10.0898 6.34905C10.061 6.34475 10.0316 6.35046 10.0066 6.36523L8.34415 7.34343C8.24675 7.3997 8.13639 7.42968 8.02391 7.43042C7.91142 7.43115 7.80068 7.40262 7.70255 7.34763L6.23955 6.51183C6.21593 6.49837 6.18865 6.49279 6.16164 6.49588C6.13464 6.49897 6.10932 6.51058 6.08935 6.52903L0.533752 11.6822C0.519187 11.6958 0.508005 11.7127 0.501096 11.7314C0.494186 11.7501 0.491742 11.7701 0.493956 11.7899C0.496171 11.8098 0.502983 11.8288 0.513851 11.8455C0.524719 11.8622 0.539341 11.8762 0.556552 11.8862C0.642952 11.937 0.726352 11.9612 0.811152 11.9612H15.0418C15.0667 11.9613 15.091 11.954 15.1118 11.9403C15.1326 11.9266 15.1489 11.9071 15.1588 11.8842C15.1687 11.8615 15.1718 11.8363 15.1676 11.8118C15.1634 11.7873 15.1521 11.7646 15.1352 11.7464L10.1648 6.38883Z" fill="white"/>
                                            <path d="M4.86661 5.91955C4.88132 5.90595 4.89263 5.88907 4.89962 5.87029C4.90661 5.85151 4.90909 5.83135 4.90685 5.81143C4.90461 5.79152 4.89772 5.77241 4.88673 5.75565C4.87575 5.73889 4.86097 5.72495 4.84361 5.71495L0.490407 3.22855C0.470979 3.21752 0.448996 3.21178 0.426656 3.21192C0.404316 3.21205 0.382402 3.21804 0.363105 3.2293C0.343809 3.24056 0.327806 3.25668 0.316698 3.27607C0.30559 3.29545 0.299765 3.31741 0.299807 3.33975V9.86375C0.299666 9.88867 0.306834 9.91308 0.320425 9.93396C0.334016 9.95485 0.353433 9.97129 0.376272 9.98125C0.399111 9.99121 0.424369 9.99425 0.448922 9.99001C0.473474 9.98576 0.496241 9.97441 0.514407 9.95735L4.86661 5.91955Z" fill="white"/>
                                        </svg>
                                    </span>Connect Via Email
                                </a>
                            </div> */}
              <ActionControl
                alertMessage='Campaign Contact feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <div className='connect_via_api lead_src_btn'>
                  {/* <p>Connect Via API</p> */}
                  <a
                    onClick={() => {
                      toggleApiLinkPopup();
                    }}
                    className='d-flex modal-trigger'
                  >
                    <span>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.1582 7.84185C9.54389 7.22778 8.71084 6.88281 7.84222 6.88281C6.97361 6.88281 6.14055 7.22778 5.52622 7.84185L3.20947 10.1578C2.59513 10.7722 2.25 11.6054 2.25 12.4742C2.25 13.343 2.59513 14.1763 3.20947 14.7906C3.82381 15.4049 4.65704 15.7501 5.52585 15.7501C6.39466 15.7501 7.22788 15.4049 7.84222 14.7906L9.00022 13.6326'
                          stroke='#FFF'
                          strokeWidth='3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M7.84277 10.1582C8.4571 10.7723 9.29016 11.1173 10.1588 11.1173C11.0274 11.1173 11.8604 10.7723 12.4748 10.1582L14.7915 7.84222C15.4059 7.22788 15.751 6.39466 15.751 5.52585C15.751 4.65704 15.4059 3.82381 14.7915 3.20947C14.1772 2.59513 13.344 2.25 12.4751 2.25C11.6063 2.25 10.7731 2.59513 10.1588 3.20947L9.00077 4.36747'
                          stroke='#FFF'
                          strokeWidth='3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    Connect Via API
                  </a>
                </div>
              </ActionControl>
            </div>
            <div className='col s12'>
              <ActionControl
                alertMessage='Campaign Contact feature is not available for template user'
                status='error'
                isDisabled={isTempUser == 1}
              >
                <div className='lead_src_drpdwn_btn'>
                  <div className='lead_src_drpdwn_btn-content-alt'>
                    <p>Direct integrations</p>
                    {selectedProspectSource !== "" && (
                      <div
                        className='direct-int-clear-button'
                        onClick={handleProspectClear}
                        title='Clear prospect source'
                      >
                        CLEAR
                      </div>
                    )}
                  </div>
                  <Select
                    value={selectedProspectSource}
                    onChange={handleProspectChange}
                  >
                    {/* <MenuItem className="menuitem-li-hover-color-with-border" value="" disabled>Choose Direct integrations</MenuItem> */}
                    {prospectSourceOption}
                  </Select>
                </div>
              </ActionControl>
            </div>
          </div>
        </div>
      </div>

      <If condition={openApiLinkPopup}>
        <ApiDetailsPopup
          closePopup={toggleApiLinkPopup}
          apiDetails={apiDetails}
          campaignDetails={props.campaignDetails}
          open={openApiLinkPopup}
        />
      </If>

      {openAddNewContactModal && (
        <AddContactToCampaign
          open={openAddNewContactModal}
          onClose={() => setOpenAddNewContactModal(false)}
          handleAddNewContactModal={() => setOpenAddNewContactModal(false)}
          campaign={
            props.campaignDetails != null ? props.campaignDetails.basic : []
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignLeadSourceDetails: state.marketingReducer.campaignLeadSourceDetails,
    campaignDetails: state.marketingReducer.campaignDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const campaignLeadSourceDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default campaignLeadSourceDetails;
