import React, {useRef} from "react";
import GlobalCustomFieldGrouped from "../GlobalCustomFieldGrouped/Index";
import {CircleRemoveIcon} from "../../icons";
import {useSWHKContext} from "../../reducers/SWHKProvider";
import {encodeQueryData} from "../../helpers";
import {handleState} from "../../reducers/Actions";
import Styles from  "./Styles.module.css";

const App = ({callback,data}) => {
    const {state, dispatch} = useSWHKContext();
    const paramsValueRefs = useRef([]);

    const handleChangeParamsField = (index, name, value) => {
        let data = [...state.params];
        data[index][name] = value;

        let objectPair = {};
        let urlRaw = "";
        let queryParamsString = "";

        if (state.params){
            state.params.forEach((param)=>{
                if (param.key !== "" || param.value !== ""){
                    let obj = {
                        [param.key]: param.value
                    };
                    objectPair = { ...objectPair, ...obj };
                }
            });
            let queryParam = encodeQueryData(objectPair);

            const splitUrl = state.urlRaw.split("?");
            urlRaw = [splitUrl[0], queryParam].join("");
            queryParamsString = queryParam;
        }

        if(index === (state.params.length-1)){
            let object = {
                key: "",
                value: ""
            };
            data = [...state.params, object];
        }

        dispatch(handleState({
            params: data,
            urlRaw: urlRaw,
            queryParamsString: queryParamsString
        }));
        callback({
            params: data
        });
    };

    const handleRemoveParamsField = (index) => {
        let data = [...state.params];
        data.splice(index, 1);

        const splitUrl = state.urlRaw.split("?");
        let urlRaw = splitUrl[0];
        const query = splitUrl[1];
        let queryParamsString = splitUrl[1];

        if (query){
            const splitParams = query.split("&");
            splitParams.splice(index, 1);
            const newQuery = splitParams.join("&");
            if (newQuery){
                urlRaw = [splitUrl[0], newQuery].join("?");
            }

            queryParamsString = newQuery;
        }

        dispatch(handleState({
            params: data,
            urlRaw: urlRaw,
            queryParamsString: queryParamsString
        }));
        callback({
            params: data
        });
    };

    const renderParamsItems = () => {
      const paramsItems = [];

      state.params.forEach((param, index)=>{
         paramsItems.push(
             <div className={Styles.wbh_headers_panel_item}>
                 <input type="text" name="key" onChange={(event)=>{handleChangeParamsField(index, event.target.name, event.target.value)}} value={param.key} placeholder="Key" />
                 <input type="text" name="value" ref={(el)=>(paramsValueRefs.current[index]=el)} onChange={(event)=>{handleChangeParamsField(index, event.target.name, event.target.value)}} value={param.value}  placeholder="Value"/>
                 <GlobalCustomFieldGrouped className="wbh_global__personalized" handleSelect={(placeholderValue)=>{
                     let start = paramsValueRefs.current[index].selectionStart;
                     let end = paramsValueRefs.current[index].selectionEnd;
                     let text = paramsValueRefs.current[index].value;
                     let before = text.substring(0, start);
                     let after = text.substring(end, text.length);

                     let newText = (before + placeholderValue + after);

                     handleChangeParamsField(index, "value", newText);

                     setTimeout(() => {
                         paramsValueRefs.current[index].selectionStart = paramsValueRefs.current[index].selectionEnd = start + placeholderValue.length;
                         paramsValueRefs.current[index].focus();
                     }, 500);
                 }} labelText={"Personalized"}/>
                 {
                     (state.params.length !== 1 && index !== (state.params.length-1)) ? (
                         <span
                             className={Styles.sh_hp_remove}
                             onClick={()=>{handleRemoveParamsField(index)}}
                            >
                            <CircleRemoveIcon />
                        </span>
                     ) : (<span style={{ height: "24px", width:"24px" }}/>)
                 }
             </div>
         );
      });

      return paramsItems;
    };
    return(
          <div className={Styles.wbh_content_border_wrp}>
              <div className={`${Styles.wbh_headers_panel_content} awesome__scroll_bar`}>
                  {renderParamsItems()}
              </div>
          </div>
    );
};

export default App;