import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';

window.renderMarketingModule = (containerId, history) => {

    if(document.getElementById(containerId)) {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
    }

    ReactDOM.render(
        // <React.StrictMode>
        <React.Fragment>
          <Provider store={store}>
            <App history={history} />
          </Provider>
        </React.Fragment>,
        // </React.StrictMode>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

window.unmountMarketingModule = (containerId) => {
    try{
        if (document.getElementById(containerId)) {
            ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
        }
    }
    catch(error){
        console.log("can not unmount MarketingModule-root")
    }
};

// if (!document.getElementById('MarketingModule-container')) {
//     document.getElementById('root').innerHTML = 'Permission denied';
// }

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('MarketingModule-root')) {
    ReactDOM.render(
        <React.Fragment>
          <Provider store={store}>
            <App />
          </Provider>
        </React.Fragment>,
        // </React.StrictMode>,
        document.getElementById('MarketingModule-root'));
    serviceWorker.unregister();
}