import { LikeIcon } from "../../../helpers/Icons";
import "./../CampaignDetails/modify-style.css";
const CampaignActionNoticeBoard = ({
  message = "Your campaign will be activated shortly. It could take up to 5 minutes to go live.",
}) => {
  return (
    <>
      <div className='campaign-noticeboard-parent-div'>
        <div
          className='campaign-noticeboard-svg-span'
          style={{ width: "100%" }}
        >
          <LikeIcon width='56px' height='56px' />
        </div>
        <p style={{ color: "#000", marginTop: "20px" }}>{message}</p>
      </div>
    </>
  );
};

export default CampaignActionNoticeBoard;
