import React, { useEffect, useState } from 'react'
import GlobalModal from '../../globals/Modal/GlobalModal'
import AddIcon from '@material-ui/icons/Add';
import NewLoader from '../../Common/NewLoader';
import { getCampaignSettingInfosApi, updateCampaignSettingInfosApi } from '../../../api/marketingApi';
import SwitchC from '../../Common/Switch/Switch';

const SendEmailWithLookupFilterArr = ['isSendUndeliverable', 'isSendDeliverable', 'isSendRisky',  'isRuntimeLookupValidationForEmail'];
const SendTextWithLookupFilterArr = ['isRuntimeLookupValidationForText', 'isSendLandlineForText', 'isSendMobileForText'];
const SendCallWithLookupFilterArr = ['isRuntimeLookupValidationForCall', 'isSendLandlineForCall', 'isSendMobileForCall'];
const SendRvmWithLookupFilterArr = ['isRuntimeLookupValidationForRvm', 'isSendLandlineForRvm', 'isSendMobileForRvm'];

function LookupValidation(props) {
    const [data, setData] = useState([]);
    const [settingLebel, setSettingLebel] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleSubmit = () =>{
        if(!loading){
            setLoading(true);
            updateCampaignSettingInfosApi({...data, id:props.campaignId })
            .then((response) => {
                if(response.data.status === 'success'){
                   window.showNotification('SUCCESS', response.data.message);
                   props.onClose()
                }else{
                    window.showNotification('ERROR', response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    useEffect(()=>{
        loadData();
    }, [props.open, props.campaignId])

    const loadData=()=>{
        setLoading(true);
        getCampaignSettingInfosApi({campaign_id: props.campaignId})
            .then((response) => {
                // console.log(response)
                setData(response.data.data);
                setSettingLebel(response.data.setting_label)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getLebel = (keyText='')=>{
        if(settingLebel[keyText]){
            return settingLebel[keyText];
        }else{
            return keyText;
        }
    }

    const handleData = (value, name)=>{
        if(!loading){
            let old_data = data;
            let new_data = {};
            if(name == 'isSendRvmWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendRvmWithLookupFilter: true, isRuntimeLookupValidationForRvm:false, isSendLandlineForRvm:false, isSendMobileForRvm:true};
                }else{
                    new_data = { ...old_data, isSendRvmWithLookupFilter: false, isRuntimeLookupValidationForRvm:false, isSendLandlineForRvm:false, isSendMobileForRvm:false};
                }
            }else if(name == 'isSendCallWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendCallWithLookupFilter: true, isRuntimeLookupValidationForCall:false, isSendLandlineForCall:false, isSendMobileForCall:true};
                }else{
                    new_data = { ...old_data, isSendCallWithLookupFilter: false, isRuntimeLookupValidationForCall:false, isSendLandlineForCall:false, isSendMobileForCall:false};
                }
            }else if(name == 'isSendTextWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendTextWithLookupFilter: true, isRuntimeLookupValidationForText:false, isSendLandlineForText:false, isSendMobileForText:true};
                }else{
                    new_data = { ...old_data, isSendTextWithLookupFilter: false, isRuntimeLookupValidationForText:false, isSendLandlineForText:false, isSendMobileForText:false};
                }
            }else if(name == 'isSendEmailWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendEmailWithLookupFilter: true, isRuntimeLookupValidationForEmail:false, isSendUndeliverable:false, isSendDeliverable:true, isSendRisky:false};
                }else{
                    new_data = { ...old_data, isSendEmailWithLookupFilter: false, isRuntimeLookupValidationForEmail:false, isSendUndeliverable:false, isSendDeliverable:false, isSendRisky: false};
                }
            }else{
               new_data = { ...old_data, [name]: value}; 
            }

            setData(new_data);

        }

    }

    const commonHtmlRender = (key='', value=false, isDisable=true)=>{
        return (
            <>
               
                <td style={{"width":"50%"}}>{getLebel(key)}</td>
                <td className='text-center' style={{"width":"50%"}} >
                    <SwitchC
                        status={data[key]}
                        brandColor={"#27AE60"}
                        onChange={(res) => {if(isDisable === false) handleData(res, key)}}
                        onText={"Yes"}
                        offText={"No"}
                    />
                </td>
            </>
        )
    } 

    const renderData = () => {
    
        let view = [];
        let i=0;

        for (const [key, value] of Object.entries(settingLebel)) {
            i++;

            if(SendEmailWithLookupFilterArr.includes(key)){
                view.push(
                    <tr key={i}>
                        {commonHtmlRender(key, data[key], !data.isSendEmailWithLookupFilter)}
                    </tr>
                )
            }else if(SendTextWithLookupFilterArr.includes(key)){
                view.push(
                    <tr key={i}>
                        {commonHtmlRender(key, data[key], !data.isSendTextWithLookupFilter)}
                    </tr>
                )
            }else if(SendCallWithLookupFilterArr.includes(key)){
                view.push(
                    <tr key={i}>
                        {commonHtmlRender(key, data[key], !data.isSendCallWithLookupFilter)}
                    </tr>
                )
            }else if(SendRvmWithLookupFilterArr.includes(key)){
                view.push(
                    <tr key={i}>
                        {commonHtmlRender(key, data[key], !data.isSendRvmWithLookupFilter)}
                    </tr>
                )
            }else{
                view.push(
                    <tr key={i} style={{background: "rgba(0, 85, 255, 0.05)"}}>
                        {commonHtmlRender(key, value, false)}
                    </tr>
                )
            }
        }
        return view;
        
    }


    return (
        <GlobalModal 
            open={props.open} 
            onClose={props.onClose}
            // hideFooter={true}
            className="global-large-modal campaign-contact-add-modal main-add-contact-modal"
            title="Lookup Validation"
            onSubmit={handleSubmit}
            buttonText={'Update Lookup Validation'}
            buttonIcon={<AddIcon fontSize="small" />}
        >
        <div className="tabs_all_content marketing-add-contact-modal">

                <NewLoader loading={loading} onlyLoader={true} size={25}/>
                <div className="">
                    <p style={{color:"red", marginBottom:"28px"}}>**Alert: Enabling services will deducts credits!</p>
                    <table>
                        {renderData()} 
                    </table>
                </div>
            </div> 
        </GlobalModal>
    )
}

export default LookupValidation