import React, {useState, useRef} from 'react'
const CustomImageViewer = (props) => {
    const [loading, setLoading] = useState(true)
    const image = useRef(null)

    const onLoad = () => {
        setLoading(false)
    }

    return(
        <div className={`${loading ? 'loading__template_item' : ''}`}>
            <img 
                ref={image} 
                src={props.url}
                key={props.key}
                onLoad={onLoad}
                style={loading ? {display: 'none'} : {}}
                alt=""
            />
            {loading ? <span>Loading image..</span> : ''}
        </div>
    )
}
export default CustomImageViewer;