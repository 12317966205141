/* eslint-disable eqeqeq */

const Helper={

    getIconTextFromContact:(contact)=>{
        
        let firstName = (contact.first_name != undefined && contact.first_name != null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name != undefined && contact.last_name != null) ? contact.last_name.trim() : ''
        
        if(firstName != '' && lastName != ''){
            return (firstName.substring(0,1)+lastName.substring(0,1)).toUpperCase()
        }else if(firstName != ''){
            return firstName.substring(0,2).toUpperCase()
        }else if(lastName != ''){
            return lastName.substring(0,2).toUpperCase()
        }else {
            return 'N/A'
        }
    },

    isEmpty:(text)=>{
        if(text != undefined && text!= null && text!= ''){
            return false
        }else{
            return true
        }
    },

    isvalidText: (text) => {
        if(text != undefined && text!= null && text !== ""){
            return true
        }else{
            return false
        }
    },
    hexToRgbA: (hex) => {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
        }
        return hex;
    },
    reduceTextLength :(text, limit = 40) => {
        if (text !== undefined) {
            text = text.toString();
          if (text.length > limit) {
            return text.substring(0, limit);
          } else {
            return text;
          }
        } else {
          return "";
        }
    
    },
    visitorTimezones : {
        'EST' : 'Eastern Standard Time',
        'America/Chicago' : 'Central Standard Time',
        'America/Denver' : 'Mountain Standard Time',
        'America/Los_Angeles' : 'Pacific Daylight Time',
        'America/Anchorage' : 'Alaska Daylight Time',
        'America/Honolulu' : 'Hawaii Standard Time',
        'America/Phoenix' : 'Arizona Standard Time',
    }
}

export default Helper;