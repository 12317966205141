import React, {useState, useEffect, useRef} from "react";
import CustomSelectBox from "../../Common/CustomSelectBox";
import {fetchCampaignTimelines, followupSave, getCustomFieldData, updateArrSlotTimeHour1, updateArrSlotTimeMin1, updateDay, updateGreetingOption, updateGreetingsOptionTimeHour1, updateGreetingsOptionTimeMin1, updateHour, updateLaterHour, updateLaterMin, updateMin, updateRecurringDay, updateRecurringTimeHour1, updateRecurringTimeMin1, updateSmsStepSetting, updateStartDate, updateTabValue, updateTime} from "../../../actions/marketingAction";
import {connect} from "react-redux";
import moment from "moment";
import ScheduleSetting from "./ScheduleSetting";
import CoreConstants from "../../../constants/CoreConstants";
import {If} from "react-if";
import {getMessageTemplate, testSMSSend} from "../../../api/marketingApi";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GlobalModal from "../../globals/Modal/GlobalModal";
import SimpleReactValidator from "simple-react-validator";
import Utils from "../../../helpers/Utils";
import QucikReplyCard from "../../QuickReplyCard";
import GlobalCustomFieldGrouped from "../../globals/GlobalCustomFieldGrouped/Index";
import ModalComponent from "../../Common/modal/ModalComponent";
import UnicodeAlert from "./alert-popup/UnicodeAlert";

const App = props => {
    const [, forceUpdate] = useState()
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));

    const textInput = useRef(null);
    const fileInputRef = useRef(null);
    const testNumberRef = useRef(null);
    const [isSendTestSMS, setIsSendTestSMS] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [showAllTimeSetting, setShowAllTimeSetting] = useState(props.showAllTimeSettings);
    const [conversationText, setConversationText] = useState('');
    const [campaignDetails, setCampaignDetails] = React.useState(props.campaignDetails);

    const [tabValue, setTabValue] = useState(props.tabValue);
    const [hour, setHour] = useState(props.hour);
    const [min, setMin] = useState(props.min);
    const [day, setDay] = useState(props.day);
    const [time, setTime] = useState(props.time);
    const [startDate, setStartDate] = useState(props.startDate);
    const [greetingOption, setGreetingOption] = useState(props.greetingOption);
    const [showAlert, setShowAlert] = useState(false);

    // for update
    const [updateSetting, setUpdateSetting] = useState(false);
    const [editableCampaignSettingId, setEditableCampaignSettingId] = useState(null);

    const [imageUpload, setImageUpload] = useState(false);
    const [imageFileUrl, setImageFileUrl] = useState('');
    const [imageFileExt, setImageFileExt] = useState('');

    const [submitting, setSubmitting] = useState(false);

    const [messageLengthError, setMessageLengthError] = useState(false);

    /* mms type on update */
    const [fileUrlForUpdate, setFileUrlForUpdate]  = useState('');
    /* for character count */
    const [smsCount,setSmsCount] = useState({
        smslength:0,
        sms:0
    });

    //! For Quick Reply
    const [isShowQuickReplies, setIsShowQuickReplies] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [needToloadMoreTemplate, setNeedToloadMoreTemplate] = useState(false);
    const [loadingMoreTemplate, setLoadingMoreTemplate] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const [isShowUnicodeAlert, setIsShowUnicodeAlert] = useState(false)

    useEffect(() => {
        setTabValue(props.tabValue);
        setHour(props.hour);
        setMin(props.min);
        setDay(props.day);
        setTime(props.time);
        setStartDate(props.startDate);
        setGreetingOption(props.greetingOption);
    }, [
        props.tabValue, props.hour, props.min, props.day, props.recurringDay, props.laterHour, props.laterMin, props.time, props.startDate, props.arrSlotTimeHour1, props.arrSlotTimeMin1, props.greetingOption, props.greetingsOptionTimeMin1, props.greetingsOptionTimeHour1, props.recurringTimeHour1, props.recurringTimeMin1,
    ]);

    useEffect(() => {
        setCampaignDetails(props.campaignDetails);
    }, [props.campaignDetails]);

    useEffect(() => {
        if(props.currentClip != null){
            if(props.currentClip.message){
                let countResult = Utils.countSms(props.currentClip.message)
                setSmsCount({
                    ...smsCount,
                    smslength:countResult.length,
                    sms:countResult.sms
                })
            }
            setUpdateSetting(true);
            setEditableCampaignSettingId(props.currentClip.id);
            setConversationText(props.currentClip.message);
            if(props.currentClip.message_type == CoreConstants.MESSAGE_TYPE_MMS){
                setFileUrlForUpdate(props.currentClip.file_path);
            }
        }
    }, [updateSetting]) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePersonalize = (event) => {
        let start = textInput.current.selectionStart;
        let end = textInput.current.selectionEnd;
        let text = textInput.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event + after);

        setConversationText(newText);

        setTimeout(() => {
            //Lines below not works without timeout
            textInput.current.selectionStart = textInput.current.selectionEnd = start + event.length;
            textInput.current.focus();
        }, 500);
    }

    const sendText = () => {
        if(submitting)return;
        setShowAlert(false);
        setIsShowUnicodeAlert(false);
        // check validation
        if(conversationText === ''){
            setShowAlert(true);
            document.getElementById("sms-setting-input-field").focus();
            return false
        }

        if(messageLengthError){
            document.getElementById("sms-setting-input-field").focus();
            return false
        }

        let type;
        switch (tabValue) {
            case 1:
            case 2:
                type = CoreConstants.SENDING_TYPE_SCHEDULE;
            break;
            case 0:
                type = CoreConstants.SENDING_TYPE_IMMEDIATE;
                break;
            default:
                type = tabValue;
            break;
        }

        setSubmitting(true)

        let formData = new FormData();
        formData.append('message', conversationText);
        formData.append('message_type', CoreConstants.MESSAGE_TYPE_SMS);
        formData.append('nScheduleHours', hour);
        formData.append('nScheduleMinutes', min);
        formData.append('nScheduleDays', day);
        formData.append('followupScheduleTime', time);
        formData.append('campaign_id', campaignDetails.basic.id);
        formData.append('type', type);
        formData.append('day_type', (tabValue != 0 ? tabValue : 1));
        formData.append('date', moment(startDate).format('YYYY-MM-DD'));
        formData.append('greeting_option', greetingOption);

        if(imageUpload){
            formData.append('file_data', fileInputRef.current.files[0]);
            formData.append('file_ext', imageFileExt);
            formData.append('message_type', CoreConstants.MESSAGE_TYPE_MMS);
        }

        if(updateSetting && !imageUpload && props.currentClip.message_type == CoreConstants.MESSAGE_TYPE_MMS && fileUrlForUpdate !== ""){
            formData.append('file_path', fileUrlForUpdate);
            formData.append('message_type', CoreConstants.MESSAGE_TYPE_MMS);
            formData.append('file_ext', props.currentClip.file_extention);
        }

        if(updateSetting){
            formData.append('id', editableCampaignSettingId);
            props.updateSmsStepSetting(formData, () => {
                props.fetchCampaignTimelines({
                    campaignId: campaignDetails.basic.id
                });
                props.setOpenSMSModal(false)
            })
        }
        else{
            props.followupSave(formData, successFollowupAdd);
        }
    }

    const successFollowupAdd = (response) => {
        setSubmitting(false)
        if(response.status == 'success'){
          window.showNotification("SUCCESS", "Campaign setting saved successfully")
            props.fetchCampaignTimelines({
                campaignId: campaignDetails.basic.id
            });
            props.setOpenSMSModal(false)
        }
        else{
          window.showNotification("ERROR", "Something went wrong. Try again.")
        }
        
    }

    // const hanldeQuickReplySelect = (data) => {
    //     setConversationText(data.message)
    // }

    const handleFileChange = (event) => {
        if(event.target.files.length > 0){
            if(event.target.files[0].size > (1000000*15)){
              window.showNotification("ERROR", "File can not be large than 15MB")
                return;
            }
            let type = event.target.files[0].type.replace('image/', '')

            if(!(type.toUpperCase() == 'JPG' || type.toUpperCase() == 'PNG' || type.toUpperCase() == 'GIF' || type.toUpperCase() == 'JPEG')){
              window.showNotification("ERROR", "File type not match. Upload only png, gif, jpeg type")
                return;
            }
            else{
                let urlCreator = window.URL || window.webkitURL;
                const urlObj = urlCreator.createObjectURL(fileInputRef.current.files[0]);
                setImageFileUrl(urlObj)
                setImageUpload(true)
                setImageFileExt(type)
            }
        }
        else{
            setImageFileUrl('')
            setImageUpload(false)
            setImageFileExt('')
        }
    }

    const hanldeRemoveUploadImage = () => {
        setImageFileUrl('')
        setImageUpload(false)
        setImageFileExt('')
    }

    const handleMessageChange = (event) => {
        let countResult = Utils.countSms(event.target.value)
        setSmsCount({
            ...smsCount,
            smslength:countResult.length,
            sms:countResult.sms
        })
        setConversationText(event.target.value)
        if(event.target.value.length > 500){
            setMessageLengthError(true)
        }
        else{
            setMessageLengthError(false)
        }
    }

    const sendTestSMS = (e) => {
        e.preventDefault();
        let test_number = testNumberRef.current.value;

        if (test_number === "") {
            window.showNotification('ERROR', "Please insert a valid number");
            return;
        }

        if(!validator.current.allValid()){
            window.showNotification('ERROR', "Valid sms text not found");
            return;
        }

        if (!isSendTestSMS) {
            setIsSendTestSMS(true);
            let formData = new FormData();
            formData.append('number', test_number);
            formData.append('sms', conversationText);
            if(imageUpload){
                formData.append('qqfile', fileInputRef.current.files[0]);
                formData.append('file_ext', imageFileExt);
                formData.append('message_type', CoreConstants.MESSAGE_TYPE_MMS);
            }

            if(updateSetting && !imageUpload && props.currentClip.message_type == CoreConstants.MESSAGE_TYPE_MMS && fileUrlForUpdate !== ""){
                formData.append('qqfile_url', fileUrlForUpdate);
                formData.append('message_type', CoreConstants.MESSAGE_TYPE_MMS);
                formData.append('file_ext', props.currentClip.file_extention);
            }
            testSMSSend(formData).then(response => {
                setIsSendTestSMS(false);
                if (response.data.status) {
                    window.showNotification('SUCCESS', response.data.message);
                } else {
                    window.showNotification('ERROR', response.data.message);
                }
            }).catch(error => {
                window.showNotification('ERROR', 'Something wrong! Try again');
                setIsSendTestSMS(false);
            })
        }
    }

    const hanldeRemovePreviousImage = () => {
        setFileUrlForUpdate('')
    }

    const getAllTemplates = (params) => {
        /* 
        page: 0
        perPage: 20
        search_key: ""
        type: ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
        
        */
        getMessageTemplate(params)
        .then(res => {
            let response = res.data;
            if(response.status === 'success'){
                if(response.html.length >= 20){
                    setNeedToloadMoreTemplate(true)
                    setCurrentPage(currentPage + 1)
                }else{
                    setNeedToloadMoreTemplate(false)
                }
                setTemplates([...templates, ...response.html])
                setLoadingTemplate(false)
                setLoadingMoreTemplate(false)
            }
            else{
                setLoadingTemplate(false)
                setLoadingMoreTemplate(false)
                setNeedToloadMoreTemplate(false)
            }
        }).catch(err => {
            setLoadingTemplate(false)
            setLoadingMoreTemplate(false)
            setNeedToloadMoreTemplate(false)
        })
    }

    const handleOnScroll = (e) => {
        if(
            needToloadMoreTemplate && 
            !loadingMoreTemplate && 
            Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
        ) {
            setLoadingMoreTemplate(true)
            getAllTemplates({ 
                page: currentPage,
                perPage: 20,
                search_key: "",
                type: [CoreConstants.MESSAGE_TEMPLATE_SMS]
            })
        }
    }

    /* for quick replies */
    const handleQuickReplyView = () => {
        setIsShowQuickReplies(true);
        if(templates.length <= 0){
            if(loadingTemplate){
                return false;
            }
            setLoadingTemplate(true)
            getAllTemplates({ 
                page: 0,
                perPage: 20,
                search_key: "",
                type: [CoreConstants.MESSAGE_TEMPLATE_SMS]
            })
        }
        
    }
    
    const renderMain = () => {
        return(
            <>
                {/*Massaging Editor*/}
                <div className="tiny-mce mt-3">
                    <div className="mb-0 row">
                        <div className="col s12 personalize_select_field">
                            <textarea id="sms-setting-input-field" className="materialize-textarea" placeholder="Type a message"  onChange={handleMessageChange} ref={textInput} value={conversationText}/>
                            <If condition={showAlert}><span className={"text-red"}>Message is required</span></If>
                            {validator.current.message('message', conversationText, 'required|string')}
                            <If condition={messageLengthError}><span className={"text-red"}>Maximum Character 500 Allowed</span></If>
                            <div className="image_file_information d-flex">
                            {imageUpload && imageFileUrl !== '' &&
                                <div className="upload-image-container ">
                                    <img 
                                        src={imageFileUrl}
                                        height="200"
                                        width="200"
                                        alt=""
                                    />
                                    <span className="clear_text" onClick={hanldeRemoveUploadImage}>Clear Image</span>
                                </div>
                            }

                            {fileUrlForUpdate !== '' &&
                                <div className="upload-image-container ml-3">
                                    <div className="upload-image-container-update">
                                        <img 
                                            src={fileUrlForUpdate}
                                            height="200"
                                            width="200"
                                            alt=""
                                        />
                                        {imageUpload && imageFileUrl !== '' &&
                                        <div className="upload-mms-hints">Previous image will be replaced automatically after update or you can remove it now.</div>
                                        }
                                    </div>
                                    <span className="clear_text" onClick={hanldeRemovePreviousImage}>Clear Previous</span>
                                </div>
                            }
                            </div>
                        </div>
                        <div className="pm_m-10px"><p>Total SMS: {smsCount.smslength}/{smsCount.sms}</p></div>
                    </div>
                    
                </div>

                {/*Massage Editor Bottom Button*/}
                <div className="send-button-area">
                    <div className="mb-0 row mt-4">
                        <div className="col s6">
                            <div className="mb-0 row">
                                <div className="col s6 m6">
                                    <GlobalCustomFieldGrouped className={"marketing__module__sms_modal"} labelText={"Personalize"} handleSelect={handlePersonalize}/>
                                </div>
                                {/* <div className="pl-1 col s6 m6 sms-quick-replies-template">
                                    <a href="#!" onClick={e => e.preventDefault()} className="quick-reply">
                                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"></path>
                                        </svg>
                                        Use Quick Reply</a>
                                    <PreRecordingList from="sms-add-modal" title="Use Quick Reply" preRecording={quickReplyList} handlePreRecordSelect={hanldeQuickReplySelect}/>

                                </div> */}
                                <div className="pl-1">
                                    <div className="quick-reply-old quick_reply_hanlde_button" onClick={handleQuickReplyView}>
                                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"></path>
                                        </svg>
                                        Use Quick Reply
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s6">
                            <div className="pr-0 send-message-btn right p-relative mt-21px" style={{"margin-top": "-21px"}}>
                                <div className="file-field input-field d-flex">
                                    <div className="file-path-wrapper align-items-center d-none">
                                            <span>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.5 0C5.6075 0 0 5.60758 0 12.5001C0 19.3926 5.6075 25 12.5 25C19.3925 25 25 19.3926 25 12.5001C25 5.60758 19.3925 0 12.5 0ZM12.5 22.7273C6.86061 22.7273 2.27273 18.1394 2.27273 12.5001C2.27273 6.86076 6.86061 2.27273 12.5 2.27273C18.1394 2.27273 22.7273 6.86076 22.7273 12.5001C22.7273 18.1394 18.1393 22.7273 12.5 22.7273Z"
                                                        fill="#FF6B6B"/>
                                                    <path
                                                        d="M12.4992 5.30273C11.6639 5.30273 10.9844 5.98273 10.9844 6.81857C10.9844 7.65364 11.6639 8.33304 12.4992 8.33304C13.3345 8.33304 14.0141 7.65364 14.0141 6.81857C14.0141 5.98273 13.3345 5.30273 12.4992 5.30273Z"
                                                        fill="#FF6B6B"/>
                                                    <path
                                                        d="M12.4996 10.6045C11.8721 10.6045 11.3633 11.1133 11.3633 11.7409V18.559C11.3633 19.1866 11.8721 19.6954 12.4996 19.6954C13.1272 19.6954 13.636 19.1866 13.636 18.559V11.7409C13.636 11.1133 13.1272 10.6045 12.4996 10.6045Z"
                                                        fill="#FF6B6B"/>
                                                </svg>
                                            </span>
                                        <input className="file-path validate d-none" type="text"
                                                placeholder=" Max size 15MB, Max 5 files."/>
                                        <span className="file-path validate">Max size 15MB, Max 5 files.</span>
                                    </div>
                                    <div className="upload_file_button d-flex accent--bg--color ">
                                        <span className="upload_file_button_icon">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.288 5.10351L6.16506 12.1474C4.98482 13.3147 3.07111 13.3147 1.89087 12.1474C0.710641 10.9802 0.710641 9.08801 1.89087 7.92077L8.30191 1.58131C9.0889 0.803315 10.3642 0.803315 11.1512 1.58131C11.9382 2.35929 11.9382 3.6211 11.1512 4.39908L4.74016 10.7386C4.34692 11.1278 3.70901 11.1278 3.31527 10.7386C2.92202 10.3498 2.92202 9.71894 3.31527 9.32967L9.01386 3.69463L8.3014 2.9902L2.60333 8.62572C1.81634 9.40372 1.81634 10.6655 2.60333 11.4435C3.39032 12.2215 4.66563 12.2215 5.45262 11.4435L11.8637 5.10401C13.0439 3.93679 13.0439 2.04461 11.8637 0.877366C10.6834 -0.289851 8.76973 -0.289851 7.58949 0.877366L0.822208 7.56904L0.846732 7.59355C-0.382548 9.15652 -0.274481 11.416 1.17844 12.8523C2.63136 14.2887 4.91528 14.3963 6.49626 13.18L6.52078 13.2046L14 5.80844L13.288 5.10351Z"
                                                    fill="#3C7EF3"/>
                                            </svg>
                                        </span>
                                        <span>Attach File</span>
                                        <input onChange={handleFileChange} type="file" accept="image/png, image/gif, image/jpeg" ref={fileInputRef}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Massage Editor Bottom Button*/}
                <ScheduleSetting showAllTimeSetting={showAllTimeSetting}/>

                <div className="send_test_wrapper mt-5">
                    <div className="send_test">
                        <label className="mb-2">Send Test SMS</label>
                        <div className="inputs">
                            <input type="email" className="form-control" ref={testNumberRef} placeholder="Your number here..."/>
                            <a href="#!" className="btn accent--bg--text--color" onClick={(e) => sendTestSMS(e)}>{isSendTestSMS ? 'Sending ...' : 'Send Test SMS'}</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const showTemplates = () => {
        let showTemplates = []
        templates.forEach((item, index) => {
            showTemplates.push(
                <QucikReplyCard
                    data={item} 
                    key={index}
                    index={index}
                    onSelect={(item) => {
                        setConversationText(item.message);
                        setIsShowQuickReplies(false);
                    }}
                    className={'main-content-from-quick-reply'}
                />
            )
        })
        return showTemplates;
    }

    const renderTemplate = () => {
        return(
            <div className="awesome__scroll_bar campaign_setting_template_container" onScroll={handleOnScroll} id="quick__reply_list__main__container_settings">
                {loadingTemplate ? 
                    <div className="loading_content">Please wait...</div>
                    :
                    <div className="campaign_setting_template_body">
                        {templates.length > 0 ? 
                            <div className="templates___body">
                                <div className="templates___body__back_button" onClick={() => setIsShowQuickReplies(false)}>{'<< Back'}</div>
                                {showTemplates()}
                            </div>
                            :
                            <div className="empty_template_body">No Text templated added</div>
                        }
                    </div>
                }
            </div>
        )
    }
    
    const renderView = () => {
        if(isShowQuickReplies){
            return renderTemplate()
        }
        return renderMain()
    }

    /* for unicode alert */
    const handleEdit = () => {
        setIsShowUnicodeAlert(false)
    }
    const handleAdd = (e) => {
        /* check unicode */
        const hasUniCode = checkHasUniCode(conversationText.trim())
        if(hasUniCode){
            setIsShowUnicodeAlert(true)
        }
        else{
            sendText()
        }
    }
    const checkHasUniCode = (text) => {
        // eslint-disable-next-line no-control-regex
        if (!text.length) return false;
        if (text.charCodeAt(0) > 255) return true;
        for (var i = 0, n = text.length; i < n; i++) {
            if (text.charCodeAt( i ) > 255) { 
                return true;
            }
            // eslint-disable-next-line no-control-regex
            let regex = /[^\u0000-\u00FF]/;
            const codePoint =  text.codePointAt(i);
            const hexCodePoint = codePoint.toString(16);
            let testing = toUTF16(hexCodePoint)
            if(regex.test(testing)){
                return true
            }
        }
        return false;
    }
    function toUTF16(codePoint) {
        var TEN_BITS = parseInt('1111111111', 2);
        function u(codeUnit) {
            return '\\u'+codeUnit.toString(16).toUpperCase();
        }
    
        if (codePoint <= 0xFFFF) {
            return u(codePoint);
        }
        codePoint -= 0x10000;
    
        // Shift right to get to most significant 10 bits
        var leadingSurrogate = 0xD800 | (codePoint >> 10);
    
        // Mask to get least significant 10 bits
        var trailingSurrogate = 0xDC00 | (codePoint & TEN_BITS);
    
        return u(leadingSurrogate) + u(trailingSurrogate);
        // return u(leadingSurrogate);
    }

    return (
        <>
            <GlobalModal 
                open={props.openSMSModal} 
                onClose={props.setOpenSMSModal}
                title="SMS Followup" 
                // onSubmit={sendText}
                onSubmit={handleAdd}
                buttonText={'Set Followup SMS'}
                buttonIcon={
                    submitting ? 
                        <MoreHorizIcon fontSize="small" />
                    :
                        <svg width="16" height="16" viewBox="0 0 14 12" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z"
                                fill="#FFF"/>
                        </svg>
                }
                className="global-large-modal campaign__setting__email">

                    {renderView()}

                    <ModalComponent 
                        isShow={isShowUnicodeAlert}
                        component={<UnicodeAlert handleEdit={handleEdit} handleSend={sendText} />}
                        blockBackdrop={true}
                        style={{
                            width: '590px',
                            height: '390px'
                        }}
                    />  

            </GlobalModal>
        </>
    );
}

const mapStateToProps = state => {
    return {
        customFieldData: state.marketingReducer.customFieldData,
        userInfo: state.marketingReducer.userInfo,
        campaignDetails: state.marketingReducer.campaignDetails,

        tabValue: state.marketingReducer.tabValue,
        hour: state.marketingReducer.hour,
        min: state.marketingReducer.min,
        day: state.marketingReducer.day,
        recurringDay: state.marketingReducer.recurringDay,
        laterHour: state.marketingReducer.laterHour,
        laterMin: state.marketingReducer.laterMin,
        time: state.marketingReducer.time,
        startDate: state.marketingReducer.startDate,
        arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
        arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
        greetingOption: state.marketingReducer.greetingOption,
        greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
        greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
        recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
        recurringTimeMin1: state.marketingReducer.recurringTimeMin1,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomFieldData: () => dispatch(getCustomFieldData()),
        followupSave: (params, callback) => dispatch(followupSave(params, callback)),
        updateSmsStepSetting: (params, callback) => dispatch(updateSmsStepSetting(params, callback)),
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),

        // for time setting
        updateTabValue: (params) => dispatch(updateTabValue(params)),
        updateHour: (params) => dispatch(updateHour(params)),
        updateMin: (params) => dispatch(updateMin(params)),
        updateDay: (params) => dispatch(updateDay(params)),
        updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        updateTime: (params) => dispatch(updateTime(params)),
        updateStartDate: (params) => dispatch(updateStartDate(params)),
        updateArrSlotTimeHour1: (params) => dispatch(updateArrSlotTimeHour1(params)),
        updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
        updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
        updateGreetingsOptionTimeMin1: (params) => dispatch(updateGreetingsOptionTimeMin1(params)),
        updateGreetingsOptionTimeHour1: (params) => dispatch(updateGreetingsOptionTimeHour1(params)),
        updateRecurringTimeHour1: (params) => dispatch(updateRecurringTimeHour1(params)),
        updateRecurringTimeMin1: (params) => dispatch(updateRecurringTimeMin1(params)),
    };
}

const SMSModal = connect(mapStateToProps, mapDispatchToProps)(App);

export default SMSModal;