const Messages = {
    emptyCampaignList: 'No campaign created yet !',
    emptyCampaignActionMessage: 'Try to add more contacts from your personal account  or compose email to someone',
    emptyMessageBody : 'Message can not be empty !',
    emptyTitleInAddQuickReply : 'Title can not be empty !',
    emptyVoiceFile : 'Please select an audio file or record new before submit !',
    videoNotFound : 'Video file not found. Please record/upload a video',


    // add contact to campaign
    
}

export default Messages;