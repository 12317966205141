import React from "react";
import BootstrapTooltip from "../../Common/BootstrapTooltip";

const TextTruncateWithTooltip = ({textLength=19, text=""}) => {

    const getSmallText = (text) => {
        if(text.length > textLength){
            return <BootstrapTooltip placement={"top-start"} arrow title={text}><span>{(text.substring(0, (textLength-3))+'...')}</span></BootstrapTooltip>;
        }
        return text;
    }

    return getSmallText(text);
}

export default TextTruncateWithTooltip;