import {bodyType, method} from "../constants";

export const getMethodLabel = (value) => {
    switch (value){
        case method.GET_METHOD:
            return "GET";
        case method.POST_METHOD:
            return "POST";
        case method.PUT_METHOD:
            return "PUT";
        case method.PATCH_METHOD:
            return "PATCH";
        case method.DELETE_METHOD:
            return "DELETE";
        default:
            return "N/A";
    }
};

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const isXmlString = (str) => {
    try {
        const xmlDoc = new DOMParser().parseFromString(str, 'application/xml');
        return !xmlDoc.getElementsByTagName('parsererror').length;
    }catch (e){
        return false;
    }
}

export const encodeQueryData = (data) => {
    const ret = [];
    for (let d in data){
        ret.push(d + "=" + data[d]);
    }
    return (ret[0]?"?":"")+ret.join("&");
}

export const prettifyXml = (sourceXml) => {
    const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    if (isXmlString(sourceXml)){
        const xsltDoc = new DOMParser().parseFromString([
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform" version="1.1">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">',
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');

        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsltDoc);
        const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        return new XMLSerializer().serializeToString(resultDoc);
    }
};

export const prettifyJson = (sourceJson) => {
    if (isJsonString(sourceJson)){
        return syntaxHighlight(JSON.stringify(JSON.parse(sourceJson), null, 2));
    }
}

export const sortOutBodyContentType = (string) => {
    let contentType = "text/plain";
    if (isJsonString(string)){
        contentType = "application/json";
    }else if (isXmlString(string)){
        contentType = "application/xml";
    }
    return contentType;
};

export const getBodyContentType = (type) => {
    switch (type){
        case bodyType.TEXT:
            return "text/plain";
        case bodyType.JSON:
            return "application/json";
        case bodyType.XML:
            return "application/xml";
        case bodyType.FORM_DATA:
            return "application/x-www-form-urlencoded";
        default:
            return "";
    }
};

export const syntaxHighlight = (json) => {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}