/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getSingleContactTimelineApi } from "../../../api/marketingApi";
//moved to CDN
// import '../marketing.css';
import './campaign-stats.css';
import GlobalModal from "../../globals/Modal/GlobalModal";

import ContactTimelineItem from "./ContactTimeLineItem";

const ContactTimeline = props => {
    const [loading, setLoading] = useState(false)
    const [timeline, setTimeline] = useState([])
    
    useEffect(() => {
        if(props.open){
            getContactTimeline()
        }
    }, [props.open])

    const getContactTimeline = () => {
        if(loading){
            return
        }
        setLoading(true)
        let formData = {
            contactId: props.contactId, 
            campaignId: props.campaignId
        }
        getSingleContactTimelineApi(formData).then(res => {
            let response = res.data;
            if (response.status === 'success') {
                setTimeline(response.data)
                setLoading(false)
            } else if (response.status == 'error') {
                if(window.showNotification){
                    window.showNotification("ERROR", response.html);
                }
            }
        })
    }



    // const renderTimeline = () => {
    //     let view = [];
    //     // return;
    //     if(timeline.length === 0){
    //         return(
    //             <div>No campaign setting found!</div>
    //         )
    //     }
    //     timeline.map((item, index) => {
    //         view.push(
    //             <div key={index} className={"campaign-contacts-stats-block"}>
    //                 <span>{getIcon(item.message_type)}</span>
    //                 <span><ArrowForwardIcon fontSize="small" /> </span>
    //                 {item.type == 4 && 
    //                     <>
    //                     {item.greeting_option == 1 ?
    //                         <span><i className="fa fa-birthday-cake text-info"></i> Birthday</span> :
    //                         <span><i className="fa fa-birthday-cake text-info"></i> Anniversary</span>
    //                     }
    //                     <span><ArrowForwardIcon fontSize="small" /> </span>
    //                     <span><QueryBuilderIcon fontSize="small" /> Time: </span> {item.hour}
    //                     </>                        
    //                 }

    //                 {item.type == 3 && 
    //                     <>
    //                     <span><CalendarTodayIcon fontSize="small" />Date: </span> {item.fixed_date}
    //                     <span><ArrowForwardIcon fontSize="small" /></span>
    //                     <span><QueryBuilderIcon fontSize="small" /> Time: </span> {item.hour}
    //                     </>                        
    //                 }

    //                 {item.type == 5 && 
    //                     <>
    //                     <span><RefreshIcon fontSize="small"  /> Recurring Day: </span> {item.day}
    //                     <span><ArrowForwardIcon fontSize="small" /></span>
    //                     <span><QueryBuilderIcon fontSize="small" /> Time: </span> {item.hour}
    //                     </>                        
    //                 }

    //                 {(item.type != 5 && item.type != 3 && item.type != 4) && 
    //                     <>
    //                     <span><CalendarTodayIcon fontSize="small"  /> Day: </span> {item.day}
    //                     <span><ArrowForwardIcon fontSize="small" /></span>
    //                     <span><QueryBuilderIcon fontSize="small" /> Time: </span> {item.hour}
    //                     </>                        
    //                 }

    //                 {item.campaign_queue_contact.length > 0 &&
    //                     item.campaign_queue_contact.map((itemEach, indexEach) => {
    //                         return(
    //                             <div key={indexEach}>
    //                                 <QueryBuilderIcon fontSize="small" /> QUEUE at {itemEach.schedule_time}
    //                                 {itemEach.created_at != itemEach.updated_at &&
    //                                 <>
    //                                 <ArrowForwardIcon fontSize="small" />
    //                                 Rescheduled <QueryBuilderIcon fontSize="small" />
    //                                 <ArrowForwardIcon fontSize="small" /> Reason: {itemEach.error_message}
    //                                 </>
    //                                 }
    //                             </div>
    //                         )
    //                     })
    //                 }

    //                 {item.campaign_sent_response.length > 0 &&
    //                     <>
    //                     {item.campaign_sent_response.map((itemEach, indexEach) => {
    //                         return(
    //                             <div key={indexEach}>
    //                                 <QueryBuilderIcon fontSize="small" />
    //                                 SENT at {window.globalTimezoneConversionToDifferentTimezone(itemEach.schedule_time, 'UTC', props.timezone, 'MM/DD/yyyy hh:mm:ss A')}
    //                                 <ArrowForwardIcon fontSize="small" />
    //                                 {itemEach.status == 0 &&
    //                                     <span className="text-warning">Pending <CachedIcon fontSize="small" /></span>
    //                                 }
    //                                 {itemEach.status == 3 &&
    //                                     <span className={"text-green"}>Sent <SendIcon fontSize="small" /></span>
    //                                 }
    //                                 {itemEach.status == 1 &&
    //                                     <span className={"text-green"}>Success <CheckIcon fontSize="small" /></span>
    //                                 }
    //                                 {itemEach.status == 5 &&
    //                                     <span className={"text-green"}>Accepted <DoneAllIcon fontSize="small" /></span>
    //                                 }
    //                                 {itemEach.status == 2 &&
    //                                     <span className={"text-red"}>Failed <ErrorIcon fontSize="small" /></span>
    //                                 }
    //                                 {itemEach.status == 4 &&
    //                                     <span className={"text-red"}>Undelivered <SmsFailedIcon fontSize="small" /></span>
    //                                 }
    //                                 {(itemEach.status != 0 && itemEach.status != 1 && itemEach.status != 2 && 
    //                                     itemEach.status != 3 && itemEach.status != 4 && itemEach.status != 5) &&
    //                                     <span><CachedIcon fontSize="small" /> No Action</span>
    //                                 }
    //                             </div>
    //                         )
    //                     })}
    //                     </>
    //                 }

    //                 {(item.campaign_queue_contact.length === 0 && item.campaign_sent_response.length === 0) &&
    //                     <p className="text-warning">Nothing is scheduled</p>
    //                 }
    //             </div>
    //         );
    //     })
    //     return view;
    // }


    const renderTimelineV2 = () => {
        let view = [];
        if(timeline.length === 0){
            return(
                <div>No campaign setting found!</div>
            )
        }
        timeline.map((item, index) => {
            view.push(
               <ContactTimelineItem item={item} index={index} timezone={props.timezone} key={index}/>
            );
        })
        return view;
    }

    return (
        <GlobalModal
            open={props.open} 
            onClose={props.onClose}
            title="Contact Timeline" 
            className="global-small-modal"
            hideFooter={true}        
        >
            <div className="single___contact__timeline___container">
                {loading ?
                    <div className="single___contact__timeline__loader">Please wait...</div> :
                    renderTimelineV2()
                }
            </div>
        </GlobalModal>
    );
}
export default ContactTimeline;