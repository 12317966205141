import React, {useEffect, useRef} from "react";
import {bodyType, method, methodLabel, tabBodyTypeMap} from "../../constants";
import {useSWHKContext} from "../../reducers/SWHKProvider";
import {handleState} from "../../reducers/Actions";
import {ArrowDownIcon} from "../../icons";
import GlobalCustomFieldGrouped from "../GlobalCustomFieldGrouped/Index";
import CustomMenuPype from "../CustomMenu/CustomMenuPype";
import Styles from "./Styles.module.css";
import {encodeQueryData} from "../../helpers";

const App = ({callback,data,errors}) => {
    const {state, dispatch} = useSWHKContext();
    const urlRawInputRef = useRef(null);
    const handleRequestUrl = (value) => {
        // ?name=joe&age=25
        if (value.includes("?")){
            // ["", "name=joe&age=25"]
            const splitUrl = value.split("?");
            // ""
            const url = splitUrl[0];

            // "name=joe&age=25"
            const query = splitUrl[1];

            dispatch(handleState({
                url: url,
                urlRaw: ["",query].join("?"),
                queryParamsString: query
            }));

            callback({
                url: url
            });

            // ["name=joe", "age=25"]
            const splitParams = query.split("&");

            if (splitParams){
                let data = [...state.params];
                splitParams.forEach((param, index)=>{
                    const splitParam = param.split("=");
                    data[index] = {
                        key: splitParam[0] ? splitParam[0] : "",
                        value: splitParam[1] ? splitParam[1] : ""
                    };

                    if(index === (data.length-1)){
                        let object = {
                            key: "",
                            value: ""
                        };
                        data = [...data, object];
                    }

                    dispatch(handleState({
                        params: data
                    }));

                    callback({
                        params: data,
                        url: url
                    });
                });
            }
        }else {
            dispatch(handleState({
                params: [
                    {
                        key: "",
                        value: ""
                    }
                ],
                url: value
            }));
            callback({
                params: [
                    {
                        key: "",
                        value: ""
                    }
                ],
                url: value
            });
        }

        dispatch(handleState({
            urlRaw: value
        }));
    };

    useEffect(()=>{
        if (data){
            let changeState = {
                method: data.method,
                url: data.url,
                title: data.title,
            };

            if (data.params){
                let objectPair = {};
                let urlRaw = "";
                let queryParamsString = "";

                data.params.forEach((param)=>{
                    if (param.key !== "" || param.value !== ""){
                        let obj = {
                            [param.key]: param.value
                        };
                        objectPair = { ...objectPair, ...obj };
                    }
                });

                let queryParam = encodeQueryData(objectPair);

                const splitUrl = state.urlRaw.split("?");
                urlRaw = [splitUrl[0], queryParam].join("");
                queryParamsString = queryParam;

                let object = {
                    key: "",
                    value: ""
                };
                let newParams = [...data.params, object];

                changeState = {
                    ...changeState,
                    params: newParams,
                    urlRaw: changeState.url + urlRaw,
                    queryParamsString: queryParamsString
                };
            }

            if (data.headers){
                let object = {
                    key: "",
                    value: ""
                };
                let newHeaders = [...data.headers, object];
                changeState = { ...changeState, headers: newHeaders };
            }

            if (data.authType !== undefined && data.authType !== null){
                changeState = { ...changeState, authType: data.authType };
            }

            if (data.bodyType !== undefined && data.bodyType !== null){

                if (data.bodyType === bodyType.FORM_DATA && data.bodyData){
                    let parsedBodyData = data.bodyData;

                    if (typeof parsedBodyData === "string"){
                        parsedBodyData = JSON.parse(parsedBodyData);
                    }

                    if (parsedBodyData){
                        let object = {
                            key: "",
                            value: ""
                        };
                        let newBodyData = [...parsedBodyData, object];

                        changeState = { ...changeState, formData: newBodyData, bodyData: newBodyData };
                    }
                }else if (data.bodyType !== bodyType.NONE){
                    changeState = { ...changeState, rawBodyData: data.bodyData, bodyData: data.bodyData };
                }
                changeState = { ...changeState, bodyType: data.bodyType, currentBodyTab: tabBodyTypeMap[data.bodyType] };
            }

            dispatch(handleState(changeState));
            callback(changeState);
        }
    },[data]);

    return(
        <div className={Styles.wbh_url_handler}>
            <CustomMenuPype
                wrapperClass="mlvc_row_per_page_dropdown"
                popup= {{
                    placement: "left",
                    list: [
                        {label: "GET", value: method.GET_METHOD},
                        {label: "POST", value: method.POST_METHOD},
                        {label: "PUT", value: method.PUT_METHOD},
                        {label: "PATCH", value: method.PATCH_METHOD},
                        {label: "DELETE", value: method.DELETE_METHOD}
                    ],
                    onClick: (item) => {
                        dispatch(handleState({
                            method: item.value
                        }));
                        callback({
                            method: item.value
                        });
                    },
                    height: "200px",
                    width: "120px",
                    listStyle: {
                        justifyContent: "left"
                    }
                }}
                label= {{
                    renderCustom: (
                        <div className={Styles.wbh_http_method_select_field}>
                            <span className={Styles.clpb_label}>{methodLabel[state.method]}</span>
                            <span className={Styles.clpb_icon}><ArrowDownIcon/></span>
                        </div>
                    )
                }}
            />
            <div className={Styles.wbh_url_field}>
                <div className={Styles.wbh_url_input_wrapper}>
                    <input className={Styles.wbh_url_input} name="raw-url" ref={urlRawInputRef} onChange={(e)=>{ handleRequestUrl(e.target.value) }} value={state.urlRaw} placeholder="Enter webhook URL"/>
                    {
                        errors && errors.url &&
                        <span className={Styles.error_message}>
                            {errors.url}
                        </span>
                    }
                </div>
                <GlobalCustomFieldGrouped className={Styles.wbh_global_personalized} handleSelect={(placeholderValue)=>{
                    if (urlRawInputRef && urlRawInputRef.current){
                        let start = urlRawInputRef.current.selectionStart;
                        let end = urlRawInputRef.current.selectionEnd;
                        let text = urlRawInputRef.current.value;
                        let before = text.substring(0, start);
                        let after = text.substring(end, text.length);
                        let newText = (before + placeholderValue + after);

                        handleRequestUrl(newText);

                        setTimeout(() => {
                            urlRawInputRef.current.selectionStart = urlRawInputRef.current.selectionEnd = start + placeholderValue.length;
                            urlRawInputRef.current.focus();
                        }, 500);
                    }
                }} labelText={"Personalized"}/>
            </div>
        </div>
    );
}

export default App;