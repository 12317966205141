import React, { useEffect, useState, useRef } from "react";
import Popover from "@material-ui/core/Popover";
import {Icons} from "./Icons";
import TextTruncateWithTooltip from "../CampaignDetails/TextTruncateWithTooltip";
import BootstrapTooltip from "../../Common/BootstrapTooltip";

const CustomPopupMenu = ({
    list,
    handleClick,
    placeholder,
    selected,
    selectedId,
    reorganized = false,
    valueField,
    labelField,
    textLength,
    height
}) => {
    const [anchorActionMenu, setAnchorActionMenu] = useState(null);
    const [open, setOpen] = useState(false);
    const dropDownLabel = useRef(null);
    const [itemData, setItemData] = useState([]);
    const [fullItemData, setFullItemData] = useState([]);

    useEffect(() => {
        if(reorganized){
            let organized_data = [];
            list.forEach((item) => {
                organized_data.push({
                    value: item[valueField], 
                    label: item[labelField]
                });
            });
            setItemData(organized_data);
            setFullItemData(organized_data);
        }else{
            let old_data = [...list];
            setItemData(old_data);
            setFullItemData(old_data);
        }
    }, [list]);

    const handleMenuLabel = (e) => {
        setAnchorActionMenu(e.currentTarget);
        setOpen(true);
    }

    const handleClose = () => {
        setAnchorActionMenu(null);
        setOpen(false);
    }

    const renderLabel = () => {
        return (
            <div style={{height: height}} className={`label_class_wrapper`} onClick={(e) => handleMenuLabel(e)} ref={dropDownLabel}>
                <div className="list___item__iv2">
                    {getSelectedList()}
                </div>
                <span>{Icons.downSingleArrow}</span>
            </div>
        );
    }

    const getSelectedList = () => {
        if(selected.length === 1){
            return <TextTruncateWithTooltip textLength={textLength} text={selected[0].label}/>;
        }

        if(selected.length > 1){
            let view = [];
            view.push(<span className="avater__name__sub__default__list"><TextTruncateWithTooltip textLength={textLength} text={selected[0].label}/></span>);
            view.push(<span className="avater__name__sub__default__list"><BootstrapTooltip placement={"top-start"} arrow title={
                <ul>
                    {
                        selected.map((selectEmail, index)=>(
                            index !== 0 ? <li>{selectEmail.label}</li> :  ""
                        ))
                    }
                </ul>
            }><span>+{selected.length-1} more</span></BootstrapTooltip></span>);
            return view;
        }
        return placeholder;
    }

    const getSmallText = (text) => {
        if(text.length > textLength){
            return (text.substring(0, (textLength-3))+"...");
        }
        return text;
    }

    const renderItem = () => {
        let view = [];
        itemData.forEach((item, index) => {
            let remove = itemExist(selectedId, item.value)
            view.push(
                <div className="pgwptc__group__each__item-wrp">
                    <div className="pgwptc__group__each__item-ovr" key={index} onClick={() => {handleClick(item, remove);}}>
                        {remove ? Icons.check : Icons.unCheck}
                        <span className="pgwptc__gei_title">
                            <TextTruncateWithTooltip textLength={textLength} text={item.label}/>
                    </span>
                    </div>
                </div>
            )
        });
        return view;
    }

    const itemExist = (arrayList, item) => {
        return arrayList.indexOf(item) !== -1;

    }

    const searchEmail = (value) => {
        if(value === ""){
            setItemData(fullItemData)
            return
        }
        let filter = itemData.filter(item => {
            return item.label.toLowerCase().includes(value.toLowerCase());
        })
        setItemData(filter)
    }


    return (
        <div className={`custom_menu_option_container`} onClick={(e) => e.stopPropagation()}>
            {renderLabel()}

            <Popover
                id={"pype_g_u_l_c_dd_w_id"}
                open={open}
                anchorEl={anchorActionMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        boxShadow: "0px 2px 3px rgba(0,0,0,0.5)",
                        width: "300px",
                        maxHeight: "400px"
                    }
                }}
            >
                <div className="p_g_u_l_c_d_main_w awesome__scroll_bar">
                    <input 
                        className="search__input"
                        placeholder="Search Email"
                        onChange={(event) => searchEmail(event.target.value)}
                    />
                    {list.length === 0 &&
                        <div className="empty__containner">
                            <span>No data found !</span>
                        </div>
                    }
                    {list.length > 0 &&
                        <div className="pype_g_u_l_c_dd">
                            {renderItem()}
                        </div>
                    }
                </div>
            </Popover>
        </div>
    )
}
export default CustomPopupMenu;