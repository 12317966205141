/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import Utils from "../../../helpers/Utils";
import { fetchLeadSourceApi, getCustomFields, getZipCodeByPlace } from "../../../api/marketingApi";
import { CustomFieldType } from "../../../constants/CoreConstants";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Helper from "../../../helpers/Helper";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { DropdownHelper } from "../../Common/DropdownHelper";

import { IconList } from "../../../constants/IconList";
import DatePicker from "react-datepicker";
import MaskedInput from 'react-text-mask'

const GOOGLE_API_KEY = 'AIzaSyBKff2kWfzq_aG5qSydkBx8neLqYhY5VnE';

const App = props => {

    const validEmailFieldRef = useRef(null)
    const validNumberFieldRef = useRef(null)

    // const numberInputField = useRef(null)

    const [contactForm, setContactForm] = useState({
        selectedSource: '',
        first_name: '',
        last_name: '',
        number: '',
        email: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        url: '',
        deal_value: '',
        birth_day: '',
        anniversary_date: ''
    })

    const [firstLoading, setFirstLoading] = useState(true)
    const [leadSources, setLeadSources] = useState('')
    // validation flags
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidNumber, setInvalidNumber] = useState(false)

    // for custom field
    const [showCustomFields, setShowCustomFields] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [customFieldsData, setCustomFieldsData] = useState([])

    const [renderUpdate, setRenderUpdate] = useState(false)
    const [value, setValue] = useState(null);


    /* from parent add contact start */
    // for custom field
    const [birthDate, setBirthDate] = useState('');
    const [anniversaryDate, setAnniversaryDate] = useState('');
    // useEffect(() => {
    //     if(firstLoading){
    //         setFirstLoading(false)
    //         fetchSourceInfos();
    //         fetchUserCustomFields()
    //     }
        
    // },[])

    useEffect(() => {
        let tempCustomData = [...customFields];
        setCustomFields(tempCustomData)
        
    },[customFieldsData])


    const fetchSourceInfos=async()=>{
        if(props.storedSourceList !==null){
            setLeadSources(props.storedSourceList)

        }else if(props.sourceList !== undefined && props.sourceList != null){
            setLeadSources(props.sourceList)

        }
        else{
            await props.fetchUserSourceList({},(callback)=>{

                setLeadSources(callback)
            })
        }

    }

    const fetchUserCustomFields = async() => {
        if(props.storedCustomField !==null) {
        	setCustomFields(props.storedCustomField)

        } else {
            await props.fetchUserCustomFields({},(callback)=>{
                callback = callback.data

                let customFieldValues = []
					callback.forEach((row, index) => {
							customFieldValues.push(null)
						});
                    setCustomFields(callback)
                    setCustomFieldsData(customFieldValues)
                })
        }
    }

    const handleAddressNew = async(event) => {   
        let placeId = event.place_id !== undefined && event.place_id != null? event.place_id : null;
        let zip = ''
        let road = ''
        let streetNumber = ''
        let state = ''
        let city= ''
        let country= ''

        if(placeId != null) {
            try{
                await getZipCodeByPlace({placeId: placeId,googleAPIKey:GOOGLE_API_KEY})
                .then(res=>{

                    zip = res?.data?.zip
                    if(res.data.data.result.address_components !== undefined && res.data.data.result.address_components != null && Array.isArray(res.data.data.result.address_components)){
                        res.data.data.result.address_components.map((item,index) => {
                            if (item.types[0] === 'street_number') {
                                streetNumber = item.long_name; 
                            } else if (item.types[0] === 'route') {
                                road = item.long_name
                            } else if (item.types[0] === 'administrative_area_level_1') {
                                state = item.short_name
                            } else if (item.types[0] === 'administrative_area_level_2') {
                                city = item.long_name
                            } else if (item.types[0] === 'country') {
                                country = item.long_name
                            } else if (item.types[0] === 'postal_code') {
                                zip = item.long_name
                            }
                            setContactForm({...contactForm, address: streetNumber+' '+road, city:city, state:state,country:country, zip: zip})
                        })

                    }else{
                        updateContactInfo(event, zip)
                    }
                })
            }
            catch(e){
                updateContactInfo(event, '')
                console.log(e)
            }
        }
    }

    const updateContactInfoNew=(event, zip)=>{
        let fullAddressArr = event.description.split(',');
        let address = (fullAddressArr[0] !== undefined) ? fullAddressArr[0].trim() : '';
        let city = (fullAddressArr[1] !== undefined) ? fullAddressArr[1].trim() : '';
        let fullState = (fullAddressArr[2] !== undefined) ? fullAddressArr[2].trim() : '';
        let country = (fullAddressArr[3] !== undefined) ? fullAddressArr[3].trim() : '';

        let stateArr = fullState.split(' ');
        let state = (stateArr[0] !== undefined) ? stateArr[0].trim() : '';

        setContactForm({...contactForm, address: address, city:city, state:state,country:country, zip: zip})
    }
    /* end */

    useEffect(() => {
        if (firstLoading) {
            setFirstLoading(false)

            // for lead source
            fetchLeadSourceApi().then((response) => {
                setLeadSources(response.data.leadSources)
                setContactForm({ ...contactForm, selectedSource: response.data.selectedSource ? response.data.selectedSource : '' })
            })

            // for custom fields

            window.globalCustomFieldWithPersonalized({
                get_custom_data: true,
                get_personalized_data: false,
                field_name: ['*'],
                }).then(res => {
                let customFieldDatas = res.data.custom_field;
                setCustomFields(customFieldDatas);
                })

            // getCustomFields({for_custom_field_update:true})
            //     .then(res => {
            //         let customFieldValues = []
            //         res.data.data.forEach((row, index) => {
            //             customFieldValues.push(null)
            //         });
            //         setCustomFields(res.data.data)
            //         setCustomFieldsData(customFieldValues)
            //     })
        }

    })

    const handleSelectChange = (value) => {
        setContactForm({ ...contactForm, selectedSource: value})
    }

    const handleInput = (element, field) => {
        let value = element.target.value
        if(field === 'number'){
            if (!Utils.checkLetterExistInText(value) || value === '') {
                if (value.length > 0 && value[0] !== '1') {
                    value= 1+value;
                }
                setContactForm({...contactForm, 'number': value})
            } else {    
                try{
                    value = value.replace(/[^0-9]+/g, '')
                    if(value.length > 0 && value[0] !== '1'){
                        value= 1+value;
                    }
                    setContactForm({...contactForm, 'number': value})
                }
                catch(e){
                    console.log(e)
                }
                
            }
            // setContactForm({...contactForm, 'number': value})
        } else if (field === 'deal_value') {
            if (Utils.checkOnlyNumber(value)) {
                setContactForm({ ...contactForm, 'deal_value': value })
            }
        } else {
            setContactForm({...contactForm, [field] : value})
        }
    }

    const handleOnBlur = (value, field) => {
        if (field == 'number' && value != '') {
            if (!Utils.validateMobileNumber(value)) {
                setInvalidNumber(true)
            } else {
                setInvalidNumber(false)
            }
        } else if (field == 'email' && value != '') {
            if (!Utils.validateEmail(value)) {
                setInvalidEmail(true)
            } else {
                setInvalidEmail(false)
            }
        } else {
            setInvalidNumber(false)
            setInvalidEmail(false)
        }
    }

    const handleCustomFieldBody = () => {
        let body = document.getElementById("add_contact_custom_field_collapsible_body")
        if (showCustomFields) {
            body.style.display = 'none'
        } else {
            body.style.display = 'block'
        }
        setShowCustomFields(!showCustomFields)
    }

    const handleCustomFieldChange = (value, index_field, id, maxLength = null) => {
        if (maxLength != null & value.length > maxLength) {
            return false
        }

        let previousData = customFieldsData
        if (value == '') {
            previousData[index_field] = null
        } else {
            previousData[index_field] = {
                user_custom_field_id: id,
                value: value
            }
        }
        setCustomFieldsData(previousData)
        setRenderUpdate(!renderUpdate)
    }

    const handleSubmit = () => {
        // check validation
        // replace number
        if (contactForm.number !== "") {
            contactForm.number = contactForm.number.replace(/[^0-9]/g, "");
        }
        if (Helper.isEmpty(contactForm.number) && Helper.isEmpty(contactForm.email)) {
          window.showNotification("ERROR", "Number or email one is required !")
            try {
                validNumberFieldRef.current.scrollIntoView({ behavior: "smooth" })
            } catch (e) { console.log('scroll off') }
            return
        }
        else if (!Helper.isEmpty(contactForm.number) && !Utils.validateMobileNumber(contactForm.number)) {
            setInvalidNumber(true)
            try {
                validNumberFieldRef.current.scrollIntoView({ behavior: "smooth" })
            } catch (e) { console.log('scroll off') }
            return
        } else if (!Helper.isEmpty(contactForm.email) && !Utils.validateEmail(contactForm.email)) {
            setInvalidEmail(true)
            try {
                validEmailFieldRef.current.scrollIntoView({ behavior: "smooth" })
            } catch (e) { console.log('scroll off') }
            return
        }
        if (contactForm.deal_value == '') {
            contactForm['deal_value'] = 0;
        }
        props.handleAddNewContact(contactForm, customFieldsData)
    }

    window.campaignAddSingleContact = () => {
        handleSubmit()
    }

    const handleAddress = async (event) => {

        let placeId = event.place_id !== undefined && event.place_id != null ? event.place_id : null;
        let zip = ''
        let road = ''
        let streetNumber = ''
        let state = ''
        let city = ''
        let country = ''
        if (placeId != null) {
            try {
                await getZipCodeByPlace({ placeId: placeId, googleAPIKey: GOOGLE_API_KEY })
                    .then(res => {

                        zip = res?.data?.zip
                        if (res.data.data.result.address_components !== undefined && res.data.data.result.address_components != null && Array.isArray(res.data.data.result.address_components)) {
                            res.data.data.result.address_components.map((item, index) => {
                                if (item.types[0] === 'street_number') {
                                    streetNumber = item.long_name;
                                } else if (item.types[0] === 'route') {
                                    road = item.long_name
                                } else if (item.types[0] === 'administrative_area_level_1') {
                                    state = item.short_name
                                } else if (item.types[0] === 'administrative_area_level_2') {
                                    city = item.long_name
                                } else if (item.types[0] === 'country') {
                                    country = item.long_name
                                } else if (item.types[0] === 'postal_code') {
                                    zip = item.long_name
                                }
                                setContactForm({ ...contactForm, address: streetNumber + ' ' + road, city: city, state: state, country: country, zip: zip })

                            })

                        } else {
                            updateContactInfo(event, zip)
                        }
                    })
            }
            catch (e) {
                updateContactInfo(event, '')
                console.log(e)
            }
        }
    }

    const updateContactInfo = (event, zip) => {
        
        let fullAddressArr = event.description.split(',');
        let address = (fullAddressArr[0] !== undefined) ? fullAddressArr[0].trim() : '';
        let city = (fullAddressArr[1] !== undefined) ? fullAddressArr[1].trim() : '';
        let fullState = (fullAddressArr[2] !== undefined) ? fullAddressArr[2].trim() : '';
        let country = (fullAddressArr[3] !== undefined) ? fullAddressArr[3].trim() : '';

        let stateArr = fullState.split(' ');
        let state = (stateArr[0] !== undefined) ? stateArr[0].trim() : '';

        setContactForm({ ...contactForm, address: address, city: city, state: state, country: country, zip: zip })
    }

    return (
        <>
            <div className="add-contact-content-body awesome__scroll_bar">
				{/* <!-- contact list top --> */}
				<div className="contact-list-top">
					<div className="row">
						<form className="col s12">
							{/* <!-- single row --> */}
                            {leadSources != null &&
							<div className="margin-none-row row add-contact-list">
								<div className="col s12">
									<div className="contact-list-input custom-input custom-select-arrow col s12">
										<label for="lead_name">Lead Source:</label>
										<div className="select-wrapper">
										<DropdownHelper
                                            datalist={leadSources}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Source"
                                            className="second__step__area__select"
                                            mapping={{ title: "source_title" }}
                                            menuItemClassName=""
                                            updateSelectedData={handleSelectChange}
                                            selectedValue={contactForm.selectedSource}
                                            dropdownHeader={'sourceChange'}
                                            parentDivClassName="ppc__lead_source add-contact-new-wrapper"
                                        />
										
                                        </div>
										<div className="custom-select">
											{IconList.dropdownIcon}
										</div>
									</div>
								</div>
							</div>
                            }
							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="first_name" className="active">First name:</label>
										<input type="text" name="" id="first_name" placeholder="Enter First Name"  onChange={(e) => handleInput(e, 'first_name')}/>
										<div className="custom-select">
											{IconList.peopleIcon}
										</div>
									</div>
								</div>
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="last_name" className="active">Last name:</label>
										<input type="text" name="" id="last_name" placeholder="Enter Last name" onChange={(e) => handleInput(e, 'last_name')}/>
										<div className="custom-select">
                                            {IconList.peopleIcon}

										</div>
									</div>
								</div>
							</div>
							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label className="active" for="number-input-field">Number</label>
										{/* <input type="text" name="" id="number-input-field" placeholder="Enter number" onChange={(e) => handleInput(e, 'number')} 
										onBlur={() => handleOnBlur(contactForm.number, 'number')} value={contactForm.number}/> */}
										<div className="custom-select">
											<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="16" cy="16" r="16" fill="#E5E8EF"></circle>
												</svg>
                                                <span className="phone-svg">
                                                {IconList.phoneSVG}
                                            </span>
											

										</div>

                                        <MaskedInput
                                            mask={['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            placeholder="Enter a number"
                                            guide={true}
                                            keepCharPositions={true}
                                            id="number-input-field"
                                            onChange={(e) => handleInput(e, 'number')}
                                            value={contactForm.number}
                                            // onPaste={(event) => {
                                            //     // event.preventDefault()
                                            //     let paste = (event.clipboardData || window.clipboardData).getData('text');
                                            //     if(paste.length < 10){
                                            //         if(!(paste.charAt(0) == "+" || paste.charAt(0) == "1")){
                                            //             setContactForm({...contactForm, 'number': "1"+paste})
                                            //             numberInputField.current.onChange(event, "number");
                                            //             return
                                            //         }
                                            //     }else{
                                            //         setContactForm({...contactForm, 'number': paste})
                                            //     }
                                            // }}
                                            // value={contactForm.number}
                                            // ref={numberInputField}
                                        />

									</div>
									                   {invalidNumber &&
                            <span className="ppc__notValid">Number not valid! Please provide a valid number</span>
                        }
								</div>
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label className="active" for="email-input-field">Email:</label>
										<input type="text" name="" id="email-input-field" placeholder="Enter Email" onChange={(e) => handleInput(e, 'email')} onBlur={() => handleOnBlur(contactForm.email, 'email')}/>
										<div className="custom-select">
											{IconList.addContactCircle}
                                                <span className="phone-svg">
                                                    {IconList.emailSVG}
                                                </span>
											

										</div>
									</div>
									{invalidEmail &&
                            <span className="ppc__notValid">Email not valid! Please provide a valid email</span>
                        }
								</div>
							</div>

							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12">
									<div className="contact-list-input custom-input col s12">
										<label for="Company" className="active">Company Name :</label>
										<input type="text" name="" id="Company" placeholder="Enter Company Name" onChange={(e) => handleInput(e, 'company_name')}/>
										<div className="custom-select">
											{IconList.addContactCircle}
                                                <span className="phone-svg">
                                                    {IconList.companySVG}
                                            </span>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- single row --> */}
							<div className="margin-none-row row add-auto-complete">
								<div className="col s12">
									<div className="contact-list-input custom-input col s12">
										<label for="Address" className="active">Address Search:</label>
                                        <GooglePlacesAutocomplete
                                            apiKey={GOOGLE_API_KEY}
                                            autoFocus={false}
                                            autocompletionRequest={{
                                                componentRestrictions: {
                                                    country: ['us', 'ca'],
                                                },
                                            }}
                                            selectProps={{
                                                value,
                                                onChange: (e)=>{ handleAddress(e.value)},
                                                placeholder:'Search Location'

                                            }}
                                        />
										<div className="custom-select">
											{IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.addressSVG}
                                            </span>
										</div>
									</div>
								</div>
							</div>

							<div className="margin-none-row row">
								<div className="col s12">
									<div className="contact-list-input custom-input col s12">
										<label for="Address" className="active">Address:</label>
										<input type="text" name="" id="Address" placeholder="Enter Street Address" 
                                        value={contactForm.address} onChange={(e) => handleInput(e, 'address')} />
										<div className="custom-select">
											{IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.addressSVG}
                                            </span>
										</div>
									</div>
								</div>
							</div> 


							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="City" className="active">City:</label>
										<input type="text" name="" id="City" placeholder="Enter City" 
                                        value={contactForm.city} onChange={(e) => handleInput(e, 'city')}/>
										<div className="custom-select">
											{IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.citySVG}
                                            </span>
										</div>
									</div>
								</div>
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="City" className="active">State:</label>
										<input type="text" name="" id="State" placeholder="Enter State" 
                                        value={contactForm.state}
                                        onChange={(e) => handleInput(e, 'state')}/>
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.stateSVG}
                                            </span>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="Country" className="active">Country:</label>
										<input type="text" name="" id="Country" placeholder="Enter Country" 
                                        onChange={(e) => handleInput(e, 'country')}
                                        value={contactForm.country}
                                        />
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.countrySVG}
                                            </span>
										</div>
									</div>
								</div>
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="Zip" className="active">Zip code:</label>
										<input type="text" name="" id="Zip" placeholder="Enter Zip code" 
                                        value={contactForm.zip}
                                        onChange={(e) => handleInput(e, 'zip')}/>
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.zipSVG}
                                            </span>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="URL" className="active">URL:</label>
										<input type="text" name="" id="URL" placeholder="Enter URL" onChange={(e) => handleInput(e, 'url')}/>
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.urlSVG}
                                            </span>
										</div>
									</div>
								</div>
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="Deal" className="active">Deal Value:</label>
										<input type="number" name="" id="Deal" placeholder="Enter Deal Value" onChange={(e) => handleInput(e, 'deal_value')} />
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.dealSVG}
                                            </span>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- single row --> */}
							<div className="margin-none-row row">
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="Date" className="active">Birth Date:</label>
                                         <DatePicker selected={birthDate} onChange={(date) => setBirthDate(date)} />
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg2 phone-svg">
                                                {IconList.birthdateSVG}
                                            </span>
										</div>
									</div>
								</div>
								<div className="col s12 m12 l6 xl6">
									<div className="contact-list-input custom-input col s12">
										<label for="Anniversary">Anniversary Date:</label>
                                        <DatePicker selected={anniversaryDate} onChange={(date) => setAnniversaryDate(date)} />
										<div className="custom-select">
                                            {IconList.addContactCircle}
                                            <span className="phone-svg">
                                                {IconList.anniversarySVG}
                                            </span>
										</div>
									</div>
								</div>
							</div>

						</form>
					</div>
				</div>
				{/* <!-- contact list top --> */}
				{customFields.length > 0 &&

                <div className="contact-list-bottom">
					<div className="custom-fild-row margin-none-row row toggle-class1">
						<div className="col s12">
							<div className="custom-fild-heading col s12" onClick={handleCustomFieldBody}>
								<h4>Custom Field</h4>
                                <span>
                                    {IconList.customFieldDropdownSVG}
                                </span>
							</div>
						</div>
					</div>
                    <div className="collapsible-body custom_field_body oggle-class2 row" id="add_contact_custom_field_collapsible_body">
                        <div className="col s12">
                            {customFields.map((each, index) => {
                                return (renderEachCustomField(each, index, handleCustomFieldChange, customFieldsData));
                            })
                            }
                        </div>
                    </div>
                </div>
                }
			</div>
            
            {/* {submittingContact &&
            <NewLoader loading={submittingContact} onlyLoader={true} size={30} message={""} />
            } */}
        </>
    );
}

const AddSingleContactTab = App;
export default AddSingleContactTab;

export const renderEachCustomField = (data, index, onChange, previousData) => {
    const type_id = data.type_id;
    let _values = (typeof previousData[index] != 'undefined' && previousData[index] != null) ? (typeof previousData[index].value != 'undefined' ? previousData[index].value : '') : '';

    const handleOnChange = (e, self) => {

        if (type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.ZIP_CODE) {
            onChange(e.target.value, index, data.id, data?.max_length)
        }
        else if (type_id == CustomFieldType.CHECKBOX) {
            let previous_value = (typeof previousData[index] != 'undefined' && previousData[index] != null) ? (typeof previousData[index].value != 'undefined' ? previousData[index].value : '') : '',
                new_value = '';
            if (e.target.checked) {
                if (previous_value.trim() == '') {
                    new_value = e.target.value
                }
                else {
                    new_value = previous_value + "," + e.target.value
                }
            }
            else {
                if (previous_value.indexOf(e.target.value) !== -1) {
                    let value_array = previous_value.split(",")
                    value_array.forEach((each_data, each_index) => {
                        if (each_data != e.target.value) {
                            if (new_value == '') {
                                new_value += each_data
                            }
                            else {
                                new_value = new_value + "," + each_data
                            }
                        }
                    });
                }
            }
            onChange(new_value, index, data.id)
        }
        else if (type_id == CustomFieldType.RADIO) {
            onChange(e.target.value, index, data.id)
        }
        else {
            onChange(e.target.value, index, data.id)
        }
    }

    if (type_id == CustomFieldType.TEXT || type_id == CustomFieldType.MULTILINE_TEXT ||
        type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.DATE ||
        type_id == CustomFieldType.PHONE || type_id == CustomFieldType.ZIP_CODE ||
        type_id == CustomFieldType.WEBSITE || type_id == CustomFieldType.CHECKBOX ||
        type_id == CustomFieldType.RADIO || type_id == CustomFieldType.SELECT) {
        return (
            <div className="input-field col s12 m12 custom_input_forms custom_contact_field" key={index}>
                <label for={`custom-field-${index}`} style={{ marginBottom: 40, position: 'relative' }}>{data?.title}</label>

                {(type_id == CustomFieldType.TEXT || type_id == CustomFieldType.PHONE) &&
                    <input type="text" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.MULTILINE_TEXT &&
                    <textarea rows="5" className={'custom-field-textarea'} id={`custom-field-${index}`} placeholder={"Enter " + data?.title}
                        onChange={(e) => handleOnChange(e)}
                    >{previousData?.[index]?.value}</textarea>
                }

                {(type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.ZIP_CODE) &&
                    <input type="number" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.DATE &&
                    <input type="date" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.WEBSITE &&
                    <input type="url" id={`custom-field-${index}`} value={previousData?.[index]?.value} placeholder={"Enter " + data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.CHECKBOX &&
                    <div className="check-box-input-groups">
                        <FormGroup row clsssName="custom_check_box_container">
                            {data.default_values != null &&  data.default_values.map((each, each_index) => {
                                let isChecked = _values.indexOf(each) !== -1 ? true : false
                                return (
                                    <FormControlLabel
                                        clsssName="custom_check_box"
                                        control={
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={handleOnChange}
                                                name={`${index}-${data.personalize_tag}[]`}
                                                value={each}
                                            />
                                        }
                                        label={each}
                                    />
                                )
                            })}
                        </FormGroup>
                    </div>
                }

                {type_id == CustomFieldType.RADIO &&
                    <div className="radio-button-input-groups">
                        <RadioGroup row aria-label={index + "-" + data.personalize_tag} name={index + "-" + data.personalize_tag} value={_values} onChange={handleOnChange}>
                            {data.default_values != null && data.default_values.map((each, each_index) => {
                                return (
                                    <FormControlLabel
                                        value={each}
                                        control={<Radio />}
                                        label={each}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </div>
                }

                {type_id == CustomFieldType.SELECT &&
                    <select className="form-control h-100" placeholder={"Enter " + data.title} name={`add_contact_custom_field${data.personalize_tag}`} onChange={(e) => handleOnChange(e)}>
                        <option value="" key={-1}>{data.title}</option>
                        {
                            data.default_values != null && data.default_values.map((each, each_index) => {
                            return (
                                <option value={each} key={`custom-select-${each_index}`}>{each}</option>
                            )
                        })}
                    </select>
                }

                {/* {(type_id != CustomFieldType.DATE || type_id != CustomFieldType.CHECKBOX) &&
                <span>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path d="M8.6368 10.3234C8.57582 10.3844 8.5348 10.462 8.5174 10.5456L7.90106 13.6284C7.87233 13.7713 7.91766 13.9186 8.02047 14.0224C8.10332 14.1052 8.2149 14.1496 8.32919 14.1496C8.35697 14.1496 8.38586 14.147 8.41459 14.141L11.4964 13.5246C11.5818 13.5071 11.6594 13.4662 11.7196 13.4051L18.6172 6.50745L15.5354 3.42578L8.6368 10.3234Z" fill="#133159"/>
                            <path d="M20.7483 1.29768C19.8984 0.447648 18.5157 0.447648 17.6664 1.29768L16.46 2.50417L19.5418 5.58599L20.7483 4.37934C21.1598 3.96878 21.3865 3.42124 21.3865 2.83891C21.3865 2.25658 21.1598 1.70905 20.7483 1.29768Z" fill="#133159"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                            <rect width="20.9231" height="20.9231" fill="white" transform="translate(0.464844 0.617188)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                } */}
            </div>
        )
    }

    return null
}
