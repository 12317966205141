/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Box, makeStyles, MenuItem, Select, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import GlobalModal from '../../globals/Modal/GlobalModal';
import AppBar from '@material-ui/core/AppBar';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import BroadcastCampaign from './BroadcastCampaign';
import './templateBroadcast.css';
import BroadcastTag from './BroadcastTag';
import { addToBroadcast, fetchCampaignList, fetchMoreCampaignList, fetchTags, getUserEmailList, getUserNumberList } from '../../../api/marketingApi';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../../../actions/marketingAction';
import moment from 'moment';
import { QuickReplyType } from '../../../constants/CoreConstants';
import SendIcon from '@material-ui/icons/Send';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BroadcastContact from './BroadcastContact';
import TimeSetting from '../../Common/TimeSetting';
import { useSelector } from "react-redux";
import Utils from '../../../helpers/Utils';
import MultiEmailSelectDropdown from "../MultiEmailSelectDropdown";


function TabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const tabStr = {
    0 : 'camp',
    1 : 'tag',
    2 : 'contact'
}

const TemplateBroadcastModal = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.marketingReducer.userInfo);

    const [value, setValue] = useState(0);
    const [campaigns,setCampaigns] = useState([]);
    const [countCampaign,setCountCampaign] = useState(0);
    const [tags,setTags] = useState([]);

    const [scheduleType, setScheduleType] = useState(1);
    const [scheduleDate, setScheduleDate] = useState(null);
    const [scheduleTime, setScheduleTime] = useState(null);

    const [froms, setFroms] = useState([]);
    const [selectedFrom,setSelectedFrom] = useState('');

    const [submitSending, setSubmitSending] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedEmailIds, setSelectedEmailIds] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const [fromText, setFromText] = useState('Phone Number')
    useEffect(() => {
        let {type} = props.template;
        fetchTags().then(res => {
            setTags(res.data.tags);
        });
        // fetchCampaignList().then(res => {
        //     setCampaigns(res.data.data);
        // });
        handleLoadMore(0, '');
        if(type == QuickReplyType.SMS || type == QuickReplyType.MMS || type == QuickReplyType.VOICE) {
            getUserNumberList().then(res => {
                let fromArr = res.data.data.map(elem => {
                    return {id:elem,value:elem.virtual_number}
                });
                if(fromArr.length > 0) {
                    setSelectedFrom(fromArr[0].id);
                }
                setFroms(fromArr);  
            })
        } else {
            setFromText('Email')
            getUserEmailList().then(res => {
                let objArr = [];
                for (const [key, value] of Object.entries(res.data.data)) {
                    objArr.push({id:key,value:value});
                }
                if(objArr.length > 0) {
                    setSelectedFrom(objArr[0].value);
                }
                setFroms(objArr);
            })
        }
        dispatch(getUserInfo());
    },[]);

    const timeSettingValue = (values) =>{
        if(values.tabIndex === 1){
            setScheduleType(2);
            setScheduleDate(values.date);
            setScheduleTime(values.time);
        }
        else{
            setScheduleType(1);
            setScheduleDate(null);
            setScheduleTime(null);
        }
    };

    const submitBroadcast = () => {
        if(submitSending)return
        setSubmitSending(true)
        addToBroadcast({
            tab : tabStr[value],
            ids : selectedIds,
            scheduleType : scheduleType,
            tempId : props.template.id,
            tempType : props.template.type,
            dateTime : moment(scheduleDate).format('YYYY-MM-DD') + ' ' + scheduleTime,
            from : typeof selectedFrom.id != 'undefined' ? selectedFrom.id : selectedFrom
        }).then(res => {
            res = res.data;
            if(res.status === 'success'){
                window.showNotification("SUCCESS","Broadcast added successfully")
                setSubmitSending(false)
                props.onClose();
            }
            else{
                if(res.status === 'error' && res.html !== undefined && res.html !== null && res.html !== ''){
                    window.showNotification("ERROR",res.html)
                }else{
                    window.showNotification("ERROR","Something went wrong")
                    setSubmitSending(false)
                }

            }
        });
    }

    const handleOnChangeTab = (value) => {
        if(submitSending)return
        setValue(value)
    }

    const handleLoadMore = (count, search) =>{
        if(count == 0){
            setCampaigns([]);
        }
        const payload = {skip: count, query: search};
        fetchMoreCampaignList(payload).then(res => {
            console.log(campaigns);
            console.log(res.data);
            if(count == 0){
                setCampaigns(res.data.data);
                setCountCampaign(res.data.total);
            }
            else{
                if(res.data.data.length>0){
                    const newTodos = [...campaigns];
                    res.data.data.map((campaign,index) => {
                        newTodos.push(campaign);
                    })
                    setCampaigns(newTodos);
                }
            }
        });
    }

    const timeSettings=()=>{
        return <TimeSetting user={userInfo} timeSettingValue={timeSettingValue}/>;
    }

    return ( 
        <GlobalModal 
            className={'cardBordcastTab marketing-alt globalBroadcastModal alt global-large-modal'}
            onClose={() => props.onClose()}
            onSubmit={() => submitBroadcast()} 
            open={props.show} 
            title="Set broadcast" 
            buttonText={"Send"}
            buttonIcon={submitSending ? <MoreHorizIcon fontSize={'small'} /> : <SendIcon fontSize="small" color="#000"/>}
            hideFooter={false}
            footerElement={timeSettings}
            modalBodyClass= {scheduleType === 1 ? "modal-body-height-320" : "modal-body-height-385"}
        >
            <div className="d-flex justify-content-end senderEmailWrapper">
                <span>From {fromText !== undefined ? fromText : 'Phone Number'}: </span>
                {
                    ((props.template.type === QuickReplyType.EMAIL || props.template.type === QuickReplyType.VIDEO_EMAIL) && (userInfo && userInfo.email_provider === 1)) ? (
                        <MultiEmailSelectDropdown
                            emailList={userInfo && userInfo.user_connected_emails ? userInfo.user_connected_emails : [] }
                            className={"template-bcst"}
                            selected = {selectedEmails}
                            selectedId = {selectedEmailIds}
                            reorganized={true}
                            valueField={"id"}
                            labelField={"email"}
                            handleSelect={(item,remove)=>{
                                let old_data_ids = [...selectedEmailIds];
                                let old_data_full = [...selectedEmails];
                                if(remove){
                                    let filter_old_data_ids = old_data_ids.filter(list => item.value !== list);
                                    setSelectedEmailIds(filter_old_data_ids);
                                    setSelectedFrom(filter_old_data_ids);

                                    let filter_old_data_full = old_data_full.filter(list => item.value !== list.value);
                                    setSelectedEmails(filter_old_data_full);
                                }
                                else{
                                    old_data_ids.push(item.value);
                                    old_data_full.push(item);
                                    setSelectedEmails(old_data_full);
                                    setSelectedEmailIds(old_data_ids);
                                    setSelectedFrom(old_data_ids);
                                }
                            }}
                        />
                    ) : (
                        <Select className="globalFormControlItem" value={selectedFrom} onChange={(e) => setSelectedFrom(e.target.value)}>
                            {
                                froms.map((elem,index) => {
                                    return <MenuItem className="menuitem-li-hover-color-with-border" key={`${index}${elem.id}`} value={elem.id}>{elem.value}</MenuItem>
                                })
                            }
                        </Select>
                    )
                }
            </div>
            <div className={classes.root + " tabheader"}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={(event, newValue) => handleOnChangeTab(newValue)}
                        indicatorColor="none"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                    <Tab label="Campaign" {...a11yProps(0)} />
                    <Tab label="Tag" {...a11yProps(1)} className="tag" />
                    <Tab label="Contact" {...a11yProps(2)} className="contact" />
                    {/* <Tab label="Contact" {...a11yProps(2)} /> */}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={(index) => setValue(index)}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <BroadcastCampaign 
                            campaigns={campaigns} 
                            countCampaign={countCampaign}
                            timeSettingValue={timeSettingValue}
                            froms={froms}
                            onChangeFrom={(value) => setSelectedFrom(value)}
                            selectedFrom={selectedFrom}
                            changeIds={(ids) => setSelectedIds(ids)}
                            loadMore = {handleLoadMore}
                            fromText={fromText}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <BroadcastTag 
                            tags={tags}
                            timeSettingValue={timeSettingValue}
                            froms={froms}
                            onChangeFrom={(value) => setSelectedFrom(value)}
                            selectedFrom={selectedFrom}
                            changeIds={(ids) => setSelectedIds(ids)}
                            fromText={fromText}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <BroadcastContact 
                            tags={tags}
                            timeSettingValue={timeSettingValue}
                            froms={froms}
                            onChangeFrom={(value) => setSelectedFrom(value)}
                            selectedFrom={selectedFrom}
                            changeIds={(ids) => setSelectedIds(ids)}
                            fromText={fromText}
                            tabValue={value}
                        />
                    </TabPanel>
                </SwipeableViews>
            </div>
            
        </GlobalModal>
     );
}
 
export default TemplateBroadcastModal;