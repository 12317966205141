/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, {useState, useRef, useEffect} from 'react'
import { connect } from 'react-redux'
import { addQuickReplyTemplate } from '../../../actions/marketingAction';
import { PlatTypeForPackage, QuickReplyType } from '../../../constants/CoreConstants';
import { IconList } from '../../../constants/IconList';
import Label from '../../../constants/Label.js';
import Messages from '../../../constants/Messages';
import Utils from '../../../helpers/Utils';
import AddDirectEmailTemplate from './AddDirectEmailTemplate';
import AddEmailTemplate from './AddEmailTemplate';
import AddSmsTemplate from './AddSmsTemplate';
import AddVoiceTemplate from './AddVoiceTemplate';
import './quickTemplate.css';
import moment from "moment";
import OutSideConstants from '../../../constants/ConstantFromOutside';
import { Link, useHistory } from 'react-router-dom';
import CustomAudioPlayer from '../../globals/CustomAudioPlayer';
import RecordedVideo from '../../Video/RecordedVideo';
// import { getMessageTemplates } from "../../../actions/marketingAction";
import { handleChangeActionTypes } from "../../Video/redux/videoEmailAction";
import { TextField } from '@material-ui/core';

const tabOptionsList = [
    {id:QuickReplyType.SMS, title: 'Text', iconActive: IconList.smsIconBlue, iconInactive: IconList.smsIconDeepBlue},
    {id:QuickReplyType.EMAIL, title: 'Email', iconActive: IconList.emailIconBlue, iconInactive: IconList.emailIconDeepBlue},
    {id:QuickReplyType.VIDEO_EMAIL, title: 'Video Email', iconActive: IconList.videoIconBlue, iconInactive: IconList.videoIconDeepBlue},
    {id:QuickReplyType.VOICE, title: 'Voice', iconActive: IconList.voiceIconBlue, iconInactive: IconList.voiceIconDeepBlue},
]

const cardOptions = {id:QuickReplyType.POSTCARD, title: 'Direct Mail', iconActive: IconList.sendIconBlue, iconInactive: IconList.sendIconDeepBlue}

const ALL_TYPES = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const AddQuickTemplate = props => {
    const history = useHistory();

    // refs
    const titleRef = useRef(null);
    /* states variable start */
    const [tabOptions,setTabOptions] = useState([]);
    const [title, setTitle] = useState('');
    const [tabIndex, setTabIndex] = useState(QuickReplyType.SMS);
    const [contentType, setContentType] = useState(QuickReplyType.SMS);
    const [textForView, setTextForView] = useState('');
    const [fileShow, setFileShow] = useState(null);
    const [showSubject, setShowSubject] = useState('');
    const [sampleScript, setSampleScript] = useState('');

    const [submitingTemplate, setSubmitingTemplate] = useState(false);
    /* states variable end */

    useEffect(() => {

        document.title = `Add Template | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }

        // const tabOptionsArr = [...tabOptionsList];
        if(Utils.getAccountData('agencyId') === 1 && tabOptionsList.length < 5
        // && process.env.REACT_APP_PRODUCTION_MODE !== true
        ) {
            tabOptionsList.push(cardOptions);
        }
        setTabOptions(tabOptionsList);
    },[]);

    // useEffect(() => {
    //     if (tabIndex == QuickReplyType.VIDEO_EMAIL) {
    //         window.sendCommunication({
    //             open: true,
    //             from: 'quick_reply',
    //             responseCallback: handleSaveVideoTemplate
    //         }, 'videoEmail');
    //     }
    // }, [tabIndex])

    /* render functinos start */

    const handleSelectTemplate = (selectedType) => {
        if ( parseInt(props.type) === selectedType ) {
            props.changeActionTypes(selectedType);
        } else {
            props.changeActionTypes(ALL_TYPES);
        }
    } 

    const leftInputPortion = () => {
        return(
            <div className="add_template_content">
                            
            <h6>Title</h6>
            <div className="input-field">
                <input placeholder="Title Here" type="text" className="validate" onChange={(event) => setTitle(event.target.value)} ref={titleRef}/>                                
            </div>

            <div className="row">
                <div className="">
                    <ul className="tabs">
                    {tabOptions.map((item, index) => {
                        return(
                            <li className="tab" onClick={() => onChangeTab(item.id)} key={"add-template-tab"+index}>
                                <a className={item.id == tabIndex ? "active": ""} style={index == (tabOptions.length-1)? {borderRadius: '0px 10px 10px 0px'}: {}} href="#!" onClick={e => e.preventDefault()}>
                                    {item.id == tabIndex ? item.iconActive : item.iconInactive}
                                    <span>{item.title}</span>
                                </a>
                            </li>
                        )
                    })}
                    </ul>
                </div>

                <div className="tab_content">
                    {
                    tabIndex === QuickReplyType.SMS ?
                        <AddSmsTemplate 
                            handleSaveTemplate={handleSaveTemplate}
                            handlePreview={handlePreview}
                            handlePreviewFile={handlePreviewFile}
                            handleContentType={handleContentType}
                            submitingTemplate={submitingTemplate}
                        /> :
                    tabIndex === QuickReplyType.EMAIL ?
                        <AddEmailTemplate
                            handleSaveTemplate={handleSaveTemplate} 
                            handlePreview={handlePreview}
                            submitingTemplate={submitingTemplate}
                            handleSubject={(value) => setShowSubject(value)}
                        /> :
                    tabIndex === QuickReplyType.VIDEO_EMAIL ?
                        <p>Add video template</p>
                        // <AddVideoEmailTemplate
                        //     handleSaveTemplate={handleSaveTemplate}
                        //     contentType={contentType}
                        //     handleContentType={handleContentType}
                        //     submitingTemplate={submitingTemplate}
                        //     handlePreviewFile={handlePreviewFile}
                        // />
                        :
                    tabIndex === QuickReplyType.VOICE ?
                        <AddVoiceTemplate 
                            handleSaveTemplate={handleSaveTemplate}
                            handlePreview={handlePreview}
                            handlePreviewFile={handlePreviewFile}
                            submitingTemplate={submitingTemplate}
                        /> : 
                    <AddDirectEmailTemplate handleSaveTemplate={handleSaveTemplate} submitingTemplate={submitingTemplate} editTemplate={false} 
                    updateTabOptionsForDM = {setContentType}/>
                    }
                </div>
            </div>
        </div>
        )
    }

    const rightViewPortion = () => {

        if(tabIndex == QuickReplyType.VOICE) {
            return <div className="add_template_display">
                <div className='m-4'>
                    <h5>Sample script</h5>
                    <TextField
                        multiline
                        rows={15}
                        placeholder={"Type your Message"}
                        variant="outlined"
                        value={sampleScript}
                        style={{width:"100%"}}
                        onChange={(e) => setSampleScript(e.target.value)}
                    />
                </div>
            </div>
        }

        return(
            <div className="add_template_display">

                <div className="smartphone_image">
                    <img src={process.env.REACT_APP_PUBLIC_URL_CUSTOM+'assets/images/quick-reply-template/smartphone.png'} alt="" />
                    <div className="smartphone_display">

                        <div className="display_number">
                            {tabIndex != QuickReplyType.SMS ? 
                                <h6 className="truncate" style={{textTransform : 'lowercase'}}>{'example_email@example.mail'}</h6>
                            :
                                <h6 className="truncate">{Utils.formatPhoneNumber('+10175806261')}</h6>
                            }
                        </div>

                        <div className="message_box">

                            <div className="display_message">
                                <div className="message_time ">
                                    <p className="right-align">{moment(new Date()).format('YYYY-MM-DD hh:mm:ss A')}</p>
                                </div>
                                <div className="display_message_body alt awesome__scroll_bar">
                                    {showSubject != '' &&
                                    <span className="messageSubject truncate">{showSubject}</span>
                                    }
                                    {tabIndex != QuickReplyType.VOICE &&
                                    <p className="left-align" dangerouslySetInnerHTML={{__html: textForView}} />
                                    }
                                    {(fileShow != null && tabIndex == QuickReplyType.SMS) &&
                                        <img style={{width: "100%"}} className="display_message_body_img" alt="" src={fileShow}/>

                                    }
                                    {(fileShow != null && tabIndex == QuickReplyType.VOICE) &&
                                    <CustomAudioPlayer
                                        hanldePlayer={() => {}}
                                        activePlayerId={fileShow}
                                        unique_key={fileShow}
                                        src={fileShow}
                                    />
                                    }

                                    {(fileShow != null && tabIndex == QuickReplyType.VIDEO_EMAIL) &&
                                    <RecordedVideo urlLink={fileShow}/>
                                    }

                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>                           

            </div>
        )
    }
    /* render functinos end */

    /* others */
    const handlePreview = (text) => {
        setTextForView(text)
    }

    const handlePreviewFile = (file) => {
        setFileShow(file)
    }

    const handleContentType = (type) => {
        setContentType(type)
    }

    const handleBackButton = () => {
        history.push('/campaign/quick-replies');
        if(typeof props.handleBack != 'undefined'){
            props.handleBack()
        }
    }

    const onChangeTab = (tab) => {
        if(tab === QuickReplyType.VIDEO_EMAIL) {
            window.sendCommunication({
                open: true,
                from: 'quick_reply',
                responseCallback: handleSaveVideoTemplate
            }, 'videoEmail');
        } else {
            setShowSubject('')
            setFileShow(null)
            setTextForView('')
            setTabIndex(tab);
            setContentType(tab);
        }
    }

    const handleSaveVideoTemplate = response => {
        handleSaveTemplate({
            videoId: response.email_video_id,
            email_body: response.message,
            email_subject: response.subject,
            title: response.title,
        },QuickReplyType.VIDEO_EMAIL);
    }

    const handleSaveTemplate = (data, tempTabIndex = '') => {

        if(submitingTemplate)return;
        // check validation
        setSubmitingTemplate(true)
        let formData = new FormData();
        if (tempTabIndex!= QuickReplyType.VIDEO_EMAIL) {
            if (title.trim() === '') {
                window.showNotification('ERROR', Messages.emptyTitleInAddQuickReply);
                titleRef.current.focus()
                setSubmitingTemplate(false)
                return false
            }
            formData.append('title', title)
        }

        //$packageType = ViewHelper::getPackTypeForView(auth()->id());
        let package_type = OutSideConstants.packageType
        formData.append('media_type', package_type == PlatTypeForPackage.PLAN_TYPE_STANDARD ? QuickReplyType.SMS : QuickReplyType.EMAIL)
        formData.append('content', tempTabIndex !== '' ? tempTabIndex :contentType)
        if(contentType == QuickReplyType.SMS){
            // sms save
            for (var key in data) {
                formData.append(key, data[key])
            }
            handleSelectTemplate (contentType);
            /* 
            mms
            /msg-temp-add-submit

            image-file: (binary)
            voice-file: (binary)
            title: hello
            isRecordFromAudio: 0
            isRecordingNotCompleted: 0
            body_sms: hello, this is for testing
            email_subject: 
            files: (binary)
            email_video_id: 
            upload-file: (binary)
            file_media: (binary)
            
            */

        } else if(contentType == QuickReplyType.MMS){
            // mms save
            for (let key in data) {
                formData.append(key, data[key])
            }
            handleSelectTemplate (contentType);
        } else if(contentType == QuickReplyType.VOICE){
            // voice save
            for (let key in data) {
                formData.append(key, data[key])
            }
            formData.append('message',sampleScript);
            handleSelectTemplate (contentType);
        } else if(tempTabIndex == QuickReplyType.VIDEO_EMAIL){
            // video email save
            for (let key in data) {
                formData.append(key, data[key])
            }
        } else if(contentType == QuickReplyType.EMAIL){
            // email save
            for (let key in data) {
                formData.append(key, data[key])
            }
            handleSelectTemplate (contentType);
        } else if(contentType == QuickReplyType.POSTCARD || contentType == QuickReplyType.GREETING_CARD || contentType == QuickReplyType.GIFT || contentType == QuickReplyType.LETTER){
            // direct mail save
            for (let key in data) {
                formData.append(key, data[key])
            }
            handleSelectTemplate (contentType);    
        }
        props.addQuickReplyTemplate(formData, (response) => {
            setSubmitingTemplate(false);
            if(response.status == 'success'){
                window.showNotification('SUCCESS',response.html);
                if (tempTabIndex == QuickReplyType.VIDEO_EMAIL) {
                    window.sendCommunication({open: false}, 'videoEmail');
                    handleSelectTemplate (tempTabIndex);
                };
                // todo here
                handleBackButton()
            }
            else if(response.status === 'validation-error'){
                // do validation here
                if(typeof response.html.title != 'undefined'){
                    window.showNotification('ERROR', response.html.title)
                }
                else if(typeof response.html.voice_title != 'undefined'){
                    window.showNotification('ERROR', response.html.voice_title)
                }
            }
            else{
                window.showNotification('ERROR','Something went wrong. Try again.')
            }
        })
        
    }

    return(
        <div className="main_content_container add_template_page white">
            <div className="">
                <div className="main_content_topbar d-flex justify-content-between align-items-center">
                    <h6>{Label.addQucikReplyTemplateHeadTitle}</h6>
                    <div className="back_button" >
                        <Link className="accent--bg--color" to={'/campaign/quick-replies?button=back'}>{IconList.backCircleWhite}<span>Back</span></Link>                                
                    </div>
                </div>
                <div className="main_content_divider"></div>
                <div className={`main_content_description  ${tabIndex == QuickReplyType.VIDEO_EMAIL ? 'activeVideoEmail' : ''}`}>
                    {leftInputPortion()}
                    {rightViewPortion()}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        type: state.marketingReducer.type,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addQuickReplyTemplate: (params, callback) => dispatch(addQuickReplyTemplate(params, callback)),
        changeActionTypes: (payload) => dispatch(handleChangeActionTypes(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQuickTemplate)
