import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    
    }
}));

const CustomModalPype = ({isShow, hanldeModal, component, blockBackdrop=false}) => {
    const classes = useStyles();
    const toggleDrawer = (open) => (event, reason) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(blockBackdrop){
            return;
        }
        hanldeModal(open)
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isShow}
            onClose={() => toggleDrawer(false)}
            closeAfterTransition
        >
            {component}
        </Modal>
    );
}
export default CustomModalPype;