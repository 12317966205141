import './custom-switch-style.css'
const checkIcon = (<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m8.229 14.062-3.521-3.541L5.75 9.479l2.479 2.459 6.021-6L15.292 7Z" fill='#FFF'/></svg>)
const cancelIcon = (<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M6.062 15 5 13.938 8.938 10 5 6.062 6.062 5 10 8.938 13.938 5 15 6.062 11.062 10 15 13.938 13.938 15 10 11.062Z" fill='#FFF'/></svg>)

const On = (props) => {
    const renderClassName = () => {
        return props.on.labelClass;
    }
    const renderIndicatorStyle = () => {
        const styles = {};
        styles['color'] = props.on.color;
        return styles;
    }
    return (
        <span className={`meis_sw_label meis_sw_l_on ${renderClassName()}`} style={renderIndicatorStyle()}>{props.on.text}</span>
    );
}
const Off = (props) => {
    const renderClassName = () => {
        return props.off.labelClass;
    }
    const renderIndicatorStyle = () => {
        const styles = {};
        styles['color'] = props.off.color;
        return styles;
    }
    return (
        <span className={`meis_sw_label meis_sw_l_off  ${renderClassName()}`} style={renderIndicatorStyle()}>{props.off.text}</span>
    );
}
const Indicator = (props) => {
    const renderClassName = () => {
        if(props.value){
            return props.on.indicatorClass;
        }
        return props.off.indicatorClass;
    }
    const renderIndicatorStyle = () => {
        const styles = {};
        if(props.value){
            // styles['backgroundColor'] = props.on.color;
            styles['background'] = `linear-gradient(to bottom, ${props.on.color} 70%, #ffffff54 100%)`;
            if(props.indicatorWidth !== ''){
                styles['left'] = `${(parseInt(props.width)-(parseInt(props.indicatorWidth)+5))}px`;
                styles['width'] = props.indicatorWidth;
            }
        }
        else{
            // styles['backgroundColor'] = props.off.color;
            styles['background'] = `linear-gradient(to bottom, #ffffff54 0%, ${props.off.color} 100%)`;
            if(props.indicatorWidth !== ''){
                styles['width'] = props.indicatorWidth;
            }
        }
        return styles;
    }
    const renderIndicatorIcon = () => {
        if(props.value){
            return props.on.icon;
        }
        return props.off.icon;
    }
    return (
        <div className={`meis_sw_indicator ${renderClassName()}`} style={renderIndicatorStyle()}>{renderIndicatorIcon()}</div>
    );
}
const MEISSwitchApp = (props) => {
    const onChange = () => {
        if(props.disabled) return;
        props.onChange(!props.value)
    }
    const renderClassName = () => {
        if(props.value){
            return 'meis_sw_active'
        }
        return 'meis_sw_inactive'
    }
    const renderStyles = () => {
        const styles = {...props.style};
        if(props.value){
            styles['backgroundColor'] = props.on.bgColor;
            if(props.showBorder){
                styles['border'] = '1px solid '+props.on.color;
            }
        }
        else{
            styles['backgroundColor'] = props.off.bgColor;
            if(props.showBorder){
                styles['border'] = '1px solid '+props.off.color;
            }
        }
        styles['height'] = props.height;
        styles['width'] = props.width;
        return styles;
    }
    return (
        <div 
            className={`meis_switch_wrapper ${props.className} ${props.disabled ? 'meis_sw_disabled' : ''} ${renderClassName()}`} 
            onClick={onChange} 
            style={renderStyles()}
        >
            <Indicator {...props} />
            <On {...props} />
            <Off {...props} />
        </div>
    );
}
const MEISCustomSwitch = (props) => {
    const default_off = {
        color: '#BDBDBD', //for text color, indicator color, border color
        bgColor: 'transparent',
        text: 'OFF',
        indicatorClass: '',
        labelClass: '',
        icon: ''
    }
    const default_on = {
        color: '#008945',
        bgColor: 'transparent',
        text: 'ON',
        indicatorClass: '',
        labelClass: '',
        icon: ''
    }
    const default_props = {
        value: true,
        onChange: () => {},
        off: default_off,
        on: default_on,
        height: '32px',
        width: '104px',
        disabled: false,
        className: '',
        style:{},
        showBorder: true,
        indicatorWidth: ''
    }
    const propsData = {...props}
    const on = {...default_on, ...propsData.on}
    const off = {...default_off, ...propsData.off}
    default_props['on'] = {...on}
    default_props['off'] = {...off}
    delete propsData['on'];
    delete propsData['off'];
    const data = {...default_props, ...propsData}
    return <MEISSwitchApp {...data} />
}
export default MEISCustomSwitch;