import { campaignPauseApi, campaignStartApi, checkIsValidToStartCampaignApi, createOrUpdateCampaignFolderApi, deleteCampaignItem, deleteCampaignToFolder, fetchCampaignList, fetchCampaignTimelineApi, getCampaignCheckListApi, getCampaignFoldersApi, moveCampaignToFolderApi, reorderCampaignFolderDataV2Api } from "../../api/marketingApi";
import Helper from "../../helpers/Helper";
import Utils from "../../helpers/Utils";
import CustomBootstrapTooltip from "../Common/CustomBootstrapTooltip";
import CampaignActionNoticeBoard from "../marketing/CampaignList/CampaignActionNoticeBoard";
import CampaignStartModal from "../marketing/CampaignList/CampaignStartModal";
import AddContactToCampaign from "../marketing/EachCampaignActions/AddContact";
import ShareCampaign from "../marketing/EachCampaignActions/ShareCampaign";
import NewCampaignPopup from "../marketing/NewCampaignPopup";

const Link = {
    'TOOL_TIP': CustomBootstrapTooltip,
    'API_LIST': {
        fetchCampaign: fetchCampaignList,
        getCampaignCheckListApi: getCampaignCheckListApi,
        fetchCampaignTimelineApi: fetchCampaignTimelineApi,
        campaignPauseApi: campaignPauseApi,
        checkIsValidToStartCampaignApi: checkIsValidToStartCampaignApi,
        campaignStart: campaignStartApi,
        deleteCampaignItem: deleteCampaignItem
    },
    'NEW_CAMPAIGN_MODAL': NewCampaignPopup,
    'ADD_CONTACT_MODAL': AddContactToCampaign,
    'SHARE_CAMPAIGN_MODAL': ShareCampaign,
    'CAMPAIGN_START_MODAL': CampaignStartModal,
    'getCampaignFoldersApi': getCampaignFoldersApi,
    'createOrUpdateCampaignFolderApi': createOrUpdateCampaignFolderApi,
    'deleteCampaignToFolder': deleteCampaignToFolder,
    'moveCampaignToFolderApi': moveCampaignToFolderApi,
    'IS_EMPTY': Helper.isEmpty,
    'VisitorTimezones': Helper.visitorTimezones,
    'GetAccountData': Utils.getAccountData,
    CampaignActionNoticeBoard: CampaignActionNoticeBoard,
    reorderCampaignFolderDataV2Api: reorderCampaignFolderDataV2Api,
}
export default Link;