import React, {} from "react";
import { connect } from 'react-redux'
// import '../marketing.css';

const App = props => {

    
    return (
        <>
            <div className="container-fulid" style={{backgroundColor: "#E5E5E5"}}>
                <div className="row">
                    <div className="col s12">

                        <p>all contact list</p>

                    </div>
                </div>
            </div>
        </>
    );
}


const mapStateToProps = state => {
    // pass state in props
    // return {
    //     marketing: state.marketingReducer
    // };
};

const mapDispatchToProps = dispatch => {
    // return {
    //     fetchCampaign: (params, callback) => dispatch(fetchCampaign(params, callback))
    // };
}

const AllContacts = connect(mapStateToProps, mapDispatchToProps)(App);
export default AllContacts;
