import marketingMiddleware from './marketingMiddleware';
import marketingTemplateMiddleware from './marketingTemplateMiddleware';
import cardBroadcastMiddleware from '../components/marketing/DirectCardSend/redux/cardBroadcastMiddleware';
import videoEmailMiddleware from '../components/Video/redux/videoEmailMiddleware';

import { all } from "redux-saga/effects";

export default function* rootMiddleware() {
    yield all(
        [
            marketingMiddleware(),
            cardBroadcastMiddleware(),
            marketingTemplateMiddleware(),
            videoEmailMiddleware(),
        ]
    )
}
