import * as Action from './ActionTypes'

export const handleStates = (payload) => ({
    type: Action.HANDLE_STATES,
    payload: payload
});
export const handleStoreFolderData = (payload) => ({
    type: Action.STORE_FOLDER,
    payload: payload
});
export const handleFolderSelect = (payload) => ({
    type: Action.HANDLE_FOLDER_SELECT,
    payload: payload
});
export const handleStoreSearchText = (payload) => ({
    type: Action.STORE_SEARCH_TEXT,
    payload: payload
});
export const handleSelectedIds = (payload) => ({
    type: Action.HANDLE_SELECTED_IDS,
    payload: payload
});
export const handleCollapseId = (payload) => ({
    type: Action.HANDLE_COLLAPSE_ID,
    payload: payload
});
export const handleSelectedForRunning = (payload) => ({
    type: Action.HANDLE_SLECTED_FOR_RUNNING,
    payload: payload
});
export const handleStoreCampaignList = (payload) => ({
    type: Action.STORE_CAMPAIGN_LIST,
    payload: payload
});
export const handlePageNumber = (payload) => ({
    type: Action.HANDLE_PAGE_NUMBER,
    payload: payload
});
export const handleActionList = (payload) => ({
    type: Action.STORE_ACTION_LIST,
    payload: payload
});
export const handleFolderEdit = (payload) => ({
    type: Action.HANDLE_EDIT_FOLDER,
    payload: payload
});