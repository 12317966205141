import React, {useEffect, useState} from "react";
import {authType, authTypeLabel} from "../../constants";
import CustomMenuPype from "../CustomMenu/CustomMenuPype";
import {useSWHKContext} from "../../reducers/SWHKProvider";
import {handleState} from "../../reducers/Actions";
import {ArrowDownIcon} from "../../icons";
import Styles from "./Styles.module.css";

const App = ({callback,data}) => {
    const {state, dispatch} = useSWHKContext();
    const [bearerToken, setBearerToken] = useState("");
    const [basicAuthCred, setBasicAuthCred] = useState({
        username: "",
        password: ""
    });

    useEffect(()=>{
        if(data){
            if (data.authType === authType.BEARER && data.authData && data.authData.token){
                setBearerToken(data.authData.token);
                callback({ authData: { token: data.authData.token } });
            }else if (data.authType === authType.BASIC && data.authData){
                if (data.authData.username){
                    setBasicAuthCred({
                        ...basicAuthCred,
                       username: data.authData.username
                    });
                    callback({ authData: { username: data.authData.username } });
                }

                if (data.authData.password){
                    setBasicAuthCred({
                         ...basicAuthCred,
                        password: data.authData.password
                    });
                    callback({ authData: { password: data.authData.password } });
                }
            }
        }
    },[data]);

    const handleBasicAuthCred = (name, value) => {
        callback({ authData: { ...basicAuthCred, [name]: value } });
        setBasicAuthCred({ ...basicAuthCred, [name]: value });
    };

    return(
      <div className={Styles.wbh_payload_authorization}>
          <div className={Styles.wbh_payload_auth_sections}>
              <div className={Styles.wbh_payload_auth_selector}>
                  <div className={Styles.wbh_payload_auth_selector_flex}>
                      <span className={Styles.wbh_payload_auth_selector_type_label}>Type</span>
                      <div>
                          <CustomMenuPype
                              wrapperClass={`${Styles.mlvc_row_per_page_dropdown} mlvc_row_per_page_dropdown`}
                              popup= {{
                                  placement: "left",
                                  list: [
                                      {label: "No Auth", value: authType.NO_AUTH},
                                      {label: "Bearer", value: authType.BEARER},
                                      {label: "Basic", value: authType.BASIC}
                                  ],
                                  onClick: (item) => {
                                      dispatch(handleState({
                                          authType: item.value
                                      }));
                                      callback({
                                          authType: item.value
                                      });
                                  },
                                  height: "200px",
                                  width: "130px",
                                  listStyle: {
                                      justifyContent: "left"
                                  }
                              }}
                              label= {{
                                  renderCustom: (
                                      <div className={Styles.wbh_authorization_select_field}>
                                          <span className={Styles.clpb_label}>{authTypeLabel[state.authType]}</span>
                                          <span className={Styles.clpb_icon}><ArrowDownIcon/></span>
                                      </div>
                                  )
                              }}
                          />
                      </div>
                  </div>
              </div>
              <div className={Styles.wbh_payload_auth_form}>
                  {
                      state.authType === authType.BEARER && (
                          <div className={Styles.wbh_authorization_bearer_token}>
                              <div className={Styles.wbh_form_group_inline}>
                                  <span>Token</span>
                                  <input onChange={(event)=>{
                                      setBearerToken(event.target.value);
                                      callback({ authData: { token: event.target.value } });
                                  }} value={bearerToken} placeholder="Token"/>
                              </div>
                          </div>
                      )
                  }

                  {
                      state.authType === authType.BASIC && (
                          <div className={Styles.wbh_authorization_basic_auth}>
                              <div className={Styles.wbh_form_group_inline}>
                                  <span>Username</span>
                                  <input name="username" onChange={(event)=>{handleBasicAuthCred(event.target.name, event.target.value)}} value={basicAuthCred.username} placeholder="Username"/>
                              </div>
                              <div className={Styles.wbh_form_group_inline}>
                                  <span>Password</span>
                                  <input type="password" name="password" onChange={(event)=>{handleBasicAuthCred(event.target.name, event.target.value)}} value={basicAuthCred.password} placeholder="Password"/>
                              </div>
                          </div>
                      )
                  }

                  {
                      state.authType === authType.NO_AUTH && (
                          <div className={Styles.wbh_no_auth_none}>
                              <p>This request does not use any authorization.</p>
                          </div>
                      )
                  }
              </div>
          </div>
      </div>
    );
};

export default App;