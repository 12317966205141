import { MenuItem, Select } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import React, { useState } from 'react'
import { createMessagingServiceApi } from '../../api/marketingApi';
import { DropdownMenuProps } from '../../helpers/DropdownSettings';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';
import GlobalModal from '../globals/Modal/GlobalModal'

const MESSAGE_SERVICE_FOR = [
    {value: 'Notify my users', title: 'Notify my users'},
    {value: 'Market my services', title: 'Market my services'},
    {value: 'Verify users', title: 'Verify users'},
    {value: 'Engage in a discussion', title: 'Engage in a discussion'},
    {value: 'Poll or service users', title: 'Poll or service users'},
    {value: 'Not listed here', title: 'Not listed here'}
];

export const ModalAddMessagingService = (props) => {

    const simpleValidator = useSimpleValidator();
    const [friendlyName, setFriendlyName] = useState('');
    const [serviceFor, setServiceFor] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const renderServiceFor = () => {
        let serviceView = [];
        MESSAGE_SERVICE_FOR.forEach((item, index) => {
            serviceView.push(
                <MenuItem 
                    className="menuitem-li-hover-color-with-border" 
                    key={index} 
                    value={item.value}
                >
                    {item.title}
                </MenuItem>
            )
        })
        return serviceView; 
    }

    const handleSubmit = () => {

        if(submitting || !simpleValidator.validate()) {
            return;
        }

        setSubmitting(true);

        let createMessageForm = {
            message_service_friendly_name: friendlyName,
            messaging_service_usage: serviceFor
        }

        createMessagingServiceApi(createMessageForm).then(res => {
            let response = res.data;
            if(response.success){
                props.onRefreshData();
                props.onClose();
            }
            else{
                window.showNotification("warning", "Something went wrong! Please try again later");
            }
            setSubmitting(false)
        }).catch(error => {
            console.log(error)
            setSubmitting(false)
        })
    }

    return (
        <GlobalModal
            title="Create Messaging Service"
            open={props.open}
            onClose={() => props.onClose()}
            onSubmit={handleSubmit}
            buttonText={`Creat${submitting ? 'ing' : 'e'} Message Service`}
            buttonIcon={<Add/>}
            className="create_messaging_service_modal global-750-modal global-modal-avoid-header global-xs-modal"
        >
            <div className="create__message__service">
                <div className="field__content">
                    <div className="field__label">Messaging Service friendly name</div>
                    <div className="field__input">
                        <input 
                            type="text" 
                            maxLength={20} 
                            value={friendlyName} 
                            onChange={(e) => setFriendlyName(e.target.value)} 
                            placeholder={'Enter friendly name'}
                        />
                        {simpleValidator.message('Friendly Name', friendlyName, 'required')}
                    </div>
                    <div className="messaging__identify__tag">
                        This friendly name will help you identify this Messaging Service at a later point of time.
                    </div>
                </div>

                <div className="field__content">
                    <div className="field__label">Select what you want to use this Messaging Service for</div>
                    <div className="field__input field_dropdown">
                        <Select 
                            value={serviceFor} 
                            onChange={e => {e.preventDefault();setServiceFor(e.target.value)}}
                            MenuProps={DropdownMenuProps}
                            displayEmpty
                        >
                            <MenuItem className="menuitem-li-hover-color-with-border" disabled value={null}>{'Select one'}</MenuItem>
                            {renderServiceFor()}
                        </Select>
                        {simpleValidator.message('Service For', serviceFor, 'required')}
                    </div>
                </div>
            </div>
        </GlobalModal>
    )
}
