/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  fetchStages,
  fetchTags,
  fetchTeamMembers,
  fetchCampaignsExceptCurrent,
  getCollaboratorList, fetchStage, getAllPipeLine,
} from "../../../api/marketingApi";
import If from "if-else-react";
import ScheduleSetting from "./ScheduleSetting";
import moment from "moment";
import {
  fetchCampaignTimelines,
  followupActionSave,
  updateArrSlotTimeHour1,
  updateArrSlotTimeMin1,
  updateCampaignSetting,
  updateDay,
  updateGreetingOption,
  updateGreetingsOptionTimeHour1,
  updateGreetingsOptionTimeMin1,
  updateHour,
  updateLaterHour,
  updateLaterMin,
  updateMin,
  updateRecurringDay,
  updateRecurringTimeHour1,
  updateRecurringTimeMin1,
  updateStartDate,
  updateTabValue,
  updateTime,
} from "../../../actions/marketingAction";
import GlobalModal from "../../globals/Modal/GlobalModal";
import CustomMenuPype from "../../marketing-list-v2/components/custom-menu/CustomMenuPype";
import { Icons } from "./action-list/Icons";

const actions = [
  "Change Stage",
  "Add tags",
  "Remove tags",
  "Remove All tags",
  "Add Collaborator",
  "Remove Collaborator",
  "Remove All Collaborator",
  "Pause All Other Campaign",
  "Move to Another Campaign",
];
const actions_list = [
  { value: 1, label: "Change Stage" },
  { value: 2, label: "Add tags" },
  { value: 3, label: "Remove tags" },
  { value: 4, label: "Remove All tags" },
  { value: 5, label: "Add Collaborator" },
  { value: 6, label: "Remove Collaborator" },
  { value: 7, label: "Remove All Collaborator" },
  { value: 8, label: "Pause All Other Campaign" },
  { value: 9, label: "Move to Another Campaign" },
];

const App = (props) => {
  const settingTypeAction = 7;
  // eslint-disable-next-line no-unused-vars
  const [campaignSetting, setCampaignSetting] = useState(settingTypeAction);
  const [conversationText, setConversationText] = useState("");
  const [campaignDetails, setCampaignDetails] = React.useState(
    props.campaignDetails
  );
  const [stages, setStages] = React.useState([]);
  const [pipelines, setPipelines] = React.useState([]);
  const [changeStage, setChangeStage] = React.useState(false);
  const [userCampaigns, setUserCampaigns] = React.useState([]);
  const [changeCampaigns, setChangeCampaigns] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showAllTimeSetting, setShowAllTimeSetting] = useState(true);
  const [tabValue, setTabValue] = useState(props.tabValue);
  const [hour, setHour] = useState(props.hour);
  const [min, setMin] = useState(props.min);
  const [day, setDay] = useState(props.day);
  const [time, setTime] = useState(props.time);
  const [startDate, setStartDate] = useState(props.startDate);
  const [greetingOption, setGreetingOption] = useState(props.greetingOption);

  const [selectedCampaign, setSelectedCampaign] = useState(false);

  const [selectedStage, setSelectedStage] = useState("");
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionFull, setSelectedOptionFull] = useState(null);

  const [userTags, setUserTags] = React.useState(null);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedRemoveTags, setSelectedRemoveTags] = useState([]);
  const [changeTags, setChangeTags] = React.useState(false);
  const [changeRemoveTags, setChangeRemoveTags] = React.useState(false);

  const [collaborators, setCollaborators] = React.useState(null);
  const [selectedCollaborators, setSelectedCollaborators] =
    React.useState(null);
  const [selectedRemoveCollaborators, setSelectedRemoveCollaborators] =
    useState([]);
  const [changeCollaborators, setChangeCollaborators] = React.useState(false);
  const [changeRemoveCollaborators, setChangeRemoveCollaborators] =
    React.useState(false);
  const [updateSetting, setUpdateSetting] = useState(false);
  const [editableCampaignSettingId, setEditableCampaignSettingId] =
    useState(null);

  useEffect(() => {
    if (props.currentClip != null) {
      loadActionsByValue(props.currentClip.action_type);
      for(let i = 0; i<actions_list.length; i++){
        if(actions_list[i].value == props.currentClip.action_type){
          setSelectedOptionFull(actions_list[i])
          break;
        }
      }
      
      setUpdateSetting(true);
      setEditableCampaignSettingId(props.currentClip.id);
      setConversationText(props.currentClip.message);

      let selectedTab = 0;
      let mint = 0;
      let hour = 0;
      let time = "12:00";
      if (props.currentClip.type > 1) {
        if (props.currentClip.type === 2 && props.currentClip.day === 1) {
          selectedTab = 1;
          let timeData = props.currentClip.hour.split(":");
          hour = parseInt(timeData[0]);
          mint = parseInt(timeData[1]);
        } else {
          selectedTab = props.currentClip.type;
        }
        time = props.currentClip.hour;
      } else {
        if (props.currentClip.type === 1) {
          selectedTab = 0;
        } else {
          selectedTab = 1;
          let timeData = props.currentClip.hour.split(":");
          hour = parseInt(timeData[0]);
          mint = parseInt(timeData[1]);
        }
      }
      props.updateTabValue(selectedTab);
      props.updateHour(hour);
      props.updateMin(mint);
      props.updateTime(time);
    } else {
      props.updateTabValue(0);
    }
  }, [updateSetting]);

  useEffect(() => {
    setTabValue(props.tabValue);
    setHour(props.hour);
    setMin(props.min);
    setDay(props.day);
    setTime(props.time);
    setStartDate(props.startDate);
    setGreetingOption(props.greetingOption);
  }, [
    props.tabValue,
    props.hour,
    props.min,
    props.day,
    props.time,
    props.startDate,
    props.greetingOption,
  ]);

  const setActionInitialValue = () => {
    setCollaborators([]);
    setUserTags([]);
    setSelectedCampaign(false);
    setSelectedCollaborators([]);
    setSelectedRemoveCollaborators([]);
    setSelectedRemoveTags([]);
    setSelectedStage("");
  };

  useEffect(() => {
    setCampaignDetails(props.campaignDetails);
  }, [props.campaignDetails]);

  const successFollowupAdd = (response) => {
    props.fetchCampaignTimelines({
      campaignId: campaignDetails.basic.id,
    });
    closeActionModal();

    window.showNotification(response[0].status, response[0].html);
  };

  const handleActionChange = (e) => {
    let actionType = parseInt(e.target.value);
    loadActionsByValue(actionType);
  };
  const handleActionChangeAlt = (item) => {
    setSelectedOptionFull(item);
    loadActionsByValue(item.value);
  };

  const loadActionsByValue = (actionType) => {
    hideAllActionFields();
    setActionInitialValue();
    setSelectedOption(actionType);
    switch (actionType) {
      case 1:
        //Change Stage
        changeNewPipeline();
        break;
      case 2:
        //Add Tags
        addTags();
        break;
      case 3:
        //Remove Tags
        removeTags();
        break;
      case 4:
        //Remove All Tags
        break;
      case 5:
        //Add Collaborator
        addCollaborators();
        break;
      case 6:
        //Remove Collaborator
        removeCollaborators();
        break;
      case 7:
        //Remove All Collaborator
        break;
      case 8:
        //Pause all other campaign
        break;
      case 9:
        //Move to another campaign
        addToAnotherCampaign();
        break;
      default:
        break;
    }
  };

  const changeNewPipeline = () => {
    getAllPipeLine()
        .then((res)=>{
          res = res.data;
          if (res && res.data){
            setPipelines(res.data);
            getStagePipeline();
          }else {
            setPipelines([]);
          }
        }).catch((err)=>{
      setPipelines([]);
    });
    setChangeStage(true);
  };
  const changeNewStage = (pipelineId) => {
    fetchStages({ pipelineId: pipelineId }).then((r) => setStages(r.data.stages));
    setChangeStage(true);
  };


  const getStagePipeline = () => {
    if (props.currentClip != null) {
      setSelectedStage(props.currentClip.action_value);
      fetchStage({ stageId: props.currentClip.action_value })
          .then((res)=>{
            res = res.data;
            if (res && res.status == 'success'){
              changeNewStage(res.data.pipeline_id);
              setSelectedPipeline(res.data.pipeline_id)
            }else {
              setSelectedPipeline("");
            }
          }).catch((err)=>{
        setSelectedPipeline("");
      });
    }
  }

  const addTags = () => {
    fetchTags({}).then((r) => setUserTags(r.data.tags));

    if (props.currentClip != null) {
      const obj = props.currentClip.action_value;
      if(props.currentClip.action_type != 2){
        setSelectedTags([]);
        setChangeTags(true);
        return
      }

      var output = obj.map((s) => {
        if (s.hasOwnProperty("label")) {
          s.title = s.label;
          delete s.label;
        }
        return s;
      });

      setSelectedTags(output);
    }

    setChangeTags(true);
  };

  const removeTags = () => {
    fetchTags({}).then((r) => setUserTags(r.data.tags));

    if (props.currentClip != null) {
      const obj = props.currentClip.action_value;
      if(props.currentClip.action_type != 3){
        setSelectedRemoveTags([]);
        setChangeRemoveTags(true);
        return
      }

      var output = obj.map((s) => {
        if (s.hasOwnProperty("label")) {
          s.title = s.label;
          delete s.label;
        }
        return s;
      });

      setSelectedRemoveTags(output);
    }

    setChangeRemoveTags(true);
  };

  const addCollaborators = () => {
    // fetchTeamMembers({})
    //     .then(r =>
    //         setCollaborators(r.data.users)
    //     );

    getCollaboratorList({})
      .then((response) => {
        if (response.data.data.length > 0) {
          let tempData = response.data.data.map((data) => ({
            value: data.id,
            title: data.full_name,
          }));
          setCollaborators(tempData);
        }
      })
      .catch((error) => {
        console.log("something is wrong" + error);
      });

    if (props.currentClip != null) {
      const obj = props.currentClip.action_value;
      if(props.currentClip.action_type != 5){
        setSelectedCollaborators([]);
        setChangeCollaborators(true);
        return
      }

      var output = obj.map((s) => {
        if (s.hasOwnProperty("label")) {
          s.title = s.label;
          delete s.label;
        }
        return s;
      });

      setSelectedCollaborators(output);
    }

    setChangeCollaborators(true);
  };

  const removeCollaborators = () => {
    fetchTeamMembers({}).then((r) => setCollaborators(r.data.users));
    getCollaboratorList({})
      .then((response) => {
        if (response.data.data.length > 0) {
          let tempData = response.data.data.map((data) => ({
            value: data.id,
            title: data.full_name,
          }));
          setCollaborators(tempData);
        }
      })
      .catch((error) => {
        console.log("something is wrong" + error);
      });

    if (props.currentClip != null) {
      const obj = props.currentClip.action_value;
      if(props.currentClip.action_type != 6){
        setSelectedRemoveCollaborators([]);
        setChangeRemoveCollaborators(true);
        return
      }

      var output = obj.map((s) => {
        if (s.hasOwnProperty("label")) {
          s.title = s.label;
          delete s.label;
        }
        return s;
      });

      setSelectedRemoveCollaborators(output);
    }

    setChangeRemoveCollaborators(true);
  };

  const addToAnotherCampaign = () => {
    fetchCampaignsExceptCurrent({
      campaignId: 2,
    }).then((r) => setUserCampaigns(r.data.campaigns));

    if (props.currentClip != null) {
      if(props.currentClip.action_type != 9){
        setChangeCampaigns(true);
        return
      }
      setSelectedCampaign(props.currentClip.action_value);
    }

    setChangeCampaigns(true);
  };

  const hideAllActionFields = () => {
    setChangeStage(false);
    setChangeTags(false);
    setChangeRemoveTags(false);
    setChangeCollaborators(false);
    setChangeRemoveCollaborators(false);
    setChangeCampaigns(false);
  };

  const handleStageChange = (e) => {
    setSelectedStage(e.target.value);
  };

  const handlePipelineChange = (e) => {
    setSelectedPipeline(e.target.value);
    setSelectedStage("");
    changeNewStage(e.target.value);
  };

  const handleTagChange = (e, value) => {
    setSelectedTags(value);
  };

  const handleTagRemoveChange = (e, value) => {
    setSelectedRemoveTags(value);
  };

  const handleCollaboratorChange = (e, value) => {
    setSelectedCollaborators(value);
  };

  const handleCollaboratorRemoveChange = (e, value) => {
    setSelectedRemoveCollaborators(value);
  };

  const handleCampaignChange = (e) => {
    setSelectedCampaign(e.target.value);
  };

  let listAction = actions.map(function (data, index) {
    return (
      <MenuItem
        className='menuitem-li-hover-color-with-border'
        key={index}
        value={index + 1}
      >
        {data}
      </MenuItem>
    );
  });

  let listPipeline = pipelines.map(function (data, index) {
    return (
        <MenuItem
            className='menuitem-li-hover-color-with-border'
            key={index}
            value={data.id}
        >
          <span>{data.title}</span>  <small><code> ({data.user.full_name})</code></small>
        </MenuItem>
    );
  });

  let listStage = stages.map(function (data, index) {
    return (
      <MenuItem
        className='menuitem-li-hover-color-with-border'
        key={index}
        value={data["id"]}
      >
        {data["stage"]}
      </MenuItem>
    );
  });

  let listCampaign = userCampaigns.map(function (data, index) {
    return (
      <MenuItem
        className='menuitem-li-hover-color-with-border'
        key={index}
        value={data["id"]}
      >
        {data["title"]}
      </MenuItem>
    );
  });

  const closeActionModal = () => {
    setSelectedOption(null);
    hideAllActionFields();
    setActionInitialValue();
    props.setOpenActionModal(false);
  };

  const validateBeforeSubmit = () => {
    if (!selectedOption) return false;

    switch (selectedOption) {
      case 1:
        if (!selectedStage) return false;

        break;
      case 2:
        if (!selectedTags.length) return false;

        break;
      case 3:
        if (!selectedRemoveTags.length) return false;

        break;
      case 4:
        //Remove All Tags
        break;
      case 5:
        if (!selectedCollaborators.length) return false;

        break;
      case 6:
        if (!selectedRemoveCollaborators.length) return false;

        break;
      case 7:
        //Remove All Collaborator
        break;
      case 8:
        //Pause all other campaign
        break;
      case 9:
        if (!selectedCampaign) return false;
        break;
      default:
        break;
    }

    return true;
  };

  const saveActionSetting = (event) => {
    // event.preventDefault();
    let type;

    switch (tabValue) {
      case 1:
      case 2:
        type = 2;
        break;
      case 0:
        type = 1;
        break;
      default:
        type = tabValue;
        break;
    }

    let validation = validateBeforeSubmit();

    if (!validation) return false;

    let formData = new FormData();
    formData.append("message", conversationText);
    formData.append("message_type", campaignSetting);
    formData.append("nScheduleHours", hour);
    formData.append("nScheduleMinutes", min);
    formData.append("nScheduleDays", day);
    formData.append("followupScheduleTime", time);
    formData.append("campaign_id", campaignDetails.basic.id);
    formData.append("type", type);
    formData.append("day_type", tabValue != 0 ? tabValue : 1);
    formData.append("date", moment(startDate).format("YYYY-MM-DD"));
    formData.append("greeting_option", greetingOption);

    formData.append(
      "selectedAddCollaborators",
      JSON.stringify(selectedCollaborators)
    );
    formData.append("selectedAddTags", JSON.stringify(selectedTags));
    formData.append("selectedCampaign", selectedCampaign);
    formData.append("selectedRemoveTags", JSON.stringify(selectedRemoveTags));
    formData.append(
      "selectedRemoveCollaborators",
      JSON.stringify(selectedRemoveCollaborators)
    );
    formData.append("selectedStage", selectedStage);
    formData.append("selectedOption", selectedOption);

    if (updateSetting) {
      formData.append("id", editableCampaignSettingId);
      props.updateCampaignSetting(formData, successFollowupAdd);
    } else {
      props.followupActionSave(formData, successFollowupAdd);
    }
  };

  return (
    <React.Fragment>
      <GlobalModal
        open={props.openActionModal}
        onClose={props.setOpenActionModal}
        title='Action'
        onSubmit={saveActionSetting}
        buttonText={"Set Followup Action"}
        buttonIcon={<span className='material-icons'>campaign</span>}
        className='global-large-modal campaign_setting_activity_modal'
      >
        {/*Massaging Editor*/}
        <div className='tiny-mce mt-3'>
          <div className='mb-0 row'>
            <div className='col s12 multi_select'>
              <CustomMenuPype
                popup={{
                  placement: "left",
                  list: actions_list,
                  onClick: (item) => {
                    handleActionChangeAlt(item);
                  },
                  height: "auto",
                  width: "300px",
                  listStyle: {
                    justifyContent: "left",
                  },
                }}
                label={{
                  renderCustom: (
                    <div
                      style={{
                        width: "300px",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                        borderRadius: "7px",
                        padding: "7px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        {selectedOption
                          ? (selectedOptionFull?.label ? selectedOptionFull?.label : "Select Action")
                          : "Select Action"}
                      </span>
                      <span>{Icons.arrow_down}</span>
                    </div>
                  ),
                }}
              />
              {!selectedOption ? (
                <span className='text-red pl-3'> Please select an action</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <If condition={changeStage}>
          {pipelines ? (
              <div className='col-12 mx-auto'>
                <div className='form-group multi_select second_multi_select '>
                  <label>Select An Pipeline</label>
                  <Select
                      value={selectedPipeline}
                      className={"form-control"}
                      onChange={handlePipelineChange}
                      displayEmpty
                  >
                    <MenuItem value={""} disabled={true}>
                      Select An Pipeline
                    </MenuItem>
                    {listPipeline}
                  </Select>
                </div>
              </div>
          ) : (
              ""
          )}
        </If>

        <If condition={changeStage}>
          {stages ? (
            <div className='col-12 mx-auto'>
              <div className='form-group multi_select second_multi_select '>
                <label>Select An Stage</label>
                <Select
                  value={selectedStage}
                  className={"form-control"}
                  onChange={handleStageChange}
                  displayEmpty
                >
                  <MenuItem value={""} disabled={true}>
                    Select An Stage
                  </MenuItem>
                  {listStage}
                </Select>
                {!selectedStage ? (
                  <span className='text-red'> Please select a stage</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </If>

        <If condition={changeTags}>
          {userTags ? (
            <div className='multi_select'>
              <Autocomplete
                onChange={(event, value) => handleTagChange(event, value)}
                multiple
                id='tags-outlined'
                options={userTags}
                getOptionLabel={(option) => option.title}
                defaultValue={selectedTags}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Select Tags'
                    placeholder='Select Tags'
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}

          <div className='col-12 mx-auto'>
            <div className='form-group multi_select second_multi_select'>
              {!selectedTags.length ? (
                <span className='text-red'> Please select tag(s)</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </If>

        <If condition={changeRemoveTags}>
          {userTags ? (
            <div className='multi_select'>
              <Autocomplete
                onChange={(event, value) => handleTagRemoveChange(event, value)}
                multiple
                id='tags-outlined'
                options={userTags}
                getOptionLabel={(option) => option.title}
                defaultValue={selectedRemoveTags}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Select Tags'
                    placeholder='Favorites'
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}
          <div className='col-12 mx-auto'>
            <div className='form-group multi_select second_multi_select'>
              {!selectedRemoveTags.length ? (
                <span className='text-red'> Please select tag(s)</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </If>

        <If condition={changeCollaborators}>
          {collaborators ? (
            <Autocomplete
              className='multi_select'
              onChange={(event, value) =>
                handleCollaboratorChange(event, value)
              }
              multiple
              id='tags-outlined'
              options={collaborators}
              getOptionLabel={(option) => option.title}
              defaultValue={selectedCollaborators}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select Collaborator'
                  placeholder='Favorites'
                />
              )}
            />
          ) : (
            ""
          )}

          <div className='col-12 mx-auto'>
            <div className='form-group multi_select second_multi_select'>
              {selectedCollaborators != null &&
              !selectedCollaborators.length ? (
                <span className='text-red'> Please select collaborator(s)</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </If>

        <If condition={changeRemoveCollaborators}>
          {collaborators ? (
            <Autocomplete
              className='multi_select'
              onChange={(event, value) =>
                handleCollaboratorRemoveChange(event, value)
              }
              multiple
              id='tags-outlined'
              options={collaborators}
              getOptionLabel={(option) => option.title}
              defaultValue={selectedRemoveCollaborators}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select Collaborator'
                  placeholder='Favorites'
                />
              )}
            />
          ) : (
            ""
          )}

          <div className='col-12 mx-auto'>
            <div className='form-group multi_select second_multi_select'>
              {!selectedRemoveCollaborators.length ? (
                <span className='text-red'> Please select collaborator(s)</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </If>

        <If condition={changeCampaigns}>
          {userCampaigns ? (
            <div className='col-12 mx-auto'>
              <div className='form-group multi_select second_multi_select'>
                <label>Select A Campaign</label>
                <Select
                  value={selectedCampaign}
                  className={"form-control"}
                  onChange={handleCampaignChange}
                >
                  <MenuItem value={null} disabled={true}>
                    Select A Campaign
                  </MenuItem>
                  {listCampaign}
                </Select>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className='col-12 mx-auto'>
            <div className='form-group multi_select second_multi_select'>
              {!selectedCampaign ? (
                <span className='text-red'> Please select a campaign</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </If>

        <ScheduleSetting showAllTimeSetting={showAllTimeSetting} />
      </GlobalModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.marketingReducer.userInfo,
    campaignDetails: state.marketingReducer.campaignDetails,

    tabValue: state.marketingReducer.tabValue,
    hour: state.marketingReducer.hour,
    min: state.marketingReducer.min,
    day: state.marketingReducer.day,
    recurringDay: state.marketingReducer.recurringDay,
    laterHour: state.marketingReducer.laterHour,
    laterMin: state.marketingReducer.laterMin,
    time: state.marketingReducer.time,
    startDate: state.marketingReducer.startDate,
    arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
    arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
    greetingOption: state.marketingReducer.greetingOption,
    greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
    greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
    recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
    recurringTimeMin1: state.marketingReducer.recurringTimeMin1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    followupActionSave: (params, callback) =>
      dispatch(followupActionSave(params, callback)),
    fetchCampaignTimelines: (params) =>
      dispatch(fetchCampaignTimelines(params)),

    updateCampaignSetting: (params, callback) =>
      dispatch(updateCampaignSetting(params, callback)),
    updateTabValue: (params) => dispatch(updateTabValue(params)),
    updateHour: (params) => dispatch(updateHour(params)),
    updateMin: (params) => dispatch(updateMin(params)),
    updateDay: (params) => dispatch(updateDay(params)),
    updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
    updateLaterHour: (params) => dispatch(updateLaterHour(params)),
    updateLaterMin: (params) => dispatch(updateLaterMin(params)),
    updateTime: (params) => dispatch(updateTime(params)),
    updateStartDate: (params) => dispatch(updateStartDate(params)),
    updateArrSlotTimeHour1: (params) =>
      dispatch(updateArrSlotTimeHour1(params)),
    updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
    updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
    updateGreetingsOptionTimeMin1: (params) =>
      dispatch(updateGreetingsOptionTimeMin1(params)),
    updateGreetingsOptionTimeHour1: (params) =>
      dispatch(updateGreetingsOptionTimeHour1(params)),
    updateRecurringTimeHour1: (params) =>
      dispatch(updateRecurringTimeHour1(params)),
    updateRecurringTimeMin1: (params) =>
      dispatch(updateRecurringTimeMin1(params)),
  };
};

const ActionModal = connect(mapStateToProps, mapDispatchToProps)(App);

export default ActionModal;
