import { useEffect } from 'react';
import Utils from '../helpers/Utils';

export function useChangePageTitle(title,sidebar = 'marketing') {

    useEffect(() => {
        document.title = `${title} | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar(sidebar);
        }
    }, [sidebar, title]);
}