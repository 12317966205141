import { all, takeEvery, call } from 'redux-saga/effects';
import * as API from '../api/marketingTemplateApi';
import * as ACTION from '../constants/marketingTemplateActionTypes'
import Utils from "../helpers/Utils";

function* marketingTemplateMiddlewareWatcher() {
    yield takeEvery(ACTION.GET_CAMPAIGN_TEMPLATE_CATEGORY, getCampaignTemplateCategoryMiddleware);
    yield takeEvery(ACTION.GET_CAMPAIGN_TEMPLATES, getCampaignTemplateByCategoryMiddleware);
    yield takeEvery(ACTION.GET_CAMPAIGN_TEMPLATE_DETAILS, getCampaignTemplateDetailsMiddleware);
    yield takeEvery(ACTION.CREATE_CAMPAIGN_BY_TEMPLATE, createCampaignByTemplateMiddleware);
}

function* getCampaignTemplateCategoryMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.getCampaignTemplateCategoryApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getCampaignTemplateByCategoryMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.getCampaignTemplateByCategoryApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getCampaignTemplateDetailsMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.getCampaignTemplateDetailsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* createCampaignByTemplateMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.createCampaignByTemplateApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* marketingTemplateMiddleware() {
    yield all([
        marketingTemplateMiddlewareWatcher()
    ])
}
