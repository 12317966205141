/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import {getCookie, getCookieLogin} from "./Cookie";

import socketIOClient from "socket.io-client";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import {confirmAlert} from "react-confirm-alert";

/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 ? email.substr(0, 13)+' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 ? name.substr(0, 13)+' ...' : name;
}

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Something went wrong', type = 'error', title = '') => {
    confirmAlert({
        title: title,
        message: message,
        buttons: [
            {
                label: 'Close',
                onClick: () => {
                    return;
                }
            }
        ]
    })
};

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name) => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = 'X'
            if(name == 'null null' || name == '' || name == ' '){
                fullNameInitial = 'X';
            }else if(name !== 'undefined' || name !== null){
                fullNameInitial = name.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return 'X';
        }
    } catch (error) {
        return 'X';
    }
}

const checkEmptyJson = data => {
     if(JSON.stringify(data) == '{}'){
        return false
     }
    return true
}

const limitText = (text, limit = 40) => {
    if (text.length > limit) {
      return text.substring(0, limit - 3).trim() + "...";
    }
    return text;
}

const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {
    if(mobileNumber != '' && mobileNumber.length < 10){
        return false;
    }
    else if (mobileNumber != '' && mobileNumber.length > 11){
        return false
    }else if(mobileNumber.length == 11 && mobileNumber.substring(0,1) != 1){
        return false;
    }
    return true    
}

const checkLetterExistInText = (text) => {
    var regExp = /[^0-9]+/g;
    let result = false
    if(regExp.test(text)){
        result = true
    }
    return result
}

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

const loadBackGroundNotificationSocket = (userId) => {
    const socket = socketIOClient(process.env.REACT_APP_NODE_SERVER, {
        resource: 'B/socket.io',
        'force new connection': true
    });

    const userRoom  = "background-task-notification:room:" + userId;

    socket.on('connect', function (data) {
        socket.emit('join-background-task-notification-room', userRoom);
    });

    socket.on('background-task-notification', function (data) {
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": typeof data.params.sticky !== 'undefined' && data.params.sticky ? "0" : "3000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };

        if (data.params.action === 'delete') {
            if (typeof data.params.type !== 'undefined' && data.params.type == 'error') {
                toastr.error(data.params.message);
            } else {
                toastr.success(data.params.message);
            }

        } else if (data.params.action === 'insert') {
            toastr.info(data.params.message);
        }
    });
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 */
export const getAccountData = (needle) => {
    // let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

const checkOnlyNumber = (text) => {
    var regExp = /^[0-9]*$/g;
    let result = false
    if(regExp.test(text)){
        result = true
    }
    return result
}

const hasWhiteSpace = (s) => {
    return /\s/g.test(s);
}

const countSms = (message) => {
    var charset7bit = ['£', '@', 'Â£', '$', 'Â¥', 'Ã¨', 'Ã©', 'Ã¹', 'Ã¬', 'Ã²', 'Ã‡', "\n", 'Ã˜', 'Ã¸', "\r", 'Ã…', 'Ã¥', 'Î”', '_', 'Î¦', 'Î“', 'Î›', 'Î©', 'Î ', 'Î¨', 'Î£', 'Î˜', 'Îž', 'Ã†', 'Ã¦', 'ÃŸ', 'Ã‰', ' ', '!', '"', '#', 'Â¤', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', 'Â¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ã„', 'Ã–', 'Ã‘', 'Ãœ', 'Â§', 'Â¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'Ã¤', 'Ã¶', 'Ã±', 'Ã¼', 'Ã '];
    var charset7bitext = ["\f", '^', '{', '}', '\\', '[', '~', ']', '|', 'â‚¬'];
    var chars_arr = message.split("");
    var coding = '7bit';
    var parts = 1;
    var part = 1;
    var chars_used = 0;
    var chars_sms = 160;
    var charsLength = chars_arr.length;
    var part_chars_used = 7;
    for (let i = 0; i < charsLength; i++) {
        if (charset7bit.indexOf(chars_arr[i]) >= 0) {
            chars_used = chars_used + 1;
        } else if (charset7bitext.indexOf(chars_arr[i]) >= 0) {
            chars_used = chars_used + 2;
        } else {
            coding = '16bit';
            chars_used = charsLength;
            break;
        }
    }    
    if (coding == '7bit') {
        if (chars_used > 160) {
            parts = Math.ceil(chars_used / 153);
            part_chars_used = 7;
            chars_sms = 153;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 160) {
                    part = part + 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.indexOf(chars_arr[i]) >= 0 && part_chars_used + 2 > 160) {
                    part = part + 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.indexOf(chars_arr[i]) == -1) {
                    part_chars_used = part_chars_used + 1;
                } else {
                    part_chars_used = part_chars_used + 2;
                }
            }
        }
        else {
            chars_sms = 160;      
        }
    }
    else {
        if (chars_used > 70) {
            parts = Math.ceil(chars_used / 67);
            part_chars_used = 3;
            chars_sms = 67;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 70) {
                    part = part + 1;
                    part_chars_used = 3;
                }
                part_chars_used = part_chars_used + 1;
            }
        }
        else {
            chars_sms = 70; 
        }
    }
    return {
        sms: parts,
        length: chars_used,
        chars_per_sms: chars_sms,
    };
 }

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    showNotification,
    checkEmptyJson,
    limitText,
    loadBackGroundNotificationSocket,
    validateEmail,
    validateMobileNumber,
    countSms,
    checkLetterExistInText,
    formatPhoneNumber,
    getAccountData,
    checkOnlyNumber,
    hasWhiteSpace
}

export default Utils;