import React, { useEffect } from 'react';
import { Icons } from '../../helpers/Icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useMLv2Context } from '../../reducer/MLV2Provider';
import Link from '../../Link';
import { handleStates } from '../../reducer/Actions';
import { Checkbox, FormGroup, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      marginInline: theme.spacing(3),
      marginBlock: theme.spacing(2),
    },
    FormControlLabel: {
        wordBreak: 'break-word', 

        '&:not(:last-child)': {
            marginBottom: '15px'
        }
        
    }
}));

const AddToFolder = ({handleClose}) => {
    const classes = useStyles();

    const {state, dispatch} = useMLv2Context();
    const [submitting, setSubmitting] = React.useState(false);
    const [selectedFolders, setSelectedFolders] = React.useState([])

    useEffect(() => {
        if(state.selectedCampaignForAction?.folders !== undefined && state.selectedCampaignForAction?.folders != null && Array.isArray(state.selectedCampaignForAction?.folders)){
            let data = [];
            state.selectedCampaignForAction?.folders.forEach(item => data.push(`${item.folder_id}`))
            setSelectedFolders(data)
        }
    }, [])

    const handleChange = (event) => {
        if(event.target.checked){
            setSelectedFolders(prv => {
                let folders = [...prv];
                folders.push(event.target.value)
                return folders;
            })
        }
        else{
            let old = [...selectedFolders];
            let index = old.indexOf(event.target.value)
            old.splice(index, 1);
            setSelectedFolders(old)
        }
        // console.log(event.target.value)
        // console.log(event.target.checked)
        // setValue(event.target.value);
    };
    const handleSubmit = () => {
        if(submitting){
            return;
        }
        setSubmitting(true)
        Link.moveCampaignToFolderApi({ 
            folder_ids: selectedFolders, 
            campaign_id: state.selectedCampaignForAction.id,
            apiVersion: 2
        })
        .then((res)=>{
            if (res.data && res.data.success){
                dispatch(handleStates({
                    selectedCampaignForAction: {},
                    selectedCampaignForActionIndex: null,
                    movingToFolder: true,
                    refreshList: true,
                    gettingList: true
                }))
                // let old_list = [...state.campaignList]
                // old_list.splice(parseInt(state.selectedCampaignForActionIndex), 1)
                // if(old_list.length === 0){
                //     dispatch(handleStates({
                //         selectedCampaignForAction: {},
                //         selectedCampaignForActionIndex: null,
                //         movingToFolder: true,
                //         refreshList: true,
                //         gettingList: true
                //     }))
                // }
                // else{
                //     dispatch(handleStates({
                //         selectedCampaignForAction: {},
                //         selectedCampaignForActionIndex: null,
                //         movingToFolder: true,
                //         campaignList: old_list
                //     }))
                // }
                handleClose(false)
            }
        })
        .catch(error => {
            setSubmitting(false)
        })
    }
    const renderFoldersV2 = () => {
        const view = [];
        state.folders.forEach((item, index) => {
            // let flag = checkItemExistInArray(state.selectedCampaignForAction?.folders, item)
            let flag = checkItemExistInArray(selectedFolders, item)
            view.push(
                <FormControlLabel
                    key={`${index}-${flag}`}
                    control={<Checkbox checked={flag} onChange={handleChange} value={item.id} name={item.title} />}
                    label={item.title}
                    className={classes.FormControlLabel}
                />
            );
        })
        return (
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                    {view}
                </FormGroup>
            </FormControl>
        );
    }
    return (
        <div className='add_new_folder_fade_modal_v2_wr'>
            <div className='add_new_folder_fade_modal_v2_header'>
                <div className='add_new_folder_fade_modal_v2_header_left'>
                    {Icons.folder_icon}
                    Add to Folder
                </div>
                <span className='add_new_folder_fade_modal_v2_header_close' onClick={() => handleClose(false)}>
                    {Icons.close_icon}
                </span>
            </div>
            <div className='add_new_folder_fade_modal_v2_body'>
                <h3 className='add_new_folder_fade_modal_v2_content_title'>Select Folder</h3>
                {renderFoldersV2()}
            </div>
            <div className='add_new_folder_fade_modal_v2_footer'>
                <span className='add_new_folder_fade_modal_v2_footer_cancel' onClick={() => handleClose(false)} >Cancel</span>
                <div className='add_new_folder_fade_modal_v2_footer_confirl' onClick={handleSubmit}>{submitting ? 'Adding':'Confirm'}</div>
            </div>
        </div>
    );
}
export default AddToFolder;


const checkItemExistInArray = (checkArray, item) => {
    if(checkArray !== undefined && checkArray != null && Array.isArray(checkArray)){
        // return checkArray.some(each => each.folder_id == item.id)
        return checkArray.some(each => each == item.id)
    }
    return false
}