/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component} from 'react';
import CanvaStartModal from "./CanvaStartModal";
const canvaStyle = {
    marginTop: '10px',
    padding: '30px 0',
    borderRadius: '16px',
    background: 'rgba(143,146,161,.05)',
    border: '2px dashed #e0e5f1'
}
var canvaApiKey = 'b39MbXGEYSY6mzq7Y87SPXcE';

class CanvaService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canvaApi: "",
            has_init: false,
            isOpenCanvaStarModal: false,
            canvaImage: null
        }
    }

    componentDidMount () {
        let _this = this;
        if (!_this.has_init) {
            (function (document, url) {
                var script = document.createElement('script');
                script.src = url;
                script.onload = function () {
                    _this.init();
                };
                document.body.appendChild(script);
            })(document, 'https://sdk.canva.com/designbutton/v2/api.js');
        } else {
            _this.createCanvaDesign();
        }
    }

    init() {
        let _this = this;
        (async function () {

            if (window.Canva && window.Canva.DesignButton) {
                if (!_this.state.has_init) {
                    const canvaApi = await window.Canva.DesignButton.initialize({
                        apiKey: canvaApiKey,
                    });

                    _this.setState({
                        has_init: true,
                        canvaApi: canvaApi
                    })
                } else {
                    _this.createCanvaDesign();
                }
            }
        })();
    }

    createCanvaDesign = () => {
        this.setState({
            isOpenCanvaStarModal: false
        });
        this.state.canvaApi.createDesign({
            design: {
                type: this.props.design_type,
                dimensions: {
                    width: this.props.design_width,
                    height: this.props.design_height,
                    units: 'px'
                }
            },
            editor: {
                fileType: 'png'
            },
            onDesignOpen: ({ designId }) => {
                // Triggered when editor finishes loading and opens a new design.
                // You can save designId for future use.
            },
            onDesignPublish: ({ exportUrl, designId }) => {
                // Triggered when design is published to an image.
                // Save the image to your server as the exportUrl will expire shortly.
                this.props.callback(exportUrl)
            },
            onDesignClose: () => {
                // Triggered when editor is closed.
            },
        });
    }

    drawCanva = (e) => {
        e.preventDefault();
        this.setState({
            isOpenCanvaStarModal: true
        });
        // this.createCanvaDesign();
    }

    closeDrawCanva = () => {
        this.setState({
            isOpenCanvaStarModal: false
        });
    }

    render() {
        return (
            <div className="text-center" style={canvaStyle}>
                <img src={`${process.env.REACT_APP_APP_URL}/assets/images/canva.png`} className="mb-1" width="45px" alt=""/>
                <div>
                    <a href={'#'} className="canva-design-button btn btn-primary accent--bg--color" onClick={this.drawCanva}>DESIGN ONLINE USING CANVA</a>
                </div>

                {this.state.isOpenCanvaStarModal &&
                    <CanvaStartModal
                        setting_type={this.props.setting_type === undefined ? 'postcard' : this.props.setting_type}
                        callback={this.createCanvaDesign}
                        title={'Canva Integration Alart'}
                        isOpen={this.state.isOpenCanvaStarModal}
                        closeModal={this.closeDrawCanva}
                    />
                }
            </div>
        )

    }
}

export default CanvaService;
