import React from 'react';
import {connect} from "react-redux";
import { fetchCampaignTimelines } from '../../../actions/marketingAction';
import TimelineEachDay from './TimelineEachDay';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),
    };
}

class CampaignTimelineSystemTemplateApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.email = 0;
    }

    refreshTimeline = () => {
        this.props.fetchCampaignTimelines({
            campaignId: this.props.campaignId,
        });
    }


    render() {
        let settings = [];
        let settingsDay = [];

        if(this.props.campaignTimeline !== undefined) {
            for(let i = 0; i < this.props.campaignTimeline.length ; i++){
                if(Array.isArray(settingsDay[this.props.campaignTimeline[i].day])){
                    settingsDay[this.props.campaignTimeline[i].day].push(this.props.campaignTimeline[i]);
                } else {
                    settingsDay[this.props.campaignTimeline[i].day] = [];
                    settingsDay[this.props.campaignTimeline[i].day].push(this.props.campaignTimeline[i]);
                }
            }

            for(let i = 0; i < settingsDay.length; i++){
                if(settingsDay[i] !== undefined){
                    settings.push( <TimelineEachDay 
                        day={i} 
                        data={settingsDay[i]} 
                        key={i} 
                        handleTimelineEdit={this.props.handleTimelineEdit}
                        refreshTimeline={this.refreshTimeline}
                        systemTemplate={typeof this.props.systemTemplate != 'undefined' ? this.props.systemTemplate : false}
                    />)
                }
            }
        }

        return (
            <React.Fragment>
                {settings}
            </React.Fragment>
        );
    }
}

const CampaignTimelineSystemTemplate = connect(mapStateToProps, mapDispatchToProps)(CampaignTimelineSystemTemplateApp);
export default CampaignTimelineSystemTemplate;
