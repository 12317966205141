import React from 'react';
import {connect} from "react-redux";
import { fetchCampaignTimelines, reloadCampaignSettingsStatus } from '../../../actions/marketingAction';
import TimelineEachDay from './TimelineEachDay';

const mapStateToProps = state => {
    return {
        campaignDetails: state.marketingReducer.campaignDetails,
        campaignTimeline: state.marketingReducer.campaignTimeline,
        reloadCamapignSettings: state.marketingReducer.reloadCamapignSettings
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),
        reloadCampaignSettingsStatus: (params) => dispatch(reloadCampaignSettingsStatus(params))
    };
}

class CampaignTimelineList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            daySettings: null,
            greetingSettins: null,
            customSettings: null,
            recurringSettings: null
        };
        this.email = 0;
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.reloadCamapignSettings && this.props.reloadCamapignSettings !== prevProps.reloadCamapignSettings) {

          this.refreshTimeline();

        }
      }

    refreshTimeline = () => {
        this.props.fetchCampaignTimelines({
            campaignId: this.props.campaignId,
        }, () => {
            this.handleTimelineRender()
        });

        if(!this.props.reloadCamapignSettings){
            this.props.reloadCampaignSettingsStatus({'status': false})

        }
    }

    render() {

        let daySettingsDay = [],
            grettingSettingsDay = [],
            customDateSettingsDay = [],
            recurringSettingsDay = [];

        let daySettings = [],
            greetingSettins = [],
            customSettings = [],
            recurringSettings = [];

        if(this.props.campaignTimeline !== null && this.props.campaignTimeline.settings !== undefined && this.props.campaignTimeline.settings !== null) {

            for(let i = 0; i < this.props.campaignTimeline.settings.length ; i++){
                if(
                    this.props.campaignTimeline.settings[i].type !== 3 &&
                    this.props.campaignTimeline.settings[i].type !== 4 &&
                    this.props.campaignTimeline.settings[i].type !== 5
                ){
                    if(Array.isArray(daySettingsDay[this.props.campaignTimeline.settings[i].day])){
                        daySettingsDay[this.props.campaignTimeline.settings[i].day].push(this.props.campaignTimeline.settings[i]);
                    } else {
                        daySettingsDay[this.props.campaignTimeline.settings[i].day] = [];
                        daySettingsDay[this.props.campaignTimeline.settings[i].day].push(this.props.campaignTimeline.settings[i]);
                    }
                }
                else if(this.props.campaignTimeline.settings[i].type === 4){
                    if(Array.isArray(grettingSettingsDay[this.props.campaignTimeline.settings[i].day])){
                        grettingSettingsDay[this.props.campaignTimeline.settings[i].day].push(this.props.campaignTimeline.settings[i]);
                    } else {
                        grettingSettingsDay[this.props.campaignTimeline.settings[i].day] = [];
                        grettingSettingsDay[this.props.campaignTimeline.settings[i].day].push(this.props.campaignTimeline.settings[i]);
                    }
                }
                else if(this.props.campaignTimeline.settings[i].type === 3){
                    if(Array.isArray(customDateSettingsDay[this.props.campaignTimeline.settings[i].day])){
                        customDateSettingsDay[this.props.campaignTimeline.settings[i].day].push(this.props.campaignTimeline.settings[i]);
                    } else {
                        customDateSettingsDay[this.props.campaignTimeline.settings[i].day] = [];
                        customDateSettingsDay[this.props.campaignTimeline.settings[i].day].push(this.props.campaignTimeline.settings[i]);
                    }
                }
                else if(this.props.campaignTimeline.settings[i].type === 5){
                    if(Array.isArray(recurringSettingsDay[0])){
                        recurringSettingsDay[0].push(this.props.campaignTimeline.settings[i]);
                    }
                    else{
                        recurringSettingsDay[0] = [];
                        recurringSettingsDay[0].push(this.props.campaignTimeline.settings[i]);
                    }
                }
            }

            for(let i = 0; i < daySettingsDay.length; i++){
                if(daySettingsDay[i] !== undefined){
                    daySettings.push( <TimelineEachDay 
                        day={i} 
                        data={daySettingsDay[i]}
                        key={i} 
                        handleTimelineEdit={this.props.handleTimelineEdit}
                        refreshTimeline={this.refreshTimeline}
                    />)
                }
            }

            for(let i = 0; i < grettingSettingsDay.length; i++){
                if(grettingSettingsDay[i] !== undefined){
                    greetingSettins.push( <TimelineEachDay 
                        day={i} 
                        data={grettingSettingsDay[i]} 
                        key={i} 
                        handleTimelineEdit={this.props.handleTimelineEdit}
                        refreshTimeline={this.refreshTimeline}
                    />)
                }
            }

            for(let i = 0; i < customDateSettingsDay.length; i++){
                if(customDateSettingsDay[i] !== undefined){
                    customSettings.push( <TimelineEachDay 
                        day={i} 
                        data={customDateSettingsDay[i]} 
                        key={i} 
                        handleTimelineEdit={this.props.handleTimelineEdit}
                        refreshTimeline={this.refreshTimeline}
                    />)
                }
            }

            for(let i = 0; i < recurringSettingsDay.length; i++){
                if(recurringSettingsDay[i] !== undefined){
                    recurringSettings.push( <TimelineEachDay 
                        day={i} 
                        data={recurringSettingsDay[i]} 
                        key={i} 
                        handleTimelineEdit={this.props.handleTimelineEdit}
                        refreshTimeline={this.refreshTimeline}
                    />)
                }
            }
        }

        return (
            <React.Fragment>
                {daySettings}
                {greetingSettins}
                {customSettings}
                {recurringSettings}
            </React.Fragment>
        );
    }
}

const CampaignTimeline = connect(mapStateToProps, mapDispatchToProps)(CampaignTimelineList);

export default CampaignTimeline;
