import React, { useState } from "react";
import GlobalModal from "../../globals/Modal/GlobalModal";
import RenderHelper from "../../../helpers/RenderHelper";
import ApiDetailResponse from './ApiDetailResponse.json'

const ApiDetailsPopup = props => {
    const [showJsonView, setShowJsonView] = useState(true);
    const [showSelect , setShowSelect] = useState(2);


    const showJsonObject = (data) => {
        return <pre dangerouslySetInnerHTML={{ __html: RenderHelper.syntaxHighlight(JSON.stringify(data, undefined, 4)) }} />
    }

    const changeSelectItem = (e) => {
        setShowSelect(e.target.value)
    }


    return(
        <GlobalModal 
            open={props.open} 
            onClose={props.closePopup}
            title="Api Details"
            hideFooter={true}
            // className=""
        >

            {
                showJsonView && 
                <div className="my-2">
                    <select value={showSelect} onChange={changeSelectItem}>
                        <option value="1">Invalid key</option>
                        <option value="2">Successful response</option>
                        <option value="3">Successful response without number</option>
                        <option value="4">Invalid json</option>
                        <option value="5">Invalid  Email</option>
                        <option value="6">Email required</option>
                    </select>
                </div> 
            } 
            {
                showJsonView &&
                <div className="separetor_div">
                    <pre>curl --location --request POST 'https://core-api.pypepro.io/v1/campaignflows' \</pre>
                    
                    <pre>--header 'Content-Type: application/json' \</pre>
                    <pre>--header 'Authorization: Bearer {props.campaignDetails ? props.campaignDetails.basic.api_key : ''}' \</pre>
                    {
                        showJsonView && showSelect == 4 && <pre>--data-raw {"''"} </pre> 

                    }
                    {
                         showJsonView && showSelect != 4 &&
                         <>
                             <pre>--data-raw {"'{"} </pre> 
                            {
                                showJsonView && showSelect !=3 &&
                                <pre>   "number": "1347687358",</pre> 
                            }

                            {
                                showJsonView && showSelect == 5 &&
                                <pre>   "email": "info gmail.com",</pre> 
                            }
                            {
                            showJsonView &&  showSelect != 6 &&  showSelect != 5 &&
                                <pre>   "email": "info@gmail.com",</pre> 
                            }
                            <pre>   "first_name": "firstTestUser",</pre> 
                            <pre>   "last_name": "lastTestUser",</pre> 
                            <pre>   "address": "siant joseph, usa",</pre>
                            <pre>   "country": "US",</pre> 
                            <pre>   "zip": 3455,</pre> 
                            <pre>   "city": "washington",</pre> 
                            <pre>   "state": "",</pre> 
                            <pre>   "latitude" : "",</pre> 
                            <pre>   "longitude" : "",</pre> 
                            <pre>   "company_name": "",</pre>  
                            <pre>   "anniversary_date" : "",</pre>  
                            <pre>   "birth_date": ""</pre> 
                            <pre>   "custom_fields": {"'{"}
                                "[[your_custom_field_1]]" : "custom field value",
                                "[[your_custom_field_2]]" : "custom field value",
                                "[[your_custom_field_3]]" : "custom field value",
                                ................................................
                                ................................................
                                {"'}"}</pre>
                            <pre>{"'}"} </pre>
                         </>
                    }
                   

                </div>
            }

            {
                showJsonView && 
                <div className="separetor_div">
                        <p>Api Response</p>
                   
                    {
                        showJsonView && showSelect == 2 &&  showJsonObject(ApiDetailResponse)
                    }
                    {
                        showJsonView && showSelect == 1  && showJsonObject(props.campaignDetails ?  `No Campaign Found With This Token : ${props.campaignDetails.basic.api_key} `: '')
                    }
                    {
                        showJsonView && showSelect == 3 &&  showJsonObject(ApiDetailResponse)
                    }
                    {
                        showJsonView && showSelect == 4 &&
                        <>
                            <pre>{"{"}</pre>
                            <pre>  "message": "Request FAILED",</pre> 
                            <pre>  "error": <pre> { "{"}</pre>
                                <pre> "Message": "postData Is Not Valid Json"</pre> 
                                <pre>  { "}"},</pre>
                            </pre> 
                            <pre>"status": "FAILED"</pre> 
                            <pre>{"}"}</pre>
                        </>
                    }

                    {
                        showJsonView && (showSelect == 5 || showSelect == 6) &&
                        <>
                            <pre>{"{"}</pre>
                            <pre>  "message": "Request FAILED",</pre> 
                            <pre>  "error": <pre> { "{"}</pre>
                                <pre> "Message": "number or email is not valid"</pre> 
                                <pre>  { "}"},</pre>
                            </pre> 
                            <pre>"status": "FAILED"</pre> 
                            <pre>{"}"}</pre>
                        </>
                    }
                </div>
            } 
            
            {
                !showJsonView &&
                <>
                    <div className="http_form_group d-flex justify-content-between">
                <div className="form-group">
                    <label htmlFor="http_post" className="active">HTTP Methods POST</label>
                    <input readOnly={true} type="text" className="validate mt-2"
                            value={props.apiDetails.rootUrl+props.apiDetails.link} />
                </div>

            </div>
            <div className="http_form_group authorization_form_group d-flex justify-content-between pt-0">
                <div className="form-group">
                    <span className="pb-1">HEADERS</span>
                    <label htmlFor="authorized" className="active">Authorization Bearer Token<p>*</p>
                        <span>string</span> </label>
                    <input readOnly={true} type="text" className="validate mt-2"
                            value={props.campaignDetails ? props.campaignDetails.basic.api_key : ''} />
                </div>

            </div>
            <div className="query_parameters">
                {
                    !showJsonView ? <button className="btn mb-2"  style={{displayInline: "block",cursor:'pointer'}} onClick={()=> setShowJsonView(!showJsonView)}>View Request Data</button>:<button className="btn mb-2"  style={{displayInline: "block",cursor:'pointer',float: 'right'}} onClick={()=> setShowJsonView(!showJsonView)}>Back</button>
                }

                </div>
                </>
            }
            
        </GlobalModal>
    )
}

export default ApiDetailsPopup;