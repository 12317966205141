import CustomConfirmAlert from "../../Common/custom-alert/CustomConfirmAlert";
import CustomMenuPype from "../components/custom-menu/CustomMenuPype";
import { IconHandler } from "../hanlders/IconHandler";
import { Icons } from "../helpers/Icons";
import Link from "../Link";
import { handleFolderEdit, handleStates } from "../reducer/Actions";
import { useMLv2Context } from "../reducer/MLV2Provider";
import { all_campaign_item } from "../reducer/states";

const each_folder_list_options = [
    // {label: 'Add New Campaign', icon: Icons.c_add},
    {label: 'Edit Folder', icon: Icons.c_edit, value: 'edit'},
    {label: 'Delete', icon: Icons.c_delete, value: 'delete'}
]

const default_folder_list_options = [
    {label: 'Add New Campaign', icon: Icons.c_add}
]

const EachFolder = ({item, index, onClick, selected=false, showIconOnly=false, provided_ref}) => {
    const {state, dispatch} = useMLv2Context();

    const iconHandler = new IconHandler(item.text_color, parseInt(item.icon))

    const handleMenuOption = (menu) => {
        if(menu.value === 'edit'){
            /* update state for edit */
            dispatch(handleFolderEdit({
                data: item,
                index: index
            }))
        }else if(menu.value === 'delete'){
            if(state.deletingFolder){
                return
            }
            dispatch(handleStates({deletingFolder: true}))
            CustomConfirmAlert({
                handleConfirm : () => {
                    /* call delete api */
                    Link.deleteCampaignToFolder({ campaign_folder_id: item.id, apiVersion: 2 })
                    .then((res)=>{
                        let response = res.data;
                        if(response.status === "success"){
                            let old = [...state.folders]
                            old.splice(parseInt(index), 1)
                            if(state.selectedFolder === item){
                                dispatch(handleStates({deletingFolder: false, folders: old, selectedFolder: all_campaign_item, gettingList: true}))
                            }
                            else{
                                dispatch(handleStates({deletingFolder: false, folders: old}))
                            }
                            // dispatch(handleStates({refresh: true, deletingFolder: false}))
                        }
                        else{
                            dispatch(handleStates({deletingFolder: false}))
                        }
                    }).catch((err)=>{
                        dispatch(handleStates({deletingFolder: false}))
                    });
                },
                handleOnClose: () => {
                    dispatch(handleStates({deletingFolder: false}))
                },
                title: 'Are you sure?',
                description: 'Campaigns inside this folder will not be deleted.',
                icon: 'question',
                closeButtonText: 'No, Keep it.',
                confirmButtonText: "Yes, Delete it.",
                callConfirmLast: true,
                type_word: 'DELETE FOLDER',
            })
        }
    }

    if(showIconOnly){
        return (
            <Link.TOOL_TIP title={item.title} arrow placement="right"> 
                <div className={`mlvc_campaign_folder_inner_single mlvc_cfc_collapse_each_folder ${selected ? "mlvc_campaign_folder_inner_single_active": ''}`} onClick={onClick}>
                    <div className="mlvc_campaign_folder_inner_single_row">
                        <span className="mlvc_campaign_folder_is_icon mlvc_cfc_collapse_svg_icon">{iconHandler.getIcon}</span>
                    </div>
                </div>
            </Link.TOOL_TIP>

        );
    }

    return (
        <div {...provided_ref?.dragHandleProps} className={`mlvc_campaign_folder_inner_single ${selected ? "mlvc_campaign_folder_inner_single_active": ''}`} onClick={onClick}>
            <div className="mlvc_campaign_folder_inner_single_row">
                <span className="mlvc_campaign_folder_is_icon">{iconHandler.getIcon}</span>
                <span className="mlvc_campaign_folder_is_title">{item.title}</span>
            </div>

            {index !== -1 &&
            <div className="mlvc_campaign_folder_inner_single_menu">
                <CustomMenuPype
                    wrapperClass="mlvc_campaign_folder_dropdown"
                    popup = {{
                        list: item.id === null ? default_folder_list_options : each_folder_list_options,
                        onClick: handleMenuOption,
                        height: 'auto',
                        
                    }}
                    onClose={() => {}}
                />
            </div>
            }
        </div>
    );
}
export default EachFolder;