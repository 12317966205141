import {sendRequest} from "../../../api/rootApi";
import Api from "./Api";

export const fetchVideoFolders = async (payload) => {
    return sendRequest("post", Api.fetchVideoFolders, payload);
}

export const fetchVideos = async (payload) => {
    return sendRequest("post", Api.fetchVideos, payload);
}

export const deleteVideo = async (payload) => {
    return sendRequest("post", Api.deleteVideo, payload);
}