import { useEffect } from "react";
import { SkeletonRectangle } from "../components/skeleton/SkeletonRectangle";
import { useIsMounted } from "../hanlders/hooks/IsMounted";
import { Icons } from "../helpers/Icons";
import Link from "../Link";
import {
  handleFolderSelect,
  handleStates,
  handleStoreFolderData,
} from "../reducer/Actions";
import { useMLv2Context } from "../reducer/MLV2Provider";
import { all_campaign_item } from "../reducer/states";
import EachFolder from "./EachFolder";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const CampaignFolder = (props) => {
  const isMounted = useIsMounted();
  const { state, dispatch } = useMLv2Context();
  useEffect(() => {
    /* getting campaign folders */
    if (!state.gettingFolder) {
      dispatch(handleStates({ gettingFolder: true }));
      gettingCampaignFolder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isMounted) {
      if (state.refresh) {
        dispatch(handleStates({ gettingFolder: true, refresh: false }));
        gettingCampaignFolder();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.refresh]);
  const gettingCampaignFolder = () => {
    Link.getCampaignFoldersApi({ apiVersion: 2 })
      .then((res) => {
        let response = res.data;
        if (response.status === "success") {
          dispatch(handleStoreFolderData(response.data));
          // default_folder_item_data['campaign_count'] = response.count;
          // states.dispatch(handleStoreData({
          //     data: response.data,
          //     count: response.count
          // }));
          // states.dispatch(hanldeGroupChange({
          //     item: default_folder_item_data,
          //     index: -1
          // }))
        } else {
          dispatch(handleStates({ gettingFolder: false }));
        }
      })
      .catch((error) => {
        dispatch(handleStates({ gettingFolder: false }));
      });
  };
  const handleClickOnFolder = (item, index) => {
    // eslint-disable-next-line eqeqeq
    if (item.id == state.selectedFolder.id) {
      return;
    }
    dispatch(
      handleStates({
        gettingList: true,
      })
    );
    dispatch(handleFolderSelect(item));
  };
  const renderEachFolder = (showIconOnly = false) => {
    const view = [];
    state.folders.forEach((item, index) => {
      if (showIconOnly) {
        view.push(
          <EachFolder
            key={index}
            index={index}
            item={item}
            onClick={() => handleClickOnFolder(item, index)}
            selected={state.selectedFolder.id === item.id}
            showIconOnly={showIconOnly}
          />
        );
      } else {
        view.push(renderDragContext(index, item, showIconOnly));
      }
    });
    return view;
  };
  const renderFolderSkeleton = (showIconOnly = false) => {
    const view = [];
    for (let i = 0; i < 5; i++) {
      if (showIconOnly) {
        view.push(
          <div
            key={i}
            className='mlvc_campaign_folder_single_skelton'
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <span className='mlvc_campaign_folder_ssk_left'>
              <SkeletonRectangle
                height='40px'
                width='78px'
                borderRadius={"4px"}
              />
            </span>
          </div>
        );
      } else {
        view.push(
          <div key={i} className='mlvc_campaign_folder_single_skelton'>
            <span className='mlvc_campaign_folder_ssk_left'>
              <SkeletonRectangle height='25px' width='25px' />
            </span>
            <span className='mlvc_campaign_folder_ssk_right'>
              <SkeletonRectangle height='25px' width='auto' />
            </span>
          </div>
        );
      }
    }
    return view;
  };
  const handleAddNewFolder = () => {
    dispatch(handleStates({ isShowFolderCreate: true }));
  };
  const handleCreateNewCampaignModal = () => {
    dispatch(handleStates({ isShowCampaignCreate: true }));
  };
  const handleCampaignFolderDrawer = (status = true) => {
    dispatch(handleStates({ isShowFolderDrawer: status }));
  };

  /* for drag and drop */
  const renderDragContext = (index, item, showIconOnly) => {
    return (
      <Draggable
        key={`${index}-${item.id}`}
        draggableId={`${item.id}`}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <EachFolder
              key={index}
              index={index}
              item={item}
              onClick={() => handleClickOnFolder(item, index)}
              selected={state.selectedFolder.id === item.id}
              showIconOnly={showIconOnly}
              provided_ref={provided}
            />
          </div>
        )}
      </Draggable>
    );
  };
  const onDragEnd = (result) => {
    let { destination, draggableId, source, type } = result;
    console.log({ destination, draggableId, source, type });
    if (destination == null || destination.index === source.index) {
      return;
    }
    if (type === "CAMPAIGN_FOLDER") {
      const folders = Reorder(state.folders, source.index, destination.index);
      dispatch(handleStoreFolderData(folders));
      /* campaign-folder-reorder */
      let nexGroupId = null;
      if (state.folders[parseInt(destination.index) + 1]) {
        nexGroupId = state.folders[parseInt(destination.index) + 1].id;
      }
      Link.reorderCampaignFolderDataV2Api({
        type: "group",
        group_id: draggableId,
        destination_group_id: state.folders[parseInt(destination.index)].id,
        destination_index: destination.index,
        source_index: source.index,
        lastItem: state.folders.length === destination.index + 1,
        nextGroupId: nexGroupId,
      })
        .then((res) => {
          let response = res.data;
          if (response.status === "success") {
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  if (state.collapseFolderMenu) {
    return (
      <div
        className={`mlvc_campaign_folder_container mlvc_cfc_collapse ${props.className}`}
        style={state.collapseFolderMenu ? { maxWidth: "100px" } : {}}
      >
        <Link.TOOL_TIP title={"Create new campaign"} arrow placement='right'>
          <div
            className='mlvc_campaign_new_camp_create'
            onClick={handleCreateNewCampaignModal}
          >
            <span>{Icons.plusGray}</span>
          </div>
        </Link.TOOL_TIP>

        <div className='mlvc_campaign_folder_inner awesome__scroll_bar'>
          <EachFolder
            key={-1}
            index={-1}
            item={all_campaign_item}
            onClick={() => handleClickOnFolder(all_campaign_item, -1)}
            selected={state.selectedFolder === all_campaign_item}
            showIconOnly={true}
          />
          {state.gettingFolder && (
            <div className='mlvc_campaign_folder_single_skelton_wr'>
              {renderFolderSkeleton(true)}
            </div>
          )}
          {!state.gettingFolder && renderEachFolder(true)}
        </div>

        <Link.TOOL_TIP title={"Create new folder"} arrow placement='right'>
          <div
            className='mlvc_campaign_crete_bottom_btn'
            onClick={handleAddNewFolder}
            style={{ cursor: "pointer" }}
          >
            <div
              className='mlvc_campaign_folder_create' /* onClick={handleAddNewFolder} */
            >
              <span>{Icons.plusGray}</span>
            </div>
          </div>
        </Link.TOOL_TIP>
      </div>
    );
  }

  return (
    <div className={`mlvc_campaign_folder_container ${props.className}`}>
      <div
        className='mlvc_campaign_new_camp_create'
        onClick={handleCreateNewCampaignModal}
      >
        <span>{Icons.plusGray}</span>
        <span>New Campaign</span>
      </div>
      <div className='mlvc_campaign_folder_container_top'>
        <span className='mlvc_campaign_folder_container_top_title'>
          My Campaigns
        </span>
        {window.screen.width <= 768 && (
          <span
            className='mlvc_campaign_folder_container_top_icon'
            onClick={handleCampaignFolderDrawer}
          >
            {Icons.collapse}
          </span>
        )}
      </div>

      <div className='mlvc_campaign_folder_inner awesome__scroll_bar'>
        <EachFolder
          key={-1}
          index={-1}
          item={all_campaign_item}
          onClick={() => handleClickOnFolder(all_campaign_item, -1)}
          selected={state.selectedFolder === all_campaign_item}
        />
        {state.gettingFolder && (
          <div className='mlvc_campaign_folder_single_skelton_wr'>
            {renderFolderSkeleton()}
          </div>
        )}
        {/* {!state.gettingFolder && renderEachFolder()} */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' type='CAMPAIGN_FOLDER'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getGroupListStyle(snapshot.isDraggingOver)}
              >
                {renderEachFolder()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* <div className="mlvc_campaign_folder_create" onClick={handleAddNewFolder}>
                    <span>{Icons.plusGray}</span>
                    <span>Create New Folder</span>
                </div> */}
      </div>

      <div className='mlvc_campaign_crete_bottom_btn'>
        <div
          className='mlvc_campaign_folder_create'
          onClick={handleAddNewFolder}
        >
          <span>{Icons.plusGray}</span>
          <span>New Folder</span>
        </div>
      </div>
    </div>
  );
};
export default CampaignFolder;

/* for drag and drop */
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const getGroupListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#ebecf0" : "inherit",
  height: "100%",
});
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    background: isDragging ? "lightgreen" : "inherit",
    borderRadius: "8px",
    ...draggableStyle,
  };
};
