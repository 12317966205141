import CustomCircleLoader from "./CustomCircleLoader";

const CustomButton = ({icon, title, isLoading=false, isLoadingTitle, onClick, bgColor = '#006DF5', textColor='#fff', style={}}) => {
    return (
        <div className="meis_custom_button_container" onClick={onClick} style={{backgroundColor: bgColor, ...style}}>
            {icon !== undefined &&
            <span>{isLoading ? <CustomCircleLoader fill={textColor} size="extra-small" /> : icon}</span>
            }
            <span style={{color: textColor}}>{isLoading ? isLoadingTitle : title}</span>
        </div>
    );
}
export default CustomButton;
/* 
icon
title
isLoading
isLoadingTitle
onClick
bgColor
textColor
style
*/