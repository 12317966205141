/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { getCampaignTemplateDetails } from '../../actions/marketingTemplateAction';
import CampaignTimelineSystemTemplate from '../marketing/CampaignDetails/CampaignTimelineSystemTemplate';
import {Link} from 'react-router-dom';
import Utils from '../../helpers/Utils';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getCampaignTemplateDetails: (params, callback) => dispatch(getCampaignTemplateDetails(params, callback))
})

const CampaignTemplatePreviewApp = (props) => {
    const [settings, setSettings] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        document.title = `Campaign Templates | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }

        setLoading(true)
        props.getCampaignTemplateDetails({campaignId : props.match.params.id}, (res) => {
            setLoading(false)
            setSettings(res.html.settings)
        })
    }, [])

    return(
        <div className="row loader">
            <Link to="/campaign/template" className="back my-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.875 9C0.875 13.4871 4.51289 17.125 9 17.125C13.4871 17.125 17.125 13.4871 17.125 9C17.125 4.51289 13.4871 0.875 9 0.875C4.51289 0.875 0.875 4.51289 0.875 9ZM9.625 5.87148L7.14141 8.375H12.9844V9.625H7.14141L9.625 12.1285L8.73828 13.009L4.76016 9L8.73828 4.99102L9.625 5.87148Z"
                        fill="#3C7EF3"/>
                </svg>
                <span className="ml-2">Back</span>
            </Link>
            {loading &&
                <span className="loader_content">Loading</span>
            }
            {!loading &&
            <div>
            {settings.length == 0 ? 
                <div className="no_data">No setting available for this campaign!</div>
                :
                <div>
                    <CampaignTimelineSystemTemplate campaignTimeline={settings} handleTimelineEdit={() => {}} campaignId={props.match.params.id} systemTemplate={true} />
                </div>
            }
            </div>
            }
        </div>
    )

}
const CampaignTemplatePreview = connect(mapStateToProps, mapDispatchToProps)(CampaignTemplatePreviewApp);
export default CampaignTemplatePreview;