import * as Action from './ActionTypes'
const mlv2Reducer = (state, action) => {
    switch(action.type){
        case Action.HANDLE_STATES:
            return {...state, ...action.payload}

        case Action.STORE_FOLDER:
            return {
                ...state, 
                folders: action.payload,
                gettingFolder: false
            }

        case Action.HANDLE_FOLDER_SELECT:
            return {
                ...state, 
                selectedFolder: action.payload,
                currentPage: 1,
                gettingList: true
            }

        case Action.STORE_SEARCH_TEXT:
            return {
                ...state, 
                searchText: action.payload
            }

        case Action.HANDLE_SELECTED_IDS:
            return {
                ...state, 
                selectedIds: action.payload
            }

        case Action.HANDLE_COLLAPSE_ID:
            return {
                ...state, 
                collapseId: action.payload
            }

        case Action.HANDLE_SLECTED_FOR_RUNNING:
            return {
                ...state, 
                selectedForRunning: action.payload
            }

        case Action.STORE_CAMPAIGN_LIST:
            if(typeof action.payload === "object"){
                return {
                    ...state, 
                    campaignList: action.payload.campaignList,
                    collapseId: null,
                    gettingList: false,
                    totalRecords: action.payload.totalRecords
                }
            }
            else{
                return {
                    ...state, 
                    campaignList: action.payload,
                    collapseId: null,
                    gettingList: false
                }
            }

        case Action.HANDLE_PAGE_NUMBER:
            return {
                ...state, 
                currentPage: action.payload,
                gettingList: true
            }

        case Action.STORE_ACTION_LIST:
            return {
                ...state, 
                actionList: action.payload,
                gettingActionList: false
            }

        case Action.HANDLE_EDIT_FOLDER:
            return {
                ...state, 
                editFolderData: action.payload.data,
                isShowFolderCreate: action.payload.data == null ? false : true,
                editFolderIndex: action.payload.index
            }

        default:
            return state;
    }
}
export default mlv2Reducer;