/* eslint-disable no-unused-vars */
import * as ACTION from "../constants/marketingActionTypes";

const timeScheduleState = {
    /*TimeSchedule start*/
    tabValue: 1,
    hour: 0,
    min: 0,
    day: 2,
    recurringDay: 1,
    laterHour: '08',
    laterMin: '08',
    time: '08:00',
    startDate: new Date(),
    arrSlotTimeHour1: '08',
    arrSlotTimeMin1: '00',
    greetingOption: 1,
    greetingsOptionTimeMin1: '00',
    greetingsOptionTimeHour1: '08',
    recurringTimeHour1: '00',
    recurringTimeMin1: '00',
    /*TimeSchedule end*/
}
const initialState = {
    campaigns : [],
    totalPage: 0,
    isLoading: true,
    isMoreLoading: false,
    firstLoading: true,
    campaignSearchText: '',
    campaignDetails : null,
    campaignDetailsLoading: false,
    customFieldData : [],
    personalizedFieldData : [],
    campaignLeadSourceDetails : null,
    userInfo: null,
    leadGroups : null,
    leadFlows : null,
    campaignContactStat: null,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    messageTemplates: [],
    filterTemplate : {},
    loadingQuickReplies: true,

    /*TimeSchedule start*/
    tabValue: 0,
    hour: 0,
    min: 0,
    day: 2,
    recurringDay: 1,
    laterHour: '08',
    laterMin: '00',
    time: '08:00',
    startDate: new Date(),
    arrSlotTimeHour1: '08',
    arrSlotTimeMin1: '00',
    greetingOption: 1,
    greetingsOptionTimeMin1: '00',
    greetingsOptionTimeHour1: '08',
    recurringTimeHour1: '00',
    recurringTimeMin1: '00',
    /*TimeSchedule end*/

    // voice mail start
    preRecording: [],

    /* timelinse */
    campaignTimeline: [],
    campaignTimezone: '',

    activeAudioPlayerId: '',
    canStart: true,
    canStartCode: 0,
    reloadCamapignSettings: false,


    /* for scroll fecth */
    perPage: 20,
    currentPage: 0,
    fetchingMore: false,
    needTofetch: false,

    // action type
    type: '',
};

const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.UPDATE_STATE_FIELD:
            return {...state, ...action.payload}

        case ACTION.SET_LOADING_MESSAGE_TEMPLATE:
            return {...state, loadingQuickReplies: action.payload}

        case ACTION.FETCH_CAMPAIGNS:
            return {...state, isMoreLoading: true, isLoading: (state.firstLoading ? true : false)}

        case ACTION.STORE_CAMPAIGNS:
            // return {...state, campaigns: action.payload.html, isMoreLoading: false, isLoading: false, firstLoading: false, totalPage: action.payload.totalPages}
            return {...state, campaigns: action.payload.data, isMoreLoading: false, isLoading: false, firstLoading: false, totalPage: action.payload.last_page}
        
        case ACTION.STORE_CUSTOM_FIELD:
            return {...state, customFieldData: action.payload.data, personalizedFieldData: action.payload.personalizedData}

        case ACTION.FETCH_CUSTOM_FIELD_NEW:
            return {...state, customFieldData: action.payload.customData, personalizedFieldData: action.payload.personalizedData}

        case ACTION.STORE_CAMPAIGN_DETAILS:
            if(action.payload == null){
                return {...state, campaignDetailsLoading: true}
            }
            return {...state, campaignDetails: action.payload, campaignDetailsLoading: false}

        case ACTION.STORE_CAMPAIGN_TIMELINE:
            return {...state, campaignTimeline: action.payload, campaignTimezone: action.payload.campaign_timezone !== undefined ? action.payload.campaign_timezone : state.campaignTimezone}

        case ACTION.STORE_CAMPAIGN_LEAD_SOURCE_DETAILS:
            return {...state, campaignLeadSourceDetails: action.payload}

        case ACTION.STORE_USER_INFO:
            return {...state, userInfo: action.payload}

        case ACTION.STORE_LEAD_GROUPS:
            return {...state, leadGroups: action.payload}

        case ACTION.UPDATE_LEAD_GROUPS:
            return {...state, leadGroups:{
                    ...state.leadGroups,
                    ...action.payload
                }}

        case ACTION.STORE_LEAD_FLOWS:
            return {...state, leadFlows: action.payload}


        case ACTION.UPDATE_LEAD_FLOWS:
            return {...state, leadFlows:{
                    ...state.leadFlows,
                    ...action.payload
                }}

        case ACTION.STORE_CAMPAIGN_CONTACT_STAT:
            return {...state, campaignContactStat: action.payload}

        case ACTION.UPDATE_CAMPAIGN_DETAILS:

            return {...state, campaignDetails:{
                ...state.campaignDetails,
                ...action.payload
                }}

        case ACTION.FAILED_FETCH_CAMPAIGNS:
            return {...state, isMoreLoading: false, isLoading: false, firstLoading: false}
        
        case ACTION.STORE_PRE_RECORDING:
            return{...state, preRecording: action.payload}

        // case ACTION.STORE_CAMPAIGN_STEP_SETTING:
        //     return{...state, preRecording: action.payload}

        /* Update time setting start */
        case ACTION.UPDATE_TAB_VALUE:
            return {...state, tabValue: action.payload}

        case ACTION.UPDATE_HOUR:
            return {...state, hour: action.payload}

        case ACTION.UPDATE_MIN:
            return {...state, min: action.payload}

        case ACTION.UPDATE_DAY:
            return {...state, day: action.payload}

        case ACTION.UPDATE_RECURRING_DAY:
            return {...state, recurringDay: action.payload}

        case ACTION.UPDATE_LATER_HOUR:
            return {...state, laterHour: action.payload}

        case ACTION.UPDATE_LATER_MIN:
            return {...state, laterMin: action.payload}

        case ACTION.UPDATE_TIME:
            return {...state, time: action.payload}

        case ACTION.UPDATE_START_DATE:
            return {...state, startDate: action.payload}

        case ACTION.UPDATE_ARR_SLOT_TIME_HOUR1:
            return {...state, arrSlotTimeHour1: action.payload}

        case ACTION.UPDATE_ARR_SLOT_TIME_MIN1:
            return {...state, arrSlotTimeMin1: action.payload}

        case ACTION.UPDATE_GREETING_OPTION:
            return {...state, greetingOption: action.payload}

        case ACTION.UPDATE_GREETING_OPTION_TIME_MIN1:
            return {...state, greetingsOptionTimeMin1: action.payload}

        case ACTION.UPDATE_GREETING_OPTION_TIME_HOUR1:
            return {...state, greetingsOptionTimeHour1: action.payload}

        case ACTION.UPDATE_RECURRING_TIME_HOUR1:
            return {...state, recurringTimeHour1: action.payload}

        case ACTION.UPDATE_RECURRING_TIME_MIN1:
            return {...state, recurringTimeMin1: action.payload}
        /* Update time setting end */

        /* Video Email Start */
        case ACTION.STORE_VIDEOS:
            return {...state, ...action.payload}

        case ACTION.STORE_VIDEO_FOLDERS:
            return {...state, videoFolders: action.payload, videoLoader: false}

        case ACTION.UPDATE_FOLDER_ID:
            return {...state, folderId: action.payload}
        /* Video Email End */

        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return {...state, messageTemplates: action.payload}
        case ACTION.SET_FILTER_MESSAGE_TEMPLATE:
            return {...state, filterTemplate: action.payload}
        /* Message Template End */

        case ACTION.STORE_AUDIO_PLAYER:
            return {...state, activeAudioPlayerId: action.payload}

        case ACTION.STORE_CAMPAIGN_ENABLE_TO_START:
            return {...state, canStart: action.payload.data.canStart, canStartCode: action.payload.data.code}

        case ACTION.RELOAD_CAMPAIGN_SETTINGS:
            return {...state, reloadCamapignSettings: action.payload }


        /* for fetching data by scroll */
        case ACTION.STORE_PER_PAGE:
            return {...state, perPage: action.payload}

        case ACTION.STORE_CURRENT_PAGE:
            return {...state, currentPage: action.payload}

        case ACTION.STORE_FETCHING_MORE_FLAG:
            return {...state, fetchingMore: action.payload}
        
        case ACTION.STORE_NEED_TO_FETCH_FLAG:
            return {...state, needTofetch: action.payload}

        case ACTION.QUICK_REPLY_ACTION_TYPES:
            return {...state, type: action.payload}
        /* end */
    
        default:
            return state;
    }
}

export default marketingReducer;
