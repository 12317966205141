import { Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { getUserNumberList, removeNumberFromMessagingServiceApi } from '../../api/marketingApi';
import { ModalAddSenders } from './ModalAddSenders';

export const MessageServiceSenderPool = (props) => {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [numbers,setNumbers] = useState([]);

    useEffect(() => {
        getUserNumberList().then(res => {
            let response = res.data;
            if(response.success){
                setNumbers(response.data);
            }
        }).catch(error => {
            console.log("error", error)
        })
    },[]);

    const handleAddSender = (data) => {
        props.onUpdate(data);
        props.onRefreshData();
    }

    const handleNumberDelete = (sid) => {
        confirmAlert({
            title: 'Are you sure?',
            message: `Do you want to remove this number from the messaging service?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        removeNumberFromMessagingServiceApi({
							user_messaging_service_id: props.data.id, 
							virtual_number_sid: sid
						}).then(res => {
                            let response = res.data;
                            if(response.success){
                                props.onUpdate({
                                    ...props.data,
                                    attached_virtual_numbers : props.data.attached_virtual_numbers.split(',').filter(vnSid => vnSid !== sid).join()
                                });
                            }
                            else{
                                window.showNotification("warning", response.message);
                            }
						})
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const renderServiceList = () => {

        const attachedNumbers = props.data.attached_virtual_numbers === null ? [] : props.data.attached_virtual_numbers.split(',');

        if(attachedNumbers.length === 0) {
            return <tr key={1} className="messaging_list_each_service__empty">
                        <td colSpan={4}>No number added yet !</td>
                    </tr>
        }


        return attachedNumbers.map((item, index) => {

            const number = numbers.find(el => el.sid === item);

            if(!number) return "";

            return <tr key={index} className="messaging_list_each_service">
            <td className="messaging__service__for__underline">
                {number.virtual_number}
            </td>
            <td>{number.sid}</td>
            <td><Button 
                    className='ms__delete_single_number' 
                    size='small'
                    startIcon={<Delete/>}
                    onClick={e => handleNumberDelete(number.sid)}>
                    Delete
                </Button>
            </td>
        </tr>

            
        });
    }

    return (
        <div>
            <h2>Sender Pool</h2>
            <p className='mb-4'>A Sender represents a channel, number type, or messaging type that can receive and send messages using the Twilio Messaging API. Messaging Services come with built-in features to help you select the best Sender for your use case and scale your application.</p>
            <div className="ms__sender_pool_wrapper">
                <Button className='ms__add_senders' onClick={() => setIsOpenModal(true)}>Add Senders</Button>
            </div>

            <div className="messaging_service_list mt-4">
                <table className="cam__list__content_table">
                    <thead className="list__content_table__header">
                        <th className="cam__list__contant__1st">Number</th>
                        <th className="cam__list__contant__2nd">SID</th>
                        <th className="cam__list__contant__3rd">Action</th>
                    </thead>
                    <tbody className="table__body">
                        {renderServiceList()}
                    </tbody>
                </table>
            </div>

            {
                isOpenModal &&
                <ModalAddSenders
                    open={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    numbers={numbers}
                    messagingService={props.data}
                    onUpdate={handleAddSender}
                />
            }

            
        </div>
    )
}
