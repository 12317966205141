export const CircleAddIcon = ({width = '24px', height = '24px', fill= '#006DF5'}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="white"/>
        </svg>
    )
}

export const CancelIcon = ({className, height='19', width='19', fill='#546376', circle=''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 5.3075L13.6925 4.25L9.5 8.4425L5.3075 4.25L4.25 5.3075L8.4425 9.5L4.25 13.6925L5.3075 14.75L9.5 10.5575L13.6925 14.75L14.75 13.6925L10.5575 9.5L14.75 5.3075Z" fill={fill}/>
        </svg>
    );
}