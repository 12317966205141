import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './custom-alert-pypepro.css';
const custom_alert_done_icon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M6.7 18 1.05 12.35 2.475 10.95 6.725 15.2 8.125 16.6ZM12.35 18 6.7 12.35 8.1 10.925 12.35 15.175 21.55 5.975 22.95 7.4ZM12.35 12.35 10.925 10.95 15.875 6 17.3 7.4Z" fill='#28a745'/></svg>
const custom_alert_cancel_icon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M6.4 19 5 17.6 10.6 12 5 6.4 6.4 5 12 10.6 17.6 5 19 6.4 13.4 12 19 17.6 17.6 19 12 13.4Z" fill='#dc3545'/></svg>
const custom_alert_warning_icon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 21Q11.175 21 10.588 20.413Q10 19.825 10 19Q10 18.175 10.588 17.587Q11.175 17 12 17Q12.825 17 13.413 17.587Q14 18.175 14 19Q14 19.825 13.413 20.413Q12.825 21 12 21ZM10 15V3H14V15Z" fill='#ffc107'/></svg>
const custom_alert_question_icon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M10.6 16Q10.6 13.975 10.963 13.087Q11.325 12.2 12.5 11.15Q13.525 10.25 14.062 9.587Q14.6 8.925 14.6 8.075Q14.6 7.05 13.913 6.375Q13.225 5.7 12 5.7Q10.725 5.7 10.062 6.475Q9.4 7.25 9.125 8.05L6.55 6.95Q7.075 5.35 8.475 4.175Q9.875 3 12 3Q14.625 3 16.038 4.463Q17.45 5.925 17.45 7.975Q17.45 9.225 16.913 10.113Q16.375 11 15.225 12.125Q14 13.3 13.738 13.912Q13.475 14.525 13.475 16ZM12 22Q11.175 22 10.588 21.413Q10 20.825 10 20Q10 19.175 10.588 18.587Q11.175 18 12 18Q12.825 18 13.413 18.587Q14 19.175 14 20Q14 20.825 13.413 21.413Q12.825 22 12 22Z" fill='#007bff'/></svg>

const CustomConfirmAlert = (params) => {

    let default_params = {
        handleConfirm : () => {},
        callConfirmLast: false,
        title: 'Are you sure?',
        description: '',
        type_word: '',
        icon: 'question', //done, cancel, warning
        handleOnClose: () => {},
        confirmButtonText: 'Confirm',
        closeButtonText: 'Close',
        showConfirmButton: true,
        showCloseButton: true,
        closeOnClickOutside: false
    }

    let data_params = {...default_params, ...params}
    let {
        handleConfirm, title, description, type_word, icon, handleOnClose, callConfirmLast,
        confirmButtonText, closeButtonText, showConfirmButton, showCloseButton, closeOnClickOutside
    } = data_params

    const handleSubmit = (onClose) => {
        if(type_word !== ''){
            let input_element = document.getElementById('custom_alert_confirmation_input_id')
            if(input_element){
                let value = input_element.value;
                if(value === type_word){
                    document.getElementById("custom_alert_confirmation_input_error_id").className = ""
                    if(callConfirmLast){
                        onClose()
                        setTimeout(() => {
                            handleConfirm()
                        }, 0);
                    }else{
                        handleConfirm()
                        onClose()
                    }
                }else{
                    document.getElementById("custom_alert_confirmation_input_error_id").className = "show_custom_alert_confirmation_input_error"
                }
            }
        }else{
            if(callConfirmLast){
                onClose()
                setTimeout(() => {
                    handleConfirm()
                }, 0);
            }else{
                handleConfirm()
                onClose()
            }
        }
    }

    const renderIcon = () => {
        if(icon === 'question'){
            return custom_alert_question_icon;
        }
        else if(icon === 'done'){
            return custom_alert_done_icon;
        }
        else if(icon === 'cancel'){
            return custom_alert_cancel_icon;
        }
        else if(icon === 'warning'){
            return custom_alert_warning_icon;
        }
        return custom_alert_question_icon;
    }

    return (
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom__alert__ui__container__pypepro'>
                    <div className="custom__alert__icon__container">
                        <div className="custom__alert__icon">{renderIcon()}</div>
                        <div className="custom__alert_main_body">
                            {title !== "" &&
                            <div className="custom__alert__head__title">{title}</div>
                            }
                            {description !== "" &&
                            <div className="custom__alert__description">{description}</div>
                            }
                            {type_word !== '' &&
                            <>
                            <div className="custom_alert_type_word_body">
                                <span className="custom_alert_type_text">Type </span>
                                <span className="custom_alert_type_word">{type_word}</span>
                                <span className="custom_alert_type_text"> below to confirm.</span>
                            </div>
                            <div className="custom_alert_confirmation_input_container">
                                <input 
                                    className="custom_alert_confirmation_input"
                                    placeholder='Type hint word here'
                                    id="custom_alert_confirmation_input_id"
                                />
                                <span id="custom_alert_confirmation_input_error_id">Type the text correctly. Text is case-sensitive !</span>
                            </div>
                            </>
                            }
                        </div>
                        <div className="custom__alert__footer__container">
                            {showCloseButton &&
                            <div onClick={() => { handleOnClose(); onClose();}} className="custom__alert__footer__cancel">{closeButtonText}</div>
                            }
                            {showConfirmButton &&
                            <div onClick={() => {handleSubmit(onClose)}} className="custom__alert__footer__confirm">{confirmButtonText}</div>
                            }
                        </div>
                    </div>
                </div>
              );
            },
            closeOnClickOutside: closeOnClickOutside,
            overlayClassName: "overlay-custom-class-name"
        })
    )
}
export default CustomConfirmAlert;