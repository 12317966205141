/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { getCustomFields } from './redux/cardBroadcastApi';
import {connect} from 'react-redux'
import { storeCustomFieldsAction } from './redux/cardBroadcastAction';
const CONTACT_EMAIL = 'CONTACT EMAIL';
const FIRST_NAME = 'FIRST NAME';
const LAST_NAME = 'LAST NAME';
const MY_VIRTUAL_NUMBER = 'My virtual number';
const MY_COMPANY = 'My Company';
const MY_NAME = 'My Name';
const MY_FIRST_NAME = 'My First Name';
const MY_LAST_NAME = 'My Last Name';
const MY_PHONE = 'My Phone';
// const MY_SIGNATURE = 'My Signature';
const MY_APPOINTMENT_URL = 'My Appointment URL';
const TODAY = 'Today Date';
const TOMORROW = 'Tomorrow Date';
const CONTACT_COUNTRY = 'Property Country';
const CONTACT_STATE = 'Property State';
const CONTACT_STREET_ADDRESS = 'Property Street Address';
const CONTACT_URL = 'Property URL';
const CONTACT_ZIP_CODE = 'Property Zip Code';
const CONTACT_PHONE_NUMBER = 'Contact Phone Number';
const CONTACT_CITY = 'Contact City';
const CONTACT_DEAL_VALUE = 'Contact Deal Value';
const CONTACT_BIRTH_DATE = 'Contact Birth Date';
const CONTACT_ANNIVERSARY_DATE = 'Contact Anniversary Date';
const CONTACT_COMPANY_NAME = 'Contact Company Name';

export const withOutCustomsFields = [
    {
      value: 'blue',
      label: 'System Defined',
      color: '#f2f2f2',
      isDisabled: true
    },
    {
        label : FIRST_NAME ,
        value : '[[first_name]]'
    },
    {
        label : LAST_NAME ,
        value :  '[[last_name]]'
    },
    {
        label : CONTACT_EMAIL,
        value : '[[email]]'
    },
    {
        label : CONTACT_PHONE_NUMBER,
        value : '[[contact_phone_number]]'
    },
    {
        label : MY_VIRTUAL_NUMBER,
        value : '[[virtual_number]]'
    },
    {
        label : MY_COMPANY ,
        value : '[[company]]'
    },
    {
        label : MY_NAME ,
        value : '[[my_name]]'
    },
    {
        label : MY_FIRST_NAME ,
        value : '[[my_first_name]]'
    },
    {
        label : MY_LAST_NAME ,
        value : '[[my_last_name]]'
    },
    {
        label : MY_PHONE ,
        value : '[[my_phone]]'
    },
    {
        label :MY_APPOINTMENT_URL,
        value  : '[[appointment_url]]'
    },
    {
        label : TODAY,
        value  : '[[today]]'
    },
    {
        label : TOMORROW,
        value  : '[[tomorrow]]'
    },
    {
        label : CONTACT_COUNTRY,
        value  : '[[contact_country]]'
    },
    {
        label : CONTACT_STATE,
        value : '[[contact_state]]'
    },
    {
        label : CONTACT_CITY,
        value : '[[contact_city]]'
    },
    {
        label : CONTACT_STREET_ADDRESS,
        value : '[[contact_street_address]]'
    },
    {
        label : CONTACT_COMPANY_NAME,
        value : '[[contact_company_name]]'
    },
    {
        label : CONTACT_URL,
        value : '[[contact_url]]'
    },
    {
        label :CONTACT_ZIP_CODE,
        value : '[[contact_zip_code]]'
    },
    {
        label :CONTACT_DEAL_VALUE,
        value : '[[contact_deal_value]]'
    },
    {
        label :CONTACT_BIRTH_DATE,
        value : '[[contact_birth_date]]'
    },
    {
        label :CONTACT_ANNIVERSARY_DATE,
        value : '[[contact_anniversary_date]]'
    },
    {
      value: 'blue',
      label: 'Contact Customize Fields',
      color: '#f2f2f2',
      isDisabled: true
    }

];

const CustomSelectBox =(props)=>{

    const [customData, setCustomData] = useState([])

    const handlePersonalize = (options) =>{
        // It will return the value of personalize tag
        props.handlePersonalize(options.value);
    }

    useEffect(() => {

        if(props.storedPersonalizeFields.length === 0){
            let data = [];
            getCustomFields({})
                .then(res => {
                    let customFields = [];
                    res.data.data.forEach((row) => {
                        customFields.push({
                            label : row.title,
                            value : row.personalize_tag,
                        });
                    });
                    data  = withOutCustomsFields.concat(customFields);

                    let personalizedFields = [];
                    personalizedFields.push({
                    value: 'blue',
                    label: 'User Customize Fields',
                    color: '#cdcdcd',
                    isDisabled: true
                    });
                    res.data.personalizedData.forEach((row) => {
                        personalizedFields.push({
                            label : row.title,
                            value : row.personalize_tag,
                        });
                    });
                    data  = data.concat(personalizedFields);

                }).then(response =>{
                    
                    setCustomData(data)
                    props.storeCustomFieldsAction(data)
            });
        }else{
            setCustomData(props.storedPersonalizeFields)
        }
    },[])



        /*let fields = this.state.customData.map(function(data,index) {
            return (
                <option key={index} value={data.value}>{data.label}</option>
            )
        })*/

        return (
            /*<select onChange={this.handlePersonalize} className=" col-md-4 form-control ml-auto mb-2">
                <option value="">Personalize</option>
                {fields}
            </select>*/
            <Select
                name="personalize"
                value = {null}
                options={customData}
                menuPlacement={props.menuPlacement !== undefined ? props.menuPlacement : 'auto'}
                placeholder={'Personalize'}
                onChange={handlePersonalize}
                className={(props.customClass === undefined) ? "col-md-4 form-control ml-auto mb-2 border-0 custom-personalize-box" : props.customClass}
            />
        )



}

const mapStateToProps = state => {
    return {
        storedPersonalizeFields: state.cardBroadcastReducer.storedPersonalizeFields
    };
};
const mapDispatchToProps = dispatch => {
    return {
       //New Component
 
 
       //End New Component
       storeCustomFieldsAction: (params, callback) => dispatch(storeCustomFieldsAction(params, callback))

    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomSelectBox);
