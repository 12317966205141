import React, {useRef} from "react";
import AwesomeTextarea from "../../AwesomeTextarea";
import {isJsonString, isXmlString} from "../../../helpers";
import {bodyType} from "../../../constants";
import {useSWHKContext} from "../../../reducers/SWHKProvider";
import {handleState} from "../../../reducers/Actions";
import GlobalCustomFieldGrouped from "../../GlobalCustomFieldGrouped/Index";
import Styles from "./Styles.module.css";

const App = ({callback}) => {
    const heightTextBox = window.innerHeight - 585 / 2;
    const {state, dispatch} = useSWHKContext();
    const textRawInputRef = useRef(null);

    return(
          <div className={Styles.wbh_payload_body_raw}>
              <div className={Styles.wbh_payload_body_form_data_textarea} style={{ height: `calc(100vh - ${heightTextBox}px)`}} >
                  <AwesomeTextarea
                      isInvalid={(!isJsonString(state.rawBodyData) &&
                          state.bodyType === bodyType.JSON) ||
                          (!isXmlString(state.rawBodyData) &&
                              state.bodyType === bodyType.XML
                          )}
                      textRawInputRef={textRawInputRef}
                      value={state.rawBodyData}
                      onUpdate={(value)=>{
                          dispatch(handleState({
                            rawBodyData: value
                          }));
                          callback({
                             bodyData: value
                          });
                      }}
                  ></AwesomeTextarea>
                  <GlobalCustomFieldGrouped className={`raw_body_personalized wbh_global__personalized ${Styles.wbh_raw_body_ps}`} handleSelect={(placeholderValue)=>{
                      if (textRawInputRef.current){
                          let start = textRawInputRef.current.selectionStart;
                          let end = textRawInputRef.current.selectionEnd;
                          let text = textRawInputRef.current.value;
                          let before = text.substring(0, start);
                          let after = text.substring(end, text.length);

                          let newText = (before + placeholderValue + after);

                          dispatch(handleState({
                              rawBodyData: newText
                          }));
                          callback({
                              bodyData: newText
                          });
                          setTimeout(() => {
                              textRawInputRef.current.selectionStart = textRawInputRef.current.selectionEnd = start + placeholderValue.length;
                              textRawInputRef.current.focus();
                          }, 500);
                      }
                  }} labelText={"Personalized"}/>
              </div>
          </div>
    );
};

export default App;