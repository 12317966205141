import React, {useState} from "react";
import {bodyType, methodLabel, tab} from "../../constants";
import {
    getBodyContentType,
    isJsonString,
    isXmlString,
    prettifyJson,
    prettifyXml
} from "../../helpers";
import {useSWHKContext} from "../../reducers/SWHKProvider";
import {CollapsedArrowIcon} from "../../icons";
import Styles from "./Styles.module.css";

const App = () => {
    const {state, dispatch} = useSWHKContext();
    const [collapsed, setCollapsed] = useState(false);
    const height = window.innerHeight - 458;

      return(
          <div className={`${Styles.wbh_output_preview_wrapper} ${collapsed ? Styles.collapsed : ''} wbh_output_preview_wrapper`}>
              <div className={Styles.wbh_opw_http}>
                  <div className={Styles.wbh_opw_header}>
                        <span className={Styles.wbh_opwh_icon} onClick={() => setCollapsed(!collapsed)}>
                            <CollapsedArrowIcon />
                        </span>

                      {collapsed ? <span className={Styles.wbh_text_expand}>Expand</span> : <span>Http Request Body</span>}
                  </div>


                  <pre className={`${Styles.pre} awesome__scroll_bar`} style={{ height: `calc(100vh - ${height}px)`}}>
                        <code>
                            {methodLabel[state.method]} /{state.queryParamsString} HTTP/1.1 <br/>
                            Host: {state.url} <br/>
                            {state.currentBodyTab !== tab.NONE_TAB && (<>Content-Type: {getBodyContentType(state.bodyType)} <br/></>)}
                            {(state.rawBodyData && state.currentBodyTab !== tab.NONE_TAB) ? (<>Content-Length: {state.rawBodyData.length} <br/><br/></>) : ""}
                            {
                                (state.currentBodyTab === tab.RAW_TAB) && (
                                    (isJsonString(state.rawBodyData) && state.bodyType === bodyType.JSON) ?
                                        (
                                            <div className="json_preview_wbh" dangerouslySetInnerHTML={{ __html: prettifyJson(state.rawBodyData) }}/>
                                        ) :
                                        (
                                            (isXmlString(state.rawBodyData) && state.bodyType === bodyType.XML) ?
                                                prettifyXml(state.rawBodyData) :
                                                state.rawBodyData
                                        ))

                            }

                            {
                                (state.currentBodyTab === tab.FORM_DATA_TAB && state.formData && state.formData.length > 0 && (state.formData[0].key !== "" || state.formData[0].value !== "")) && (state.formData.map((body)=>(
                                    <>
                                        <br/>
                                        Content-Disposition: form-data; name="{body.key}" <br/>
                                        {body.value} <br/>
                                    </>
                                )))
                            }
                        </code>
                    </pre>
              </div>
          </div>
  );
};

export default App;