const icons = {
    plus : <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.4754" r="10" fill="white"/>
            <path d="M13.8461 10.4754C13.8461 10.8299 13.8153 11.1165 13.4609 11.1165H10.641V13.9363C10.641 14.2902 10.3544 14.3216 9.99996 14.3216C9.64548 14.3216 9.35894 14.2902 9.35894 13.9363V11.1165H6.53906C6.18522 11.1165 6.15381 10.8299 6.15381 10.4754C6.15381 10.1209 6.18522 9.8344 6.53906 9.8344H9.35894V7.01453C9.35894 6.66004 9.64548 6.62927 9.99996 6.62927C10.3544 6.62927 10.641 6.66004 10.641 7.01453V9.8344H13.4609C13.8153 9.8344 13.8461 10.1209 13.8461 10.4754Z" fill="#3C7EF3"/>
        </svg>,
    preview: <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="15" fill="#6258FF" fillOpacity="0.5"/>
                <rect x="2.5" y="2.5" width="25" height="25" rx="12.5" fill="#6258FF"/>
                <g clipPath="url(#clip0)">
                    <path d="M15 16.6094C14.112 16.6094 13.3906 15.888 13.3906 15C13.3906 14.1121 14.112 13.3907 15 13.3907C15.8879 13.3907 16.6093 14.1121 16.6093 15C16.6093 15.888 15.8879 16.6094 15 16.6094ZM14.8779 15.4773C14.6781 15.4773 14.5116 15.3108 14.5116 15.111H13.9789C13.99 15.6105 14.3895 16.01 14.8779 16.01V15.4773Z" fill="white"/>
                    <path d="M19.8835 15.3329C19.3396 16.0099 17.3973 18.2186 15 18.2186C12.6027 18.2186 10.6604 16.0099 10.1165 15.3329C9.96115 15.1442 9.96115 14.8668 10.1165 14.667C10.6604 13.99 12.6027 11.7813 15 11.7813C17.3973 11.7813 19.3396 13.99 19.8835 14.667C20.0388 14.8557 20.0388 15.1331 19.8835 15.3329ZM15 12.6692C13.7125 12.6692 12.6693 13.7125 12.6693 15C12.6693 16.2874 13.7125 17.3307 15 17.3307C16.2875 17.3307 17.3307 16.2874 17.3307 15C17.3307 13.7125 16.2875 12.6692 15 12.6692Z" fill="white"/>
                </g>
                <rect x="2.5" y="2.5" width="25" height="25" rx="12.5" stroke="#F6F7FB"/>
                <defs>
                    <clipPath id="clip0">
                        <rect width="10" height="10" fill="white" transform="matrix(1 0 0 -1 10 20)"/>
                    </clipPath>
                </defs>
            </svg>,
    createCampaign: <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" rx="15" fill="#3C7EF3" fillOpacity="0.5"/>
                    <rect x="2.5" y="2.5" width="25" height="25" rx="12.5" fill="#3C7EF3"/>
                    <path d="M19.7102 15C19.7102 15.4341 19.6725 15.7851 19.2384 15.7851H15.7851V19.2384C15.7851 19.6717 15.4342 19.7102 15.0001 19.7102C14.5659 19.7102 14.215 19.6717 14.215 19.2384V15.7851H10.7617C10.3284 15.7851 10.2899 15.4341 10.2899 15C10.2899 14.5659 10.3284 14.215 10.7617 14.215H14.215V10.7617C14.215 10.3276 14.5659 10.2899 15.0001 10.2899C15.4342 10.2899 15.7851 10.3276 15.7851 10.7617V14.215H19.2384C19.6725 14.215 19.7102 14.5659 19.7102 15Z" fill="white"/>
                    <rect x="2.5" y="2.5" width="25" height="25" rx="12.5" stroke="#F6F7FB"/>
                </svg>,
    search: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fillOpacity="0.65"/>
        </svg>

}
export default icons;