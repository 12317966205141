import { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { MenuItem } from "@material-ui/core";
import { DropdownMenuProps } from "../../helpers/DropdownSettings";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { addNumberInMessagingServiceApi, createCampaignUseCaseApi, createMessagingServiceApi, getUserA2pBrnadListApi, getUserMessagingServiceApi, getUserNumberList } from "../../api/marketingApi";
import './campaign-registration.css'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import NumberDropdown from "../messagingService/Dropdown/Index";

const MESSAGE_SERVICE_FOR = [
    {value: 'Notify my users', title: 'Notify my users'},
    {value: 'Market my services', title: 'Market my services'},
    {value: 'Verify users', title: 'Verify users'},
    {value: 'Engage in a discussion', title: 'Engage in a discussion'},
    {value: 'Poll or service users', title: 'Poll or service users'},
    {value: 'Not listed here', title: 'Not listed here'}
];

const CAMPAIGN_USE_CASE = [
    {value: '2FA', title: '2FA', description: 'Any authentication or account verification such as OTP'},
    {value: 'Account Notifications', title: 'Account Notifications', description: 'Notifications about the status of an account or related to being a part of an account'},
    {value: 'Customer Care', title: 'Customer Care', description: 'Support, account management, and other avenues of customer interaction'},
    {value: 'Delivery Notifications', title: 'Delivery Notifications', description: 'Information about the status of a delivery'},
    {value: 'Fraud Alert Messaging', title: 'Fraud Alert Messaging', description: 'Messaging about potential fraudulent activity such as spending alerts'},
    {value: 'Higher Education', title: 'Higher Education', description: 'Message campaigns from colleges, universities, and other education institutions'},
    {value: 'Marketing', title: 'Marketing', description: 'Promotional content such as sales and limited time offers'},
    {value: 'Mixed', title: 'Mixed', description: 'A campaign that covers multiple use cases such as Customer Care and Delivery Notifications. Note - mixed campaigns are likely to have lower throughput and a higher cost per message.'},
    {value: 'Polling and voting', title: 'Polling and voting', description: 'For conducting polling and voting, such as customer surveys. Not for political use.'},
    {value: 'Public Service Announcement', title: 'Public Service Announcement', description: 'PSAs to raise audience awareness about a given topic'},
    {value: 'Security Alert', title: 'Security Alert', description: 'Notification of a compromised system (software or hardware related)'}
];

const SET_UP_PAGE = 1;
const CREATE_NEW_SERVICE = 2;
const ADD_SENDER = 3;
const CAMPAIGN_REGISTRATION = 4;
const SUBMIT = 4;
/* 
"a2p_10dlc_status": 1, //0 = not, 1 = yes, 3 = pending
*/

const CampaignRegistration = () => {

    const [pageNumber, setPageNumber] = useState(1);
    /* 
        1 = set up page
        2 = create new service
        3 = add sender
        4 = register campaign use case
    */
    const [submitting, setSubmitting] = useState(false);
    const [numbers, setNumbers] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [a2pBrandList, setA2pBrandList] = useState([]);
    /* first step */
    const [isCreateNew, setIsCreateNew] = useState(true)
    const [selectedService, setSelectedService] = useState(null)

    /* form field */
    const [friendlyName, setFriendlyName] = useState('')
    const [serviceFor, setServiceFor] = useState(null);
    const [sender, setSender] = useState([]);
    const [useCase, setUseCase] = useState(null);
    const [useCaseDescription, setUseCaseDescription] = useState('');
    const [sampleMessage1, setSampleMessage1] = useState('');
    const [sampleMessage2, setSampleMessage2] = useState('');
    const [hasIncludeEmbeddedLink, setHasIncludeEmbeddedLink] = useState(false);
    const [hasIncludeNumber, setHasIncludeNumber] = useState(false);
    const [a2pBrandRegistrationSid, setA2pBrandRegistrationSid] = useState(null);

    const [agreeToAddVn, setAgreeToAddVn] = useState(false)

    const [selectedIds, setSelectedIds] = useState([])

    useEffect(() => {
        getUserNumberList().then(res => {
            let response = res.data;
            if(response.success){
                setNumbers(response.data)
            }
        }).catch(error => {
            console.log("error", error)
        })
        getUserMessagingService();
        getUserA2pBrandList();
    }, [])

    const getUserMessagingService = () => {
        getUserMessagingServiceApi().then(res => {
            let response = res.data;
            if(response.success){
                setServiceList(response.data)
            }
        })
    }

    const getUserA2pBrandList = () => {
        getUserA2pBrnadListApi().then(res => {
            let response = res.data;
            if(response.success){
                setA2pBrandList(response.data)
            }
        }).catch(error => {
            console.log("error")
        })
    }

    const handleServiceChange = (e) => {
        e.preventDefault();
        setServiceFor( e.target.value);
    }
    
    const handleSenderChange = (e) => {
        e.preventDefault();
        setSender( e.target.value);
    }
    
    const handleUseCaseChange = (e) => {
        e.preventDefault();
        setUseCase( e.target.value);
    }
    
    const handleA2pBrandChange = (e) => {
        e.preventDefault();
        setA2pBrandRegistrationSid( e.target.value);
    }
    
    const handleUseCaseDescriptionChange = (value) => {
        setUseCaseDescription(value);
    }

    const handleSubmit = (page, callBack) => {
        if(submitting){
            return
        }
        setSubmitting(true)
        if(page === CREATE_NEW_SERVICE){
            let create_message_form = {
                message_service_friendly_name: friendlyName,
                messaging_service_usage: serviceFor
            }
            createMessagingServiceApi(create_message_form).then(res => {
                let response = res.data;
                if(response.success){
                    setSelectedService(response.data)
                    if(callBack !== undefined){
                        callBack();
                    }
                }
                else{
                    window.showNotification("warning", "Something went wrong! Please try again later");
                }
                setSubmitting(false)
            }).catch(error => {
                console.log(error)
                setSubmitting(false)
            })
            return
        }
        else if(page === ADD_SENDER){
            let vn_data = [];
            sender.forEach((item, index) => {
                vn_data.push(item.sid)
            })
            let add_vn_form = {
                user_messaging_service_id: selectedService.id,
                virtual_number_sids: vn_data
            }
            addNumberInMessagingServiceApi(add_vn_form).then(res => {
                let response = res.data;
                if(response.success){
                    let data = selectedService
                    data.attached_virtual_numbers = sender.join();
                    setSelectedService(data)
                    if(callBack !== undefined){
                        callBack();
                    }
                    setAgreeToAddVn(false)
                }
                else{
                    window.showNotification("warning", response.message);
                }
                setSubmitting(false)
            }).catch(error => {
                console.log(error)
                setSubmitting(false)
            })
           return
        }
        else if(page === SUBMIT){
            if(
                a2pBrandRegistrationSid === null ||
                useCaseDescription.trim() === "" ||
                sampleMessage1.trim() === "" ||
                sampleMessage2.trim() === "" ||
                useCase == null ||
                sender.length === 0
            ){
                window.showNotification("warning", "Need to fill up form. All field is required !");
                setSubmitting(false)
                return;
            }

            if(sampleMessage1.length < 15 || sampleMessage1.length > 255){
                window.showNotification("warning", "Description can not be less than 15 letters and more than letters");
                setSubmitting(false)
                return;
            }

            if(sampleMessage2.length < 15 || sampleMessage2.length > 255){
                window.showNotification("warning", "Description can not be less than 20 letters and more than letters");
                setSubmitting(false)
                return;
            }

            if(useCaseDescription.length < 15 || useCaseDescription.length > 255){
                window.showNotification("warning", "Description can not be less than 20 letters and more than letters");
                setSubmitting(false)
                return;
            }

            let campaign_use_case = useCase.replace(/ /g, '_')

            let form_data = {
                a2p_brand_registration_sid: a2pBrandRegistrationSid,
                description: useCaseDescription,
                sampleMessage1: sampleMessage1,
                sampleMessage2: sampleMessage2,
                usAppToPersonUsecase: campaign_use_case,
                hasEmbeddedLinks: hasIncludeEmbeddedLink ? 'True' : 'False',
                hasEmbeddedPhone: hasIncludeNumber ? 'True' : 'False',
                user_messaging_service_id: selectedService.id
            }
            createCampaignUseCaseApi(form_data).then(res => {
                let response = res.data;
                if(response.success){
                    getUserMessagingService()
                    setIsCreateNew(true)
                    setPageNumber(SET_UP_PAGE)
                }else{
                    window.showNotification("warning", "Something went wrong! Please try agian");
                }
                setSubmitting(false)
            }).catch(error => {
                setSubmitting(false)
                console.log(error)
            })
        }
    }

    const changeStep = (page) => {
        if(page === CREATE_NEW_SERVICE){
            if(isCreateNew){
                setFriendlyName("")
                setServiceFor(null)
                setSender([])
                setUseCase(null)
                setUseCaseDescription('')
                setSampleMessage1('')
                setSampleMessage2('')
                setHasIncludeEmbeddedLink(false)
                setHasIncludeNumber(false)
                setPageNumber(CREATE_NEW_SERVICE)
            }
            else{
                if(selectedService === null){
                    return;
                }
                setFriendlyName(selectedService.messaging_service_name)
                setServiceFor(selectedService.messaging_service_usage)
                if(selectedService.attached_virtual_numbers !== undefined && selectedService.attached_virtual_numbers != null && selectedService.attached_virtual_numbers !== ""){
                    try{
                        let data = selectedService.attached_virtual_numbers.split(',')
                        if(data.length > 0){
                            let old_data = []
                            numbers.forEach((item, index) => {
                                if(data.includes(item.sid)){
                                    old_data.push(item)
                                }
                            })
                            setSender(old_data);
                            setPageNumber(CAMPAIGN_REGISTRATION)
                        }
                    }catch(error){
                        setPageNumber(ADD_SENDER)
                    }
                }else{
                    setPageNumber(ADD_SENDER)
                }
            }
        }else if (page === ADD_SENDER){
            if(friendlyName !== "" && serviceFor != null){
                handleSubmit(CREATE_NEW_SERVICE, () => {
                    setPageNumber(ADD_SENDER)
                })
            }else{
                window.showNotification("warning", "Need to fill up form. All field is required !");
            }
        }else if(page === CAMPAIGN_REGISTRATION){
            if(agreeToAddVn && sender.length !== 0){
                handleSubmit(ADD_SENDER, () => {
                    setPageNumber(CAMPAIGN_REGISTRATION)
                })
            }
            else{
                window.showNotification("warning", "Need to fill up form. All field is required !");
            }
        }
    }

    const renderServiceFor = () => {
        let serviceView = [];
        MESSAGE_SERVICE_FOR.forEach((item, index) => {
            serviceView.push(
                <MenuItem 
                    className="menuitem-li-hover-color-with-border" 
                    key={index} 
                    value={item.value}
                >
                    {item.title}
                </MenuItem>
            )
        })
        return serviceView; 
    }

    const renderSender = () => {
        let serviceView = [];
        numbers.forEach((item, index) => {
            serviceView.push(
                <MenuItem 
                    className="menuitem-li-hover-color-with-border" 
                    key={index} 
                    value={item}
                >
                    {item.virtual_number}
                </MenuItem>
            )
        })
        return serviceView; 
    }
    
    const renderUseCase = () => {
        let serviceView = [];
        CAMPAIGN_USE_CASE.forEach((item, index) => {
            serviceView.push(
                <MenuItem 
                    className="menuitem-li-hover-color-with-border menuitem__li__display__block" 
                    key={index} 
                    value={item.value}
                >
                    <p className="each__item__title">{item.title}</p>
                    <p className="each__item__des">{item.description}</p>
                </MenuItem>
            )
        })
        return serviceView; 
    }

    const renderA2pBrandList = () => {
        let serviceView = [];
        a2pBrandList.forEach((item, index) => {
            serviceView.push(
                <MenuItem 
                    className="menuitem-li-hover-color-with-border menuitem__li__display__block" 
                    key={index} 
                    value={item.us_a2p_brand_registration_sid}
                >
                    <p className="each__item__title">{item.us_a2p_brand_name}</p>
                    <p className="each__item__des">{item.us_a2p_brand_registration_sid}</p>
                </MenuItem>
            )
        })
        return serviceView; 
    }

    const renderServiceList = () => {
        let view = [];
        serviceList.forEach((item, index) => {
            view.push(
                <tr key={index} className="messaging_list_each_service">
                    <td className="messaging__service__for__checkbox" onClick={() => setSelectedService(item)}>
                        {item.a2p_10dlc_status !== 1 &&
                        <>
                        {item === selectedService ?
                            <RadioButtonCheckedIcon className="active__radio__campaign" /> : <RadioButtonUncheckedIcon className="deactive__radio__campaign" />
                        }
                        </>
                        }
                    </td>
                    <td className="messaging__service__for__underline">{item.messaging_service_name}</td>
                    <td >{item.messaging_service_sid}</td>
                    {item.a2p_10dlc_status === 0 ? 
                        <td className="not__registered"><span><WarningIcon /></span><span> Not Registered</span></td>
                     :
                        item.a2p_10dlc_status === 1 ?
                            <td className="not__registered"><span><CheckCircleIcon /></span><span>Registered</span></td>
                        :
                            <td className="not__registered"><span><MoreHorizIcon /></span><span>Pending</span></td>
                    }
                   
                </tr>
            )
        })
        if(view.length === 0){
            view.push(
                <tr key={1} className="messaging_list_each_service__empty">
                   <td colSpan={4}>No service created yet !</td>
                </tr>
            )
        }
        return view;
    }

    const renderButton = () => {
        if(pageNumber === SET_UP_PAGE){
            if(isCreateNew){
                return(
                    <div className="campaign__registration__form__btn__wrapper">
                        <div className="campaign__registration__form__btn" onClick={() => changeStep(CREATE_NEW_SERVICE)}>{'Continue'}</div>
                    </div>
                )
            }
            return(
                <div className="campaign__registration__form__btn__wrapper">
                    <div className={`campaign__registration__form__btn ${selectedService === null ? 'disabled__button' : ''}`} onClick={selectedService == null ? () => {} : () => changeStep(CREATE_NEW_SERVICE)}>{'Continue'}</div>
                </div>
            )
        }
        else if(pageNumber === CREATE_NEW_SERVICE){
            return(
                <div className="campaign__registration__form__btn__wrapper">
                    <div className="campaign__registration__form__btn" onClick={() => setPageNumber(SET_UP_PAGE)}>{'Back'}</div>
                    {selectedService == null ?
                        <div className="campaign__registration__form__btn" onClick={() => changeStep(ADD_SENDER)}>{submitting ? 'Saving' : 'Save & Continue'}</div>
                    :
                        <div className="campaign__registration__form__btn" onClick={() => setPageNumber(ADD_SENDER)}>{'Next'}</div>
                    }
                </div>
            )
        }else if(pageNumber === ADD_SENDER){
            return(
                <div className="campaign__registration__form__btn__wrapper">
                    <div className="campaign__registration__form__btn" onClick={() => setPageNumber(CREATE_NEW_SERVICE)}>{'Back'}</div>
                    <div className="campaign__registration__form__btn" onClick={() => changeStep(CAMPAIGN_REGISTRATION)}>{submitting ? 'Adding' : 'Add Phone Numbers to the Messaging Service'}</div>
                </div>
            )
        }
        else if(pageNumber === CAMPAIGN_REGISTRATION){
            return(
                <div className="campaign__registration__form__btn__wrapper">
                    <div className="campaign__registration__form__btn" onClick={() => setPageNumber(ADD_SENDER)}>{'Back'}</div>
                    <div className="campaign__registration__form__btn" onClick={() => handleSubmit(SUBMIT)}>{submitting ? 'Registering' : 'Register campaign use case'}</div>
                </div>
            )
        }       
    }

    const handleMenuChange = (page) => {
        if(page === SET_UP_PAGE){
            setSelectedService(null)
            setFriendlyName("")
            setServiceFor(null)
            setSender([])
            setUseCase(null)
            setUseCaseDescription('')
            setSampleMessage1('')
            setSampleMessage2('')
            setHasIncludeEmbeddedLink(false)
            setHasIncludeNumber(false)
            setPageNumber(page)
            return;
        }else{
            if(pageNumber === SET_UP_PAGE){
                return
            }
        }
        if(page <= pageNumber ){
            setPageNumber(page)
        }else{
            if(page === CREATE_NEW_SERVICE){
                if(isCreateNew){
                    setPageNumber(page)
                }
                else{
                    if(selectedService === null){
                        return;
                    }
                    setFriendlyName(selectedService.messaging_service_name)
                    setServiceFor(selectedService.messaging_service_usage)
                    if(selectedService.attached_virtual_numbers !== undefined && selectedService.attached_virtual_numbers != null && selectedService.attached_virtual_numbers !== ""){
                        try{
                            let data = selectedService.attached_virtual_numbers.split(',')
                            if(data.length > 0){
                                let old_data = []
                                numbers.forEach((item, index) => {
                                    if(data.includes(item.sid)){
                                        old_data.push(item)
                                    }
                                })
                                setSender(old_data);
                            }
                        }catch(error){ }
                    }
                    setPageNumber(page)
                }
            }
            else if(page === ADD_SENDER){
                if(selectedService === null || friendlyName === '' || serviceFor == null){
                    return;
                }
                setPageNumber(page)
            }
            else if(page === CAMPAIGN_REGISTRATION){
                if(selectedService === null || friendlyName === '' || serviceFor == null || sender.length === 0){
                    return;
                }
                setPageNumber(page)
            }
        }
    }

    const renderLeftMenu = () => {
        return(
            <ul>
                <li className={`campaign__registration__left__single__span ${pageNumber === SET_UP_PAGE ? 'left__campaign__regActive' : 'left__campaign__step__done'}`} onClick={() => handleMenuChange(SET_UP_PAGE)}>
                    <span>Set Up Messaging Service</span>
                    {pageNumber === 1 ?
                        <span className="warning__campaign__reg"><WarningIcon/></span>
                    :
                        <span className="check__campaign__reg"><CheckCircleIcon/></span>
                    }
                </li>
                <li className={`campaign__registration__left__single__span ${pageNumber === CREATE_NEW_SERVICE ? 'left__campaign__regActive' : ((friendlyName !== '' && serviceFor != null) ? 'left__campaign__step__done': '')}`} onClick={() => handleMenuChange(CREATE_NEW_SERVICE)}>
                    <span>Create Messaging Service</span>
                    {pageNumber === 1 ?
                        <span className="warning__campaign__reg"><WarningIcon/></span>
                    :
                        (friendlyName !== '' && serviceFor != null) ?
                                <span className="check__campaign__reg"><CheckCircleIcon/></span>
                            :
                                <span className="warning__campaign__reg"><WarningIcon/></span>
                    }
                </li>
                <li className={`campaign__registration__left__single__span ${pageNumber === ADD_SENDER ? 'left__campaign__regActive' : (sender.length > 0 ? 'left__campaign__step__done': '')}`} onClick={() => handleMenuChange(ADD_SENDER)}>
                    <span>Add Senders</span>
                    {pageNumber === SET_UP_PAGE ?
                        <span className="warning__campaign__reg"><WarningIcon/></span>
                    :
                        (pageNumber !== ADD_SENDER && sender.length > 0) ?
                            <span className="check__campaign__reg"><CheckCircleIcon/></span>
                        :
                            <span className="warning__campaign__reg"><WarningIcon/></span>
                    }
                </li>
                <li className={`campaign__registration__left__single__span ${pageNumber === CAMPAIGN_REGISTRATION ? 'left__campaign__regActive' : ''}`} onClick={() => handleMenuChange(CAMPAIGN_REGISTRATION)}>
                    <span>Register Campaign Use Case</span>
                    <span className="warning__campaign__reg"><WarningIcon/></span>
                </li>
            </ul>
        )
    }

    /* for sender */
    const handleSelectUser = (item, remove) => {
        if(item.value === '__clear_all__'){
            setSender([])
            setSelectedIds([])
            return
        }
        if(item.value === '__all__'){
            if(remove){
                setSender([])
                setSelectedIds([])
            }
            else{
                setSender([item])
                setSelectedIds(['__all__'])
            }
        }else{
            if(selectedIds.length > 0 && selectedIds[0] === '__all__'){
                setSender([item])
                setSelectedIds([item.sid])
            }
            else{
                let old_data_ids = [...selectedIds]
                let old_data_full = [...sender]
                if(remove){
                    let filter_old_data_ids = old_data_ids.filter(list => item.sid !== list)
                    setSelectedIds(filter_old_data_ids)
    
                    let filter_old_data_full = old_data_full.filter(list => item.sid !== list.sid)
                    setSender(filter_old_data_full)
                }
                else{
                    old_data_ids.push(item.sid)
                    old_data_full.push(item)
                    setSender(old_data_full)
                    setSelectedIds(old_data_ids)
                }
            }
        }
    }

    return(
        <div className="container-fulid campaign__registration__container__v2">
            <div className="row">
                <div className="col s12">
                    <h5 className="campaign__reg__con__v2__title">Register Campaign Use Cases</h5>
                </div>
            </div>

            <div className="campaign__registration__container__inner">
                <div className="campaign__registration__container__left">
                    {renderLeftMenu()}
                </div>

                <div className="campaign__registration__container__right">

                    {pageNumber === SET_UP_PAGE &&
                    <div className="seu__up__list">
                        <h4 className="seu__up__list__h4">Set Up Messaging Service</h4>
                        <p className="seu__up__list__p1">
                            A Messaging Service is a free Twilio feature designed to make it easier to send messages at scale and across multiple countries. You can think of a Messaging Service as a higher-level “bundling” of messaging functionality around a common set of senders (e.g. 10-digit long code phone numbers), features, and configuration.
                        </p>
                        <p className="seu__up__list__p2">
                            Messaging Services are a prerequisite to access A2P 10DLC in the United States. You are not required to make any code changes to the way you currently send messages, but customers commonly use a “Messaging Service SID” instead of a “from number” which lets Twilio handle sender selection logic. This saves months of development time at scale.  
                            <a href="https://www.twilio.com/docs/messaging/services" target={'_blank'} rel={'noreferrer'}>Learn more about Messaging Services</a>
                        </p>

                        <h4 className="seu__up__list__h5">Select a Messaging Service to link to your campaign use case</h4>
                        <div className="campaign__select__radio__wr">
                            <div className="campaign__select__radio__single" onClick={() => setIsCreateNew(true)}>
                                <span className="campaign__select__radio__1st">{isCreateNew ? <RadioButtonCheckedIcon className="active__radio__campaign" /> : <RadioButtonUncheckedIcon className="deactive__radio__campaign" />}</span>
                                <span className="campaign__select__radio__2nd">Create a new Messaging Service</span>
                            </div>

                            <div className="campaign__select__radio__single" onClick={() => setIsCreateNew(false)}>
                                <span className="campaign__select__radio__1st">{!isCreateNew ? <RadioButtonCheckedIcon className="active__radio__campaign" /> : <RadioButtonUncheckedIcon className="deactive__radio__campaign" />}</span>
                                <span className="campaign__select__radio__2nd">Use an existing Messaging Service</span>
                            </div>
                        </div>

                        {!isCreateNew &&
                        <div className="messaging_service_list">
                            <table className="cam__list__content_table">
                                <thead className="list__content_table__header">
                                    <th className="cam__list__contant__1st"></th>
                                    <th className="cam__list__contant__2nd">Messaging Service Name</th>
                                    <th className="cam__list__contant__3rd">Messaging Service SID</th>
                                    <th className="cam__list__contant__4th">US A2P 10DLC Status</th>
                                </thead>
                                <tbody className="table__body">
                                    {renderServiceList()}
                                </tbody>
                            </table>
                        </div>
                        }

                    </div>
                    }

                    {pageNumber === CREATE_NEW_SERVICE &&
                        <div className="create__message__service">
                            <h4 className="seu__up__list__h4">Create Messaging Service</h4>

                            <div className="field__content">
                                <div className="field__label">Messaging Service friendly name</div>
                                <div className="field__input">
                                    <input 
                                        type="text" 
                                        maxLength={20} 
                                        value={friendlyName} 
                                        onChange={(e) => setFriendlyName(e.target.value)} 
                                        placeholder={'Enter friendly name'}
                                        disabled={selectedService == null ? false : true}
                                    />
                                </div>
                                <div className="messaging__identify__tag">
                                    This friendly name will help you identify this Messaging Service at a later point of time.
                                </div>
                            </div>

                            <div className="field__content">
                                <div className="field__label">Select what you want to use this Messaging Service for</div>
                                <div className="field__input field_dropdown">
                                    <Select 
                                        value={serviceFor} 
                                        onChange={handleServiceChange}
                                        MenuProps={DropdownMenuProps}
                                        displayEmpty
                                        disabled={selectedService == null ? false : true}
                                    >
                                        <MenuItem className="menuitem-li-hover-color-with-border" disabled value={null}>{'Select one'}</MenuItem>
                                        {renderServiceFor()}
                                    </Select>
                                </div>
                            </div>

                        </div>
                    }

                    {pageNumber === ADD_SENDER &&
                        <div className="add__sender__container">
                            <h4 className="seu__up__list__h4">Add Senders</h4>
                            <p className="seu__up__list__p2">
                                A Sender represents a channel, number type, or messaging type that can receive and send messages using the Twilio Messaging API. Messaging Services come with built-in features to help you select the best Sender for your use case and scale your application.
                            </p>
                            <h5 className="seu__up__list__h5">
                                Numbers associated to Messaging Service: {selectedService.messaging_service_name}
                            </h5>

                            <div className="field__input field_dropdown">
                                {/* <Select 
                                    value={sender} 
                                    onChange={handleSenderChange}
                                    MenuProps={DropdownMenuProps}
                                    displayEmpty
                                    multiple={true}
                                    renderValue={() => {
                                        if(sender.length > 0){
                                            let view = [];
                                            sender.forEach((item, index) => {
                                                view.push(
                                                    <span key={index} className="each__selected">{item.virtual_number}</span>
                                                )
                                            })
                                            return view;
                                        }
                                        return <span className="no__number__select">Select from list</span>
                                    }}
                                >
                                    <MenuItem className="menuitem-li-hover-color-with-border">{'Select from list'}</MenuItem>
                                    {renderSender()}
                                </Select> */}
                                <NumberDropdown
                                    userList={numbers}
                                    handleSelect = {(value, remove) => handleSelectUser(value, remove)}
                                    selected = {sender}
                                    selectedId = {selectedIds}
                                />
                            </div>

                            <div className="agree_to_add_vn" >
                                <span className="" onClick={() => setAgreeToAddVn(!agreeToAddVn)}>
                                {agreeToAddVn ? 
                                    <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                                }
                                </span>
                                <div>I agree to add only the phone numbers I need for this A2P Campaign use case. Adding excessive numbers to evade spam detection is prohibited by <a href="https://www.twilio.com/legal/messaging-policy" target={'_blank'} rel="noreferrer">Twilio’s Messaging Policy</a> </div>
                            </div>
                        </div>
                    }

                    {pageNumber === CAMPAIGN_REGISTRATION &&
                        <div className="campaign__registration__container">
                            <h4 className="seu__up__list__h4">Register Campaign Use Case</h4>
                            <p className="seu__up__list__p1">You will be submitting this information to carriers so they understand what type of messages you’ll be sending. Each campaign registration costs a one time $50 fee. In addition, carriers charge a monthly fee between $2 and $10 depending on your campaign use case. Declaring your exact campaign use case rather than using a mixed campaign use case will generally result in higher limits from AT&T because you're being more specific about what messages you are sending.</p>
                        
                            <div className="field__content">
                                <div className="field__label">US A2P Brand</div>
                                <div className="field__input field_dropdown">
                                    <Select 
                                        value={a2pBrandRegistrationSid} 
                                        onChange={handleA2pBrandChange}
                                        MenuProps={DropdownMenuProps}
                                        displayEmpty
                                    >
                                        <MenuItem className="menuitem-li-hover-color-with-border" disabled value={null}>{'Select A2P Brand'}</MenuItem>
                                        {renderA2pBrandList()}
                                    </Select>
                                </div>
                                {a2pBrandList.length === 0 &&
                                <span className="extra__bottom__label">You don't have any <b>US A2P Brand</b> list. So you can't complete your <b>Register Campaign Use Case</b> process</span>
                                }
                            </div>
                            
                            <div className="field__content">
                                <div className="field__label">Available A2P campaign use cases</div>
                                <div className="field__input field_dropdown">
                                    <Select 
                                        value={useCase} 
                                        onChange={handleUseCaseChange}
                                        MenuProps={DropdownMenuProps}
                                        displayEmpty
                                    >
                                        <MenuItem className="menuitem-li-hover-color-with-border menuitem__li__display__block" disabled value={null}>{'Select Campaign use case'}</MenuItem>
                                        {renderUseCase()}
                                    </Select>
                                </div>
                            </div>
                                                   
                            <div className="field__content">
                                <div className="field__label">Campaign use case description   <small>Min letter count: 15, Max letter count: 255</small></div>
                                <div className="field__input">
                                    <textarea 
                                        type="text" 
                                        maxLength={255}
                                        minLength={15}
                                        value={useCaseDescription} 
                                        onChange={(e) => handleUseCaseDescriptionChange(e.target.value)} 
                                        placeholder={'Enter use case description'}
                                    >
                                        {useCaseDescription}
                                    </textarea>
                                </div>
                                <div className="campaign__registration__count__v2">Count : {useCaseDescription.length}</div>
                                <span className="extra__bottom__label">Describe what you will be using this campaign use case for.</span>
                            </div>
                                            
                            <div className="field__content">
                                <div className="field__label">Sample message #1   <small>Min letter count: 20, Max letter count: 255</small></div>
                                <div className="field__input">
                                    <textarea 
                                        type="text"
                                        minLength={20}
                                        maxLength={255}
                                        value={sampleMessage1} 
                                        onChange={(e) => setSampleMessage1(e.target.value)} 
                                        placeholder={'Enter sample message #1'}
                                    >
                                        {sampleMessage1}
                                    </textarea>
                                </div>
                                <span className="extra__bottom__label">Provide an example of a message that you will be sending with this campaign use case. This message sample will be used by carriers to help identify your traffic.</span>
                            </div>
                                            
                            <div className="field__content">
                                <div className="field__label">Sample message #2   <small>Min letter count: 20, Max letter count: 255</small></div>
                                <div className="field__input">
                                    <textarea 
                                        type="text" 
                                        value={sampleMessage2} 
                                        minLength={20}
                                        maxLength={255}
                                        onChange={(e) => setSampleMessage2(e.target.value)} 
                                        placeholder={'Enter sample message #1'}
                                    >
                                        {sampleMessage2}
                                    </textarea>
                                </div>
                                <span className="extra__bottom__label">Provide an example of a message that you will be sending with this campaign use case. This message sample will be used by carriers to help identify your traffic.</span>
                            </div>
                                            
                            <div className="field__content">
                                <div className="field__label">Message contents</div>
                                <div className="step__4__checkbox">
                                    <div className="field__input__checkbox">
                                        {hasIncludeEmbeddedLink ? 
                                            <span onClick={() => setHasIncludeEmbeddedLink(false)}><CheckBoxIcon fontSize="small" /></span>
                                        :
                                            <span onClick={() => setHasIncludeEmbeddedLink(true)}><CheckBoxOutlineBlankIcon fontSize="small" /></span>
                                        }
                                        <span>Messages will include embedded links.</span>
                                    </div>
                                    <div className="field__input__checkbox">
                                        {hasIncludeNumber ? 
                                            <span onClick={() => setHasIncludeNumber(false)}><CheckBoxIcon fontSize="small" /></span>
                                        :
                                            <span onClick={() => setHasIncludeNumber(true)}><CheckBoxOutlineBlankIcon fontSize="small" /></span>
                                        }
                                        <span>Messages will include phone numbers.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }              
                    {renderButton()}
                </div>
               
            </div>
        </div>
    )
}
export default CampaignRegistration;