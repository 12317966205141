import React, {useEffect, useState} from "react";
import RequestUrlField from "../RequestUrlField";
import {tab} from "../../constants";
import ParamsTab from "../ParamsTab";
import AuthorizationTab from "../AuthorizationTab";
import HeadersTab from "../HeadersTab";
import BodyTab from "../BodyTab";
import Styles from "./Style.module.css";

const App = ({callback,data,errors}) => {
    const [title, setTitle] = useState("");
    const [currentTab, setCurrentTab] = useState(tab.PARAMS_TAB);
    const height = window.innerHeight - 668 / 2;

    useEffect(()=>{
        if (data){
            setTitle(data.title);
        }
    },[data]);

    return(
        <div className={`${Styles.wbh_main_wrapper} wbh_main_wrapper`}>
            <div className={Styles.wbh_header}>
                <div className={Styles.wbh_title_wrapper}>
                    <input name="title" value={title} onChange={(e)=>{
                        setTitle(e.target.value);
                        callback({
                           title: e.target.value
                        });
                    }} placeholder="Title"/>
                    {
                        errors && errors.title &&
                        <span className={Styles.error_message}>
                            {errors.title}
                        </span>
                    }
                </div>
                <RequestUrlField callback={callback} data={data} errors={errors}/>
            </div>
            <div className={Styles.wbh_payload_handler}>
                <ul className={Styles.wbh_payload_option}>
                    <li className={(currentTab === tab.PARAMS_TAB ? `${Styles.active_tab} `: "")+Styles.wbh_payload_item} onClick={()=>{setCurrentTab(tab.PARAMS_TAB)}}>Params</li>
                    <li className={(currentTab === tab.AUTHORIZATION_TAB ? `${Styles.active_tab} `: "")+Styles.wbh_payload_item} onClick={()=>{setCurrentTab(tab.AUTHORIZATION_TAB)}}>Authorization</li>
                    <li className={(currentTab === tab.HEADERS_TAB ? `${Styles.active_tab} `: "")+Styles.wbh_payload_item} onClick={()=>{setCurrentTab(tab.HEADERS_TAB)}}>Headers</li>
                    <li className={(currentTab === tab.BODY_TAB ? `${Styles.active_tab} `: "")+Styles.wbh_payload_item} onClick={()=>{setCurrentTab(tab.BODY_TAB)}}>Body</li>
                </ul>
            </div>
            <div className={Styles.wbh_payload_handler_body} style={{ height: `calc(100vh - ${height}px)`}} >
                {
                    currentTab === tab.PARAMS_TAB && (<ParamsTab callback={callback} data={data}/>)
                }

                {
                    currentTab === tab.AUTHORIZATION_TAB && (<AuthorizationTab callback={callback} data={data}/>)
                }

                {
                    currentTab === tab.HEADERS_TAB && (<HeadersTab callback={callback}/>)
                }

                {
                    currentTab === tab.BODY_TAB && (<BodyTab callback={callback}/>)
                }
            </div>
        </div>
    );
};

export default App;