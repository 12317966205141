/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import { useEffect, useState } from "react";

const LETTERSETUP = {
    ["trifold8.5x11"] : {
        pages : ['Front','Back'],
        totalPages : 2,
    },
    ["2pgtrifold8.5x11"] : {
        pages : ['Page 1 Front','Page 1 Back','Page 2 Front','Page 2 Back'],
        totalPages : 4,
    },
    ["8.5x11newsletter4pg"] : {
        spreads : ['Cover','Spread 1'],
        pages : { 0 : ['Back','Front'],1 : ['Page 1','Page 2']},
        totalPages : 4,
    },
    ["8.5x11newsletter8pg"] : {
        spreads : ['Cover','Spread 1','Spread 2','Spread 3'],
        pages : { 0 : ['Back','Front'],1 : ['Page 1','Page 2'], 2 : ['Page 3','Page 4'],3 : ['Page 5','Page 6']},
        totalPages : 8,
    },
}

const LetterPageButtons = (props) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [currentSpread, setCurrentSpread] = useState(0);

    useEffect(() => {
        props.setPage(currentPage);
    },[currentPage]);

    useEffect(() => {
        setCurrentPage(currentSpread*2);
    },[currentSpread]);


    const getPageSelectButtons = () => {
        let setup = LETTERSETUP[props.selectedCardSize];
        if(Array.isArray(setup.pages)){
            let buttons = setup.pages.map((item,index) => {
                return <button className={'btn btn-sm btn-primary letter-button mr-1' + (currentPage == index?' active':'')} onClick={(e) => {e.preventDefault();setCurrentPage(index)}}>{item}</button>;
            })
            return <>
                    <div className="form-group">
                        <label htmlFor="">Select Page :</label><br/>
                        {buttons}
                    </div>
                </>
        }

        let spreads = setup.spreads.map((item,index) => {
            return <button className={'btn btn-sm btn-primary letter-button mr-1' + (currentSpread == index?' active':'')} onClick={(e) => {e.preventDefault();setCurrentSpread(index)}}>{item}</button>;
        });

        let buttons = setup.pages[currentSpread].map((item,index) => {
            return <button className={'btn btn-sm btn-primary letter-button mr-1' + (currentPage == (currentSpread*2+index)?' active':'')} onClick={(e) => {e.preventDefault();setCurrentPage(currentSpread*2+index)}}>{item}</button>;
        });

        return <>
                <div className="form-group">
                    <label htmlFor="">Select Spread :</label><br/>
                    {spreads}
                </div>
                <div className="form-group">
                    <label htmlFor="">Select Page :</label><br/>
                    {buttons}
                </div>

            </>

    }
    
    return ( getPageSelectButtons() );
}
 
export default LetterPageButtons;