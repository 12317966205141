import React from 'react';
import Styles from "./SmartWebhookDrawer.module.css";
import SmartWebhookBuilder from "./SmartWebhookBuilder";
import CustomDrawerPype from "./components/CustomDrawer/CustomDrawerPype";
import "./StyleOverwrite.css";

const SmartWebhookDrawer = ({ open, onClose, onSubmit, data=null, isSubmitting=false }) => {

  return (
      <CustomDrawerPype
          isShow={open}
          hanldeDrawer={onClose}
          wrapperClass="wbh_drawer_container"
          customWidthClass={Styles.wbh_drawer_width_1200}
          drawerClassName="whb__drawer"
          renderChild={() => (
              open &&
              <SmartWebhookBuilder
                  onClose={onClose}
                  onSubmit={onSubmit}
                  data={data}
                  isSubmitting={isSubmitting}
              />
          )}
          anchor="right"
      />
  );
};

export default SmartWebhookDrawer;