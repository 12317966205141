/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import NewCampaignPopup from "../NewCampaignPopup";


export const EmptyPage = () => {

    const [openAddNewCampaignModal, setOpenAddNewCampaignModal] = useState(false);

    const handleAddNewCampaignModal = (status) => {
        setOpenAddNewCampaignModal(status);
    };

    return(
        <div className="col s12 m12 l12 xl12 mt-5">
            <div className="mt-0 card message-info-details b-radius-10 box-shadow-none">
                    <div className="chatbox-area chatbox-empty" id="cutomScrollbar2">
                        <div className="row chatbox-row">
                            <div className="col s6 chatbox-col">
                                <div className="no-data-found">
                                    <img className=" responsive-img" src={`${process.env.REACT_APP_APP_URL}/assets/images/no-data-found.svg`}
                                        alt="No Data Found"/>
                                </div>
                            </div>
                            <div className="col s6 chatbox-col flex-column">
                                <h3 className="chatbox-empty-title">It's time to create your first campaign!</h3>
                                <div className="chatbox-btn new_campaign_button">
                                    <a className="modal-trigger accent--bg--text--color " onClick={() => handleAddNewCampaignModal(true)}>
                                        <span>
                                            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path className="accent--fill--color" d="M8.33611 4.00045C8.33611 4.35494 8.30534 4.64148 7.95085 4.64148H5.13098V7.46135C5.13098 7.81519 4.84444 7.8466 4.48995 7.8466C4.13547 7.8466 3.84893 7.81519 3.84893 7.46135V4.64148H1.02906C0.675209 4.64148 0.643799 4.35494 0.643799 4.00045C0.643799 3.64596 0.675209 3.35943 1.02906 3.35943H3.84893V0.539553C3.84893 0.185066 4.13547 0.154297 4.48995 0.154297C4.84444 0.154297 5.13098 0.185066 5.13098 0.539553V3.35943H7.95085C8.30534 3.35943 8.33611 3.64596 8.33611 4.00045Z"/>
                                            </svg>
                                        </span>
                                        New Campaign
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
            { openAddNewCampaignModal &&
                <NewCampaignPopup 
                    handleAddNewCampaignModal={handleAddNewCampaignModal}
                    open={openAddNewCampaignModal}
                    close={() => handleAddNewCampaignModal(false)}
                />
            }
        </div>
    )
}
