/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { getCampaignSettingContactListApi, getCampaignSettingQueueContactListApi } from "../../../../api/marketingApi";
import { IconList } from "../../../../constants/IconList";
import Helper from "../../../../helpers/Helper";
import Utils from "../../../../helpers/Utils";
import { GlobalTable } from "../../../globals/GlobalTable";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import {useHistory} from "react-router-dom";
import useDelayCallback from "../../../../hooks/useDelayCallback";
import './StatContacts.css'
import { HistoryProvider } from "../../../../App";
import GlobalSearchBox from "../../../globals/GlobalSearchBox";

const StatContacts = (props) => {    const history = useContext(HistoryProvider);
    const [contactList, setContactList] = useState([])
    const [totalContacts, setTotalContacts] = useState(0)
    const [page, setPage] = useState(1)
    const [paginationChange, setPaginationChange] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [limit, setLimit] = useState(20)
    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [searchKey, setSearchKey] = useState('')
    const [firstLanding, setFirstLanding] = useState(true)
    const [search, setSearch] = useState('')
    
    useEffect(() => {
        getStatContact()
    }, [])

    useDelayCallback(() => {
        if(firstLanding){
            return false;
        }
        if(page !== "" && parseInt(page) !== 0 && paginationChange){
            // if(parseInt(totalPage) >= parseInt(page)) {
                getStatContact();
            // }
        }
    }, [page]);

    useDelayCallback(() => {
        if(firstLanding){
            return false;
        }
        getStatContact();
    }, [search]);

    const getStatContact = () => {
        if(isLoading){
            return;
        }
       
        setIsLoading(true)
        if(props.contactType === 'queue'){
            let formData = {
                campaignSettingsId: props.campaignSettingId.toString(),
                searchKey: search,
                page: parseInt(page),
                limit: parseInt(limit)
            }
            getCampaignSettingQueueContactListApi(formData).then(response => {
                if(response.message != null) {
                    window.showNotification("Error", response.message);
                } else {
                    setContactList(response.campaignQueueContacts);
                    setTotalContacts(response.totalContacts);
                    setFirstLanding(false)
                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                setFirstLanding(false)
            })
        }
        else{
            let formData = {
                campaignSettingsId: props.campaignSettingId.toString(),
                searchKey: search,
                page: parseInt(page),
                limit: parseInt(limit),
                status: (props.contactType === 'Processed' || props.contactType === 'Sent') ? [3, 5] : [parseInt(props.contactType)],
            }
         
            getCampaignSettingContactListApi(formData).then(response => {
               
                if(response.message != null) {
                    window.showNotification("ERROR", response.message);
                } else {
                    setTotalContacts(response.totalContacts);
                    setContactList(response.campaignContacts);
                    setFirstLanding(false)
                }
               
                setIsLoading(false)
            }).catch(err => {
                
                setFirstLanding(false)
            })
        }
    }

    const handleSearch = (event) => {
        let text = event.target.value
        setSearch(text)
    }

    const onChangePage = (event, page) => {
        event.preventDefault()
        if(page != null){
            setPage(page)
            setPaginationChange(true);
        }
    }

    const handleChangeSearchPage = (pageNo) => {
        if (!Utils.checkLetterExistInText(pageNo) || pageNo === ""){
            setPaginationChange(true);
            setPage(pageNo);  
        }
    }

    const renderPagination = () => {
    
        let previous_page = null, next_page = null, totalPage = 0; 
        totalPage = Math.ceil(totalContacts / limit);
        if(page > 1){
            previous_page = page - 1
        }
    
        if(page != totalPage){
            next_page = page + 1
        }
    
        if(totalContacts == 0){
            return false;
        }
    
        return(
            <div className="pagination_bar_stat_contact">
                <ul>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.previous}
                        </a>
                    </li>
                    {/* <li>

                        <a>
                            <span>{page}</span>
                        </a>
                    </li> */}
                    <li className="active">
                        <div className="stat-contact-pagination-text" >
                        <input
                            className="stat-contact-pagination-no"
                            type="text" 
                            placeholder="Search Contact Page"
                            value={page}
                            onChange={(event)=> handleChangeSearchPage(event.target.value)}
                        />               
                        </div>
                    </li>
                    <li>
                        <a className="d-flex align-items-center"> 
                            <span>
                                {IconList.backwardSlash}
                            </span>	
                            {totalPage}
                        </a>
                    </li>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, next_page)}
                            style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.next}	
                        </a>
                    </li>
                    <li style={{color: '#9daac1', marginLeft: 20}}>Total leads : {totalContacts}</li>
                </ul>
            </div>
        )
    }

    const onClickEach = (contact_id) => {
        if(contact_id !== undefined){
            props.onClose();
            history.push('/contacts/'+contact_id);
        }
    }

    const columns = [
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Full Name</span>,
            selector: row => row['firstName'],
            sortable: false,
            cell: row => {
                let name = ''
                if(Helper.isvalidText(row.firstName) && Helper.isvalidText(row.lastName)){
                    name = row.firstName + ' ' + row.lastName
                }
                else if(Helper.isvalidText(row.firstName)){
                    name = row.firstName
                }
                else if(Helper.isvalidText(row.lastName)){
                    name = row.lastName
                }
                return <div onClick={() => onClickEach(row.contactId)}>{name}</div>;
            },
            // minWidth:'250px'
            maxWidth:'250px'
            // grow: 2,
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Email</span>,
            selector: row => {
                return <div onClick={() => onClickEach(row.contactId)}>{row['email']}</div>;
            },
            sortable: false
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Phone Number</span>,
            selector: row => {
                return <div onClick={() => onClickEach(row.contactId)}>{Utils.formatPhoneNumber(row['number'])}</div>
            },
            sortable: false
        }
    ];
    
    if(props.contactType === 'queue'){
        columns.push({
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Schedule time</span>,
            selector: row => window.globalTimezoneConversionToDifferentTimezone(row['scheduleTime'],'UTC', props.campaignTimezone, 'MM/DD/yyyy hh:mm:ss A'),
            sortable: false
        })
    }
    else if(props.contactType === 4 || props.contactType === 2){
        columns.push({
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Reason</span>,
            selector: row => row['message'],
            sortable: false,
            cell: row => {
                let message = "";
                let link = "";
                if(row['message'] !== undefined && row['message'] != null && row['message'].trim() !== ""){
                    if(row['message'].includes("Resource has been exhausted")){
                        message = "Gmail rate limit over. For more details, please visit this link. "
                        link=<a target={"_blank"} href="https://support.google.com/a/answer/166852?hl=en"> Gmail rate limit</a>;
                    }
                    else if(row['message'].includes("Campaign Is Not Running")){
                        message = "Campaign Is Not Running."
                    }
                    else if(row['message'].includes("Contact Unsubscribe Or Blocked")){
                        message = "Contact Unsubscribe Or Blocked !"
                    }
                    else if(row['message'].includes("Too many concurrent connections opened")){
                        message = "Too many concurrent connections opened. Gmail rate limit over. For more details, please visit this link. "
                        link=<a target={"_blank"} href="https://support.google.com/a/answer/166852?hl=en"> Gmail rate limit</a>;
                    }else{
                        message = row['message'];
                    }
                }
                return <div className="campaign_setting_stats__failed__message">{message} {link}</div>;
            },
        })
    }
    else{
        columns.push({
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Sent time</span>,
            selector: row => window.globalTimezoneConversionToDifferentTimezone(row['scheduleTime'],'UTC', props.campaignTimezone, 'MM/DD/yyyy hh:mm:ss A'),
            sortable: false 
        })
    }

    return (
        <GlobalModal 
            open={props.open} 
            onClose={props.onClose}
            title={props.title()} 
            className="global-medium-modal campaign-setting-stats-contact"
            hideFooter={true}
        >
            <GlobalSearchBox wrapperClass="search_button_stat_contact" type="search" autoComplete="off" name="search" id="stat_contact_search" placeholder="Search Contact" onChange={handleSearch} />
           <GlobalTable
                table_unique_id={'campaign_setting_contact_list'}
                columns={columns}
                data={contactList}
                keyField={'id'}
                noDataComponent={(
                    <span style={{padding: 10, color: 'red'}}>No contact found!</span>
                )}
                progressPending={isLoading}
                defaultSortField="email"
                selectableRows={false}
                pagination={false}
                // subHeader={false}
                // subHeaderData={{
                //     subHeaderComponent : (
                //         <div className="top_search_field d-flex align-items-center" style={{width: '100%'}}>
                //             {/* <input value={searchKey} onChange={(event) => setSearchKey(event.target.value)} className="" type="text" placeholder="Search Contact" /> */}
                //             <input value={searchKey} onChange={(event) => searchResult(event.target.value)} className="" type="text" placeholder="Search Contact" />
                //             <span>
                //                 {IconList.searchKey}
                //             </span>
                //         </div>
                //     )
                // }}
                noHeader={true}
                onRowClicked={(event) => console.log(event)}
            />
            {renderPagination()}
        </GlobalModal>
    )
}
export default StatContacts;