import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import React, { useMemo, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { removeMessagingServiceApi, updateMessagingServiceApi } from '../../api/marketingApi';
import { DropdownMenuProps } from '../../helpers/DropdownSettings';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';

const MESSAGE_SERVICE_FOR = [
    {value: 'Notify my users', title: 'Notify my users'},
    {value: 'Market my services', title: 'Market my services'},
    {value: 'Verify users', title: 'Verify users'},
    {value: 'Engage in a discussion', title: 'Engage in a discussion'},
    {value: 'Poll or service users', title: 'Poll or service users'},
    {value: 'Not listed here', title: 'Not listed here'}
];

export const MessageServiceUpdateForm = (props) => {

    const simpleValidator = useSimpleValidator();
    const [submitting, setSubmitting] = useState(false);
    const [friendlyName, setFriendlyName] = useState(props.data.messaging_service_name);
    const [serviceFor, setServiceFor] = useState(props.data.messaging_service_usage);

    const renderServiceFor = () => {
        let serviceView = [];
        MESSAGE_SERVICE_FOR.forEach((item, index) => {
            serviceView.push(
                <MenuItem 
                    className="menuitem-li-hover-color-with-border" 
                    key={index} 
                    value={item.value}
                >
                    {item.title}
                </MenuItem>
            )
        })
        return serviceView; 
    }

    const copySidToClipboard = () => {
        navigator.clipboard.writeText(props.data.messaging_service_sid)
        window.showNotification('SUCCESS','SID copied to clipboard');
    }

    const isDisabledFormButtons = useMemo(() => {
        return friendlyName === props.data.messaging_service_name &&  serviceFor === props.data.messaging_service_usage;
    },[friendlyName,serviceFor,props.data.messaging_service_name,props.data.messaging_service_usage])

    const handleReset = () => {
        setFriendlyName(props.data.messaging_service_name);
        setServiceFor(props.data.messaging_service_usage);
    }

    const handleSave = () => {
        
        if(submitting || !simpleValidator.validate()) {
            return;
        }

        setSubmitting(true);

        let updateMessageForm = {
            user_messaging_service_id : props.data.id,
            message_service_friendly_name: friendlyName,
            messaging_service_usage: serviceFor
        }

        updateMessagingServiceApi(updateMessageForm).then(res => {
            let response = res.data;
            if(response.success){
                props.onUpdate({
                    ...props.data,
                    messaging_service_name : friendlyName,
                    messaging_service_usage : serviceFor
                });
                window.showNotification('SUCCESS','Updated successfully');
            }
            else{
                window.showNotification("warning", "Something went wrong! Please try again later");
            }
            setSubmitting(false)
        }).catch(error => {
            console.log(error)
            setSubmitting(false)
        })

    }

    const handleDelete = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to remove this messaging service?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setSubmitting(true);
                        removeMessagingServiceApi({user_messaging_service_id : props.data.id}).then(res => {
                            let response = res.data;
                            if(response.success) {
                                window.showNotification('SUCCESS','Removed successfully');
                                props.onRefreshData();
                                props.onBack();
                            } else {
                                window.showNotification("warning", "Something went wrong! Please try again later");
                            }
                            setSubmitting(false)
                        }).catch(error => {
                            console.log(error)
                            setSubmitting(false)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    return (
        <div>
            <h2>Properties</h2>
            <div className="create__message__service">
                <div className="field__content">
                    <div className="field__label">Messaging Service Name</div>
                    <div className="field__input">
                        <input 
                            type="text" 
                            maxLength={20} 
                            value={friendlyName} 
                            onChange={(e) => setFriendlyName(e.target.value)} 
                            placeholder={'Enter friendly name'}
                        />
                        {simpleValidator.message('Friendly Name', friendlyName, 'required')}
                    </div>
                </div>

                <div className="field__content mt-4">
                    <div className="field__label">Select what you want to use this Messaging Service for</div>
                    <div className="field__input field_dropdown">
                        <Select 
                            value={serviceFor} 
                            onChange={e => {e.preventDefault();setServiceFor(e.target.value)}}
                            MenuProps={DropdownMenuProps}
                            displayEmpty
                        >
                            <MenuItem className="menuitem-li-hover-color-with-border" disabled value={null}>{'Select one'}</MenuItem>
                            {renderServiceFor()}
                        </Select>
                        {simpleValidator.message('Service For', serviceFor, 'required')}
                    </div>
                </div>

                <div className="field__content mt-4">
                    <div className="field__label">Messaging Service SID</div>
                    <div className="field__label ms__sid">
                        {props.data.messaging_service_sid}
                        <svg onClick={copySidToClipboard} role="img" aria-hidden="false" width="25px" height="25px" viewBox="0 0 20 20" aria-labelledby="CopyIcon-9"><title id="CopyIcon-9">copy sid</title><path fill="currentColor" fill-rule="evenodd" d="M13.469 2.5c.63 0 1.15.48 1.212 1.094l.007.125-.001 1.593h1.407c.73 0 1.331.558 1.4 1.271l.006.136v9.375c0 .776-.63 1.406-1.406 1.406H6.719c-.777 0-1.407-.63-1.407-1.406v-1.407H3.719c-.631 0-1.15-.48-1.213-1.094L2.5 13.47v-9.75c0-.631.48-1.15 1.094-1.213L3.72 2.5h9.75zm2.625 3.75H6.719a.469.469 0 00-.469.469v9.375c0 .259.21.468.469.468h9.375c.259 0 .468-.21.468-.468V6.719a.469.469 0 00-.468-.469zm-2.625-2.813h-9.75a.281.281 0 00-.274.217l-.007.065v9.75c0 .133.092.244.216.274l.065.007 1.593-.001v-7.03c0-.731.558-1.332 1.271-1.4l.136-.006 7.031-.001V3.719a.281.281 0 00-.217-.274l-.064-.007z"></path></svg>
                    </div>
                </div>
            </div>
            <div className='ms__form_buttons'>
                <button className='ms__save_button' disabled={isDisabledFormButtons} onClick={handleSave}>Save</button>
                <button className='ms__reset_button' disabled={isDisabledFormButtons} onClick={handleReset}>Reset</button>
                {
                    submitting ?
                    <span className='d-inline-block'><span>Saving</span> <CircularProgress className='ms__saving_circular'/></span> :
                    <span className='ms__delete_button' onClick={handleDelete}>Delete Messaging Service</span>
                    
                }
            </div>
        </div>
    )
}
